import React, { useContext, useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

import checkmark from "Image/check.svg";
import Timer from "components/CountDown/Timer";
import PopupGeneric from "components/PopupGeneric";
import { PopupContext } from "Context/Popup";

const PopupClaim = () => {
  const { popup, setPopup } = useContext(PopupContext);
  const [claimAddress, setClaimAddress] = useState({
    isFocused: false,
    value: "",
  });
  const [checked, setChecked] = useState(false);
  const [initialScale, setInitialScale] = useState(0);

  useEffect(() => {
    if (!popup || popup.type !== "claim") return null;

    setClaimAddress((prev) => ({
      ...prev,
      value: popup.claimAddress,
    }));
  }, [popup]);

  if (!popup || popup.type !== "claim") return null;

  // Todo: Do We need this claimAddress for disabled?
  const disabled = !checked || !claimAddress.value.match(/^0x[a-fA-F0-9]{40}$/);

  return (
    <PopupGeneric
      customScale={isMobile && claimAddress.isFocused ? 0.9 : initialScale}
      setInitialScale={setInitialScale}
    >
      <p className="box-desc">
        I confirm that I have read and comply with the{" "}
        <a href="/tsl" target="_blank" rel="noreferrer">
          Token Sale Rules.
        </a>
      </p>
      <div className="checkbox-block">
        <div
          className="checkbox"
          onClick={() => {
            // Todo: Make function not to be repeating
            setChecked((prevState) => !prevState);
          }}
        >
          {checked && <img src={checkmark} alt="checkmark" />}
        </div>
        <label
          htmlFor="check"
          className="label-check"
          onClick={() => {
            // Todo: Make function not to be repeating
            setChecked((prevState) => !prevState);
          }}
        >
          I confirm
        </label>
      </div>
      <div className="claim-1-airdrop">
        <span className="claim-title">CLAIM RBLS WITH WALLET</span>
        <input
          className="claim-input"
          value={claimAddress.value}
          onBlur={() => {
            setClaimAddress((prev) => ({
              ...prev,
              isFocused: false,
            }));
          }}
          onFocus={() => {
            setClaimAddress((prev) => ({
              ...prev,
              isFocused: true,
            }));
          }}
          onChange={(e) => {
            setClaimAddress((prev) => ({
              ...prev,
              value: e.target.value,
            }));
          }}
        />
      </div>
      <div className="claim-timer">
        Claim countdown: <Timer date={popup.timer} handleSet={() => {}}></Timer>
      </div>
      <div>
        <button
          className="claim-button left-button"
          onClick={() => {
            setPopup(null);
          }}
        >
          Cancel
        </button>
        <button
          className={`claim-button right-button${
            disabled ? " custom-disable" : ""
          }`}
          onClick={() => popup.onContinue(claimAddress.value)}
          disabled={disabled}
        >
          Continue
        </button>
      </div>
    </PopupGeneric>
  );
};

export default PopupClaim;
