import React from 'react';
import s from './styles.module.scss'
import LandsImage from '../../../../Image/IncludeLands.png'
import LandsImageTablet from '../../../../Image/includeBGTablet.png'
import LandsImageMobile from '../../../../Image/includeBGMobile.png'
import {useDimensions} from "../../../../hooks/useDimensions";

const BattleSetInclude = () => {
  const {width} = useDimensions()

  return (
    <div className={s.battleSetInclude}>
      <img src={width > 1024 ? LandsImage : width > 576 ?  LandsImageTablet : LandsImageMobile } alt="lands"/>
    </div>
  );
};

export default BattleSetInclude;