import React from 'react';
import s from "./styles.module.scss";
import {StandardLayout} from "../../components/layouts/StandardLayout";
import BattleSetTitle from "./components/BattleSetTitle/BattleSetTitle";
import BattleSetSecure from "./components/BattleSetSecure/BattleSetSecure";
import BattleSetInclude from "./components/BattleSetInclude/BattleSetInclude";
import BattleSetSubscribe from "./components/BattleSetSubscribe/BattleSetSubscribe";
import BattleSetSteps from "./components/BattleSetSteps/BattleSetSteps";
import BattleSetBuy from "./components/BattleSetBuy/BattleSetBuy";

const BattleSet = () => {
  const shortened = false // false = dev, true = qa

  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
        <div className={s.container}>
          <BattleSetTitle shortened={shortened}/>
          <BattleSetSecure shortened={shortened}/>
          <BattleSetInclude/>
          {
            shortened
              ? <BattleSetSubscribe/>
              : <>
                <BattleSetSteps/>
                <BattleSetBuy/>
              </>
          }

        </div>
      </main>
    </StandardLayout>

  );
}

export default BattleSet;