import React from 'react';
import land from "../../../../Image/nftLand.png";
import landMobile from "../../../../Image/nftLandMobile.png";
import s from './styles.module.scss'
import NftLandsBg from '../../../../Image/nftLandsBg.png'
import NftLandsBgTablet from '../../../../Image/nftLandsBgTablet.png'
import NftLandsBgMobile from '../../../../Image/nftLandsBgMobile.png'
import NftPageInfoButtonsBlock from "../NftPageInfoButtonsBlock/NftPageInfoButtonsBlock";
import {useDimensions} from "../../../../hooks/useDimensions";
import {config} from "../../../../utils/config";

const NftPageLands = () => {
  const {width} = useDimensions()

  const infoButton = [
    {title: 'Marketplace', url: config.MARKETPLACE_LINK},
    {title: 'OpenSea', url: 'https://opensea.io/collection/rebel-bots-xoilium-lands'},
    {title: 'Contract', url: 'https://polygonscan.com/address/0x577682bde6873022525948e91487abdf80f71c99'},
  ]

  return (
    <>
      <div className={s.NftLandsBgContainer}>
        <img className={s.NftLandsBg} src={width > 1024 ? NftLandsBg : width > 576 ? NftLandsBgTablet : NftLandsBgMobile} alt="nft"/>
      </div>
      <div className={`land ${s.nftPageLands}`}>
        <div className={`land-bg ${s.landBg}`} />
        <div className="container">
          <div className={`land-row ${s.landRow}`}>
            <div className={`land-text ${s.landText}`}>
              <h3 className='web1'>LANDS</h3>
              <p>
                Every player on Xoilium has a plot of Land under the protection of a
                Kingdom Lord. This land acts as your home base where you will build and
                grow your army of Fighting Bots and store precious game resources between venturing out to battle.
                <NftPageInfoButtonsBlock variant={'land'} buttons={infoButton} />
              </p>
            </div>
            <div className={`land-image ${s.landImage}`}>
              <img
                className={`js-land-image ${s.jsLandImage}`}
                src={width > 576 ? land : landMobile}
                alt="LIMITED LAND PLOTS"
                title="LIMITED LAND PLOTS"
              />
              <img
                className={`phone ${s.jsLandImage}`}
                src={width > 576 ? land : landMobile}
                alt="LIMITED LAND PLOTS"
                title="LIMITED LAND PLOTS"
              />
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default NftPageLands;