import React, {useState} from 'react';
import s from "../../page/Guilds/styles.module.scss";
import {Dropdown} from "../ui/Dropdown";
import {ReactComponent as DropdownArrow} from "../../Image/dropdownArrow.svg";
import iconWarning from "../../Image/icon-warning.svg";
import iconSuccess from "../../Image/icon-success.svg";

const RegisterDropdown = ({title, name,  width, state,  setState, isSubmitted, options, placeholder, required, hear}) => {
  const [isOpen, setIsOpen] = useState(false)

  const setGuildValue = (data) => {

    setState(data);
    setIsOpen(false);
  };

  return (
    <div className={`${s.formGroup} ${s.formGroupDropDown} ${hear ? s.formGroup__hear : ''}`}>
      <label className={s.formLabel}>
        {title} {required && <span style={{ color: "#E40B0B" }}>*</span>}
      </label>
      <div className={s.formIcon}>
        <Dropdown
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          className={s.formControl}
          isOpen={isOpen}
          name={name}
          onSelect={(item) => setGuildValue( item.title)}
          options={options}
        >
          <div
            onClick={() => setIsOpen((prev) => !prev)}
            className={`${s.dropdown} ${s.formControl}`}
          >
            {state
              ? state
              : placeholder}
          </div>
          <DropdownArrow
            className={s.down}
            onClick={() => setIsOpen((prev) => !prev)}
            style={{
              transform: `translateX(${width > 3800 ? '-30px' : '-10px'})${width > 3800 ? ' scale(2) translateY(13px)' : ''} rotate(${
                isOpen ? "180deg" : "0deg"
              })`,
            }}
          />
        </Dropdown>
        {!state && isSubmitted && (
          <>
            <img src={iconWarning} alt="warning" />
          </>
        )}
        {isSubmitted && state && (
          <img src={iconSuccess} alt="warning" />
        )}
      </div>
      {!state && isSubmitted && (
        <>
          <span className={s.formFeedback}>This is required</span>
        </>
      )}
    </div>
  );
}

export default RegisterDropdown;