import React from 'react';
import s from "../../GamingGuilds/components/GamingGuildsMain/styles.module.scss";
import OutlineButton from "../../../components/ui/OutlineButton/OutlineButton";
import RblsMainImage from "../../../Image/rblsInCircle.png";
import {config} from "../../../utils/config";

const TradingLeaderboardMain = () => {
  return (
    <div className={`${s.gamingGuildsMain} ${s.gamingGuildsMain__trading}`}>
      <img className={`${s.bannerImage} ${s.bannerImage__trading}`} src={RblsMainImage} alt="banner" />

      <h2><span className='yellow'>Trading</span> Contest</h2>

      <div className={s.textContainer}>
        <p>
          Win a share of 100,000 RBLS tokens to celebrate the launch of the Rebel Bots
          marketplace! All traders in the contest will win a share of the RBLS prize
          pool<b>*</b>, and the top 100 traders will get boosted rewards.
        </p>
        <p>
          How to win: <br/>
          <span> 1. Enter the Marketplace </span><br/>
          2. Buy and sell Xoilium Lands and Fighting Bots <br/>
          3. Finish the contest at the top of the leaderboard to earn a bigger share of the prize pool! <br/>
        </p> <br/>
        <p>
          Enter the marketplace now and trade before 30 April to win!
        </p>
      </div>
      <button className={s.rentButtonContainer}>
        <OutlineButton variant={'selfOpen'} href={config.MARKETPLACE_LINK} text={'Marketplace'} />
      </button>
    </div>
  );
}

export default TradingLeaderboardMain;