import React, {useRef} from 'react';
import HomeMain from "./components/HomeMain/HomeMain";
import s from "./styles.module.scss";
import {StandardLayout} from "../../components/layouts/StandardLayout";
import HomeGlory from "./components/HomeGlory/HomeGlory";
import HomeVideo from "./components/HomeVideo/HomeVideo";
import HomeRealm from "./components/HomeRealm/HomeRealm";
import HomeAssemble from "./components/HomeAssemble/HomeAssemble";
import HomePrepare from "./components/HomePrepare/HomePrepare";
import HomeEmailForm from "./components/HomeEmailForm/HomeEmailForm";
import HomePartners from "./components/HomePartners/HomePartners";
import {ParallaxProvider} from "react-scroll-parallax";
// import HomeTwitter from "./components/HomeTwitter/HomeTwitter";

const Home = () => {

  const homeVideoRef = useRef(null);

  return (
      <StandardLayout isHeaderForced={true}>
        <main className={s.background}>
          <ParallaxProvider>

            <HomeMain homeVideoRef={homeVideoRef} />
            <HomeVideo homeVideoRef={homeVideoRef} />
            <HomeGlory />
            <HomeRealm />
            <HomeAssemble />
            <HomePrepare />
            <HomeEmailForm />
            <HomePartners />
            {/*<HomeTwitter />*/}

          </ParallaxProvider>


        </main>
      </StandardLayout>

  );
}

export default Home;