import React, {
  useState,
  useEffect,
  useContext,
} from "react";

import Lock from "Image/Lock.svg";
import Brand1 from "Image/brandAvacado.png";
import Brand2 from "Image/brandPathDao.png";
import Brand5 from "Image/brandJuice.png";
import Brand3 from "Image/brandPolemos.png";
import Brand4 from "Image/brandRealDeal.png";
import LandPrice from "Image/land-price.svg";
import LandPriceMobile from "Image/land-price-mobile.svg";
import boxes from "Image/boxesNft.png";
import applyGuildMirror from "Image/apply-guild-mirror.svg";
import HabitatImage2 from "Image/landSale-moreInfo-image1.png";

import HabitatImage2Mobile from "Image/landSale-moreInfo-image1Mobile.png";
import applyGuildMirrorTablet from "Image/apply-guild-mirror-tablet.svg";

import s from "./styles.module.scss";
import Footer from "components/Footer";
import Header from "components/Header";
import Timer from "components/CountDown/Timer";
import CustomButton from "components/CustomButton/CustomButton";

import { useDimensions } from "hooks/useDimensions";
import { LandSaleContext } from "Context/LandSaleWallet";
import LandsNftPageMain from "./components/LandsNftPageMain/LandsNftPageMain";
import YourLands from "./components/YourLands/YourLands";
import Lookup from "../../components/Lookup/Lookup";
import {getLandLookup} from "../../api/land";
import {useLocation} from "react-router-dom";

import timerBg from "Image/apply-guild-bg-tablet.png";

const LandsNft = () => {
  const airdropStart = window.config.LAND_SALE_PUBLIC_TS * 1000;
  // const airdropStart = +new Date(); // + 5000;

  const location = useLocation();
  const { width } = useDimensions();
  const {
    account,
    connect,
    landIds,
    commify,
    balance,
    totalAmount,
    MAX_LAND_SUPPLY,
    landSaleOpened,
    setLandSaleOpened,
    landSaleEnded,
    setLandSaleEnded,
    kingdomLands,
    isCorrectNetwork
  } = useContext(LandSaleContext);

  const initialSpecificLand = {
    count: 0,
    total: totalAmount(0),
    isMax: false,
  };

  const initialRandomLand = {
    count: 1,
    total: totalAmount(1),
    isMax: false,
  };

  // const initialSearch = {
  //   value: "",
  //   searchValue: "",
  //   searchData: {},
  //   isLoading: undefined,
  // };

  const [handleAirdrop, setHandleAirdrop] = useState();
  const [specificLands, setSpecificLands] = useState(initialSpecificLand);
  const [randomLands, setRandomLands] = useState(initialRandomLand);
  // const [, setOptions] = useState([]);
  // const [, setIsOpenSearch] = useState(false);
  // const [search, setSearch] = useState(initialSearch);

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (landIds === MAX_LAND_SUPPLY) {
      setLandSaleOpened(false);
      setLandSaleEnded(true);
      setHandleAirdrop(true);
    } else if (typeof handleAirdrop !== "undefined" && !handleAirdrop) {
      setLandSaleOpened(true);
      setLandSaleEnded(false);
    } else {
      setLandSaleOpened(false);
      setLandSaleEnded(false);
    }
  }, [handleAirdrop, landIds]);

  // const onSearch = useDebounce((value) => {
  //   if (value.length >= 1) {
  //     getBotsSearch(value)
  //       .then((data) => {
  //         setSearch((prev) => ({
  //           ...prev,
  //           isLoading: false,
  //           searchData: data,
  //         }));
  //       })
  //       .catch(() => {
  //         setSearch((prev) => ({
  //           ...prev,
  //           isLoading: false,
  //           searchData: {},
  //         }));
  //       });
  //   } else {
  //     setSearch((prev) => ({
  //       ...prev,
  //       searchData: {
  //         ...prev.searchData,
  //         content: [],
  //       },
  //     }));
  //   }
  // }, 500);
  //
  // const initialSearchOptions = useMemo(() => {
  //   return search.searchData?.content?.map(({ id, code }) => ({
  //     id,
  //     title: `${code} / #${id}`,
  //     onClick: async () => {
  //       setSpecificLands(initialSpecificLand)
  //       getKingdomLands(id);
  //       setIsOpenSearch(false);
  //       setSearch((prev) => ({
  //         ...prev,
  //         value: `${code} / #${id}`,
  //       }));
  //     },
  //   }));
  // }, [search.searchData]);
  //
  // useEffect(() => {
  //   setOptions(initialSearchOptions);
  // }, [search.searchData]);
  //
  // useEffect(() => {
  //   onSearch(search.searchValue);
  // }, [search.searchValue]);

  useEffect(() => {
    if (!specificLands.isMax || !randomLands.isMax) return;
    if (MAX_LAND_SUPPLY - landIds < 100) {
      if (specificLands.count <= 100) {
        setSpecificLands({
          count: MAX_LAND_SUPPLY - landIds,
          total: totalAmount(MAX_LAND_SUPPLY - landIds),
        });
      }
      if (randomLands.count <= 100) {
        setRandomLands({
          count: MAX_LAND_SUPPLY - landIds,
          total: totalAmount(MAX_LAND_SUPPLY - landIds),
        });
      }
    }
  }, [landIds, specificLands.isMax]);

  console.log({ balance });


  // const changeRandomLands = (step) => {
  //   if (
  //     randomLands.count + step >= 1 &&
  //     randomLands.count + step <= 100 &&
  //     randomLands.count + step <= MAX_LAND_SUPPLY - landIds
  //   ) {
  //     setRandomLands((prev) => ({
  //       count: prev.count + step,
  //       total: totalAmount(prev.count + step),
  //     }));
  //   }
  // };


  useEffect(() => {
    if (!kingdomLands.isLoading && !!kingdomLands.lands.length) {
      setSpecificLands({
        count: 1,
        total: totalAmount(1),
      });
    }
  }, [kingdomLands.isLoading]);

  return (
    <>
      <Header
        //use this one for disabling link
        // isSoldOut={landIds === MAX_LAND_SUPPLY}
        isSoldOut={false}
      />

      <main className="main">
        <div className={s.background}>
          <LandsNftPageMain />
          {/*<YourLands account={'0xCb943129A414bf8017f0D356dac25a4da54CA620948'}/>*/}
          {(isCorrectNetwork && account) ? <YourLands account={account}/>
            :
            <>
              <div
                className={`${s.timer} ${ s.connectWalet }`}
              >
                <>
                  <h3>
                    Check your lands
                  </h3>
                  <p>
                    {(isCorrectNetwork && account)
                      ? "Open for everyone"
                      : "Connect your wallet to proceed."}
                  </p>
                  <CustomButton
                    className={s.formControl}
                    form="application"
                    label="Connect wallet"
                    callback={() => connect(false, location.pathname)}
                    connect={true}
                    texture={
                      width < 576
                        ? "btn-apply-guild-mobile.svg"
                        : width < 1024
                          ? "btn-apply-guild-tablet.svg"
                          : "btn-apply-guild.svg"
                    }
                  />
                </>
              </div>
            </>
          }

          <Lookup type={'Lands'} hasFactoryCodeSearch={false} assetFetcher={getLandLookup} padding={'150px'} />
          {landIds >= 0 && (
            <div className={s.landsSoldContainer}>
              <div className={s.landsSold}>
                <h3 style={{textAlign: 'left'}}>Lands Sold</h3>
                <div className={s.progressBarContainer}>
                  <div className={s.progressBar}>
                    <div
                      className={s.progressBarArrows}
                      data-percentage="50%"
                    >
                      {width > 678 && <div
                        className={s.gradient}
                        style={{
                          backgroundSize: `${width}px 100%`,
                          width: `${(landIds / MAX_LAND_SUPPLY) * 100}%`,
                        }}
                      />}
                      {width < 678 && <div
                        className={s.gradient}
                        style={{
                          backgroundSize: `${width}px 100%`,
                          width: `${(landIds / MAX_LAND_SUPPLY) * 100}%`,
                        }}
                      />
                      }
                      {width > 678 && <span>
                         {commify(landIds)}/{commify(MAX_LAND_SUPPLY)}
                            </span>}
                      {width < 678 && <span>
                          {commify(landIds)}/{commify(MAX_LAND_SUPPLY)}
                            </span>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}



          <div
            className={`${s.applyGuild} ${
              account && width < 670 ? s.applyGuild_account : ""
            }`}
          >
            {/* {typeof handleAirdrop !== "undefined" && !handleAirdrop ? ( */}
            <h3>join these guilds {width < 1024 && <br/>}on xoilium</h3>
            <div className={s.guildBrands}>
              <div className={s.guildBrandsLogos}>
                <img src={Brand1} alt="brand"/>
                {width > 1024 && <img src={Brand4} alt="brand"/>}
                {width < 670 && <img src={Brand4} alt="brand"/>}
                {width > 1024 && <img src={Brand5} alt="brand"/>}
                <img src={Brand3} alt="brand"/>
                {width < 670 && <img src={Brand5} alt="brand"/>}
                <img src={Brand2} alt="brand"/>
              </div>
              <div className={s.guildBrandsOhters}>
                +360 <br/> others
              </div>
            </div>
          </div>
          <div
            className={`${s.timer} ${landSaleOpened ? s.connectWalet : ""} ${
              landSaleEnded ? s.landSaleEnded : ""
            }`}
            style={width < 1024 ? { backgroundImage: `url(${timerBg})`, backgroundPositionX: '26%' } : {}}
          >
            {!landSaleOpened && !landSaleEnded && (
              <>
                <img
                  src={width < 1024 ? applyGuildMirrorTablet : applyGuildMirror}
                  alt="mirror"
                />
                <img src={Lock} alt="lock" />
                <h3>public land sale:</h3>
                <Timer
                  date={airdropStart}
                  handleSet={setHandleAirdrop}
                  showColumn={false}
                />
                <p>Build. Battle. Win.</p>
              </>
            )}
            {landSaleOpened && !landSaleEnded && (
              <>
                <h3>
                  Get in the <span>game</span>
                </h3>
                <p>
                  {(isCorrectNetwork && account)
                    ? "Build. Battle. Win."
                    : "Build. Battle. Win."}
                </p>
                {(isCorrectNetwork && account) ? (
                  <CustomButton
                    className={s.formControl}
                    form="application"
                    label="Buy Lands"
                    callback={() => window.open('https://opensea.io/collection/rebel-bots-xoilium-lands', '_blank')}
                    texture={
                      width < 576
                        ? "btn-apply-guild-mobile.svg"
                        : width < 1024
                          ? "btn-apply-guild-tablet.svg"
                          : "btn-apply-guild.svg"
                    }
                  />
                ) : (
                  <CustomButton
                    className={s.formControl}
                    form="application"
                    label="Buy Lands"
                    callback={() => window.open('https://opensea.io/collection/rebel-bots-xoilium-lands', '_blank')}
                    texture={
                      width < 576
                        ? "btn-apply-guild-mobile.svg"
                        : width < 1024
                          ? "btn-apply-guild-tablet.svg"
                          : "btn-apply-guild.svg"
                    }
                  />
                )}
              </>
            )}
            {landSaleEnded && (
              <h3>
                public land sale {width < 1024 && <br />} <span>ended</span>
              </h3>
            )}
          </div>
          <div className={s.moreInfo} >
            <h3 style={{textAlign: width < 670 ? 'left' : 'inherit'}}>
              more info {width < 670 && <br />} about {width > 670 && <br />}
              xoilium {width < 670 && <br />}
              <span>lands</span>
            </h3>
            <div className={s.moreInfoText}>
              <p style={{ marginBottom: "2rem" }}>
                There are 150,000 lands on Xoilium. Each land comes with a team
                of 3 fighting bot NFTs who will be yours to control on the
                battlefield in Xoil Wars. Each land is contained within one of
                the 10,000 kingdoms governed by a Rebel Bot holder and contained
                in one of the six environments on planet Xoilium. Every player
                must hold a land in order to play and earn the precious Xoil.
              </p>
              <p>
                On your land you will be able to create and upgrade your base,
                build and train your fighting bot soldiers, store game and
                crypto resources, and communicate with your fellow kingdom
                players.
              </p>
            </div>
            <div className={s.images}>
              <img
                src={width < 670 ? HabitatImage2Mobile : HabitatImage2}
                alt="habitat-2"
              />{" "}
              {width > 680 ? (
                <img src={LandPrice} alt="land-price" />
              ) : (
                <img src={LandPriceMobile} alt="land-price" />
              )}
              {width > 1224 ? <span>+</span> : ""}
              <div>
                {width < 1224 ? "+" : ""}
                <img style={{marginTop: width > 1224 ? '300px' : '0'}} src={boxes} alt="boxes" />
              </div>
            </div>
          </div>
          <div className="faq land-sale">
            <div className="faq-left" />
            <div className="faq-right" />
            <div className="faq-container">
              <div className="faq-title">
                <h1>
                  <span style={{ color: "#FFC328" }}>Land Sale</span> FAQ
                </h1>
              </div>
              <div className="faq-list">
                <div className="faq-list__item js-faq-item">
                  <div className="faq-list__item-top js-faq-item-top">
                    <div className="faq-list__item-question">
                      What is land used for in the game?
                    </div>
                    <svg viewBox="0 0 35 30">
                      <path
                        className="fill"
                        d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                        strokeWidth="0.646162"
                      />
                      <path
                        className="stroke"
                        d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                      />
                    </svg>
                  </div>
                  <div className="faq-list__item-answer js-faq-item-answer">
                    <p>
                      Lands are one of two NFTs types required for players who
                      wish to play Xoil Wars. In lands players will be able to
                      build their base and evolve their army of fighting bots.
                    </p>
                    <p>
                      The number of lands on Xoilium is limited and without
                      owning a land, players will not be able to enter the game.
                    </p>
                    <p>
                      Players owning lands on Xoilium will also be able to use
                      them in other future games released in the Rebel Bots
                      universe.
                    </p>
                  </div>
                </div>
                <div className="faq-list__item js-faq-item">
                  <div className="faq-list__item-top js-faq-item-top">
                    <div className="faq-list__item-question">
                      How can I play and earn in Xoil Wars?
                    </div>
                    <svg viewBox="0 0 35 30">
                      <path
                        className="fill"
                        d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                        strokeWidth="0.646162"
                      />
                      <path
                        className="stroke"
                        d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                      />
                    </svg>
                  </div>
                  <div className="faq-list__item-answer js-faq-item-answer">
                    <p>
                      Players owning land will be able to enter the game and
                      participate in both adventure and multiplayer battles with
                      your Fighting Bot NFTs, which are the second type of NFT
                      players require.
                    </p>
                    <p>
                      Each win in battle will grant the player both crypto
                      rewards and game resources which can be used to build new
                      fighting bots These fighting bots can be used to progress
                      in leagues or can be sold on the open marketplace.
                    </p>
                    <p>
                      For more details read Xoil Wars{" "}
                      <a href="/whitepaper" target={"_blank"} rel="noreferrer">
                        whitepaper
                      </a>
                    </p>
                  </div>
                </div>
                <div className="faq-list__item js-faq-item">
                  <div className="faq-list__item-top js-faq-item-top">
                    <div className="faq-list__item-question">
                      Does it matter which kingdom my land is?
                    </div>
                    <svg viewBox="0 0 35 30">
                      <path
                        className="fill"
                        d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                        strokeWidth="0.646162"
                      />
                      <path
                        className="stroke"
                        d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                      />
                    </svg>
                  </div>
                  <div className="faq-list__item-answer js-faq-item-answer">
                    <p>
                      Each land is located in a different kingdom which will
                      initially have 10-26 players.
                    </p>
                    <p>
                      Players can work together with their fellow kingdom
                      members in order to rank higher in the precious kingdom vs
                      kingdom leaderboard which grants huge rewards at the end
                      of every season, so make sure your kingdom members are
                      here to fight.
                    </p>
                    <p>
                      Larger kingdoms will have larger prize pools for the
                      individual kingdom leaderboard, but you will also need to
                      compete against more players to win compared to smaller
                      kingdoms.
                    </p>
                  </div>
                </div>
                <div className="faq-list__item js-faq-item">
                  <div className="faq-list__item-top js-faq-item-top">
                    <div className="faq-list__item-question">
                      What is the difference between the different areas on
                      Xoilium?
                    </div>
                    <svg viewBox="0 0 35 30">
                      <path
                        className="fill"
                        d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                        strokeWidth="0.646162"
                      />
                      <path
                        className="stroke"
                        d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                      />
                    </svg>
                  </div>
                  <div className="faq-list__item-answer js-faq-item-answer">
                    <p>
                      The Rebel Bots are still investigating the different
                      environment areas on Xoilium and while for now they all
                      seem to be the same it might not always stay this way.
                    </p>
                  </div>
                </div>
                <div className="faq-list__item js-faq-item">
                  <div className="faq-list__item-top js-faq-item-top">
                    <div className="faq-list__item-question">
                      Can you sell/transfer lands before game launch?
                    </div>
                    <svg viewBox="0 0 35 30">
                      <path
                        className="fill"
                        d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                        strokeWidth="0.646162"
                      />
                      <path
                        className="stroke"
                        d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                      />
                    </svg>
                  </div>
                  <div className="faq-list__item-answer js-faq-item-answer">
                    <p>
                      Yes, similar to other NFTs, Xoilium lands can be
                      transferred to other wallets or traded on leading
                      marketplaces like Opensea and others.
                    </p>
                  </div>
                </div>
                <div className="faq-list__item js-faq-item">
                  <div className="faq-list__item-top js-faq-item-top">
                    <div className="faq-list__item-question">
                      I bought land, when will I receive my 3 fighting bots?
                    </div>
                    <svg viewBox="0 0 35 30">
                      <path
                        className="fill"
                        d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                        strokeWidth="0.646162"
                      />
                      <path
                        className="stroke"
                        d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                      />
                    </svg>
                  </div>
                  <div className="faq-list__item-answer js-faq-item-answer">
                    <p>
                      Lands will be transferred to the purchaser's wallet upon
                      completing a successful transaction in the land sale.
                    </p>
                    <p>
                      The three random fighting bot NFTs that are added to each
                      purchase will only be claimable when the game is launched
                      and only by the wallet that holds the purchased land.
                    </p>
                  </div>
                </div>
                <div className="faq-list__item js-faq-item">
                  <div className="faq-list__item-top js-faq-item-top">
                    <div className="faq-list__item-question">
                      How much land can I buy?
                    </div>
                    <svg viewBox="0 0 35 30">
                      <path
                        className="fill"
                        d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                        strokeWidth="0.646162"
                      />
                      <path
                        className="stroke"
                        d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                      />
                    </svg>
                  </div>
                  <div className="faq-list__item-answer js-faq-item-answer">
                    <p>
                      There is no limit on the number of lands each wallet can
                      purchase.
                    </p>
                  </div>
                </div>
                <div className="faq-list__item js-faq-item">
                  <div className="faq-list__item-top js-faq-item-top">
                    <div className="faq-list__item-question">
                      My friends and I would like to buy land in the same
                      kingdom. How can we do that?
                    </div>
                    <svg viewBox="0 0 35 30">
                      <path
                        className="fill"
                        d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                        strokeWidth="0.646162"
                      />
                      <path
                        className="stroke"
                        d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                      />
                    </svg>
                  </div>
                  <div className="faq-list__item-answer js-faq-item-answer">
                    <p>
                      Choose a specific kingdom which has enough available lands
                      and complete the purchase by entering the kingdom’s ID
                      number.
                    </p>
                    <p>
                      If you are not sure which kingdom to buy in, have the
                      first person select a random kingdom, and then use the
                      same kingdom ID number for the other purchases.
                    </p>
                  </div>
                </div>
                <div className="faq-list__item js-faq-item">
                  <div className="faq-list__item-top js-faq-item-top">
                    <div className="faq-list__item-question">
                      Which Blockchain network are the lands on?
                    </div>
                    <svg viewBox="0 0 35 30">
                      <path
                        className="fill"
                        d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                        strokeWidth="0.646162"
                      />
                      <path
                        className="stroke"
                        d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                      />
                    </svg>
                  </div>
                  <div className="faq-list__item-answer js-faq-item-answer">
                    <p>
                      Apart from the Rebel Bots NFTs which are on the Ethereum
                      network all other NFTs and tokens related to the Xoil Wars
                      game are on the Polygon Network including Land NFTs and
                      Fighting Bot NFTs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default LandsNft;
