import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import React from 'react'
import GuildRow from "./GuildRow/GuildRow";
import s from './styles.module.scss';
import InfoIcon from '../../Image/info_more_icon.svg'
import {colorizedSizeGuild, tooltipFactory} from "../../utils/asset.utils";
import {getGamingGuilds} from "../../api/guilds";
import Lookup from "../Lookup/Lookup";
import {GTableSizes, search, setId, sortBySize} from "./GTableHelpers";
import GuildsArrows from '../../Image/guildsArrows.svg'

const GTable = () => {
    const [guilds, setGuilds] = useState([])
    const [page, setPage] = useState(1)
    const [tipPage, setTipPage] = useState(0);
    const [changedGuilds, setChangeGuild] = useState([])


    useEffect(() => {
        getAllGuilds()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getAllGuilds = useCallback(() => {
        getGamingGuilds().then(res => {
            const data =
              sortBySize(res.data.map(el => ({...el, sizeInNumber: el.featured ? GTableSizes[el.size] + 10 : GTableSizes[el.size]  || 0 }) ))
              .reverse()

            setGuilds(data)
            setChangeGuild(data )
        })

    }, [])


    const paginationNumbers = useMemo(() => {
        const length = Math.ceil(changedGuilds.length / 8);
        return [...Array(length).keys()]
    }, [changedGuilds])

    const headers = useMemo(() => ['Guild name', 'Size', 'Language', 'Contact'], [])

    const paginationPage = useMemo(() => {
        const limit = {
            minPage: 0,
            maxPage: 0
        }

        if (page >= 5) {
            limit.minPage = page - 4
            limit.maxPage = page
        }

        if (paginationNumbers.length >= 4 && paginationNumbers.length - page < 4) {
            limit.minPage = paginationNumbers.length - 4
            limit.maxPage = paginationNumbers.length
        }

        return limit;

    }, [page, paginationNumbers])

    const paginationTip = (
        <div className={s.guild__pagination__tip}>
            <input type='text' className={s.guild__pagination__tip__input} value={tipPage} onChange={(val) => setTipPage(+val.target.value)} />
            <button onClick={() => setPage(tipPage)} className={s.guild__pagination__tip__btn}>OK</button>
        </div>
    )

    const searchGuilds = (value) => {
        if(!value && guilds.length){
            setChangeGuild(guilds)
            return
        }
        setPage(1)
        value && setChangeGuild(search(guilds, value))
    }

    const [reverseSizeSorting, setReverseSizeSorting] = useState(false)
    const [reversNameSorting, setReverseNameSorting] = useState(false)

    const sort = (type) =>{
        if(type === 'Guild name'){
            const sortingGuilds = guilds.sort(el => el.featured ? -1 : 1).sort(function(a,b){
                return a.name.localeCompare(b.name);
            }).sort(el => el.featured ? -1 : 1)

            if(reversNameSorting){
                setChangeGuild([...sortingGuilds].reverse().sort(el => el.featured ? -1 : 1))
                setReverseNameSorting(false)
            }else{
                setChangeGuild([...sortingGuilds].sort(el => el.featured ? -1 : 1))
                setReverseNameSorting(true)
            }
        }
        if(type === 'Size'){
            const sortingGuilds = guilds.sort(el => el.featured ? -1 : 1).sort(function(a, b) {
                return a.sizeInNumber - b.sizeInNumber;
            }).sort(el => el.featured ? -1 : 1)

            if(reverseSizeSorting){
                setChangeGuild([...sortingGuilds].reverse().sort(el => el.featured ? -1 : 1))
                setReverseSizeSorting(false)
            }else{
                setChangeGuild([...sortingGuilds].sort(el => el.featured ? -1 : 1))
                setReverseSizeSorting(true)
            }
        }
    }

    const guildsForRender = changedGuilds.length > 8
        ?  setId(changedGuilds).slice((page - 1) * 8, page * 8)
        :  setId(changedGuilds)

    return (
        <>
            <Lookup type={'Guild'} lookupPlaceholder={'Search'} hasFactoryCodeSearch searchFetcher={searchGuilds} />
            <img src={GuildsArrows} className={s.guilds_arrows} alt=""/>
            <div className={s.guild__table__wrapper}>
                <table id='guildsTable' className={s.guild__table}>
                    <TableHeaders headers={headers} sort={sort} />
                    <tbody>
                    {guildsForRender.map((el, index) => (<GuildRow  index={index}  {...el}/>))}
                    </tbody>
                </table>
            </div>
            <div className={s.guild__pagination}>
                <div className={s.guild__pagination__block}>
                    <p className={`${s.guild__pagination__number } ${s.guild__pagination__arrow}  ${page <= 1 ? s.disabledLink : ''}`}
                       onClick={page <= 1 ? null : () => setPage(prev => prev - 1)}>{'<'}</p>
                    {
                        paginationNumbers.length > 3 && page >= paginationNumbers.length - 3 && <>
                            <p className={s.guild__pagination__number} onClick={() => setPage(1)}>1</p>
                            {paginationNumbers.length >= 6 &&
                                <PaginationChooseTooltip title={paginationTip}>
                                    <p className={s.guild__pagination__number}>...</p>
                                </PaginationChooseTooltip>
                            }
                        </>
                    }
                    {
                        paginationNumbers.slice(paginationPage.minPage, paginationPage.maxPage <= 4 ? 4 : paginationPage.maxPage).map(el => (
                            <p style={{color: page === el + 1 ? '#FFC328' : '#FFF'}}
                               className={`${s.guild__pagination__number} web63`}
                               onClick={() => setPage(el + 1)}>{el + 1}</p>))
                    }
                    {
                        page < paginationNumbers.length - 3 && <>
                            {paginationNumbers.length >= 6 &&
                                <PaginationChooseTooltip title={paginationTip}>
                                    <p className={s.guild__pagination__number}>...</p>
                                </PaginationChooseTooltip>
                            }
                            <p className={s.guild__pagination__number}
                               onClick={() => setPage(paginationNumbers.length)}>{paginationNumbers.length}</p>
                        </>
                    }
                    <p className={`${s.guild__pagination__number } ${s.guild__pagination__arrow}  ${page >= paginationNumbers.length ? s.disabledLink : ''}`}
                       onClick={page >= paginationNumbers.length ? null : () => setPage(prev => prev + 1)}>{'>'}</p>
                </div>
            </div>
        </>
    )
}

const TableHeaders = ({headers, sort}) => {

    const infoBlock = (
        <div className={s.tooltip__info}>
            <h4 className={s.tooltip__info__title}>Guild sizes</h4>
            <ul className={s.tooltip__info__measureBlock}>
                <li className={s.tooltip__info__measurement}>
                    <div>
                    {colorizedSizeGuild('Platoon')}
                    </div>
                    <span>- </span>
                    <p className={s.tooltip__info__measurement__more}>  {'< 100 Battle Sets'}</p>
                </li>
                <li className={s.tooltip__info__measurement}>
                    <div>
                    {colorizedSizeGuild('Troop')}
                    </div>
                    <span>- </span>
                    <p className={s.tooltip__info__measurement__more}>  100-250 Battle Sets</p>
                </li>
                <li className={s.tooltip__info__measurement}>
                    <div>
                    {colorizedSizeGuild('Battalion')}
                    </div>
                    <span>- </span>
                    <p className={s.tooltip__info__measurement__more}>  250-500 Battle Sets</p>
                </li>
                <li className={s.tooltip__info__measurement}>
                    <div>
                    {colorizedSizeGuild('Regiment')}
                    </div>
                    <span>- </span>
                    <p className={s.tooltip__info__measurement__more}>  500-1,000 Battle Sets</p>
                </li>
                <li className={s.tooltip__info__measurement}>
                    <div>
                    {colorizedSizeGuild('Brigade')}
                    </div>
                    <span>- </span>
                    <p className={s.tooltip__info__measurement__more}>  1,000-5,000 Battle Sets</p>
                </li>
                <li className={s.tooltip__info__measurement}>
                    <div>
                    {colorizedSizeGuild('Empire')}
                    </div>
                    <span>- </span>
                    <p className={s.tooltip__info__measurement__more}>  5,000+ Battle Sets</p>
                </li>
            </ul>
        </div>
    )


    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

    const tooltipHandler = () => {
        setTooltipIsOpen(prevState => !prevState)
    }
    const rootEl = useRef(null);

    useEffect(() => {
        const onClick = e => rootEl.current.contains(e.target) || setTooltipIsOpen(false)
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, []);

    return (
        <thead>
        <tr className={s.guild__table__header}>
            {
                headers.map((el, index) => (
                    <td datatype={el}  className={index === 0 ? `${s.guild__table__headData} ${s.guild__table__headData__text}` : s.guild__table__headData__text}>
                        {el === 'Size' &&
                          <InfoTooltip
                            title={infoBlock}
                            open={tooltipIsOpen}
                            type={'guildInfo'}
                          >
                            <img ref={rootEl} onClick={ tooltipHandler } src={InfoIcon} alt='info' className={s.tooltip__info__icon}/>
                        </InfoTooltip>}
                        <span onClick={() => sort(el)}>{el}</span>
                    </td>)
                )
            }
        </tr>
        </thead>
    )
}

const PaginationChooseTooltip = tooltipFactory(true, {
    backgroundColor: '#1D1D1D',
    maxWidth: 'none',
    left: '10px',
}, {
    left: '-10px !important',
    top: '0',
    color: '#1D1D1D',
    width: '23.23px',
    height: '15.56px',
}, 'bottom')

const InfoTooltip = tooltipFactory(true, {
    backgroundColor: '#FFF',
    maxWidth: 'none',
    top: '10px',
}, {
    color: '#FFF',
    width: '29.89px',
    height: '17.63px',
    top: window.screen.width > 3000 ? '8px !important' : '-7px !important',
    overscrollBehavior: 'none'
}, 'bottom')

export default GTable;