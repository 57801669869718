import React from 'react';
import s from './styles.module.scss'

const PlayRegister = () => {
  return (
    <section className={s.playRegister}>
      <h3><span className='yellow'>GET YOUR</span>  BATTLE SET</h3>
      <p>Get your battle set and prepare to dominate! Master your strategy, gain an advantage over the competition, and get ready to play and earn.</p>
    </section>
  );
}

export default PlayRegister;