import { api } from ".";

export const getFeaturedBots = async () => {
  try {
    const { data } = await api.get("/stories/featured-list");
    return data;
  } catch (e) {}
};

export const getBotsSearch = async (value) => {
  try {
    const { data } = await api.get("/rb/", {
      params: {
        regex: value,
      },
    });
    return data;
  } catch (e) {}
};

export const getBotById = async (id) => {
  try {
    const { data } = await api.get(`/stories/bot/${id}`);
    const botDescTrimmed =
      data.botDesc && data.botDesc.replace("<p></p>\n", "").trim();

    const ownerDescTrimmed =
      data.ownerDesc && data.ownerDesc.replace("<p></p>\n", "").trim();

    return {
      ...data,
      botDesc: botDescTrimmed,
      ownerDesc: ownerDescTrimmed || data.owner,
    };
  } catch (e) {}
};

export const getNonceByAddress = async (account) => {
  try {
    const { data } = await api.get("/nonce", { params: { address: account } });
    return data;
  } catch (e) {}
};

export const putBotById = async ({
  id,
  address,
  sign,
  bot: { name, botDesc, ownerDesc },
}) => {
  try {
    console.log({ botDesc, ownerDesc, name, id, address })
    return await api.put(`/stories/bot/${id}`, {
      address,
      sign,
      bot: {
        name,
        botDesc,
        ownerDesc,
      },
    });
  } catch (err) {
    return { status: err.response.status };
  }
};

export const updateRebelBot = async (data) => {
  const {id, botInfo, address, sign} = data;
  const {name, kingdomName, botDesc} = botInfo;
  try {
    return await api.put(`/rb/details/${id}`, {
      id,
      name,
      kingdomName,
      description: botDesc,
      address,
      sign
    })
  } catch (err) {
    return { status: err.response.status };
  }
}


export const getRebelBots = async (address, page) => {
  return await api.get(`/rb/details/wallet/${address}`, {
    params: {
      page
    }
  })
}

export const getRebelBotsLookup = async (id) => {
  try {
    const { data } = await api.get(`/rb/details/${id}`);
    return data;
  } catch (e) {}
};

export const getMyBots = async (address, page) => {
  try {
    return await api.get(`/troopers/wallet/${address}`, {
      params: {
        page
      }
    })
  } catch (e) {}
};