import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { teamData } from "db/team";

import { StandardLayout } from "components/layouts/StandardLayout";
import { Back } from "components/functional/Back";
import { TeamMember } from "components/TeamMemberCard";
import CustomButton from "components/CustomButton/CustomButton";

import { ReactComponent as DownArrow } from "../../Image/downArrow.svg";
import AttackPower from "../../Image/attack-power.png";
import DefencePower from "../../Image/defence-power.png";
import SpellPower from "../../Image/spell-power.png";

import s from "./styles.module.scss";
import { useDimensions } from "hooks/useDimensions";

const TeamDetail = () => {
  const params = useParams();
  const { width } = useDimensions();
  const data = teamData[params.id - 1];
  const limit = width < 1024 ? 2 : 4;
  const [listLength, setListLength] = useState(limit);

  return (
    <StandardLayout>
      <main className={s.background}>
        <Back className={s.backButton} to={"/team"} />
        <h1>{data.name}</h1>
        <p className={s.short_desc}>{data.short_desc}</p>
        <div className={s.memberContent}>
          <img className={s.memberAvatar} src={data.img.default} alt="avatar" />
          {width < 1024 && (
            <div className={s.levels}>
              <div>
                <img src={AttackPower} alt="icon" /> <span>Attack:</span>
                <span>{data.attack_power}</span>
              </div>
              <div>
                <img src={DefencePower} alt="icon" /> <span>Defence:</span>
                <span>{data.defence_power}</span>
              </div>
              <div>
                <img src={SpellPower} alt="icon" /> <span>Spell Power:</span>
                <span>{data.spell_power}</span>
              </div>
            </div>
          )}
          <div className={s.memberInfo}>
            {width > 1024 && (
              <div className={s.levels}>
                <div>
                  <img src={AttackPower} alt="icon" /> <span>Attack:</span>
                  <span>{data.attack_power}</span>
                </div>
                <div>
                  <img src={DefencePower} alt="icon" /> <span>Defence:</span>
                  <span>{data.defence_power}</span>
                </div>
                <div>
                  <img src={SpellPower} alt="icon" /> <span>Spell Power:</span>
                  <span>{data.spell_power}</span>
                </div>
              </div>
            )}
            <div className={s.credentials}>
              <div>
                <span>Famous for:</span>
                <span>{data.famous_for}</span>
              </div>
              <div>
                <span>Extra skill:</span>
                <span>{data.extra_skill}</span>
              </div>
              <div>
                <span>Special ability:</span>
                <span>{data.special_ability}</span>
              </div>
              <div>
                <span>Beast leadership:</span>
                <span>{data.best_leadership}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={s.divider}></div>
        <div className={s.members}>
          {teamData
            .filter((i) => i.id !== parseInt(params.id))
            .slice(0, listLength)
            .map((member, key) => (
              <TeamMember
                onClick={() => setListLength(limit)}
                data={{ ...member, key }}
                key={key}
              />
            ))}
          {listLength < teamData.length && (
            <div className={s.buttonHolder}>
              <CustomButton
                className={s.formControl}
                form="application"
                key={listLength}
                label={
                  <>
                    <span>Show more</span>
                    <DownArrow />
                  </>
                }
                callback={(e) => {
                  e.target.blur();
                  e.target.classList.toggle(s.toggleClass)
                  window.setTimeout(() => {
                    setListLength(listLength + limit);
                    e.target.classList.toggle(s.toggleClass)
                  }, 100);
                }}
                texture={
                  width > 1024
                    ? "show-more-btn.svg"
                    : "show-more-btn-mobile.svg"
                }
              />
            </div>
          )}
        </div>
      </main>
    </StandardLayout>
  );
};

export default TeamDetail;
