import React, {useEffect, useState} from 'react';
import ExplorerModalWrapper from "../ExplorerModalWrapper/ExplorerModalWrapper";
import s from "./styles.module.scss";
import TwitterIcon from '../../../Image/twitterIconModal.svg'
import html2canvas from "html2canvas";
import { TwitterShareButton} from "react-share";
import {saveBotBuilder} from "../../../api/card";
import {useDimensions} from "../../../hooks/useDimensions";
import Loader from "../../ui/Loader/Loader";


const ShareBotModal = ({open, handleClose, isTeamBuilder = false}) => {
  const [botImage, setBotImage] = useState("")
  const [shareUrl, setShareUrl] = useState("")
  const {width} = useDimensions()
  const [showBot, setShowBot] = useState(false)

  const shareText = isTeamBuilder
    ? 'Check out this team of Fighting Bots I\'ve assembled, ready to battle in #XoilWars Build your dream bot here - https://rebelbots.com/cardsExplorer #rebelbots #P2E #RBArmy'
    : `Check out this fighting bot I've assembled, he is ready to battle in #XoilWars Build your dream bot here: https://rebelbots.com/cardsExplorer #rebelbots #P2E #RBArmy`

  useEffect(() => {
    html2canvas(document.querySelector('#builderWrapper'), {
      letterRendering: 1,
      allowTaint: true,
      useCORS: true,
      backgroundColor: '#1E1E1E',
      windowHeight: isTeamBuilder? 1080 : 1080,
      windowWidth: isTeamBuilder? 1920 :1420,
      width: isTeamBuilder ? 1820 : 1500,
      height: isTeamBuilder ? 1080 : 555,
      imageTimeout: 15000,
      logging: true,
      scale: 2,
      ignoreElements: function( element ) {
        if( 'botBuilderButton' === element.id || element.classList.contains( 'closeCard' )) {
          return true;
        }
      },
      onclone: function (clonedDoc) {
        if(isTeamBuilder){
          clonedDoc.getElementById('builderTeamWrapper').classList.add('share')
        }
      }
    })
      .then((canvas) => {
        canvas.style.display = 'none';
        let image = canvas.toDataURL("image/jpeg")
        if(image){
          setBotImage(image)
          if(open){
            html2canvas(document.querySelector('#builderWrapper'), {
              letterRendering: 1,
              allowTaint: true,
              useCORS: true,
              backgroundColor: '#1E1E1E',
              windowHeight: 1080,
              windowWidth: 2020,
              width: isTeamBuilder ? 1920 : 2250,
              height: isTeamBuilder ? 1080 : 900,
              imageTimeout: 15000,
              logging: true,
              scale: 2,
              ignoreElements: function( element ) {
                if( 'botBuilderButton' === element.id || element.classList.contains( 'closeCard' )) {
                  return true;
                }
              },
              onclone: function (clonedDoc) {
                if(isTeamBuilder){
                  clonedDoc.getElementById('builderTeamWrapper').classList.add('share')
                }else{
                  const bot = clonedDoc.getElementById('bot')
                  if (bot){
                    bot.style.marginLeft = '140px';
                  }
                  clonedDoc.getElementById('builderWrapper').style.borderBottom = 'none';
                  clonedDoc.getElementById('builderWrapper').style.paddingTop = '200px';
                }
              }
            })
              .then((canvasShare) => {
                canvasShare.style.display = 'none';
                let image = canvasShare.toDataURL("image/jpeg", 0.7)
                saveBotBuilder(image).then(({data}) => setShareUrl(data))
                if(open){
                  setTimeout(() => setShowBot(true), 4000)
                }
              })
          }
        }
      })

  }, [open]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ExplorerModalWrapper open={open}
                          handleClose={handleClose}
                          title={isTeamBuilder ? 'Share this battle set' :'Share this bot'}>
      <div className={`${s.wrapper} ${isTeamBuilder ? s.battleSet : ''}`}>
        <div className={s.info__mobile}>
          Share this {isTeamBuilder ? 'battle set' : 'bot'} with your friends and subscribers
        </div>
        {
          (botImage && showBot)
            ? <img className={s.bot} src={botImage} alt="bot"/>
            : <Loader />
        }
        <div className={s.shareButtons}>
          <div className={s.info}>
            Share this {isTeamBuilder ? 'Battle set' : 'bot'} with your friends and subscribers
          </div>
          {
            width >= 1224
              ?  <div className={`${s.buttonsWrapper} ${!!shareUrl ? '' : s.buttonsWrapper_block}`}>
                {/*<FacebookShareButton*/}
                {/*  url={window.location.href + '/'*/}
                {/*    + shareUrl.split('botbuilder/')[1]?.slice(0, -4)*/}
                {/*  }*/}
                {/*  title={shareText}*/}
                {/*  hashtag={'#rebelbots #P2E #RBArmy'}*/}
                {/*>*/}
                {/*  <div  className={s.button}>*/}
                {/*    {*/}
                {/*      shareUrl*/}
                {/*        ? <>*/}
                {/*          <img src={FaceBook} alt=""/>*/}
                {/*          <span> Facebook</span>*/}
                {/*        </>*/}
                {/*        : <svg className={s.spinner} viewBox="0 0 50 50">*/}
                {/*          <circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>*/}
                {/*        </svg>*/}
                {/*    }*/}


                {/*  </div>*/}
                {/*</FacebookShareButton>*/}
                <TwitterShareButton
                  url={window.location.href + '/'
                    + shareUrl.split('botbuilder/')[1]?.slice(0, -4)
                  }
                  title={shareText}
                  hashtag={'#rebelbots #P2E #RBArmy'}
                >
                  <div className={`${s.button} ${width > 1018 ? 'button-type1 black' : ''}`}>
                    {
                      (shareUrl && showBot)
                        ? <>
                          <span className="button-type1-before" />
                          <span className={`${s.label} label`}>
                            <img src={TwitterIcon} alt="twitter"/>
                          <span> Twitter</span>
                          </span>
                          <span className="button-type1-after" />
                        </>
                        : <svg className={s.spinner} viewBox="0 0 50 50">
                          {/*<circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>*/}
                        </svg>
                    }

                  </div>
                </TwitterShareButton>

              </div>
              : <div className={s.buttonsWrapper__mobile}>
                {/*<FacebookShareButton*/}
                {/*  url={window.location.href + '/'*/}
                {/*    + shareUrl.split('botbuilder/')[1]?.slice(0, -4)*/}
                {/*  }*/}
                {/*  title={shareText}*/}
                {/*  hashtag={'#rebelbots #P2E #RBArmy'}*/}
                {/*>*/}
                {/*</FacebookShareButton>*/}
                <TwitterShareButton
                  url={window.location.href + '/'
                    + shareUrl.split('botbuilder/')[1]?.slice(0, -4)
                  }
                  title={shareText}
                  hashtag={'#rebelbots #P2E #RBArmy'}
                >
                </TwitterShareButton>
              </div>

          }

        </div>
      </div>

    </ExplorerModalWrapper>
  );
}

export default ShareBotModal;