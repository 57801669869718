import React from 'react';
import {roadmapArray} from "./roadmapArray";

const Roadmap = () => {
  return (
    <div className="roadmap">
      <div className="container">
        <h2>Roadmap</h2>
        <div className='upcomingRoadmap'>Information about our upcoming roadmap.</div>
        <div className="roadmap-content">
          <div className="roadmap-list js-roadmap">
            {roadmapArray.map((el, index) => <RoadmapItem  {...el} key={el.title} index={index}/>) }
          </div>

          <div className="roadmap-tip">
            The above dates are not final and open for change, be sure
            to check our social media for updates on the roadmap
          </div>
        </div>
      </div>
    </div>
  );
}

const RoadmapItem = ({title, description, date, index, area}) => {
  const areaClasses = {
    'tools': 'blue',
    'game': '',
    'nft': 'red'
  }

  return(
    <div className={`roadmap-list__item js-roadmap-item  ${index === 9 ? 'slick-active' : ''}
     ${areaClasses[area]} ${index < 8 && 'roadmap-list__greyscale'}`}
    >
      <div className="roadmap-list__item-bg" />
      <div className="roadmap-list__item-date">
        {date}
      </div>
      <div className="roadmap-list__item-title">
        {title}
      </div>
      <div className="roadmap-list__item-text">
        {description}
      </div>
    </div>
  )
}

export default Roadmap;