import React from "react";

import Header from "components/Header";
import Footer from "components/Footer";

const Faq = () => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <main className="faq">
          <div className="faq-left" />
          <div className="faq-right" />
          <div className="faq-container">
            <div className="faq-title">
              <h1>FAQ</h1>
            </div>
            <div className="faq-list">
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="faq-list__item-question">
                    What is Xoil Wars?
                  </div>
                  <svg viewBox="0 0 35 30">
                    <path
                      className="fill"
                      d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                      strokeWidth="0.646162"
                    />
                    <path
                      className="stroke"
                      d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                    />
                  </svg>
                </div>
                <div className="faq-list__item-answer js-faq-item-answer">
                  <p>
                    Xoil Wars is the first gaming title in the Rebel Bots
                    universe. The game will be a play to earn card battle game
                    launched on both mobile and web using Unity.
                  </p>
                  <p>
                    Players will be able to build their base and team of
                    Fighting Bots. They can then play, progress and earn in a
                    single player or multiplayer game taking place in outer
                    space on planet Xoilium.
                  </p>
                  <p>
                    Read the <a href="/whitepaper">whitepaper</a> for full
                    details
                  </p>
                </div>
              </div>
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="faq-list__item-question">
                    When did the project start?
                  </div>
                  <svg viewBox="0 0 35 30">
                    <path
                      className="fill"
                      d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                      strokeWidth="0.646162"
                    />
                    <path
                      className="stroke"
                      d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                    />
                  </svg>
                </div>
                <div className="faq-list__item-answer js-faq-item-answer">
                  <p>
                    The project launched in August 2021 with the minting of the
                    Rebel Bots NFT collection (available on{" "}
                    <a
                      href="https://opensea.io/collection/rebelbots"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Opensea
                    </a>
                    ).
                  </p>
                  <p>
                    The founders who are game development experts then set about
                    designing a Play to Earn game which builds on the Rebel Bots
                    NFT collection and set to bring NFT games to a whole new
                    level.
                  </p>
                </div>
              </div>
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="faq-list__item-question">
                    Who is the team behind Rebel Bots?
                  </div>
                  <svg viewBox="0 0 35 30">
                    <path
                      className="fill"
                      d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                      strokeWidth="0.646162"
                    />
                    <path
                      className="stroke"
                      d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                    />
                  </svg>
                </div>
                <div className="faq-list__item-answer js-faq-item-answer">
                  <p>
                    The team has 2 main offices in Israel and Kiev, the founders
                    and core team members come from careers in developing highly
                    successful mobile games and believe NFTs are the future of
                    gaming. They have decades of experience designing,
                    developing and delivering social games for companies
                    including Gameloft, Playtika, Tencent and Youda Games. The
                    Rebel Bot team has over 25 people and is utilizing 100% of
                    their CPUs to develop the best play to earn game in the
                    world.
                  </p>
                </div>
              </div>
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="faq-list__item-question">
                    How can players earn from Xoil Wars?
                  </div>
                  <svg viewBox="0 0 35 30">
                    <path
                      className="fill"
                      d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                      strokeWidth="0.646162"
                    />
                    <path
                      className="stroke"
                      d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                    />
                  </svg>
                </div>
                <div className="faq-list__item-answer js-faq-item-answer">
                  <p>Players can earn in multiple ways including:</p>
                  <ul>
                    <li>
                      Winning Adventure and Multiplayer battles which will
                      generate Xoil token prizes.
                    </li>
                    <li>
                      Minting and selling Fighting Bot NFTs on the market.
                    </li>
                    <li>
                      Winning season rewards by ranking high on various
                      leaderboards.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="faq-list__item-question">
                    What do I need in order to Play?
                  </div>
                  <svg viewBox="0 0 35 30">
                    <path
                      className="fill"
                      d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                      strokeWidth="0.646162"
                    />
                    <path
                      className="stroke"
                      d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                    />
                  </svg>
                </div>
                <div className="faq-list__item-answer js-faq-item-answer">
                  <p>
                    Players will need to purchase 1 Land NFT and 3 Fighting bots
                    in order to start playing the game. Land allows players to
                    build their base and create their fighting bot teams. The
                    initial sale of Land NFTs in February 2022 will include 3
                    Fighting Bot NFTs with every land, making sure every player
                    who purchases a land NFT can start playing right away when
                    the game is launched. You read that right, every player will
                    get a complete team of fighting bots with the land you
                    purchase!
                  </p>
                </div>
              </div>
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="faq-list__item-question">
                    Is there a difference between Fighting Bots and Rebel Bot
                    NFTs?
                  </div>
                  <svg viewBox="0 0 35 30">
                    <path
                      className="fill"
                      d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                      strokeWidth="0.646162"
                    />
                    <path
                      className="stroke"
                      d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                    />
                  </svg>
                </div>
                <div className="faq-list__item-answer js-faq-item-answer">
                  <p>Yes.</p>
                  <ul>
                    <li>
                      Rebel Bots are not a playable character in the game. They
                      are NFTs on the Ethereum network and govern the Kingdoms
                      which contain players’ Lands.
                    </li>
                    <li>
                      Fighting Bots are your in-game NFTs you use to play,
                      battle and earn. These can be minted for a low price on
                      the game network (polygon) when you collect enough parts
                      and other game resources.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="faq-list__item-question">
                    Do I need a Rebel Bot NFT to play?
                  </div>
                  <svg viewBox="0 0 35 30">
                    <path
                      className="fill"
                      d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                      strokeWidth="0.646162"
                    />
                    <path
                      className="stroke"
                      d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                    />
                  </svg>
                </div>
                <div className="faq-list__item-answer js-faq-item-answer">
                  <p>
                    No - to play you need 1 Land NFT and 3 Fighting Bot NFTs.
                    Rebel Bots ownership grants the owner the ability to govern
                    a whole kingdom filled with lands. The number of player
                    lands in your kingdom increases with the rarity of the NFT
                    which can be seen on{" "}
                    <a
                      href="https://rarity.tools/rebelbots"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Rarity tools
                    </a>
                    . Rarer Rebel Bots have more kingdom lands. Read the{" "}
                    <a href="/whitepaper">whitepaper</a> to see the rarity tiers
                    and land allocations.
                  </p>
                </div>
              </div>
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="faq-list__item-question">
                    Will there be scholarships?
                  </div>
                  <svg viewBox="0 0 35 30">
                    <path
                      className="fill"
                      d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                      strokeWidth="0.646162"
                    />
                    <path
                      className="stroke"
                      d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                    />
                  </svg>
                </div>
                <div className="faq-list__item-answer js-faq-item-answer">
                  <p>
                    Yes - the game is designed in a way to allow all types of
                    users to enjoy it. In the Xoil Wars marketplace there will
                    be a scholarship mechanism built to allow easy creation and
                    management of scholarships.
                  </p>
                </div>
              </div>
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="faq-list__item-question">
                    Anything else I should know?
                  </div>
                  <svg viewBox="0 0 35 30">
                    <path
                      className="fill"
                      d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                      strokeWidth="0.646162"
                    />
                    <path
                      className="stroke"
                      d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                    />
                  </svg>
                </div>
                <div className="faq-list__item-answer js-faq-item-answer">
                  <p>
                    Xoil Wars community is the best you’ll find in the play to
                    earn space, join our{" "}
                    <a
                      href="https://discord.gg/rebel-bots"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Discord
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://twitter.com/REBEL_BOTS"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      Twitter
                    </a>{" "}
                    to meet other rebels from around the world and stay updated
                    on news and game developments.
                  </p>
                </div>
              </div>
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="faq-list__item-question">
                    I have an amazing idea for the game, how can I reach the
                    team?
                  </div>
                  <svg viewBox="0 0 35 30">
                    <path
                      className="fill"
                      d="M35 29.5L18 10.5L4.22817 25.892L1 29.5L35 29.5Z"
                      strokeWidth="0.646162"
                    />
                    <path
                      className="stroke"
                      d="M9.59834 10.9133L18.5 0.960935L35 19.9609L2 19.9609L5.13323 16.353"
                    />
                  </svg>
                </div>
                <div className="faq-list__item-answer js-faq-item-answer">
                  <p>
                    Drop us an email at{" "}
                    <a href="mailto:Contact@rebelbots.com">
                      Contact@rebelbots.com
                    </a>{" "}
                    and we’ll do our best to get back to you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Faq;
