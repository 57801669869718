import React from 'react';
import s from './styles.module.scss'
import {StandardLayout} from "../../components/layouts/StandardLayout";
import PlayRegister from "./components/PlayRegister/PlayRegister";
import PlayDownload from "./components/PlayDownload/PlayDownload";
import PlayConnect from "./components/PlayConnect/PlayConnect";
import PlayDominate from "./components/PlayDominate/PlayDominate";

const Play = () => {
  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
        <h1><span className='yellow'>play</span> xoil wars</h1>
        <h6>Join us and be among the first brave soldiers on the Xoilium battlefields!</h6>

        <PlayRegister />
        <PlayDownload />
        <PlayConnect />
        <PlayDominate />
      </main>
    </StandardLayout>
  );
}

export default Play;