import React from 'react';
import s from "../../../page/CardsExplorer/styles.module.scss";
import {Card} from "../../Card";
import {useNavigate} from "react-router-dom";

const CardsExplorerWrapper = ({cards, isSelection, setIsSelection, setCard, setFilters, selectedCards, setKingdomCard, kingdomCard}) => {

  let navigate = useNavigate();
  const selectedCardsIdArray = Object.values(selectedCards).map(el => el?.card?.cardID).filter(Boolean)

  return (
    <div className={s.cardWrapper}>
      {
        cards.map(card => <Card key={card.cardID}
                                navigate={navigate}
                                isSelection={isSelection}
                                setIsSelection={setIsSelection}
                                setCard={setCard}
                                setFilters={setFilters}
                                selectedCardsIdArray={selectedCardsIdArray }
                                setKingdomCard={setKingdomCard}
                                kingdomCard={kingdomCard}
                                {...card} />)
      }
    </div>
  );
}

export default CardsExplorerWrapper;