import XoilMainImage from '../../Image/XoilMainImage.png'
import XoilMainImageMobile from '../../Image/RblsMainImageMobile.png'
import RblsMainImage from '../../Image/RblsMainImage.png'
import RblsMainImageMobile from '../../Image/XoilMainImageMobile.png'
import {config} from "../../utils/config";

export const rblsXoilData = {
  'xoil': {
    title: 'xoil',
    subtitle: 'The Lifeblood of the Rebel Bots',
    image: XoilMainImage,
    imageMobile: XoilMainImageMobile,
    buyButtons: [{name: 'Quickswap', url: 'https://quickswap.exchange/#/swap?currency0=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&currency1=0xA7AaC53F7C860B1F9d610147341C57579c51403e&swapIndex=0'}],
    infoUrls: [{name: 'Contract', url: 'https://polygonscan.com/token/0xA7AaC53F7C860B1F9d610147341C57579c51403e'}, {name: 'White Paper', url: '/whitepaper', selfOpen: true}],
    infoButtons: [{name: 'Buy Battle Set', url: '/battleSet', selfOpen: true}, {name: 'Assemble my army', url: config.MARKETPLACE_LINK}],
    description: `
    <p> Battle for <span>XOIL</span> to become the richest and most powerful player on Planet Xoilium!</p> 
    <p>To survive and conquer, you must compete for the scarce Xoil token, the valuable Extraterrestrial 
    form of oil. As the player reward token and lifeblood of your robotic army, Xoil sustains 
    the planet's economy with carefully engineered sink and burn mechanics.</p>
    `,
    content: [{
      id: 1,
      title: `<span class="yellow"> BUILD. BATTLE.</span> EARN.`,
      text: `Build and grow your army of Fighting Bots to become victorious, or competing
                Kingdoms and players will overwhelm your forces. Assembling your new Fighting 
                Bot soldiers consumes Xoil tokens. <br> <span> Xoil can be used to purchase new parts,
                obtain recharge SPARK, and complete construction. Earn Xoil by defeating your 
                opponents in PVP, dominating player and Kingdom leaderboards, and completing missions 
                in the intergalactic wilderness. The more powerful your army becomes, the more you can earn!</span> `
    }]
  },
  'rbls': {
    title: 'rbls',
    subtitle: 'POWERING THE REBEL BOTS UNIVERSE',
    image: RblsMainImage,
    imageMobile: RblsMainImageMobile,
    buyButtons: [{name: 'SushiSwap', url: 'https://app.sushi.com/'}, {name: 'Gate.io', url: 'https://www.gate.io/trade/RBLS_USDT'}],
    infoUrls: [{name: 'Contract', url: 'https://polygonscan.com/address/0xe26cda27c13f4f87cFFc2F437C5900b27eBb5bbB'}, {name: 'Rebel Stories', url: '/profile', selfOpen: true}],
    infoButtons: [{name: 'White Paper', url: '/whitepaper', selfOpen: true}],
    description: `
    <p>
        The <span>rbls cryptocurrency</span> token will power governance and utility for Xoil Wars and all future 
        gamefi titles in the Rebel Bots gaming universe.  Our vision is for RBLS to be used by millions of players 
        in Xoil Wars to build, level up, and grow their armies!
    </p>
    <p>
        The RBLS token operates on the <span>polygon</span> blockchain ecosystem and will have in-game utility and staking benefits.
        RBLS is backed by world-class gaming investorsincluding Ubisoft, Animoca Brands, The Overwolf and Polygon Studios,
        with a complete audit by CertiK.
    </p>
    `,
    content: [
      {
      id: 4,
      title: `<span class="yellow"> GAME UTILITY &</span> STAKING BENEFITS`,
      text: `A scarce game currency, players will sink RBLS to:
              <ol>
                <li>Build and mint new powerful Fighting Bots for their armies.</li>
                <li>Purchase rare parts and items from the Xoilium shop.</li>
              </ol>  
              <div> Holders of the RBLS token will also have access to staking benefits in future.</div>
               `
    },
    {
      id: 2,
      title: `<span class="yellow"> DAO VOTING</span> POWER`,
      text: `Beyond 2023, the RBLS token will evolve into the Rebel Bots Decentralised Autonomous Organization (DAO). RBLS will
                become the governance token which grants token holders voting power over decisions to help lead the Rebel
                 Bots gaming universe to success.`
    },
    {
      id: 3,
      title: `<span class="yellow"> SUSTAINABLE <span>GROWTH</span> </span> TOKENOMICS`,
      text: `RBLS launched in March 2022 with an ultimate supply cap of 300 Million to be released over 5 years.
                The generation schedule of RBLS is designed to launch long-term sustainable growth with multiple demand drivers
                 and controlled release.
                <div>To learn more about the RBLS token generation schedule, holder benefits and utility
                 details read the Rebel Bots whitepaper.</div>
    `
    },
    ]
  },
}