import React from "react";

import s from "./styles.module.scss";
import { ReactComponent as Location } from "Image/location.svg";
import { isIOS } from "utils/detectOS";

export const CareerBlock = ({ data }) => {
  return data.map(({ id, title, location, tag }, ind) => {
    console.log(id, title, location, tag);
    if (isIOS()) {
      return (
        <a key={id} className={`${s.link} ${s.block}`} href={`/careers/${ind}`}>
          <p className={s.title}>{title}</p>
          <p className={s.location_text}>
            <Location />
            {location}
          </p>
          <p className={s.tag}>{tag}</p>
        </a>
      );
    } else {
      return (
        <a
          key={id}
          className={`${s.link} ${s.block}`}
          href={`/careers/${ind}`}
        >
          <p className={s.title}>{title}</p>
          <p className={s.location_text}>
            <Location />
            {location}
          </p>
          <p className={s.tag}>{tag}</p>
        </a>
      );
    }
  });
};
