import React from 'react';
import s from './styles.module.scss'
import NftBotsList from '../../../../Image/nftBotsShowList.png'

const BotNftPageMain = () => {
    return (
        <div className={s.botNftPageMain}>
            <h2 className='web55'><span className='yellow'>rebel </span>bots</h2>
            <div>
                <p>
                    Introducing the Rebel Bots - 10,000 Ethereum NFTs who reign supreme. After landing on Xoilium,
                    the Rebel Bots discovered the valuable Xoil resource was scarce. Each Rebel Bot staked claim
                    over lands and built a kingdom to protect their survival and Xoil riches. These Rebel Bots are
                    no ordinary NFTs. Each is engineered with unique traits which determine it's rarity. The rarer
                    the Bot, the greater their Kingdom size and earning power.
                </p>
                <p className={s.lastParagraph}>
                    Join the ranks of these legendary bots and lead your Kingdom to Xoil and victory!
                </p>
            </div>

            <img src={NftBotsList} className={s.botsList} alt="bots list"/>
        </div>
    );
}

export default BotNftPageMain;