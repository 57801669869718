import React from 'react';
import s from "../styles.module.scss";
import FilterArrowIcon from "../../../Image/filterArrow.svg";

const BuilderHeader = ({open, setOpen}) => {
  return (
    <div className={s.botBuilderHeader} >
      <div className={s.botBuilderHeader_subtitle}>
        Strategic Prototyping <br/>
        And Research eXperience
      </div>
      <div className={s.botBuilderHeader_title}>
        s.p.<span style={{ color: "#FFC328" }}>a.R.x</span>
      </div>
      <img onClick={() => setOpen(!open)} style={{transform: `rotate(${open ? 0 : 180}deg)`, transition: 'transform 0.2s'} } src={FilterArrowIcon} alt="FilterArrowIcon"/>
    </div>
  );
}

export default BuilderHeader;