import React from 'react';
import s from './styles.module.scss'
import introPlanet from "../../../../Image/intro-planet.webp";
import introRobots from "../../../../Image/intro-robots.webp";
import introSparks from "../../../../Image/intro-sparks.webp";
import introSTabletLandscape from "../../../../Image/intro-tablet-landscape.webp";
import introSTabletPortrait from "../../../../Image/intro-tablet-portrait.webp";
import introPhone from "../../../../Image/intro-tablet-portrait.webp";
import textLogo from "../../../../Image/text-logo.webp";
import Youtubelogo from "../../../../Image/Youtubelogo.svg";
import {useDimensions} from "../../../../hooks/useDimensions";

const HomeMain = ({homeVideoRef}) => {
  const {width} = useDimensions()

  // const scrollRange = width > 1620 ? window.screen.height + 50 : width > 1120 ? window.screen.height - 185  :  window.screen.height - 100
  // let scrollRange = window.screen.height

  // eslint-disable-next-line default-case
  // switch (true){
  //   case(width > 1620): scrollRange += 70;break
  //   case(width > 1420): scrollRange += 30;break
  //   case(width > 1120): scrollRange -= 0;break
  //   case(width > 920): scrollRange += 50;break
  // }

  const scrollToNextBlock = () => {
        // document.getElementById('homeVideo').scrollIntoView({
        //   behavior: 'smooth',
        // });
      const homeVideoElement = homeVideoRef.current;
      const topOffset = homeVideoElement.offsetTop;
      const additionalOffset = width > 1024 ? width / 25 : 0
      window.scrollTo({ top: topOffset + additionalOffset, behavior: 'smooth' });
  }

  return (
    <section id='main' className={s.homeMain}>
      <section  className="section intro" id="intro">
        <div className="intro-bg">
          <div className="intro-parallax">
            <div
              className="intro-bg-image js-parallax-back desktop-image"
              style={{ backgroundImage: `url(${introPlanet})` }}
            />
            <div
              className="intro-bg-image js-parallax-middle desktop-image"
              style={{ backgroundImage: `url(${introRobots})` }}
            />
            <div
              className="intro-bg-image js-parallax-front desktop-image"
              style={{ backgroundImage: `url(${introSparks})` }}
            />
            <div
              className="intro-bg-image tablet-landscape"
              style={{ backgroundImage: `url(${introSTabletLandscape})` }}
            />
            <div
              className="intro-bg-image tablet-portrait"
              style={{ backgroundImage: `url(${introSTabletPortrait})` }}
            />
            <div
              className="intro-bg-image phone"
              style={{ backgroundImage: `url(${introPhone})` }}
            />
          </div>
        </div>
        <div className="intro-container">
          <div className="intro-content">
            <img
              className="intro-logo"
              src={textLogo}
              alt="Join the Rebel Bots resistance"
              title="Join the Rebel Bots resistance"
            />
            <div className="intro-bottom">
              <div className="lead">The Ultimate Play to Earn Game</div>
              <div className="intro-fixed">
                <div>
                  <button
                    className="button-type1 intro-button"
                    data-regular="Check it out!"
                    onClick={scrollToNextBlock}
                  >
                    <span className="button-type1-before" />
                    <img src={Youtubelogo} alt="" />
                    <span className="label">Check it out!</span>
                    <span className="button-type1-after" />
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </section>
  );
}

export default HomeMain;