import React from 'react';
import ExplorerModalWrapper from "../ExplorerModalWrapper/ExplorerModalWrapper";
import s from "./styles.module.scss";

const ClaimBotModal = ({open, handleClose, disabled, text, title}) => {
  return (
    <ExplorerModalWrapper open={open} handleClose={ disabled ? null : handleClose } title={title} >
      <div className={s.logInModal}>
        <div className={s.info}>
          {text}
        </div>
        <div className={s.buttonsWrapper}>
          {
            !disabled && <div onClick={ handleClose } className={`${s.button} button-type1 black`}>
                <span className="button-type1-before" />
                <div className={`${s.label} label`}>
                  <span>Ok</span>
                </div>
                <span className="button-type1-after" />
              </div>
          }
        </div>
      </div>
    </ExplorerModalWrapper>
  );
}

export default ClaimBotModal;