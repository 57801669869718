import React from 'react';
import KingdomCardImage from "../../../../Image/KingdomCard.png";
import s from "../BuilderTeam/styles.module.scss";
import {useDimensions} from "../../../../hooks/useDimensions";

const KingdomCard = ({ setFilters, kingdomCard, setIsSelection, cardsCount }) => {
  const {width} = useDimensions()

  const selectCard = () => {
    setIsSelection(true)
    setFilters(prevState => {
      const newPart = {}
      for (let key in prevState['part']){
        newPart[key] = key === 'Kingdom'
      }
      return {...prevState, 'part': newPart}
    })

    window.scrollTo({
      top: width >= 576 ? width >= 1300 ? 1400 : 2400 : 2530 ,
      behavior: 'smooth',
    })
  }

  return (
    <div className={s.kingdomCard__background} style={{marginTop: cardsCount && width > 1750 ? '65px' : '30px'}}>
      <img onClick={selectCard}
           src={kingdomCard ? kingdomCard.cardURI : KingdomCardImage}
           className={`${s.kingdomCard} ${kingdomCard ? s.kingdomCard__card : ''}`}
           alt="Kingdom card"
      />
      <p>kingdom card</p>
    </div>
  );
}

export default KingdomCard;