import React from "react";
import s from './styles.module.scss'
import {Checkbox} from "../ui/Checkbox";
import {useDimensions} from "../../hooks/useDimensions";

export const Card = (props) => {
  const {width} = useDimensions()

  const clickHandler = () => {
    props.setIsSelection(false)
    if(props.isSelection){
      if(props.part === 'Kingdom'){
        props.setKingdomCard(props)
        window.scrollTo({
          top: width >= 576 ? width >= 1300 ? 500 : 800 : 200,
          behavior: 'smooth',
        })
        return
      }
      props.setCard(props)
      window.scrollTo({
        top: width >= 576 ? width >= 1300 ? 500 : 800 : 1200,
        behavior: 'smooth',
      })
      return
    }

    // props.navigate(`/card/${props.cardID}`)
    window.location.href = `/card/${props.cardID}`;
  }

  const isSelected = props.selectedCardsIdArray.includes(props.cardID) || props?.kingdomCard?.cardID === props.cardID

  return (
    <div onClick={clickHandler}  className={s.card}>
      <img title={props.tooltip} src={props.cardURI} alt="card" />
      {
        props.isSelection &&
        <Checkbox value={isSelected}/>
      }
    </div>

  );
};


