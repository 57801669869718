import {useState} from "react";
import EmptyCardHead from "../Image/empyCard5.svg";
import EmptyCardTorso from "../Image/empyCard4.svg";
import EmptyCardLeftArm from "../Image/empyCard3.svg";
import EmptyCardRightArm from "../Image/empyCard2.svg";
import EmptyCardLegs from "../Image/empyCard.svg";
import EmptyCardHeadTablet from "../Image/emtyCardTablet1.svg";
import EmptyCardTorsoTablet from "../Image/emtyCardTablet2.svg";
import EmptyCardLeftArmTablet from "../Image/emtyCardTablet3.svg";
import EmptyCardRightArmTablet from "../Image/emtyCardTablet4.svg";
import EmptyCardLegsTablet from "../Image/emtyCardTablet5.svg";
import {useDimensions} from "./useDimensions";


export const useSelectCard = (setFilters) => {
  const [bot, setBot] = useState(1)
  const {width} = useDimensions()
  const [isSelection, setIsSelection] = useState(false)
  const [partFilter, setPartFilter] = useState(null)
  const tabletWidth = 992

  const botInitialState = {
    'Head': {id: 1, card: null, empty: width >= tabletWidth ? EmptyCardHead : EmptyCardHeadTablet, title: 'Head', titleName: 'Head'},
    'Torso': {id: 2, card: null, empty: width >= tabletWidth ? EmptyCardTorso : EmptyCardTorsoTablet, title: 'Torso', titleName: 'Torso'},
    'Left Arm': {id: 3, card: null, empty: width >= tabletWidth ?EmptyCardLeftArm : EmptyCardLeftArmTablet, title: 'Left Arm', titleName: 'Left arm'},
    'Right Arm': {id: 4, card: null, empty: width >= tabletWidth ?EmptyCardRightArm :EmptyCardRightArmTablet , title: 'Right Arm', titleName: 'Right arm'},
    'Legs': {id: 5, card: null, empty: width >= tabletWidth ?EmptyCardLegs : EmptyCardLegsTablet, title: 'Legs', titleName: 'Legs'},
  }

  const setId = (obj) => {
    return {...obj, id: Math.ceil(Math.random() * 1000)}
  }

  const setSelection = (value, part) => {
    if(value){
      setFilters(prevState => {
        if(!partFilter){
          setPartFilter(prevState['part'])
        }
        const newPart = {}
        for (let key in prevState['part']){
          newPart[key] = key === part
        }
        return {...prevState, 'part': newPart}
      })
    }else{
      setFilters(prevState => ({...prevState,...(partFilter && {'part': partFilter}) }))
    }

    setIsSelection(value)
  }

  const [selectedCards, setSelectedCards] = useState({
    1: setId(botInitialState),
    2: setId(botInitialState),
    3: setId(botInitialState)
  })

  const setCard = (card) => {
    if(bot){
      setSelectedCards(prevState => ({...prevState,
        [bot]: {...prevState[bot], [card.part]: {...prevState[bot][card.part], card: card }}
      }))
    }
  }

  const removeCard = (part) => {
    setSelectedCards(prevState => ({...prevState, [bot]: {...prevState[bot], [part]: {...prevState[bot][part], card: null }}}))
  }

  return {
    selectedCards: bot === 0 ? selectedCards : selectedCards[bot],
    setCard,
    removeCard,
    bot,
    setBot,
    isSelection,
    setIsSelection: setSelection
  }
}