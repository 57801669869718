import { useEffect, useState} from "react";
import {getReleaseHeadCards} from "../api/card";
import {initialFilters} from "../page/CardsExplorer/components/CardsExplorerFilters/initialFilters";

export const useCardFilter = () => {
  const [allCards, setAllCards] = useState([])
  const [costFilter, setCostFilter] = useState('')
  const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('filters2')) || initialFilters)
  const [returnCards, setReturnCards] = useState([])
  const [searchText, setSearchText] = useState('')
  const [kingdomCards, setKingdomCards] = useState([])

  const filter = ( filterName, isFirstFilter, type ) => {
    const filterArray = []
    for(let key in filters[filterName]){
      if (filters[filterName][key] === true){
        filterArray.push(key)
      }
    }

    if(isFirstFilter){
      setReturnCards(allCards.filter(card => filterArray.includes(card[filterName]) ))
      setReturnCards(prevState => [...prevState, kingdomCards])
      return
    }

    if(type === 'target' || type === 'abilities'){
      setReturnCards(prevState => prevState.filter(card => {
        let returnValue = false
        for(let value of card[filterName]){
          if(filterArray.map(filterName => filterName.toLowerCase()).includes(value.toLowerCase())){
            returnValue = true
          }
        }
        return returnValue
      }))
      return
    }

    filterArray.push('Kingdom')
    setReturnCards(prevState => prevState.filter(card => filterArray.includes(card[filterName]) ))

  }

  // SORT BY A-Z AND LOW, HIGH CONST
  const sort = (sortType) => {
    setCostFilter(sortType)
  }

  // FILTER BY SEARCH
  const onSearch = (value) => {
    setSearchText(value)
  }


  useEffect(() => {
    getReleaseHeadCards()
      .then(({data}) => {
        data = data.sort(function(a,b){
          return a.cardName.localeCompare(b.cardName);
        })
        data = data.map(card => {
          if(card.part === 'Kingdom'){
            card.rarity = "Kingdom"
            card.attack = "Kingdom"
            card.abilities = ['Select all']
            if(card.cardName === 'Smart mines' || card.cardName === 'Drain' ){
              card.abilities.push('Deal-Damage')
            }
            setKingdomCards(prevState => [...prevState, card])
          }
          if(card.target.includes('first')){
            card.target.push('closest')

          }
          if(card.target.includes('last')){
            card.target.push('furthest')
          }
          return card
        })
        setAllCards(data)
        setReturnCards(data)
      })
  }, [])

  useEffect(()=> {

    localStorage.setItem('filters2', JSON.stringify(filters))

    filter('part', true,)
    filter('rarity')
    filter('type')
    filter('attack')
    filter('target', false, 'target')
    filter('abilities', false, 'abilities')

    if(searchText){
      setReturnCards(prevState => prevState.filter((card) => (
        card.cardName.toLowerCase().includes(searchText.toLowerCase())
        || card.cardDescription.toLowerCase().includes(searchText.toLowerCase()))))
    }

    switch (costFilter){
      case 'Alphabetical (A-Z)': {

        setReturnCards( prevState => prevState.sort(function(a,b){
          return a.cardName.localeCompare(b.cardName);
        }))

        break
      }
      case 'CPU Cost - Low to High': {
        setReturnCards( prevState => prevState.sort(function(a, b) {
          return a.cost - b.cost;
        }))

        break
      }
      case 'CPU Cost - High to Low': {
        setReturnCards( prevState => prevState.sort(function(a, b) {
          return a.cost - b.cost;
        }).reverse())
        break
      }
      default: {
      }
    }

  }, [filters, costFilter, searchText, allCards]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    for(let key in filters){
      if(Object.values(filters[key]).includes(false) && filters[key]['Select all']){
        filters[key]['Select all'] = false
      }
    }
  }, [filters])



  return {
    cards: returnCards,
    sort,
    onSearch,
    filters,
    filter,
    setFilters
  }
}