import React, {useEffect, useState} from 'react';
import ExplorerModalWrapper from "../ExplorerModalWrapper/ExplorerModalWrapper";
import s from "./../ShareBotModal/styles.module.scss";
import html2canvas from "html2canvas";
import Loader from "../../ui/Loader/Loader";
import {useDimensions} from "../../../hooks/useDimensions";


const SaveTeamModal = ({open, handleClose, isTeamBuilder}) => {
  const [botImage, setBotImage] = useState('')
  const [showBot, setShowBot] = useState(false)
  const {width} = useDimensions()

  useEffect(() => {
    const saveImg = () => {
      html2canvas(document.querySelector('#builderWrapper'), {
        letterRendering: 1,
        allowTaint: true,
        useCORS: true,
        backgroundColor: '#1E1E1E',
        windowHeight: 1080,
        windowWidth: 1920,
        width: 1820,
        height: 1080,
        imageTimeout: 15000,
        logging: true,
        scale: 2,
        ignoreElements: function( element ) {
          if( 'botBuilderButton' === element.id || element.classList.contains( 'closeCard' )) {
            return true;
          }
        },
        onclone: function (clonedDoc) {
          clonedDoc.getElementById('builderTeamWrapper').classList.add('share')
        }
      } )
        .then( (canvas) => {
          canvas.style.display = 'none';
          let image = canvas.toDataURL("image/jpeg", 0.7)
          if(image && open){
            setBotImage(image)
            setTimeout(() => setShowBot(true), 6500)
          }
        })
    }

    saveImg()
  }, [open])


  return (
    <ExplorerModalWrapper open={open}
                          handleClose={handleClose}
                          title={isTeamBuilder ? 'save this battle set' :'Share this bot'}
    >
      <div className={`${s.wrapper} ${s.team}`}>
        <div className={s.bot}>
          {
            (botImage && showBot)
              ? <img src={botImage} alt="bot"/>
              : <div> <Loader /></div>
          }
        </div>
        <div className={`${s.shareButtons} `}
        >
          <div className={s.buttonsWrapper}>
            {
              (botImage && showBot) &&
              <a download='bot.jpeg' href={botImage} className={`${s.button} ${width > 1018 ? 'button-type1 black' : ''}`}>
                <span className="button-type1-before" />
                <span className={`${s.label} label`}>
                <span> Save .jpeg</span>
                </span>
                <span className="button-type1-after" />
              </a>
            }
          </div>
        </div>
      </div>

    </ExplorerModalWrapper>
  );
}

export default SaveTeamModal;