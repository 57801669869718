import React from 'react';
import s from './styles.module.scss'
import NftPageInfoButtonsBlock from "../../../NftPage/compoents/NftPageInfoButtonsBlock/NftPageInfoButtonsBlock";
import {useDimensions} from "../../../../hooks/useDimensions";
import {config} from "../../../../utils/config";

const LandsNftPageMain = () => {
  const {width} = useDimensions()

  const infoButton = [
    {title: 'Marketplace', url: config.MARKETPLACE_LINK},
    {title: 'Contract', url: 'https://polygonscan.com/address/0x577682bde6873022525948e91487abdf80f71c99'},
  ]

  return (
    <>
      <div className={s.landsNftPageMain}>
        <h2 className='web55'>lands</h2>
        <p className={`web3 ${s.textWrapper} ${s.textWrapper_1}`}>
          Do you have what it takes to conquer the Xoil Wars and become the wealthiest and most powerful player on planet Xoilium?
        </p>
        <p className={`web3 ${s.textWrapper} ${s.textWrapper_2}`}>
          With your Land at the heart of one of 10,000 Xoilium kingdoms and under the protection of a
          Rebel Bot Lord, it acts as your home base to store your valuable resources, build your Fighting Bot army, and plan your conquests.
        </p>
        <p className={`web3 ${s.textWrapper} ${s.textWrapper_3}`}>
          {
            width > 576 &&
            <>
              <span className={'web9'}>The time has come</span>
              to secure your Land and enter the ranks of the elite. Choose from six  extra-terrestrial environments and join
              the Xoil Wars now to build and command your army of Fighting Bots, embark on epic adventures, and fight for
              victory and Xoil to become the ultimate champion!
            </>
          }
        </p>
          {
            width > 968 &&
            <div className={s.buttonsWrapper}>
              <NftPageInfoButtonsBlock newTab={true} url={'https://opensea.io/collection/rebel-bots-xoilium-lands'}  variant={'landPage'} buttons={infoButton} />
            </div>
          }
      </div>
      {
        width < 576 &&
        <>
          <p className={`web3 ${s.textWrapper} ${s.textWrapper_4}`}>
          <div className={'web9'}>The time <br/> <span>has come</span></div>
          <span> to secure your Land and enter the ranks of the elite.  </span>
          <p>
            Choose from six extra-terrerstrial environments and join the Xoil
            Wars now to build and command your army of Fighting Bots, embark on epic adventures,
            and fight for victory and Xoil to become the ultimate champion!
          </p>
          </p>
        </>
      }
      {
        width <= 968 &&
        <div className={s.buttonsWrapper}>
          <NftPageInfoButtonsBlock variant={'landPage'} url={'https://opensea.io/collection/rebel-bots-xoilium-lands'} buttons={infoButton} />
        </div>
      }
    </>
  );
}

export default LandsNftPageMain;