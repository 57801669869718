import React, {useContext, useEffect, useState} from 'react';
import s from "./styles.module.scss";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import {useDimensions} from "../../../../hooks/useDimensions";
import {getMyBots} from "../../../../api/bots";
import {LandSaleContext} from "../../../../Context/LandSaleWallet";
import AssetList from "../../../../components/AssetList/AssetList";
import EmptyLandsImage from "../../../../Image/emptyLands.png";
import ShowMoreArrow from "../../../../Image/showMoreArrow.svg";
import EmptyLandsImageTablet from "../../../../Image/emptyLandsTablet.svg";
import {useLocation} from "react-router-dom";

const FightingBotsList = () => {
  const {
    account,
    connect,
    isCorrectNetwork
  } = useContext(LandSaleContext);

  // const account = '0x3B4D6c78EC0d8D7bDd41c29Dd8409944D442Ad21'
  const location = useLocation();
  const {width} = useDimensions()
  const [bots, setBots] = useState([]);
  const [page, setPage] = useState(0)
  const [showMorePageCount, setShowMorePageCount] = useState(1)
  const [newAccount, setNewAccount] = useState(null)

  const botFetch = () => {
    if (page !== null) {
      // For pagination
      setTimeout(() => {
        getMyBots(account, page).then((res) => {
          const {botsData, hasNext} = res?.data;
          if (botsData) {
            setBots(prev => [...prev, ...botsData])
          }

          hasNext ? setPage(page => page + 1) : setPage(null)
        })
      }, 1000)
    }
  }

  useEffect(() => {
    if(newAccount !== account){
      setNewAccount(account);
      setBots([])
      setPage(0)
    }
    if(account){
      botFetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, account])

  // if(true) {
  if(isCorrectNetwork && account) {
    return (
      <>
        <h3 className={`${s.yourLandsTitle} ${bots.length > 0 ? s.haveBots : ''}`}><span className='yellow'> your </span> fighting bots</h3>
        <div className={s.landsWrapper}>

          {
            bots.length > 0 ? <AssetList type={'fightingBots'} refresher={console.log} assets={width > 1024 ? bots : bots.slice(0,  showMorePageCount * 4)}/>
              :
              <div className={s.lands__empty}>
                <img className={s.empty__img} src={(width > 1024 || width < 576) ? EmptyLandsImage : EmptyLandsImageTablet}
                     alt="empty"/>
                <div className={s.lands__emptyText}>
                  <p className='web48'>
                    It looks like you
                    <span> don’t have any  Fighting Bots.</span>
                  </p>
                  <p className='web48'>
                    <b>You can get them here:</b>
                  </p>
                  <div className={s.land__buttonContainer}>
                    <a
                      className={`button-type1 notAdaptive ${s.buttonUrl}`}
                      href={`/battleSet`}
                      // target={"_blank"}
                      // rel="noreferrer"
                      data-regular="Buy Now"
                    >
                      <span className="button-type1-before"/>
                      <span className="label">Buy Battle Set</span>
                      <span className="button-type1-after"/>
                    </a>
                  </div>
                </div>
              </div>
          }
        </div>
        {(width < 1024 && bots.length >= 4 * showMorePageCount) &&
          <div className={s.showMore} onClick={() => setShowMorePageCount(prevState => prevState + 1)}>
            <span>Show more</span>  <img src={ShowMoreArrow} alt=""/>
          </div>}
      </>
    )
  }


  return (
    <div
      className={`${s.timer} ${s.connectWalet}`}>
      <>
        <h3>
          Check your bots
        </h3>
        <><p>Connect your wallet to proceed.</p>
          <CustomButton
            className={s.formControl}
            form="application"
            label="Connect wallet"
            callback={() => connect(false, location.pathname)}
            connect={true}
            // disabled={true}
            texture={
              width < 576
                ? "btn-apply-guild-mobile.svg"
                : width < 1024
                  ? "btn-apply-guild-tablet.svg"
                  : "btn-apply-guild.svg"
            }
          />
        </>

      </>

    </div>
  );
}

export default FightingBotsList;