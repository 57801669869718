import claimAbi from "../ABI/ClaimABI.json";
import BattleAbi from '../ABI/BattleSetBuy.json'
import {LoadingPopup, PurchaseFailedPopup, SuccessFullPurchasePopup} from "../page/Landsale/popups";
import {initialCheckoutEvent} from "../utils/fbPixelEvents";
import {getKingdomLands, getLandIds} from "../api/land";
import {buyBattleSetInit} from "../api/veriff";
import {hashCodeRand} from "../utils/blockchain.utils";

export const getMediumGas = async (web3) => {
    const gasStation = window.config.ENVIRONMENT === 'prod' ? 'https://gasstation.polygon.technology/v2'
      : 'https://gasstation-testnet.polygon.technology/amoy';
    try {
        const data = await (await fetch(gasStation)).json();
        const gasPriceBase = data.fast.maxFee;
        const gasPrice = web3.utils.toWei(gasPriceBase.toFixed(9), "gwei");
        const gasMaxFee = web3.utils.toWei(data.fast.maxFee.toFixed(9), "gwei");
        const gasMaxPriorityFee = web3.utils.toWei(
          data.fast.maxPriorityFee.toFixed(9),
          "gwei"
        );
        return {gasPrice, gasMaxFee, gasMaxPriorityFee};
    } catch (error) {
        console.error(error);
        //if request fails, return 0 gas price
        return 0;
    }
}

export const buyTokens = async (specifics, tokenNumber, account, contract, MARKET_CONTRACT, setPopup, transactionCompleted, kingdomLands, web3) => {
    let confirmationReceived = false;
    let result;
    const {gasPrice, gasMaxFee, gasMaxPriorityFee} = await getMediumGas(web3);

    if (!!specifics?.length) {
        result = contract.market.methods
          .buySpecificTokens(specifics)
          .send({
              from: account,
              to: MARKET_CONTRACT,
              // gasPrice: gasPrice,
              // maxFeePerGas: gasMaxFee,
              maxPriorityFeePerGas: gasMaxPriorityFee,
              // gas,
          });
    } else {
        result = contract.market.methods.buyTokens(tokenNumber).send({
            from: account,
            to: MARKET_CONTRACT,
            // gasPrice: gasPrice,
            // maxFeePerGas: gasMaxFee,
            maxPriorityFeePerGas: gasMaxPriorityFee,
        });
    }
    result
      .on("transactionHash", () => LoadingPopup(setPopup))
      .on("confirmation", function (confirmationNumber) {
          if (!confirmationReceived) {
              if (confirmationNumber >= 24) {
                  confirmationReceived = true;
                  if (!!specifics?.length) getKingdomLands(kingdomLands.id);
                  initialCheckoutEvent();
                  SuccessFullPurchasePopup(setPopup);
                  getLandIds();
                  transactionCompleted();
              }
          }
      })
      .on("receipt", function () {
          kingdomLands.id && getKingdomLands(kingdomLands.id);
          // console.log("receipt: ", receipt);
      })
      .on("error", function (error, receipt) {
          console.error(error, receipt, typeof error);
          if (
            error.message &&
            error.message.match("Be aware that it might still be mined!")
          ) {
              return;
          }
          // TODO track if it was mined already here?
          // (and show PurchaseFailedLandPopup)

          PurchaseFailedPopup(setPopup);
          getKingdomLands(kingdomLands.id);
          getLandIds();
          transactionCompleted();
      });
}

export const buyBattleSet = async ({specificTokens, tokenNumber}, web3, account, setPopup) => {
    const battleSetBuyContract = {
        address: window.config.BS_MARKET_CONTRACT,
        abi: BattleAbi
    }

    const genHash = hashCodeRand()
    localStorage.setItem('gHashBS', genHash)

    const {gasMaxFee, gasMaxPriorityFee} = await getMediumGas(web3)

    const methodOptions = {
        text: tokenNumber ? 'buyBattleSets' : 'buySpecificBattleSets',
        data: tokenNumber ? tokenNumber : specificTokens
    }
    // This is for not triggering "Be aware"
    web3.eth.transactionBlockTimeout = 7500;
    web3.eth.transactionPollingTimeout = 7500;

    const buyBSContract = new web3.eth.Contract(battleSetBuyContract.abi, battleSetBuyContract.address)

    try {
        await buyBSContract.methods[methodOptions.text](methodOptions.data, genHash).send({
            from: account,
            // maxFeePerGas: gasMaxFee,
            maxPriorityFeePerGas: gasMaxPriorityFee
        }).on('transactionHash', (hash) => {
            if (hash) {
                buyBattleSetInit(Array.isArray(methodOptions.data) ? methodOptions.data.length : methodOptions.data)
            }
            return getTransactionReceiptMined(console.log, hash, web3)
        }).on('receipt', (receipt) => {
            if (receipt) SuccessFullPurchasePopup(setPopup)
        }).on('error', () => {
            PurchaseFailedPopup(setPopup)
        })
    } catch (e) {
        PurchaseFailedPopup(setPopup)
        console.log(e)
    }
}

export const claimBots = async (web3, lands, account, all, fireModal) => {
    const claimAbiItem = {
        address: window.config.CLAIM_CONR,
        abi: claimAbi
    }
    const {gasMaxFee, gasMaxPriorityFee} = await getMediumGas(web3);

    const methodOptions = {
        text: all ? 'claimFightingBotsInLands' : 'claimFightingBotsInLand',
        data: all ? lands.map(el => el.id) : lands.id
    }

    const claimContract = new web3.eth.Contract(claimAbiItem.abi, claimAbiItem.address)
    try {
        return await claimContract.methods[methodOptions.text](methodOptions.data).send({
            from: account,
            maxFeePerGas: gasMaxFee,
            maxPriorityFeePerGas: gasMaxPriorityFee
        }).on('transactionHash', (hash) => {
            if (hash) fireModal(true)
            return getTransactionReceiptMined(fireModal, hash, web3)
        }).on('error', () => {
            fireModal(true)
        })
    } catch (e) {
        console.log(e)
    }
}

export function getTransactionReceiptMined(showModal, txHash, web3, interval) {
    const transactionReceiptAsync = function (resolve, reject) {
        web3.eth.getTransactionReceipt(txHash, (error, receipt) => {
            if (error) {
                showModal(false)
                reject(error);
            } else if (receipt === null) {
                setTimeout(
                  () => transactionReceiptAsync(resolve, reject),
                  interval ? interval : 2000);
            } else {
                showModal(true)
                resolve(receipt);
            }
        });
    };

    if (Array.isArray(txHash)) {
        return Promise.all(txHash.map(
          oneTxHash => getTransactionReceiptMined(oneTxHash, web3, interval)));
    } else if (typeof txHash === "string") {
        return new Promise(transactionReceiptAsync);
    } else {
        showModal(true)
        throw new Error("Invalid Type: " + txHash);
    }
}