import React from 'react';
import s from './styles.module.scss'
import {classList} from "./classList";
import {useDimensions} from "../../../../hooks/useDimensions";

const FightingBotsClassList = () => {
  const {width} = useDimensions()

  return (
    <div className={s.FightingBotsClassList}>
      {
        classList.map(el => (
          <div key={el.title}
               className={s.item}
               style={{backgroundImage: width > 1024
                          ? `url(${el.backgroundImage})`
                          : width > 576 ? `url(${el.backgroundImageTablet})` :  `url(${el.backgroundImageMobile})`
          }}
          >
            <h2><span style={{color: el.titleColor}}>{el.title}</span> bots</h2>
            <div className={s.item__content}>
              <img src={width > 1024 ? el.botsImage : width > 576 ? el.botsImageTablet : el.botsImageMobile} alt="bots"/>
              <p className={'fb-text'}>
                {el.description}
              </p>
            </div>
          </div>
        ))
      }

    </div>
  );
}

export default FightingBotsClassList;