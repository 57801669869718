import { api } from "api";

export const getLandIds = async () => {
  try {
    // total supply {data} out of 150000
    const { data } = await api.get("/lands/total-supply");
    console.log({ data })
    return data;
  } catch (err) {
    throw new Error(err.response.status);
  }
};

export const getKingdomLands = async (id) => {
  try {
    const { data } = await api.get(`lands/kingdom-lands/${id}`);
    return data;
  } catch (err) {
    throw new Error(err.response.status);
  }
};
export const getLandLookup = async (value) => {
  try {
    const { data } = await api.get(`lands/${value}`);
    return data || [];
  } catch (e) {
  }
};
export const getMyLands = async (account, page) => {
  try {
    // const { data } = await api.get(`https://api.rebelbots.com/lands/wallet/0xC65a41b7564208a15D87dc16BFB19acecA522D63?page=${page}`);
    const { data } = await api.get(`${window.config.SERVER_API}/lands/wallet/${account}?page=${page}`);
    return data;
  } catch (e) {}
};