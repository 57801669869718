import React, { useEffect, useState } from "react";

import Header from "components/Header";
import Footer from "components/Footer";

// Todo: try to use this layout anywhere, on every page, refactor if some customization is needed
export const StandardLayout = ({
  children,
  isHeaderForced,
  noLinks=false
}) => {
  const [isForceOpen, setIsForceOpen] = useState(false);

  useEffect(() => {
    if (!isHeaderForced) return;
    if (typeof window !== "undefined" && window.document) {
      document.body.style.overflow = isForceOpen ? "hidden" : "visible";
    }
  }, [isForceOpen, isHeaderForced]);

  return (
    <div className={`wrapper${noLinks ? ' no-links' : ''}`}>
      <Header
        {...(isHeaderForced && {
          isForceOpen,
          setIsForceOpen,
        })}
      />
      {children}
      <Footer />
    </div>
  );
};
