import React, {createRef, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import s from './styles.module.scss'
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.scss';
import './swiper.css'
// import required modules
import {Navigation, Pagination} from "swiper";
import {fixAssetId, fixKingdomName, tooltipFactory} from "../../utils/asset.utils";
import {useDimensions} from "../../hooks/useDimensions";
import CloseBtn from '../../Image/closeBtn_bots.svg'
import {getNonceByAddress, updateRebelBot} from "../../api/bots";
import ClaimBotModal from "../Modals/ClaimLandModal/ClaimLandModal";
import FightingBotsItem from "./components/FightingBotsItem/FightingBotsItem";
import {getPassiveSkills} from "../../api/card";
import xoilIcon from '../../Image/xoilIcon.png'

// import html areas
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {Editor} from "react-draft-wysiwyg";
import {LandSaleContext} from "../../Context/LandSaleWallet";
// import {config} from "../../utils/config";

const AssetList = ({claimFetch, claimedAssets, assets, type, account, refresher}) => {
    const {width} = useDimensions()
    const {signTransaction, toCheckSum} = useContext(LandSaleContext)
    const [openModal, setOpenModal] = useState(false)
    const [modalText, setModalText] = useState({
        title: '',
        content: ''
    })
    const botInfoRef = createRef();

    const createEmpty = () => EditorState.createEmpty();
    const [aboutBotEditorState, setAboutBotEditorState] = useState(createEmpty);
    const [botId, setBotId] = useState()

    const [positionOfClickedElement, setPosition] = useState({
        left: 0,
        top: 0
    })

    const [bot, setBot] = useState({
        name: '',
        kingdomName: ''
    })

    useEffect(() => {
        if (botId && assets) {
            setBot({
                name: assets.filter(el => el.id === botId)[0].name ?? '',
                kingdomName: assets.filter(el => el.id === botId)[0].kingdomName ?? ''
            })
            initState(assets.filter(el => el.id === botId)[0].desc)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botId, assets])

    const [passiveSkills, setPassiveSkills] = useState({})

    useEffect(() => {
        if (type === 'fightingBots') {
            getPassiveSkills()
              .then(({data}) => {
                  setPassiveSkills(data)
              })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const editChange = useCallback(async () => {
        try {
            const nonce = await getNonceByAddress(account);
            const {signature} = await signTransaction(nonce);

            const botInfo = {
                name: bot.name,
                botDesc: draftToHtml(await convertToRaw(aboutBotEditorState.getCurrentContent())),
                kingdomName: bot.kingdomName
            }

            const {status} = await updateRebelBot({
                id: botId,
                address: toCheckSum(account),
                sign: signature,
                botInfo,
            });
            if (status !== 200) {
                refresher(null);
                setModalText({
                    title: 'Saving failed',
                    content: 'Saving new story failed. Please try again.'
                })
                setOpenModal(true)
            } else {
                refresher(null);
                setModalText({
                    title: 'Success',
                    content: "New story has been saved successfully. You've updated rebel bots."
                })
                setOpenModal(true)
                setBot({
                    name: '',
                    kingdomName: ''
                })
                setAboutBotEditorState(null)
            }
        } catch (e) {
            console.log(e)
            setModalText({
                title: 'Saving failed',
                content: 'Saving new story failed. Please try again.'
            })
            setOpenModal(true)
        }
        setBotId(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, bot, aboutBotEditorState]);

    const getHtmlStringText = (html) => {
        // eslint-disable-next-line no-undef
        return jQuery("<div>").html(html).text();
    };

    // Adaptive logic
    const swiperSliderPerView = width > 1750 ? 4 : width > 1440 ? 3 : 2
    const editWidth = width > 1375 ? 1142 : width > 800 ? 691.53 : 300
    const purePosElem = positionOfClickedElement.left - 170 + editWidth

    // const dimension = useMemo(() => {
    //     return {
    //         width: width > 1042 ? 251.43 : width > 576 ? 211.82 : 192.08,
    //         height: width > 1042 ? 75 : width > 576 ? 61 : 57,
    //         texture: width > 1042 ? '' : width > 576 ? '_tablet' : '_mobile'
    //     }
    // }, [width])

    const editBlockAdaptive = useMemo(() => {
        return {
            desktop: {
                '--positionEditLeft': `${purePosElem <= window.windowWidth ? `-170px` : positionOfClickedElement.left - editWidth < 0 ? `${(window.windowWidth / -swiperSliderPerView) - 300}px` : `unset`}`,
                '--positionEditRight': `${purePosElem <= window.windowWidth ? `unset` : positionOfClickedElement.left - editWidth < 0 ? `${(window.windowWidth / -swiperSliderPerView) - 300}px` : `-150px`}`
            },
            tablet: {
                '--positionEditLeft': `${-positionOfClickedElement.left + (width <= 811 ? 0 : 100)}px`
            },
            mobile: {
                '--positionEditRight': `-70px`
            }
        }
    }, [purePosElem, width, positionOfClickedElement, editWidth, swiperSliderPerView])

    const initState = useCallback((description) => {
        const botsInfoBlocksFromHTML = convertFromHTML(description || "");
        const botsInfoState = ContentState.createFromBlockArray(
          botsInfoBlocksFromHTML.contentBlocks,
          botsInfoBlocksFromHTML.entityMap
        );
        setAboutBotEditorState(EditorState.createWithContent(botsInfoState));
    }, []);

    const saveAboutBotEditorState = useCallback(
      (state) => {
          const str = getHtmlStringText(
            draftToHtml(convertToRaw(state.getCurrentContent()))
          );
          const oldStr = getHtmlStringText(
            draftToHtml(convertToRaw(aboutBotEditorState.getCurrentContent()))
          );
          if (str.length <= 9999) {
              setAboutBotEditorState(state);
          } else {
              setAboutBotEditorState(aboutBotEditorState);

              if (oldStr.length !== str.length) {
                  botInfoRef?.current?.blur();
              }
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [aboutBotEditorState])

    const editBlock = (
      <div className={s.editBotsBlock}
           style={width > 1375 ?
             editBlockAdaptive.desktop :
             width > 692 ?
               editBlockAdaptive.tablet :
               editBlockAdaptive.mobile}>
          <img className={s.closeImg} src={CloseBtn} alt="" onClick={() => setBotId(null)}/>
          <div className={s.inputBlock}>
              <p className={s.web7}>Kingdom Name</p>
              <textarea enterKeyHint={'done'} className={s.editInput}
                        onChange={(value) => inputChange(value.target.value, 'kingdomName')}
                        value={bot.kingdomName}
                        placeholder={'Enter Kingdom name'}/>
          </div>
          <div className={s.inputBlock}>
              <p className={s.web7}>Rebel Bot Name</p>
              <textarea enterKeyHint={'done'} className={s.editInput} onChange={(value) => inputChange(value.target.value, 'name')}
                        value={bot.name}
                        placeholder={'Enter Rebel Bot name'}/>
          </div>
          <div className={`${s.inputBlock} ${s.inputBlock__textarea} desc_editor`}>
              <p className={s.web7}>Description</p>
              <Editor
                editorRef={(ref) => (botInfoRef.current = ref)}
                editorState={aboutBotEditorState}
                onEditorStateChange={saveAboutBotEditorState}
                wrapperClassName={s.editTextarea}
              />
          </div>
          {/*<button className={s.saveBtn}*/}
          {/*        disabled={bot && (bot.name.trim() === '' && bot.kingdomName.trim() === '')}*/}
          {/*        style={{*/}
          {/*            width: dimension.width,*/}
          {/*            height: dimension.height,*/}
          {/*            background: `url('/assets/saveBtn_texture${dimension.texture}.svg') no-repeat center / 100%`*/}
          {/*        }} onClick={() => editChange()}>*/}
          {/*    Save*/}
          {/*</button>*/}
          <div className={`${s.saveBtn} `}>
              <button
                className={`black button-type1 `}
                onClick={() => editChange()}
                disabled={bot && (bot.name.trim() === '' && bot.kingdomName.trim() === '')}
              >
                  <span className="button-type1-before" />
                  <span className="label ">Save</span>
                  <span className="button-type1-after" />
              </button>
          </div>
      </div>
    )

    const inputChange = (value, name) => {
        if (value.length >= 64) return
        setBot((bot) => ({
            ...bot,
            [name]: value.trim() !== '' ? value : ''
        }))
    }

    const setTooltipSettings = (event, id) => {
        const {left, top} = event.target.getBoundingClientRect();
        setPosition({
            left,
            top
        })
        setBotId(id)
        refresher(false)
    }

    const assetImages = useMemo(() => (
      type !== 'fightingBots' ? {
          width: '237px',
          height: '237px'
      } : {
          width: '295px',
          height: '295px',
          position: 'relative',
          left: '-30px',
          marginTop: '-5px',
      }
    ), [type])

    return (
      <div className={s.assetsList}>
          <ClaimBotModal open={openModal} handleClose={() => setOpenModal(false)} text={modalText.content}
                         title={modalText.title}/>
          {
              width > 1042 ?
                <div style={{position: 'relative'}}>
                    <Swiper
                      effect={'fade'}
                      slidesPerView={swiperSliderPerView}
                      spaceBetween={10}
                      pagination={{
                          clickable: true,
                          dynamicBullets: true
                      }}
                      onReachEnd={() => refresher(prev => !prev)}
                      slidesPerGroup={swiperSliderPerView}
                      navigation={true}
                      className='mySwiper'
                      modules={[Pagination, Navigation]}
                      onNavigationNext={() => setBotId(null)}
                      onNavigationPrev={() => setBotId(null)}
                    >
                        {assets.map(asset => (
                          <SwiperSlide key={asset.id}>
                              <div className={s.assetItem}>
                                  <h5 className='web9'>{fixAssetId(asset.id)}</h5>
                                  {type === 'bots' && <>
                                      <EditTooltip title={editBlock} open={botId === asset.id}>
                                          <div className={s.assetEditIconBots}
                                               onClick={(event) => setTooltipSettings(event, asset.id)}/>
                                      </EditTooltip>
                                  </>
                                  }
                                  {type !== 'fightingBots' &&
                                    <p
                                      className={`web56 ${s.assetItem__title}`}>{type === 'Lands' ? fixAssetId(asset.kingdomId) : fixKingdomName(asset.kingdomName) !== '' ? fixKingdomName(asset.kingdomName) : asset.code}</p>
                                  }
                                  <div className={s.assetItemImageBlock}
                                       onClick={type === 'bots' ? (() => (window.location.href = `profile/${asset?.id}`)) : null}
                                  >
                                      <img style={assetImages} src={asset.img} alt="asset"/>
                                      {type !== 'fightingBots' && <div className={s.assetItemGlass}></div>}
                                  </div>
                                  {
                                      itemContent(asset, type, claimFetch, claimedAssets, passiveSkills)
                                  }
                              </div>
                          </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                :
                assets.map(asset => (
                  <div key={asset.id} className={s.assetItem}>
                      <h5 className={s.assetAssetIdText}>{fixAssetId(asset.id)}</h5>
                      {type === 'bots' && <>
                          <EditTooltip title={editBlock} open={botId === asset.id}>
                              <div className={s.assetEditIconBots}
                                   onClick={(event) => setTooltipSettings(event, asset.id)}/>
                          </EditTooltip>
                      </>}
                      <p className={`web56 ${s.assetItem__title}`}>{type === 'Lands' ? fixAssetId(asset.kingdomId) : fixKingdomName(asset.kingdomName) !== '' ? fixKingdomName(asset.kingdomName) : asset.code}</p>
                      <div className={s.assetItemImageBlock}
                           onClick={type === 'bots' ?  (() => (window.location.href = `profile/${asset?.id}`)) : null}
                      >
                          <img style={assetImages} src={asset.img} alt="asset"/>
                          {type !== 'fightingBots' && <div className={s.assetItemGlass}></div>}

                      </div>
                      {
                          itemContent(asset, type, claimFetch, claimedAssets, passiveSkills)
                      }
                  </div>
                ))
          }
      </div>
    );
};

const EditTooltip = tooltipFactory(true, {
    color: '#FFC328',
    backgroundColor: '#FFC328',
    fontSize: 16,
    maxHeight: 100,
    maxWidth: 100,
    padding: '4px 0',
    top: '20.87px',
}, {
    top: '-7px !important',
    left: '-17px !important',
    color: '#FFC328',
    width: '39.89px',
    height: '17.63px',
}, 'bottom-start')

const itemContent = (asset, type, claim, claimedAssets, passiveSkills) => {
    const canClick = claimedAssets && claimedAssets.indexOf(asset) === -1 && !asset.botsClaimed ? (() => claim(asset, false)) : null

    return (
      'botsClaimed' in asset ?
        <div className={s.assetItem__claimed}
             onClick={canClick}>
            {/*Fighting bots: <span*/}
            {/*  style={{*/}
            {/*      color: `${asset.botsClaimed || claimedAssets.indexOf(asset) !== -1 ? '#FFF' : '#FFC328'}`,*/}
            {/*      fontWeight: `${asset.botsClaimed || claimedAssets.indexOf(asset) !== -1 ? '300' : '700'}`*/}
            {/*  }}>{asset.botsClaimed || claimedAssets.indexOf(asset) !== -1 ? 'Claimed' : 'Claim bots'}</span>*/}
        </div>
        : type === 'fightingBots'
          ? <FightingBotsItem passiveSkills={passiveSkills} {...asset}/>
          : <div>
              {/*<p className={s.assetItem__claimed}*/}
              {/*   onClick={!asset.revenueClaimed && (() => claim(asset.id, false))}>Land: <span*/}
              {/*    style={{*/}
              {/*        color: `${asset.revenueClaimed ? '#FFF' : '#FFC328'}`,*/}
              {/*        fontWeight: `${asset.revenueClaimed ? '300' : '700'}`*/}
              {/*    }}>{asset.revenueClaimed ? 'Claimed' : 'Claim land'}</span></p>*/}
              <p className={s.assetItem__claimed}>Revenue share: <span
                style={{color: '#FFC328', fontWeight: 900}}>{asset.revShare}%</span></p>
              <p className={s.assetItem__claimed}>Kingdom lands: <span
                className={s.assetItem__claimed__span}>{asset.soldLands}/{asset.totalLands}</span></p>
              <p className={s.assetItem__claimed}>Treasury: <span
                  className={s.assetItem__claimed__span}> <img src={xoilIcon} data-alt='xoil' alt="xoil"/> {asset.treasury ? asset.treasury / 1e18 : 0}</span></p>
          </div>
    )
}

export default AssetList;