import React from 'react';
import animoca from "../../../../Image/animoca.png";
import ubisoft from "../../../../Image/ubisoft.png";
import overwolf from "../../../../Image/overwolf.png";
import makersfund from "../../../../Image/makersfund.png";
import remagine from "../../../../Image/remagine.png";
import polygon from "../../../../Image/polygon.png";
import nodecapital from "../../../../Image/nodecapital.png";
import ldcapital from "../../../../Image/ldcapital.png";
import metavest from "../../../../Image/metavest.png";
import mvst from "../../../../Image/mvst.png";
import cointelligence from "../../../../Image/cointelligence.png";
import blockchain from "../../../../Image/blockchain.png";
import tykheblock from "../../../../Image/tykheblock.png";

const HomePartners = () => {
  return (
    <div className="partners">
      <div className="container">
        <h3>Investors and partners</h3>
        <div className="partners-list">
          <div className="partners-list-primary">
            <a
              className="partners-list__item"
              href="https://www.animocabrands.com/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={animoca} alt="Animoca" title="Animoca" />
            </a>
            <a
              className="partners-list__item"
              href="https://www.ubisoft.com/en-us/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={ubisoft} alt="Ubisoft" title="Ubisoft" />
            </a>
            <a
              className="partners-list__item"
              href="https://www.overwolf.com/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={overwolf} alt="Overwolf" title="Overwolf" />
            </a>
          </div>
          <div className="partners-list-secondary">
            <a
              className="partners-list__item"
              href="https://makersfund.com/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src={makersfund}
                alt="Makers Fund"
                title="Makers Fund"
              />
            </a>
            <a
              className="partners-list__item"
              href="https://www.remagineventures.com/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src={remagine}
                alt="Remagine Ventures"
                title="Remagine Ventures"
              />
            </a>
            <a
              className="partners-list__item"
              href="https://polygon.technology/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={polygon} alt="Polygon" title="Polygon" />
            </a>
            <a
              className="partners-list__item"
              href="https://www.node.capital/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src={nodecapital}
                alt="Node.capital"
                title="Node.capital"
              />
            </a>
            <a
              className="partners-list__item"
              href="https://ldcap.com/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src={ldcapital}
                alt="LD capital"
                title="LD capital"
              />
            </a>
          </div>
          <div className="partners-list-other">
            <a
              className="partners-list__item"
              href="https://metavest.capital/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={metavest} alt="Metavest" title="Metavest" />
            </a>
            <a
              className="partners-list__item"
              href="https://mvstmg.com/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={mvst} alt="MVST" title="MVST" />
            </a>
            <a
              className="partners-list__item"
              href="https://www.cointelligence.com/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src={cointelligence}
                alt="Coin intelligence"
                title="Coin intelligence"
              />
            </a>
            <a
              className="partners-list__item"
              href="https://blockchainff.com/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src={blockchain}
                alt="Blockchain FF"
                title="Blockchain FF"
              />
            </a>
            <a
              className="partners-list__item"
              href="https://tykheblock.ventures/#/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src={tykheblock}
                alt="Tykhe Ventures"
                title="Tykhe Ventures"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePartners;