import s from './styles.module.scss';
import React, {useEffect, useMemo, useRef, useState} from "react";
import {DropdownSearch} from "../ui/DropdownSearch";
import {useDimensions} from "../../hooks/useDimensions";
import {useDebounce} from "../../hooks/useDebounce";
import {Input} from "../ui/Input";
import {commaNumber, fixAssetId, fixKingdomName} from "../../utils/asset.utils";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import XoilIcon from '../../Image/xoil_icon.png'

const Lookup = ({type, searchFetcher, assetFetcher, hasFactoryCodeSearch, lookupPlaceholder = 'Search by SN'}) => {
    const [options, setOptions] = useState([]);
    const dropdownRef = useRef(null);
    const {outside, setOutside} = useOutsideClick(dropdownRef)

    const [search, setSearch] = useState({
        searchValue: "",
        searchData: {},
        isLoading: undefined,
    });

    const [asset, setAsset] = useState();

    const onSearch = useDebounce((value) => {
        if (value.length >= 1) {
            if (type === 'Guild' || type === 'leaderboard') {
                return searchFetcher(value)
            }

            if (!isNaN(value) && type === 'Lands') {
                assetFetcher(value).then(res => {
                    if (!res) {
                        setAsset(null)
                        return setSearch((prev) => ({
                            ...prev,
                            isLoading: false,
                            searchData: {},
                        }));
                    }
                    setAsset(res)
                })
            }
            if (hasFactoryCodeSearch) {
                searchFetcher(value)
                    .then((data) => {
                        setSearch((prev) => {
                            if (!prev?.content?.length) setAsset(null)
                            return ({
                                ...prev,
                                isLoading: false,
                                searchData: data,
                            })
                        });
                    })
                    .catch(() => {
                        setSearch((prev) => ({
                            ...prev,
                            isLoading: false,
                            searchData: {},
                        }));
                    });
            }
        } else {
            if (type === 'Guild' || type === 'leaderboard') {
                searchFetcher(null)
            }
            setSearch((prev) => ({
                ...prev,
                searchData: {
                    ...prev.searchData,
                    content: [],
                },
            }));
            setAsset(null)
        }
    }, 500);

    const initialSearchOptions = useMemo(() => {
        return search.searchData?.content?.map(({id, code}) => ({
            id,
            title: `${code} / #${id}`,
            onClick: () => {
                assetFetcher(id).then(res => setAsset(res))
                setOutside(true)
            },
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, type]);

    useEffect(() => {
        setOptions(initialSearchOptions);
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [search.searchData]);

    useEffect(() => {
        onSearch(search.searchValue);
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [search.searchValue]);

    const {width} = useDimensions()
    const openseaBaseUrl = type === 'Lands' ? 'https://opensea.io/assets/matic/0x577682bde6873022525948e91487abdf80f71c99/' : 'https://opensea.io/assets/ethereum/0xbbe23e96c48030dc5d4906e73c4876c254100d33/'

    const lookupStyle = useMemo(() => {
        return type !== 'Lands' ? {
            paddingLeft: width > 1024 ? '150px' : width > 768 ? '50px' : '20px',
            marginTop: width > 1042 ? '52.42px' : width > 692 ? '113.82px' : '75.06px',
            marginBottom: asset ? '0' : '153.92px'
        } : {
            paddingLeft: width > 1024 ? '150px' : width > 768 ? '50px' : '20px',
            marginTop: width > 1042 ? '92.92px' : width > 692 ? '114.51px' : '54.66px',
            marginBottom: asset ? '0' : '153.92px'
        }
    }, [type, width, asset])

    return (
        <>
            <div className={s.assetLookup} style={lookupStyle} data-alt='assetsLookup'>
                <div className={s.search_block}>
                    <p className={s.medium_text}>
                        {
                            width > 1024 ?
                              <>
                                <span>{type === 'Rebel bot claim' || type === 'Rebel bot search' ? 'Rebel bot' : type === 'Guild' ? 'Guilds'  : type === 'leaderboard' ? 'leaderboard' : 'Land'} </span>LOOKUP
                                :
                              </>
                              : <>{type === 'Rebel bot claim' || type === 'Rebel bot search' ? 'Rebel bot' : type === 'Guild' ? 'Guilds' : type === 'leaderboard' ? 'leaderboard' : 'Land'}<span> LOOKUP:</span></>
                        }
                    </p>
                    <div ref={dropdownRef} className={s.input_block}>
                        <DropdownSearch
                            options={[options]}
                            isOpen={type === 'Guild' ? false : !outside}
                            search={search}
                            landsLookup={type === 'Rebel bot'}
                        >
                            <Input
                                type={hasFactoryCodeSearch ? 'text' : 'number'}
                                onWheel={event => event.currentTarget.blur()}
                                value={search?.searchValue}
                                onFocus={() => setOutside(false)}
                                onChange={(e) =>
                                    setSearch((prev) => ({
                                        ...prev,
                                        isLoading: true,
                                        searchValue: e.target.value,
                                        searchData: {},
                                    }))
                                }
                                placeholder={lookupPlaceholder}
                                onClear={() => {
                                    setSearch({
                                        searchValue: "",
                                        isLoading: undefined,
                                        searchData: {},
                                    });
                                    setAsset(null)
                                    setOptions([]);
                                }}
                                data-type='search'
                            />
                        </DropdownSearch>
                    </div>
                </div>
            </div>
            {
                (asset && !!search.searchValue.length) &&
                <div className={s.asset}>
                    <div className={s.asset__content}>
                        <img className={s.asset__image} src={asset?.img} alt="land"/>
                        <div className={`${s.asset__text}`}>
                            <h5 onClick={type !== 'Lands' ? (() => (window.location.href = `profile/${asset?.id}`)) : null}>{fixAssetId(asset?.id)}</h5>
                            <div className={`${type === 'Lands' ? '' : s.asset__text_bot}`}>
                                <p>
                                    <b>Kingdom:</b> {type === 'Lands' ? fixAssetId(asset?.kingdomId) : fixKingdomName(asset.kingdomName) !== '' ? fixKingdomName(asset.kingdomName) : asset.code}
                                </p>
                                {
                                    type === 'Lands'
                                        ? <p><b> Fighting bots:</b> {asset?.botsClaimed ? 'claimed' : 'not claimed'}</p>
                                        : <>
                                            {type === 'Lands' && <p><b>Land: ${asset?.code}</b></p>}
                                            <p><b>Revenue share:</b> {asset?.revShare}%</p>
                                            <p><b>Treasury:</b> <img alt='xoil' className={s.xoil_icon} src={XoilIcon}/>
                                                {commaNumber(Number(asset?.treasury) / 1e18)}
                                            </p>
                                            <p><b>Kingdom lands:</b> {asset?.soldLands} / {asset?.totalLands}</p>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        width > 1024 &&
                        <div className={s.asset__buttonContainer}>
                            <a
                                className={`button-type1 ${s.openSeaBtn}`}
                                href={openseaBaseUrl + asset?.id}
                                target={"_blank"}
                                rel="noreferrer"
                                data-regular="Buy Now"
                            >
                                <span className="label">View on OpenSea</span>
                            </a>
                        </div>
                    }
                </div>
            }
            {
                (width <= 1024 && asset?.id !== undefined && !!search.searchValue.length) &&
                <div className={s.asset__buttonContainer}>
                    <a
                        className={`button-type1 ${s.openSeaBtn}`}
                        href={openseaBaseUrl + asset?.id}
                        target={"_blank"}
                        rel="noreferrer"
                        data-regular="Buy Now"
                    >
                        <span className="label">View on OpenSea</span>
                    </a>
                </div>
            }
        </>
    )
}

export default Lookup;