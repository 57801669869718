import React from 'react';
import s from './styles.module.scss'

const FightingBotsMainBlock = () => {
  return (
    <div className={s.fightingBotsMainBlock}>
      <h2 className="web55"><span className='yellow'>fighting</span> bots</h2>
      <span className={s.promoText}>join the <span> xoil wars!</span></span>
      <div className={`${s.textWrapper} web3`}>
        <p>Get ready to build and command your Fighting Bot army to dominate the battle field!</p>
        <p>Select your team from Military, Industrial, and Engineer classes - each with their own specialized combat skills and abilities.</p>
      </div>
    </div>
  );
}

export default FightingBotsMainBlock;