import React from 'react';
import s from "./styles.module.scss";
import {useBotStats} from "../../../../hooks/useBotStats";
import EmptyBot from "../../../../Image/dis.svg";

const BuilderTeamItem = (props) => {
  const cards = Object.values(props).map(prop => prop.card).filter(Boolean)
  let cardsCount = 0

  for(let key in props){
    if(props[key].card){
      cardsCount += 1
    }
  }

  const {mainClass, hpMin, hpMax, passiveSkills} = useBotStats(props, cardsCount)

  return (
    <div className={`${s.builderTeamItem} ${props?.share ? s.shareHide : ''}` }>
      <div className={s.topWrapper}>
        <div className={s.bot}>
          {cards.map(card => <img src={card.partURI} className={`${s.part} ${s[card.part.split(' ').join('')]}`} key={s[card.part]} alt={card['part']}/>)}
          {!cards[0] && <img src={EmptyBot} alt="empty bot" className={s.emptyBot}/>}
        </div>
        <div>
          <div className={s.textBlockWrapper}>
            <div className={s.title}>class</div>
            <p>{mainClass}</p>
            <p>{hpMin} - {hpMax}</p>
          </div>
          <div className={s.textBlockWrapper}>
            <div className={s.title}>skills</div>
            {passiveSkills.map(skill => <p>{skill['Skill name']}</p>)}
          </div>
        </div>
      </div>
      <div className={s.cardsWrapper}>
        {
          cards.map(card =>
            <div key={props.id.toString() + card.cardID}>
              <img src={card.cardURI} alt=""/>
              <p>{card.part.split(' ')[0]} <span className={s.lowercase}>{card.part.split(' ')[1]}</span> </p>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default BuilderTeamItem;