import {api} from "api";

export const guildsApply = async (data) => {
  try {
    const formData = new FormData();
    const keys = Object.keys(data);

    keys.forEach((key) => {
      formData.append(key, data[key]);
    });

    return await api.post("/guilds/apply-v2", formData, {
      headers: {"Content-Type": "multipart/form-data"},
    });
  } catch (err) {
    throw new Error(err.response.status);
  }
};

export const getGamingGuilds = async () => {
  const guildsFile = window.config.ENVIRONMENT === 'prod' ? 'guilds.json' : 'guilds-stage.json';
  const url = 'https://cdn.rebelbots.com/guildsdirectory/' + guildsFile;
  return (await fetch(url)).json();
}
