import React from "react";

import { CareerBlock } from "./components/CareerBlock";
import { StandardLayout } from "components/layouts/StandardLayout";

import { jobsData } from "db/jobs";

import s from "./styles.module.scss";

const Career = () => {
  return (
    <StandardLayout isHeaderForced>
      <main className="main">
        <div className={s.container}>
          <p className={s.head_text}>
            <span>open</span> positions
          </p>
          <div className={s.career_wrapper}>
            <CareerBlock data={jobsData} />
          </div>
        </div>
      </main>
    </StandardLayout>
  );
};

export default Career;
