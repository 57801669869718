import React from 'react';
import {StandardLayout} from "../../components/layouts/StandardLayout";
import s from './styles.module.scss'
import FightingBotsMainBlock from "./components/FightingBotsMainBlock/FightingBotsMainBlock";
import FightingBotsBuilder from "./components/FightingBotsBuilder/FightingBotsBuilder";
import FightingBotsEnterBattle from "./components/FightingBotsEnterBattle/FightingBotsEnterBattle";
import FightingBotsCompetition from "./components/FightingBotsCompetition/FightingBotsCompetition";
import FightingBotsList from "./components/FightingBotsList/FightingBotsList";
import FightingBotsFavoriteClass from "./components/FightingBotsFavoriteClass/FightingBotsFavoriteClass";
import FightingBotsClassList from "./components/FightingBotsСlassList/FightingBotsСlassList";
import FightingBotsDeck from "./components/FightingBotsDeck/FightingBotsDeck";

const NftPage = () => {
  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
        <div className={s.container}>
          <FightingBotsMainBlock/>
          <FightingBotsBuilder/>
          <FightingBotsEnterBattle/>
          <FightingBotsCompetition/>
          <FightingBotsList/>
          <FightingBotsFavoriteClass/>
          <FightingBotsClassList/>
          <FightingBotsDeck/>

          <h1>join the xoil wars!</h1>
        </div>
      </main>
    </StandardLayout>
  );
}

export default NftPage;