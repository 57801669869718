import React from "react";
import s from "./teammember.module.scss";

export const TeamMember = ({ data, onClick }) => {
  return (
    <a
      href={`/team/${data.id}`}
      onClick={(e) => {
        onClick && onClick();
        e.target.blur();
      }}
      className={`${s.card} ${data.disabled ? s.disabled : ""}`}
    >
      <div
        className={s.avatarHolder}
        onClick={(e) => e.target.parentElement.blur()}
      >
        <div onClick={(e) => e.target.parentElement.parentElement.blur()}>
          <img
            className={s.avatar}
            alt="avatar"
            src={data.img.default}
            onClick={(e) =>
              e.target.parentElement.parentElement.parentElement.blur()
            }
          />
        </div>
      </div>
      <p className={s.name} onClick={(e) => e.target.parentElement.blur()}>
        {data.name}
      </p>
      <p className={s.position} onClick={(e) => e.target.parentElement.blur()}>
        {data.position}
      </p>
      <p
        className={s.description}
        onClick={(e) => e.target.parentElement.blur()}
      >
        {data.description}
      </p>
    </a>
  );
};
