export var config = {
    CONTRACT_ADDRESS: '0x1463682D94BadE4F1790BabCD138e68779908810',
    ETH_JSONRPC_URL: 'https://rinkeby.infura.io/v3/9d2fb1ea9b484e54826f657cba257432',
    SALE_START_RESTRICTED: 1627287707318,
    SALE_START_PUBLIC: 1630443600000,
    MAX_BATCH: 10,
    MAX_NFTS: 10000,
    NFT_PRICE: 1000000000000000,
    TEST_ENABLED: 1,
    SERVER_API: 'https://api-bots-dev.madskil.com',
    PHASE: 0,
    MAINTENANCE: false,
    SIGN_TEXT: 'Welcome! Nonce: %s',
    SIGN_SHOP_TEXT: 'Welcome to the store! Nonce: %s',
    MARKETPLACE_LINK: 'https://marketplace.rebelbots.com'
};