import React from 'react';
import s from './styles.module.scss'
import {StandardLayout} from "../../components/layouts/StandardLayout";
import CardBg from '../../Image/cardBg.svg'
import {useParams} from "react-router-dom";
import CardPageAbilities from "./components/CardPageAbilities";
import {useCardPage} from "./useCardPage";
import {useDimensions} from "../../hooks/useDimensions";

const CardPage = () => {
  const {id} = useParams()
  const {width} = useDimensions()
  const {card, abilities} = useCardPage(id)

  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>


        <h3>Card <span style={{ color: "#FFC328" }}> explorer</span></h3>

        <div className={s.cardWrapper}>
          {
            card && <img className={s.cardImg} src={card.cardURI} alt ="card"/>
          }
          {width > 765 && <CardPageAbilities abilities={abilities} card={card}/>}
          <img className={s.cardBg} src={CardBg} alt="card background"/>
        </div>
        {width < 765 && <CardPageAbilities  abilities={abilities} card={card}/>}
        {/*<Comments id={id} />*/}
      </main>
    </StandardLayout>
  );
}

export default CardPage