import React, {useContext, useEffect, useState} from 'react';
import s from './styles.module.scss'
import {useDimensions} from "../../../../hooks/useDimensions";
import {getMyLands} from "../../../../api/land";
import {LandSaleContext} from "../../../../Context/LandSaleWallet";
import {claimBots} from "../../../../blockchain.service/methods";
import AssetList from "../../../../components/AssetList/AssetList";
import ClaimBotModal from "../../../../components/Modals/ClaimLandModal/ClaimLandModal";
import ShowMoreArrow from "../../../../Image/showMoreArrow.svg";
import EmptyAssetsList from "../../../../components/EmptyAssetsList/EmptyAssetsList";

const YourLands = ({account}) => {
    const {width} = useDimensions()
    const [lands, setLands] = useState([])
    const {web3} = useContext(LandSaleContext)
    const [openModal, setOpenModal] = useState(false)
    const [showMorePageCount, setShowMorePageCount] = useState(1)
    const [modalText, setModalText] = useState({
        title: '',
        content: '',
        disabled: false,
        handleClose: () => {}
    })

    const [claimedAssets, setClaimedAssets] = useState([])
    const [isRefresh, setRefresh] = useState(false)
    const [page, setPage] = useState(0)

    const getBots = () => {
        if (page !== null) {
            getMyLands(account, page).then((data) => {
                const {landsData, hasNext} = data;
                if (landsData) {
                    setLands(prev => [...prev, ...landsData])
                }

                hasNext ? setPage(page => page + 1) : setPage(null)
            })
        }
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    // Pagination effect
    useEffect(() => {
        getBots();
    }, [isRefresh])

    const blockHandlerTransaction = async (lands, all) => {
        setModalText({
            title: 'Claim in progress',
            content: "Claiming fighting bots is in progress. Please wait.",
            disabled: true
        })
        const response = await claimBots(web3, lands, account, all, setOpenModal);
        if (!response) {
            setModalText({
                title: 'Transaction failed',
                content: "We couldn't provide the transaction, please try again",
                disabled: false
            })
        } else {
            setClaimedAssets(prev => Array.isArray(lands) ? prev.concat(lands) : [...prev, lands])
            setRefresh(true)
            setModalText({
                title: 'Claim complete',
                content: "Claiming Fighting Bots is now complete.",
                disabled: false
            })
        }
    }

    const blockchainHandlerWithModal = async (lands, all) => {
        const filteredLands = all ?
            lands.filter(el => !el.botsClaimed && claimedAssets.indexOf(el) === -1)
            : lands;
        if (filteredLands.length > window.config.CLAIM_LIMIT) {
            setModalText({
                title: 'Warning',
                content: 'Due to bock size restrictions, Fighting Bots can be claimed from 30 lands at a time. If you own more than 30 lands, please claim the Fighting Bots from additional Lands in a second transaction.',
                disabled: false,
                handleClose: () => blockHandlerTransaction(filteredLands.slice(0, window.config.CLAIM_LIMIT), all)
            })
            return setOpenModal(true)
        }
        blockHandlerTransaction(filteredLands, all);
    }

    // const landsForClaimed = useMemo(() => lands.filter(el => !el.botsClaimed && claimedAssets.indexOf(el) === -1).length, [claimedAssets, lands])

    return (
        <div className={s.yourLands}>
            <ClaimBotModal open={openModal} title={modalText.title}
                           handleClose={modalText.handleClose ? modalText.handleClose : () => setOpenModal(false)}
                           disabled={modalText.disabled} text={modalText.content}/>
            <h3><span className='yellow'> your </span> lands</h3>

            <div className={s.landsWrapper}>
                {
                    lands.length > 0 ?
                        <AssetList type={'Lands'} claimedAssets={claimedAssets} claimFetch={blockchainHandlerWithModal}
                                   refresher={lands.length > 4 ? setRefresh : console.log} assets={width > 1024 ? lands : lands.slice(0,  showMorePageCount * 4)}/>
                        : <EmptyAssetsList />

                }
            </div>
            {(width < 1024 && lands.length >= 4 * showMorePageCount) &&
              <div className={s.showMore} onClick={() => setShowMorePageCount(prevState => prevState + 1)}>
                  <span>Show more</span>  <img src={ShowMoreArrow} alt=""/>
              </div>}
            {/*{*/}
            {/*    lands.length > 0 &&*/}
            {/*    <div className={s.landsClaimBotsBtnBlock}>*/}
            {/*        <OutlineButton text={landsForClaimed ? 'Claim all Fighting Bots' : 'Fighting Bots Сlaimed'}*/}
            {/*                       variant={'claim'}*/}
            {/*                       disabled={!landsForClaimed}*/}
            {/*                       onClick={landsForClaimed ? () => blockchainHandlerWithModal(lands, true) : () => {*/}
            {/*                       }}/>*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    );
}

export default YourLands;