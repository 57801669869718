import React, {useState} from "react";
import s from "./styles.module.scss";

export const SearchInput = ({handleSearch}) => {
  const [searchText, setSearchText] = useState('')

  const handleSearchText = (data) => {
    setSearchText(data)
    handleSearch(data)
  }

  return (<div className={s.inputContainer}>
      <input value={searchText}
             onChange={(e) => handleSearchText(e.target.value)}

             placeholder={'Search for cards by Keyword'} className={s.styledInput}/>
    </div>);
};
