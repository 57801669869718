import React from "react";
import { useParams } from "react-router-dom";

import { jobsData } from "db/jobs";
import { useDimensions } from "hooks/useDimensions";
import LocationIcon from "Image/LocationIcon.svg";
import CustomButton from "components/CustomButton/CustomButton";
import { Back } from "components/functional/Back";

import s from "./styles.module.scss";
import { StandardLayout } from "components/layouts/StandardLayout";

const CareerDetail = () => {
  const { width } = useDimensions();
  const params = useParams();

  const data = jobsData[params.id];

  if(!data){
    window.location.href = `/careers`;
  }

  return (
    <StandardLayout isHeaderForced>
      <main className="main">
        <div className={s.container}>
          <Back className={s.backButton} to="/careers" />
          <div className={s.careerInfo}>
            <h1 className={s.careerTitle}>{data.title}</h1>
            <p className={s.careerLocation}>
              <img src={LocationIcon} alt="location" />
              {data.location}
            </p>
            <div className={s.badges}>
              <div className={s.badge}>{data.tag}</div>
            </div>
          </div>
          <div className={s.jobDetail}>
            <div className={s.jobDetail__descriptions}>
              <div className={s.jobDetail__description}>
                <h3 className={s.jobDetail__description__title}>
                  company introduction
                </h3>
                <p
                  dangerouslySetInnerHTML={{ __html: data.aboutCompany }}
                  className={s.jobDetail__description__body}
                ></p>
              </div>
              <div className={s.jobDetail__description}>
                <h3 className={s.jobDetail__description__title}>
                  job description
                </h3>
                <p
                  dangerouslySetInnerHTML={{ __html: data.jobDescription }}
                  className={s.jobDetail__description__body}
                ></p>
              </div>
              <div className={s.jobDetail__description}>
                <h3 className={s.jobDetail__description__title}>
                  responsibilities
                </h3>
                <div className={s.jobDetail__description__body}>
                  <ul className={s.descriptionList}>
                    {data.responsibilities.map((responsibility, key) => (
                      <li
                        key={key}
                        dangerouslySetInnerHTML={{ __html: responsibility }}
                      ></li>
                    ))}
                  </ul>
                </div>
              </div>
              {
                data?.skills &&
              <div className={s.jobDetail__description}>
                <h3 className={s.jobDetail__description__title}>
                  skills & qualifications
                </h3>
                <div className={s.jobDetail__description__body}>
                  <ul className={s.descriptionList}>
                    {data.skills.map((skill, key) => (
                      <li
                        key={key}
                        dangerouslySetInnerHTML={{ __html: skill }}
                      ></li>
                    ))}
                  </ul>
                </div>
              </div>
              }
              {
              data?.requirements &&
              <div className={s.jobDetail__description}>
                <h3 className={s.jobDetail__description__title}>
                  Requirements
                </h3>
                <div className={s.jobDetail__description__body}>
                  <ul className={s.descriptionList}>
                    {data.requirements.map((skill, key) => (
                      <li
                        key={key}
                        dangerouslySetInnerHTML={{ __html: skill }}
                      ></li>
                    ))}
                  </ul>
                </div>
              </div>
              }
              {
              data?.advantage &&
              <div className={s.jobDetail__description}>
                <h3 className={s.jobDetail__description__title}>
                  Advantage
                </h3>
                <div className={s.jobDetail__description__body}>
                  <ul className={s.descriptionList}>
                    {data.advantage.map((skill, key) => (
                      <li
                        key={key}
                        dangerouslySetInnerHTML={{ __html: skill }}
                      ></li>
                    ))}
                  </ul>
                </div>
              </div>
              }
              {data.whyWorkForUs && (
                <div className={s.jobDetail__description}>
                  <h3 className={s.jobDetail__description__title}>
                    why work for us
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: data.whyWorkForUs }}
                    className={s.jobDetail__description__body}
                  ></p>
                </div>
              )}
              {data.team && (
                <div className={s.jobDetail__description}>
                  <h3 className={s.jobDetail__description__title}>The Team:</h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: data.team.text_first }}
                    className={s.jobDetail__description__body}
                  ></p>
                  <div className={s.jobDetail__description__body}>
                    <ul className={s.descriptionList}>
                      {data.team.teamMembers.map((member, key) => (
                        <li
                          key={key}
                          dangerouslySetInnerHTML={{ __html: member }}
                        ></li>
                      ))}
                    </ul>
                  </div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.team.text_second,
                    }}
                    className={s.jobDetail__description__body}
                  ></p>
                </div>
              )}
              <div className={s.jobDetail__description}>
                <h3 className={s.jobDetail__description__title}>
                  What we are offering
                </h3>
                <div className={s.jobDetail__description__body}>
                  <ul className={s.descriptionList}>
                    {data.offers.map((offer, key) => (
                      <li
                        key={key}
                        dangerouslySetInnerHTML={{ __html: offer }}
                      ></li>
                    ))}
                  </ul>
                </div>
              </div>
              {data.personalSkills && (
                <div className={s.jobDetail__description}>
                  <h3 className={s.jobDetail__description__title}>
                    personal skills
                  </h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: data.personalSkills }}
                    className={s.jobDetail__description__body}
                  ></p>
                </div>
              )}
              <span>
                If you do want to obtain rapid career growth in the fast-growing
                and well-established company, and be a part of a dream-team,
                come and join us!
              </span>
            </div>
            <CustomButton
              label="Apply for this Position"
              callback={() => setTimeout(() => window?.open("mailto: jobs@xoilium.com"))}
              texture={
                width < 576
                  ? "submit-application-mobile.svg"
                  : width < 1024
                  ? "submit-application-tablet.svg"
                  : "submit-application.svg"
              }
            />
          </div>
        </div>
      </main>
    </StandardLayout>
  );
};

export default CareerDetail;
