import React, {useEffect, useState} from 'react';
import FilterArrowIcon from '../../../Image/filterArrow.svg'
import s from './styles.module.scss'
import {Checkbox} from "../Checkbox";
import {useDimensions} from "../../../hooks/useDimensions";

export const DropdownFilter = ({title, options, setFilters, showTitle}) => {
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(Object.values(options).includes(false))
  }, [options])

  const { width } = useDimensions();

  let optionsCount = Object.values(options).filter((el) => !el).length
  optionsCount > 1 && optionsCount--;

  return (
    <div className={s.dropdownFilter}>
      <div className={s.dropdownTop} onClick={() => setOpen(!open)}>
        <p style={{color: active ? '#FFC328' : '#ffffff'}} className={s.title}>{showTitle || title}</p>
        <div className={s.arrowWrapper}>
          {width > 576 && <p className={s.subtitle}>{optionsCount}</p>}
          <img style={{transform: `rotate(${open ? 0 : 180}deg)`, transition: 'transform 0.2s'} } src={FilterArrowIcon} alt="FilterArrowIcon"/>
        </div>
      </div>
      <div className={`${s.filters} ${open ? s.open : ''}`}>
        {
          Object.keys(options).map(option => <Checkbox key={title+option}
                                                       value={options[option]}
                                                       setFilters={setFilters}
                                                       label={option}
                                                       title={title}
          />)
        }
      </div>
    </div>);
}
