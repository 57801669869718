import {useEffect, useState} from "react";
import {getCard, getKeywords} from "../../api/card";

export const useCardPage = (id) => {
  const [card, setCard] = useState(null)
  const [keywords, setKeywords] = useState(null)
  const [abilities, setAbilities] = useState([])

  useEffect( () => {
    getKeywords()
      .then(({data}) => {
        setKeywords(data)
      })

    getCard(id)
      .then(({data}) => {
        setCard(data)
      })
  }, [id])

  useEffect(() => {
    if(card && keywords){
      const newAbilities = []

      card.abilities.forEach(ability => {
        for (let key in keywords){
          if(keywords[key].Name === ability){
            newAbilities.push(keywords[key])
          }
        }
      })

      setAbilities(newAbilities)
    }
  }, [keywords, card])

  return {
    abilities,
    card
  }
}