import React from 'react';
import {StandardLayout} from "../../components/layouts/StandardLayout";
import s from './styles.module.scss'
import NftPageMain from "./compoents/NftPageMain/NftPageMain";
import NftPageLands from "./compoents/NftPageLands/NftPageLands";
import NftPageBots from "./compoents/NftPageBots/NftPageBots";

const NftPage = () => {
  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
        <div className={s.container}>
          <NftPageMain />
          <NftPageLands />
          <NftPageBots />
        </div>
      </main>
    </StandardLayout>
  );
}

export default NftPage;