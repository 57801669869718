import React from 'react';
import {StandardLayout} from "../../components/layouts/StandardLayout";
import s from "./styles.module.scss";
import {Link} from "react-router-dom";
import {useSupport} from "../../hooks/useSupport";

const Support = () => {
  const {categories, deleteTags, title} = useSupport()


  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
        <h3>Rebelbots Support Knowledge Base </h3>
        <p className={s.desc}>
          {title}
        </p>

        <div>
          {
            categories.map(category =>
              <div className={s.category} key={category['post-id']}>
                <Link to={'/support/' + category.name} >{category.name}</Link>
                <div>{deleteTags(category.content)}</div>
              </div>)
          }
        </div>
      </main>
    </StandardLayout>
  );
}

export default Support;