import React from "react";

import s from "./styles.module.scss";
import { ReactComponent as Clear } from "Image/clear.svg";

// Todo: Add input design types (ex. search)
export const Input = ({ value, onClear, ...rest }) => {
  return (
    <div className={s.search_border} data-type="search-input">
      <input value={value} className={s.search} {...rest} />
      {value && <Clear className={s.clear} onClick={onClear} />}
    </div>
  );
};
