import React from 'react';
import s from "../styles.module.scss";
import EmptyIcon from "../../../Image/emty.svg";

const CardPageAbilities = ({abilities, card}) => {
  return (
    <div className={s.ability__wrapper}>
      {
        abilities && abilities.map(ability =>
          <div key={card.cardID + ability.ID} className={s.ability}>
            <div className={s.ability__top}>
              <div>
                <span className={s.ability__title}>{ability.Name}</span>
                <img src={ability?.iconURL}
                     alt={'ability'}
                     onError={(e) => {e.target.onerror = null; e.target.src = EmptyIcon}}
                />

              </div>
              <span className={s.ability__type}>type: {ability.Grouping}</span>
            </div>
            <div className={s.ability__desc}>
              {ability['Description (in game)']}
            </div>
          </div>
        )
      }
    </div>
  );
}

export default CardPageAbilities;