import React, { useState} from 'react';
import s from './styles.module.scss'
import BuilderHeader from "./components/BuilderHeader";
import BuilderWrapper from "./components/BuilderWrapper";
import BuilderTeam from "./components/BuilderTeam/BuilderTeam";
import {useDimensions} from "../../hooks/useDimensions";
import SelectBot from "./components/SelectBot";

export const BotBuilder = ({isSelection, setIsSelection, selectedCards, removeCard, setFilters, bot, setBot, kingdomCard}) => {
  const [open, setOpen] = useState(true)
  const {width} = useDimensions()
  const isMobile = width >= 576

  return (
    <>
      <BuilderHeader open={open} setOpen={setOpen}/>

      <div id='builderWrapper'
           style={{marginBottom: open ? '80px': 0, marginTop: isMobile ? open ? '90px' : '40px' : open ? '130px' : '90px'}}
           className={`${s.botBuilder} ${selectedCards[1] ? s.botBuilder_team : ''} ${open ? s.open : ''}`}
      >
      <SelectBot setBot={setBot} bot={bot}/>

      {/*if the team is selected then the object will have 1, 2 or 3*/}
      {
        selectedCards[1]
          ? <BuilderTeam
            selectedCards={selectedCards}
            setIsSelection={setIsSelection}
            setFilters={setFilters}
            kingdomCard={kingdomCard}
          />
          : <BuilderWrapper bot={bot}
                            setFilters={setFilters}
                            isSelection={isSelection}
                            setIsSelection={setIsSelection}
                            selectedCards={selectedCards}
                            removeCard={removeCard}
          />
      }


      </div>

    </>

  );
}

