import React from 'react';
import s from './styles.module.scss'
import GamingGuildsMain from "./components/GamingGuildsMain/GamingGuildsMain";
import {StandardLayout} from "../../components/layouts/StandardLayout";
import GTable from "../../components/GuildTable/GTable";
import GuildsListBanner from "../../components/GuildsListBanner/GuildsListBanner";

const GamingGuilds = () => {
    return (
        <StandardLayout isHeaderForced={true}>
            <main className={s.background}>
                <div className={s.gamingGuilds}>
                    <GamingGuildsMain/>
                    <GuildsListBanner/>
                    <GTable/>
                </div>
            </main>
        </StandardLayout>
    );
}

export default GamingGuilds;