import React from "react";

import Header from "components/Header";
import Footer from "components/Footer";

const Dao = () => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <main className="dev">
          <div className="dev-planet" />
          <div className="container-center">
            <div className="dev-title">
              <h1>Coming soon</h1>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Dao;
