import {api} from "./index";

export const verify = async () => {
    const date = new Date();
    return await fetch(`${window.config.VERIFF_BASE_URL}/v1/sessions`, {
        mode: 'cors',
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify({
            verification: {
                "callback": "https://bots-dev.rebelbots.com",
                "timestamp": date.toISOString()
            }
        }),
        headers: {
            'Content-Type': 'application/json',
            'X-AUTH-CLIENT': window.config.VERIFF_KEY,
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
            'Access-Control-Allow-Origin': '*'
        },
        redirect: 'follow'
    })
}

export const buyBattleSetInit = async (tkNumber) => {
    await api.post('/battleset/init', {
        count: tkNumber,
        hash: localStorage.getItem('gHashBS')
    })
}

export const verifyPoll = async (sessionId) => {
    try {
        const response = await api.get(`${window.config.SERVER_API}/kyc/decision/${sessionId}`)
        switch (response?.data?.code) {
            case 'INTERNAL SERVER ERROR':
                return {status: null}
            case 9001:
                return {status: 'Positive'}
            case 9121:
                return {status: 'Review'}
            case 9103:
                return {status: 'Resubmit', reason: response?.data?.reason}
            case 9104:
                return {status: 'Expired'}
            default:
                return {status: 'Negative'}
        }
    } catch (e) {
        return undefined
    }
}