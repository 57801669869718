import {useEffect, useState} from "react";
import {HpRanges} from "../components/BotBuilder/components/HpRanges";
import {getPassiveSkills} from "../api/card";

export const useBotStats = (selectedCards, cardsCount) => {
  const [hpMin, setHpMin] = useState(0)
  const [hpMax, setHpMax] = useState(0)
  const [passiveSkills, setPassiveSkills] = useState(['Undetermined', 'Undetermined', 'Undetermined', 'Undetermined'])
  const [allPassiveSkills, setAllPassiveSkills] = useState([])

  const [mainClass, setMainClass] = useState('Undetermined')

  const botClasses = {
    Military: 0,
    Engineer: 0,
    Industrial: 0
  }

  useEffect(() => {
    setHpMin(0)
    setHpMax(0)
    for(let key in selectedCards){
      if(selectedCards[key].card){
        const {type: botClass, rarity, part  } = selectedCards[key].card

        botClasses[botClass] += part === 'Head' ? 40 : 15

        setHpMin(prevState => prevState + HpRanges[botClass][rarity][0] )
        setHpMax(prevState => prevState + HpRanges[botClass][rarity][1] )
      }
    }

    if(cardsCount === 5){
      const newMainClass = Object.entries(botClasses)
        .reduce((acc, curr) => acc[1] > curr[1] ? acc : curr)[0]

      const newPassiveSkills = allPassiveSkills.filter(skill => skill.Class.toLowerCase() === newMainClass.toLowerCase())
      setMainClass(newMainClass)
      setPassiveSkills(newPassiveSkills)
    }else{
      setPassiveSkills(['Undetermined', 'Undetermined', 'Undetermined', 'Undetermined'])
      setMainClass('Undetermined')
    }
  }, [selectedCards, allPassiveSkills]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getPassiveSkills()
      .then(({data}) => {
        setAllPassiveSkills(data)
      })
  }, [])



  return {
    hpMin,
    hpMax,
    mainClass,
    passiveSkills
  }
}