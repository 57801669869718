export const initialFilters =  {
  part: {
    'Select all': true,
    'Kingdom': true,
    'Head': true,
    'Left Arm': true,
    'Right Arm': true,
    'Torso': true,
    'Legs': true
  },
  rarity: {
    'Select all': true,
    'common': true,
    'rare': true,
    'epic': true,
    'legendary': true
  },
  attack: {
    'Select all': true,
    'Melee': true,
    'Ranged': true,
    'Tactic': true,
  },
  abilities: {
    'Select all': true,
    'Absorb': true,
    'Anticipate': true,
    'Avenge': true,
    'Burn': true,
    'Cost': true,
    'Dazed': true,
    'Deal-Damage': true,
    'Discard': true,
    'Dodge': true,
    'Downgrade': true,
    'Draw': true,
    'Energy': true,
    'Fixate': true,
    'Fizzle': true,
    'Follow-Up': true,
    'Frail': true,
    'Grounded': true,
    'Lucky': true,
    'Overheat': true,
    'Overload': true,
    'Pierce': true,
    'Power-Up': true,
    'power': true,
    'Processing': true,
    'Purge': true,
    'Ransomware': true,
    'Rapid-Fire': true,
    'Recycle': true,
    'Reflect': true,
    'Relocate': true,
    'Repair': true,
    'Revenge': true,
    'Shield': true,
    'Spiked': true,
    'Target-Lock': true,
    'Taunt': true,
    'Unlucky': true,
    'Weak': true,
    'Zap': true,
  },
  target: {
    'Select all': true,
    'all': true,
    'closest': true,
    'everyone': true,
    'furthest': true,
    'opponent': true,
    'random': true,
    'self': true,
    'target': true,
    'you': true,
  },
  type: {
    'Select all': true,
    'Industrial': true,
    'Military': true,
    'Engineer': true,
  }
}