import React from 'react';
import s from "../../../components/GuildTable/GuildRow/styles.module.scss";

const TradingLeaderboardTableRow = ({trades, address, id}) => {

  return (
    <tr key={address} className={s.table__row}>
      <td className={s.table__row__guild}>
        <p className={s.table__row__guild__rating}>{id + 1} </p>
        <p className={s.table__row__guild__name}>{address}</p>
      </td>
      <td>
        <p className={s.table__row__guild__name}>{trades}</p>
      </td>
    </tr>
  )
}

export default TradingLeaderboardTableRow;
