import React from 'react';
import s from './styles.module.scss'

const HomeEmailForm = () => {

  return (
    <div className={`subscribe ${s.homeEmailForm}`}>
      <div className="container">
        <div className="subscribe-content js-form-holder">
          <h4 data-sent="WELCOME TO THE RESISTANCE!">
            are you ready to start your journey?
          </h4>
          <div className="form js-form">
            <div className="form-input js-form-input">
              <div className="form-input-bg">
                <svg viewBox="0 0 1760 76">
                  <path d="M1 26.4412L23.5077 1M216.809 71.5L208.203 75M518.015 75L512.058 71.5" />
                  <path
                    d="M512.058 71.5H216.809M208.203 75H1V26.4412M23.5077 1H73.8191M682.851 75H1760M672.921 75H518.015"
                    shapeRendering="crispEdges"
                  />
                </svg>
              </div>
              <div className="form-input-bg-phone phone">
                <svg viewBox="0 0 1322 58">
                  <path d="M58 1H18L1 20.2157V57H157.5L164 54.2549H1321" />
                </svg>
                <svg className="border-right" viewBox="0 0 1 58">
                  <path d="M.5 54.2549V1" />
                </svg>
              </div>
              <input
                className="js-email-input"
                type="email"
                inputMode="email"
                placeholder="Enter your Email"
              />
              <label>
                          <span className="js-subscribe-label">
                            Enter your Email
                          </span>
              </label>
            </div>
            <button className="button-submit disabled js-subscribe-submit">
                        <span className="button-submit-bg">
                          <svg viewBox="0 0 188 78">
                            <path d="M0 78V0H188V52.4598L165 78Z" />
                          </svg>
                        </span>
              <span className="button-submit-loading">
                          <svg viewBox="0 0 188 78">
                            <path d="M0.638672 76.804V1.33691H187.32V52.4598L164.732 76.804H0.638672Z" />
                          </svg>
                        </span>
              <span className="label">Submit</span>
            </button>
          </div>
          <div className="form-success">
            <p>Be in the lookout for an email from us</p>
            <svg viewBox="0 0 126 119">
              <path
                d="M125.5 44.1029L38.5435 118.666L0.499992 86.0883V42.6514L38.5435 75.2291L125.5 0.666016V44.1029Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeEmailForm;