export const GTableSizes = {
  'Platoon': 1,
  'Troop': 2,
  'Battalion': 3,
  'Regiment': 4,
  'Brigade': 5,
  'Empire': 6,
}


export const sortBySize = arr => {
  return arr.sort(function(a, b) {
    return a.sizeInNumber - b.sizeInNumber;
  })
}

export const setId = arr => {
  return arr.map((el, index) => ({...el, id: index}))
}

export const search = (arr, value) => {
  return setId(arr.filter(el => el.name?.toLowerCase().includes(value.toLowerCase())))
}

