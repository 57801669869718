import React, {useEffect, useState} from 'react';
import {StandardLayout} from "../../components/layouts/StandardLayout";
import s from "./styles.module.scss";
import {useSupport} from "../../hooks/useSupport";
import {useParams} from "react-router-dom";

const SupportItem = () => {
  const {id} = useParams();
  const {post} = useSupport(null,  id)
  const imageBaseUrl = 'https://media.quickblog.co/images/uploadsthumb/'
  const [popupImageUrl, setPopupImageUrl] = useState('')
  const [popupIsOpen, setPopupIsOpen] = useState(false)

  useEffect(() => {
    document.querySelectorAll('.supportItem img').forEach(el => {
      el.addEventListener('click', (event) => {
        event.stopPropagation()
        event.preventDefault()
        setPopupImageUrl(event.target.src)
        setPopupIsOpen(true)
        // window.open(event.target.src)
      })
    })
  }, [post])

  if(!post){
    return <></>
  }



  return (
    <StandardLayout isHeaderForced={true}>
      <div className={`${s.modal} ${popupIsOpen ? s.modal_open : ''}`}>
        <span className={s.close} onClick={() => setPopupIsOpen(false)}>&times;</span>
        <img className={s.modal_content} src={popupImageUrl} id="img01" alt=''/>
      </div>
      <main className={s.background}>
        <div className={s.topWrapper}>
          {
            post['image-url'] !== 'NULL' && <img src={imageBaseUrl + post['image-url']} alt="land"/>
          }

          <h3>{post['name']}</h3>
        </div>

        <p className={`supportItem ${s.desc}`}
           dangerouslySetInnerHTML={{ __html:  post['content'] }}>
        </p>

      </main>
    </StandardLayout>
  );
}

export default SupportItem;