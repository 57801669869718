import React from 'react';
import s from './styles.module.scss'
import {useDimensions} from "../../../../hooks/useDimensions";

const FightingBotsEnterBattle = () => {

  const {width} = useDimensions()

  return (
    <>
      <div className={s.fightingBotsEnterBattle}>
        <div className={s.textWrapper}>
          <h3>{width > 576 ? <>enter <span > the battle</span></> : <> enter the <br /> battle </>}</h3>
          <p className={`fb-text`}>
            {
              width > 576
                ? "With 300 different parts, you'll have endless options to " +
                "build the ultimate team. Choose from 100 parts per class and 6 Kingdom Cards" +
                " to build your strategy and conquer the battlefield!"
                : 'Each fighting bot is built from 5 different parts ' +
                'which determine its unique abilities on the battlefield, and builds ' +
                'the deck that players will use to battle each other for victory.'
            }

          </p>
        </div>
      </div>
      <div className={s.promoText}>
        Build. Battle. Earn.
        <span>
        To the Victor goes
        </span>
        <span>
        the Spoils
        </span>
      </div>
    </>
  );
}

export default FightingBotsEnterBattle;