import React from 'react';
import s from './styles.module.scss'
import bannerImage from '../../../../Image/playDownloadBanner.png'
import downloadIcon from '../../../../Image/downloadIcon.svg'

const PlayDownload = () => {
  const downloadsArray = [
    {
      title: 'Windows',
      text1: 'Core i5 (or similar),',
      text2: ' 4gb RAM',
      link: ' https://cdn.rebelbots.com/builds/Rebel_Bots_Xoil_Wars_1.1.5.0.exe'
    },
    {
      title: 'Mac',
      text1: 'Core i5 (or similar),',
      text2: ' 4gb RAM',
      link: 'https://cdn.rebelbots.com/builds/Rebel_Bots_Xoil_Wars_1.1.5.0.zip'
    },
    {
      title: 'Android',
      text1: 'Android version',
      text2: ' 7.0',
      link: 'https://cdn.rebelbots.com/builds/Rebel_Bots_Xoil_Wars_1.1.5.0.apk'
    }
    // {
    //   title: 'iOS',
    //   text1: 'iOS version',
    //   text2: ' 15.7.10',
    //   link: '#'
    // }
  ]

  const downloadsItems = downloadsArray.map(el => (
    <div key={el.title}>
      <h5>{el.title}</h5>
      <p>{el.text1}</p>
      <p>{el.text2}</p>
      <a href={el.link}><img src={downloadIcon} alt=""/>Download</a>
    </div>
  ))

  return (
    <section className={s.playDownload}>
      <div className={s.topWrapper}>
        <img src={bannerImage} alt=""/>
        <h3><span className='yellow'>Choose Your </span>  Platform</h3>

      </div>

      <div className={s.buttons}>
        {downloadsItems}
      </div>
    </section>
  );
}


export default PlayDownload;