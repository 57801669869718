// Todo: refactor applyJob make sure it works with axios
// Todo: if it is possible reduce the formdata.append
export const applyJob = async (data) => {
  const formdata = new FormData();
  const cvFile = data.cv[0];
  const coverLetterFile = data.coverLetter?.[0];
  if (coverLetterFile && typeof coverLetterFile !== "undefined") {
    formdata.append("coverLetter", coverLetterFile, coverLetterFile.name);
  }
  formdata.append("cv", cvFile, cvFile.name);
  formdata.append("firstName", data.firstName);
  formdata.append("lastName", data.lastName);
  formdata.append("email", data.email);
  formdata.append("telegram", data.telegram);
  formdata.append("discord", data.discord);
  formdata.append("location", data.location);
  formdata.append("linkedin", data.linkedin);
  formdata.append("job", data.job);

  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };
  
  const { status } = await fetch(
    `${window.config.SERVER_API}/career/apply`,
    requestOptions
  );

  if (status > 299) {
    throw new Error(status);
  }
  // return await api.post('career/apply', data);
};
