import React from 'react';
import s from './styles.module.scss'
import BuilderTeamItem from "./BuilderTeamItem";
import BuilderButtons from "../BuilderButtons";
import KingdomCard from "../KingdomCard/KingdomCard";
import shareBuilderTeamBg from "../../../../Image/shareBuilderTeamBg.png"

const BuilderTeam = ({selectedCards, setIsSelection, setFilters, kingdomCard}) => {
  const cardsCount = Object.values(selectedCards).reduce((previousValue, currentValue) => {
    return previousValue + Object.values(currentValue).filter(el => Boolean(el.card)).length
  }, 0)

  return (
    <div id='builderTeam'>
      <div id='builderTeamWrapper' className={`${s.builderTeam}`}>
        <KingdomCard
          setFilters={setFilters}
          setIsSelection={setIsSelection}
          kingdomCard={kingdomCard}
          cardsCount={cardsCount}
        />

        <div className={s.shareItemsWrapper}>
          <img src={shareBuilderTeamBg} className={s.shareBuilderTeamBg} alt={'team backgound'}/>
          {Object.values(selectedCards).map(card => <BuilderTeamItem  key={card.id} {...card} />)}
        </div>

        {Object.values(selectedCards).map(card => <BuilderTeamItem share  key={card.id} {...card} />)}
      </div>

      <BuilderButtons cardsCount={(cardsCount === 15 && kingdomCard) ? 5 : false } isTeamBuilder={true} />
    </div>

  );
}

export default BuilderTeam;