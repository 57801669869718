import debounce from "lodash.debounce";
import { useEffect, useState } from "react";

// Todo: make hooks/ folder for hooks except utils
export const useDimensions = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const resize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    resize();
    window.addEventListener("resize", debounce(resize, 100));
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return {
    width: dimensions.width,
    height: dimensions.height,
  };
};
