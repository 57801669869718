import React, { useState, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import LocationIcon from "Image/LocationIcon.svg";
import iconSuccess from "Image/icon-success.svg";
import iconWarning from "Image/icon-warning.svg";
import { ReactComponent as Exit } from "Image/exit.svg";

import s from "./styles.module.scss";
import { jobsData } from "db/jobs";
import { applyJob } from "api/careers";
import { useDimensions } from "hooks/useDimensions";
import { Back } from "components/functional/Back";
import CustomButton from "components/CustomButton/CustomButton";
import { StandardLayout } from "components/layouts/StandardLayout";
import {
  validateEmail,
  validateLinkedInUrl,
  validateTelegram,
  validateDisord,
} from "utils/helpers";

const CareerApply = () => {
  const params = useParams();
  const { width } = useDimensions();

  const isLoadingSubmit = useRef(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isApplied, setIsApplied] = useState(false);

  const data = jobsData[params.id];
  const acceptance = ".pdf,.doc,.docx,.txt";

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm();

  /* eslint-disable react-hooks/exhaustive-deps */

  const onSubmit = handleSubmit(async (formData) => {
    try {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      if (!isLoadingSubmit.current) {
        isLoadingSubmit.current = true;
        await applyJob({
          ...formData,
          job: data.title,
        });
        setIsApplied(true);
        isLoadingSubmit.current = false;
      }
    } catch (err) {
      setIsApplied(false);
      isLoadingSubmit.current = false;
    }
  });

  const requiredErrors = useMemo(
    () =>
      Object.keys(errors)?.filter(
        (item) =>
          item !== "telegram" &&
          item !== "discord" &&
          item !== "coverLetter" &&
          item !== "linkedin"
      ).length,
    [
      errors.email,
      errors.location,
      errors.firstName,
      errors.lastName,
      errors.cv,
    ]
  );

  // Todo: create a function pluralize for later reusability
  const pluralized = requiredErrors > 1 ? "s" : "";

  return (
    <StandardLayout isHeaderForced>
      <main className="main">
        <div className={s.background}>
          {isApplied ? (
            <>
              <Back className={s.backButton} to="/careers" />
              <div className={s.appliedAlert}>
                <h3>Your application has been submitted!</h3>
                <p>Our HR department will contact you for the next steps</p>
                <p>Good luck!</p>
              </div>
            </>
          ) : (
            <>
              <div className={s.careerInfo}>
                <h1 className={s.careerTitle}>{data.title}</h1>
                <p className={s.careerLocation}>
                  <img src={LocationIcon} alt="location" />
                  {data.location}
                </p>
                <div className={s.badges}>
                  <div className={s.badge}>{data.tag}</div>
                </div>
              </div>
              <div className={s.applyJobTitle}>
                <span style={{ color: "#FFF" }}>apply</span>{" "}
                {width < 530 ? <br /> : ""}for this job
              </div>
              <form
                className={s.form}
                id="job-application"
                onSubmit={(e) => {
                  onSubmit(e);
                  setIsSubmitted(true);
                }}
              >
                {requiredErrors > 0 && (
                  <span className={s.errorFeedback}>
                    {`Missing response${pluralized} to ${requiredErrors} required question${pluralized}`}
                  </span>
                )}
                <div className={s.signDescription}>
                  <span style={{ color: "#E40B0B" }}>* </span>
                  Required
                </div>
                <div className={s.buttonBlock}>
                  <label className={s.labelText}>
                    Resume<span style={{ color: "#E40B0B" }}>*</span>
                  </label>
                  <div className={s.block}>
                    <div
                      className={s.blocks}
                      style={{ justifyContent: errors.cv ? "start" : null }}
                    >
                      {!!watch("cv")?.length && (
                        <>
                          <Exit
                            className={s.watchNameRemove}
                            onClick={() => {
                              setValue("cv", "");
                              clearErrors("cv");
                            }}
                          />
                          <p className={s.watchName}>
                            {watch("cv")[0]["name"]}
                          </p>
                        </>
                      )}
                      <input
                        style={{
                          display: watch("cv")?.length ? "none" : "inline",
                        }}
                        autoComplete="off"
                        className={s.formControl}
                        data-placeholder={
                          watch("cv")?.length > 0
                            ? watch("cv")[0]["name"]
                            : "+ Browse Resume"
                        }
                        type="file"
                        accept={acceptance}
                        onClick={(e) => e.target.blur()}
                        {...register("cv", {
                          required: true,
                          validate: {
                            correctType: (e) => {
                              let file = e?.[0];
                              if (
                                file?.name?.includes(".pdf") ||
                                file?.name?.includes(".doc") ||
                                file?.name?.includes(".docx") ||
                                file?.name?.includes(".txt")
                              ) {
                                return true;
                              }
                              return false;
                            },
                            fileSize: (e) => {
                              let file = e?.[0];
                              if (file?.size < 4000000) {
                                return true;
                              }
                              return false;
                            },
                          },
                        })}
                      />
                    </div>
                    {errors.cv && (
                      <p className={s.errorText}>
                        {errors.cv.type === "correctType"
                          ? `The selected ${
                              watch("cv")?.[0]?.name
                            } cannot be uploaded. Only files with the following extensions are allowed: 'txt, doc, docx, pdf'`
                          : errors.cv.type === "fileSize"
                          ? `The specific file ${
                              watch("cv")?.[0]?.name
                            } could not be uploaded, file size is exceeding the maximum file size of 4MB`
                          : "This is required"}
                      </p>
                    )}
                  </div>
                </div>

                <div className={s.buttonBlock}>
                  <label className={s.labelText}>
                    Cover letter<span style={{ color: "transparent" }}>*</span>
                  </label>
                  <div className={s.block}>
                    <div
                      className={s.blocks}
                      style={{
                        justifyContent: errors.coverLetter ? "start" : null,
                      }}
                    >
                      {!!watch("coverLetter")?.length && (
                        <>
                          <Exit
                            className={s.watchNameRemove}
                            onClick={() => {
                              setValue("coverLetter", "");
                              clearErrors("coverLetter");
                            }}
                          />
                          <p className={s.watchName}>
                            {watch("coverLetter")[0]["name"]}
                          </p>
                        </>
                      )}
                      <input
                        style={{
                          display: watch("coverLetter")?.length
                            ? "none"
                            : "inline",
                        }}
                        autoComplete="off"
                        className={s.formControl}
                        onClick={(e) => e.target.blur()}
                        data-placeholder={
                          watch("coverLetter")?.length > 0
                            ? watch("coverLetter")[0].name
                            : "+ Browse Cover letter"
                        }
                        type="file"
                        accept={acceptance}
                        {...register("coverLetter", {
                          required: false,
                          validate: {
                            correctType: (e) => {
                              let file = e?.[0];
                              if (!file) {
                                return true;
                              }
                              if (
                                file?.name?.includes(".pdf") ||
                                file?.name?.includes(".doc") ||
                                file?.name?.includes(".docx") ||
                                file?.name?.includes(".txt")
                              ) {
                                return true;
                              }
                              return false;
                            },
                            fileSize: (e) => {
                              let file = e?.[0];
                              if (!file) {
                                return true;
                              }
                              if (file?.size < 4000000) {
                                return true;
                              }
                              return false;
                            },
                          },
                        })}
                      />
                    </div>
                    {errors.coverLetter && (
                      <p className={s.errorText}>
                        {errors.coverLetter.type === "correctType"
                          ? `The selected ${
                              watch("coverLetter")?.[0]?.["name"]
                            } cannot be uploaded. Only files with the following extensions are allowed: 'txt, doc, docx, pdf'`
                          : `The specific file ${
                              watch("coverLetter")?.[0]?.["name"]
                            } could not be uploaded, file size is exceeding the maximum file size of 4MB`}
                      </p>
                    )}
                  </div>
                </div>
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    First Name<span style={{ color: "#E40B0B" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    maxLength={500}
                    {...register("firstName", {
                      required: true,
                    })}
                  />
                  {errors.firstName && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>This is required</span>
                    </>
                  )}
                  {isSubmitted &&
                    dirtyFields.firstName &&
                    !errors.firstName && (
                      <img src={iconSuccess} alt="warning" />
                    )}
                </div>
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Last Name<span style={{ color: "#E40B0B" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    maxLength={500}
                    {...register("lastName", {
                      required: true,
                    })}
                  />
                  {errors.lastName && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>This is required</span>
                    </>
                  )}
                  {isSubmitted && dirtyFields.lastName && !errors.lastName && (
                    <img src={iconSuccess} alt="warning" />
                  )}
                </div>
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Email<span style={{ color: "#E40B0B" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    maxLength={500}
                    {...register("email", {
                      required: true,
                      validate: validateEmail,
                    })}
                  />
                  {errors.email && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>
                        {errors.email.type === "required"
                          ? "This is required"
                          : "Please enter a valid email address"}
                      </span>
                    </>
                  )}
                  {isSubmitted && dirtyFields.email && !errors.email && (
                    <img src={iconSuccess} alt="warning" />
                  )}
                </div>
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Telegram<span style={{ color: "transparent" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    maxLength={500}
                    {...register("telegram", {
                      required: false,
                      validate: (url) => validateTelegram(url, true),
                    })}
                  />
                  {errors.telegram && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>
                        Please enter a valid Telegram address
                      </span>
                    </>
                  )}
                </div>
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Discord<span style={{ color: "transparent" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    maxLength={500}
                    {...register("discord", {
                      required: false,
                      validate: (url) => validateDisord(url, true),
                    })}
                  />
                  {errors.discord && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>
                        Please enter a valid Discord address
                      </span>
                    </>
                  )}
                </div>
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Location<span style={{ color: "#E40B0B" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    maxLength={500}
                    {...register("location", {
                      required: true,
                    })}
                  />
                  {errors.location && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>This is required</span>
                    </>
                  )}
                  {isSubmitted && dirtyFields.location && !errors.location && (
                    <img src={iconSuccess} alt="warning" />
                  )}
                </div>
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    LinkedIn<span style={{ color: "transparent" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    maxLength={500}
                    {...register("linkedin", {
                      required: false,
                      validate: (url) => validateLinkedInUrl(url, true),
                    })}
                  />
                  {errors.linkedin && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>
                        Please enter a valid LinkedIn profile URL
                      </span>
                    </>
                  )}
                </div>
                <div className={s.formGroup}>
                  <CustomButton
                    form="job-application"
                    label="Submit Application"
                    callback={(e) => {
                      onSubmit(e);
                      setIsSubmitted(true);
                    }}
                    className={s.formControl}
                    texture={
                      width < 576
                        ? "submit-application-mobile.svg"
                        : width < 1024
                        ? "submit-application-tablet.svg"
                        : "submit-application.svg"
                    }
                  />
                </div>
              </form>
            </>
          )}
        </div>
      </main>
    </StandardLayout>
  );
};

export default CareerApply;
