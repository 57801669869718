import React from 'react';
import s from '../styles.module.scss'
import CloseIcon from "../../../Image/closeCard.svg"
import {useDimensions} from "../../../hooks/useDimensions";

const CardsSelectPanel = ({setIsSelection, selectedCards, removeCard, bot}) => {
  const {width} = useDimensions()

  const selectCard = (e) => {
    e.stopPropagation()
    const part = e.target.dataset.part
    setIsSelection(true, part)

    window.scrollTo({
      top: width >= 576 ? width >= 1300 ? 1400 : 2400 : 2530 ,
      behavior: 'smooth',
    })
  }

  const removeCardHandler = (e) => {
    const part = e.target.dataset.part
    removeCard(part)
  }

  const selectedCardsCopy = {...selectedCards}
  delete selectedCardsCopy.id

  return (
    <div className={`${s.cards}`} id={'builderCards'}>
      {
        Object.values(selectedCardsCopy).map(selectedCard => (
          <div className={`${s.card} `} key={bot + selectedCard.id}>
            {
              selectedCard.card && <img className={`${s.closeCard} closeCard`}
                                        data-part={selectedCard.title}
                                        onClick={ removeCardHandler }
                                        src={CloseIcon} alt="close"/>
            }
            {
              selectedCard.card
                ?   <img onClick={ selectCard }
                         data-part={selectedCard.title}
                         className={s.selectedCard}
                         src={selectedCard.card.cardURI }
                         alt={selectedCard.title}
                />
                :
                <div
                     className={s.emptyCard}
                     onClick={ selectCard }
                     data-part={selectedCard.title}
                >
                  <img
                    onClick={ selectCard }
                    data-part={selectedCard.title}
                     src={selectedCard.empty}
                     alt={selectedCard.title}
                  />
                </div>
            }


            <p style={{marginLeft: selectedCard.card ? '28px' : '0px'}}>{selectedCard.titleName}</p>
          </div>
        ))
      }
    </div>
  );
}

export default CardsSelectPanel;