import React from 'react';

const MenuItem = ({location, title, items, openMenuIndex, setOpenMenuIndex, index}) => {
  let isOpen = openMenuIndex !== index


  const menuHandler = () => {
    const menuHandlerValue = index === openMenuIndex ? null : index
    setOpenMenuIndex(menuHandlerValue)
  }

  // FIX METAMASK BROWSER REDIRECT BUG
  const subMenuHandler = (e, link, blank) => {
    e.preventDefault()
    e.stopPropagation()
    window.open(link, blank ? '_blank' : '_self')
  }

  return (
    <div key={title} className="header-menu__item js-header-menu-item">
      <span className={`${isOpen ? 'hide' : ''}`} onClick={menuHandler}>{title}</span>
      <svg viewBox="0 0 18 10">
        <path
          d="M16.8359 0.166693L8.83594 9.4043L2.35508 1.92084L0.835938 0.166692L16.8359 0.166693Z"
          strokeWidth="0.295611"
        />
      </svg>
      <nav className={`header-menu__item-submenu ${isOpen ? 'hide' : ''}`}>
        {
          items.map(item =>
            <a
              key={item.url}
              className={`header-menu__item-submenu__item${
                location === item.url ? " selected" : ""
              }
              ${item.icon ? 'connect' : ''}
              `}
              onClick={e => subMenuHandler(e, item.url, item.blank)}
              href={`${item.url}`}
              rel="noreferrer"
              target={item.blank ? '_blank' : '_self'}
            >
              {
                item.icon && <img alt='menu' src={`${item.icon}`}/>
              }
              {item.name}
            </a>
          )


        }
      </nav>
      {/* </a> */}
    </div>
  );
}

export default MenuItem;