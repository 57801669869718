import React, { useState, useMemo, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";

import { ReactComponent as Rectangle } from "Image/rectangle.svg";
import { ReactComponent as DropdownArrow } from "Image/dropdownArrow.svg";
import iconSuccess from "Image/icon-success.svg";
import iconWarning from "Image/icon-warning.svg";
import { FileUploader } from "react-drag-drop-files";


import Footer from "components/Footer";
import Header from "components/Header";
import CustomButton from "components/CustomButton/CustomButton";
import { useDimensions } from "hooks/useDimensions";
import { Dropdown } from "components/ui/Dropdown";
import { guildsApply } from "api/guilds";
import s from "./styles.module.scss";
import RegisterDropdown from "../../components/RegisterGuild/RegisterDropdown";
import {GuildSchema} from "../../components/RegisterGuild/GuildSchem";
import {dropdownOptionsEnums} from "../../components/RegisterGuild/DropdownOptionsEnums";
import RegisterInput from "../../components/RegisterInput/RegisterInput";
import LanguagesPicker from "../../components/RegisterGuild/LanguagesPicker";

const Guilds = () => {
  const { width } = useDimensions();
  const inputRef = useRef([0, 0, 0, 0, 0].map(() => React.createRef()));

  const [isRequestError, setIsRequestError] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOpenCommunication, setIsOpenCommunication] = useState(false);
  const [isOpenGuildSize, setIsOpenGuildSize] = useState(false);
  const [guildSize, setGuildSize] = useState(null);
  const [subGuild, setSubGuild] = useState('');
  const [ownsNFT, setOwnsNFT] = useState('');
  const [languages, setLanguages] = useState('');
  const [region, setRegion] = useState('');
  const [communication] = useState(null);
  const [platform, setPlatform] = useState('')
  const [howDidHear, setHowDidHear] = useState('')
  const [separateCommunications, setSeparateCommunications] = useState('')

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields },
  } = useForm();

  /* eslint-disable react-hooks/exhaustive-deps */

  const [file, setFile] = useState(null);

  const onSubmit = handleSubmit(async (formData) => {

    if (
        guildSize &&
        howDidHear &&
        region &&
        platform &&
        languages &&
        ownsNFT &&
        file
      ){
      try {
        await guildsApply({ ...formData,details: formData.details ? formData.details :'' ,
          guildSize,
          communication,
          knowFrom: howDidHear === 'other' ? formData.other :  howDidHear,
          region,
          platform,
          separateCommunications: separateCommunications === 'Yes',
          languages: languages.join(', '),
          nfts: ownsNFT === 'Yes',
          ...(file && { logo: file })
        })
        setIsRequestError(false);
        setIsApplied(true);

        document.getElementById('appliedMessage').scrollIntoView({behavior: 'auto', block: 'start'});
      } catch (err) {
        setIsRequestError(true);
        setIsApplied(false);
      }
    }
  });

  // Todo: might needs to be deleted
  const requiredErrors = useMemo(
    () => Object.keys(errors)?.filter((item) => item !== "website").length,
    [
      errors.name,
      errors.website,
      errors.primaryContact,
      errors.region,
      errors.guildSize,
      errors.contactPerson,
      errors.email,
      errors.contactDiscordId,
      errors.twitter,
      errors.telegram,
      errors.discord,
      errors.contactPerson,
      errors.contactEmail,
      errors.mainWallet,
    ]
  );

  const setGuildSizeValue = (data) => {
    setGuildSize(data);
    setIsOpenGuildSize(false);
  };

  // const setCommunicationValue = (data) => {
  //   setCommunication(data);
  //   setIsOpenCommunication(false);
  // };

  // This whole functionality is written for adding custom ref to input
  const onBlurInput = (ind) => (inputRef.current[ind].scrollLeft = 0);
  const registerCreator = useCallback((ind, ref, rest) => {
    return {
      ref: (el) => {
        inputRef.current[ind] = el;
        ref(el);
      },
      ...rest,
    };
  }, []);

  const form = useMemo(
    () => (GuildSchema(register, onBlurInput, registerCreator)),
    []
  );

  const fileTypes = ["JPG", "PNG"];

  const handleChange = (file) => {
    setFile(file);
  };

  return (
    <div className="wrapper">
      <Header />
      <main
        className="main"
        onClick={(e) => {
          e.preventDefault();
          isOpenCommunication && setIsOpenCommunication(false);
          isOpenGuildSize && setIsOpenGuildSize(false);
        }}
      >
        <div className={s.background}>
          <div className={s.content}>
            <div className={`${s.formBlock} ${isApplied ? s.isApplied : ""}`}>
              <p className={s.formBlockTitle}>
                <span>REGISTER  </span> GUILD
              </p>
              <p className={s.formBlockSubtitle}>
                GROW YOUR GUILD ON XOILIUM
              </p>
            </div>
            <div className={s.topContent}>

              <p className={s.textInfo}>
                Are you ready to take your guild to the next level and dominate the play2earn world?
              </p>
              <br />
              <p className={s.textInfo}>
                Rebel Bots Xoil Wars allows your players to build exciting battle strategies,
                climb the leaderboards, and work with guild mates to have fun while being rewarded. Once you register your guild you will have access to: <br/>
                - Cutting-edge rental systems that allow rapid and easy scaling. <br/>
                - Dashboard tools with smart analytics to identify performance of you rplayers and optimize NFT returns. <br/>
                - Growth support and promotion opportunities to find scholars fast. <br/>
              </p>
              <br />
              <p className={s.textInfo}>
                Join over 360 Xoilium guilds and our industry-leading guild partners to access the resources you need to succeed.
              </p>
            </div>
            {width > 1450 && (
              <div className={s.blockText}>
                <p>Unique Renting system</p>
                <Rectangle />
                <p>Scholars management tools</p>
                <Rectangle />
                <p>Gaming courses</p>
              </div>
            )}
            {width <= 1024 && width >= 767 && (
              <div className={s.blockText}>
                <div>
                  <p>Unique Renting system</p>
                  <Rectangle />
                  <p>Gaming courses</p>
                </div>
                <p className={s.bottomText}>Scholars management tools</p>
              </div>
            )}
            {width <= 1024 && width < 767 && (
              <div className={s.blockText}>
                <Rectangle />
                <p className={s.topText}>Scholars management tools</p>
                <p>Unique Renting system</p>
                <p className={s.bottomText}>Gaming courses</p>
                <Rectangle />
              </div>
            )}

            <div className={`${s.formBlock} ${isApplied ? s.isApplied : ""}`}>
              <p className={s.formBlockText}>
                <span>Grow your guild empire </span>  and launch your journey to success with Rebel Bots Xoil Wars.
              </p>
              <p className={s.formBlockText}>
                Register below to receive your free Xoilium Guild welcome pack
              </p>
            </div>

          </div>

          <div >
            {isApplied && (
              <div  className={s.appliedMessage}>
                <div id='appliedMessage' className={s.isAppliedText}>
                  <h3>WELCOME TO XOIL WARS!</h3>
                  <p>
                    Check your email for your Xoilium Guild Welcome Pack. It contains useful links that will help your guild
                    dominate the Xoilium battlefield.
                  </p>
                  <p>
                    Check out these Xoil Wars resources:
                    <ol className={s.appliedLinks}>
                      <li><a href="/wiki" onClick={() => window.open('/wiki')}> Player Guides</a></li>
                      <li><a href="/cardsExplorer" onClick={() => window.open('/cardsExplorer')}> Card Explorer and Fighting Bot Prototyper</a></li>
                    </ol>



                  </p>
                  <p>Join our <a href="https://t.co/KDkkigQnbo" onClick={() => window.open('https://t.co/KDkkigQnbo')}>socials</a>  and stay up to date with the latest Xoil Wars announcements</p>
                </div>
              </div>
            )}
          </div>


          {!isApplied && (
            <>
              <form
                className={s.form}
                id="application"
                onSubmit={(e) => {
                  onSubmit(e);

                  setIsSubmitted(true);
                }}
              >
                {(requiredErrors > 0 || isRequestError) && (
                  <div className={s.errorTextBlock}>
                    <p>Please make sure you’ve filled the form as requested</p>
                  </div>
                )}
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Guild Name<span style={{ color: "#E40B0B" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    maxLength={100}
                    {...form.name()}
                  />
                  {errors.name && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>This is required</span>
                    </>
                  )}
                  {isSubmitted && dirtyFields.name && !errors.name && (
                    <img src={iconSuccess} alt="warning" />
                  )}
                </div>

                <RegisterInput title={'Guild Description'}
                               placeholder={"Enter your guild's slogan/short description"}
                               max={40}
                               errors={errors}
                               form={form.description}
                               name={'description'}
                               dirtyFields={dirtyFields}
                               isSubmitted={isSubmitted}

                />
                <div className={s.formGroup}>
                  <label className={`${s.formLabel} ${s.left}`}>
                    Guild Logo <span style={{ color: "#E40B0B" }}>*</span>
                  </label>
                  <FileUploader classes={s.fileUploader} maxSize={1} handleChange={handleChange} name="file" types={fileTypes} />
                  {errors.logo && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>This is required</span>
                    </>
                  )}
                  {isSubmitted && dirtyFields.logo && !errors.logo && (
                    <img src={iconSuccess} alt="warning" />
                  )}
                </div>
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Guild Email
                    <span style={{ color: "#E40B0B", alignSelf: "flex-start" }}>
                      *
                    </span>
                  </label>

                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    maxLength={200}
                    {...form.email()}
                    placeholder={'Where we will send your welcome package'}
                  />
                  {errors.email && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>{errors.email?.type === 'validate' ? 'Please enter a valid email address' :'This is required'}</span>
                    </>
                  )}
                  {isSubmitted &&
                    dirtyFields.email &&
                    !errors.email && (
                      <img src={iconSuccess} alt="warning" />
                    )}
                </div>
                <RegisterInput title={'Main Wallet'}
                               placeholder={"Master wallet containing guild’s NFTs."}
                               errors={errors}
                               form={form.mainWallet}
                               name={'mainWallet'}
                               dirtyFields={dirtyFields}
                               isSubmitted={isSubmitted}
                />
                <RegisterDropdown title={'Sub Guild?'}
                                  options={dropdownOptionsEnums.subGuild}
                                  name={'subGuild'}
                                  state={subGuild}
                                  setState={setSubGuild}
                                  isSubmitted={isSubmitted}
                                  placeholder={'Is this a sub guild of a Main Guild?'}
                                  width={width}
                                  required
                />
                {
                  subGuild === 'Yes' && <>
                    <RegisterInput title={'Main Guild Name'}
                                   errors={errors}
                                   form={form.mainGuildName}
                                   dirtyFields={dirtyFields}
                                   isSubmitted={isSubmitted}
                                   name={'mainGuildName'}
                                   required
                    />
                    <RegisterDropdown title={'Separate Communications?'}
                                      options={dropdownOptionsEnums.subGuild}
                                      name={'separateCommunications'}
                                      required
                                      state={separateCommunications}
                                      setState={setSeparateCommunications}
                                      isSubmitted={isSubmitted}
                                      placeholder={'Do you want to receive communications as a separate guild?'}
                                      width={width}
                    />
                  </>
                }
                <LanguagesPicker title={'Languages'}
                                  options={dropdownOptionsEnums.languages}
                                  name={'Languages'}
                                  state={languages}
                                  setState={setLanguages}
                                  isSubmitted={isSubmitted}
                                  placeholder={'Choose up to 3'}
                                  width={width}
                                  required
                />


                <div className={`${s.formGroup} ${s.formGroupDropDown}`}>
                  <label className={s.formLabel}>
                    Guild Size<span style={{ color: "#E40B0B" }}>*</span>
                  </label>
                  <div className={s.formIcon}>
                    <Dropdown
                      onMouseEnter={() => setIsOpenGuildSize(true)}
                      onMouseLeave={() => setIsOpenGuildSize(false)}
                      className={s.formControl}
                      isOpen={isOpenGuildSize}
                      name="guildSize"
                      onSelect={(item) => setGuildSizeValue(item.title)}
                      options={[
                        { id: 1, title: "1-99" },
                        { id: 2, title: "100-249" },
                        { id: 3, title: "250-499" },
                        { id: 4, title: "500-999" },
                        { id: 5, title: "1000-4999" },
                        { id: 6, title: "5000+" },
                      ]}
                    >
                      <div
                        onClick={() => setIsOpenGuildSize((prev) => !prev)}
                        className={`${s.dropdown} ${s.formControl}`}
                      >
                        {guildSize
                          ? guildSize
                          : "Number of Scholars"}
                      </div>
                      <DropdownArrow
                        className={s.down}
                        onClick={() => setIsOpenGuildSize((prev) => !prev)}
                        style={{
                          transform: `translateX(${width > 3800 ? '-30px' : '-10px'})${width > 3800 ? ' scale(2) translateY(13px)' : ''} rotate(${
                            isOpenGuildSize ? "180deg" : "0deg"
                          })`,
                        }}
                      />
                    </Dropdown>
                    {!guildSize && isSubmitted && (
                      <>
                        <img src={iconWarning} alt="warning" />
                      </>
                    )}
                    {isSubmitted && guildSize && (
                      <img src={iconSuccess} alt="warning" />
                    )}
                  </div>
                  {!guildSize && isSubmitted && (
                    <>
                      <span className={s.formFeedback}>This is required</span>
                    </>
                  )}
                </div>

                <RegisterDropdown title={'Xoil Wars NFTs?'}
                                  options={dropdownOptionsEnums.ownsNFT}
                                  state={ownsNFT}
                                  name={'nfts'}
                                  setState={setOwnsNFT}
                                  isSubmitted={isSubmitted}
                                  placeholder={'Does your guild own Xoil Wars NFTs?'}
                                  width={width}
                                  required
                />
                <RegisterDropdown title={'Region'}
                                  name={'region'}
                                  options={dropdownOptionsEnums.region}
                                  state={region}
                                  setState={setRegion}
                                  isSubmitted={isSubmitted}
                                  placeholder={'Primary member location'}
                                  width={width}
                                  required
                />

                <label className={s.formLabel} style={{display: 'block', textAlign: 'center'}}>
                  HOW SHOULD SCHOLARS FIND YOUR GUILD?
                </label>

                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Discord<span style={{ color: "transparent" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    placeholder={"Discord invite URL"}
                    maxLength={120}
                    {...form.discord()}
                  />
                  {isSubmitted &&
                    dirtyFields.website &&
                    !errors.website &&
                    !!watch("discord").length && (
                      <img src={iconSuccess} alt="warning" />
                    )}
                </div>

                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Twitter<span style={{ color: "transparent" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    placeholder={"Guild Twitter URL"}
                    {...form.twitter()}
                    maxLength={120}
                  />
                  {isSubmitted &&
                    dirtyFields.website &&
                    !errors.website &&
                    !!watch("twitter").length && (
                      <img src={iconSuccess} alt="warning" />
                    )}
                </div>

                <RegisterInput placeholder={"Guild Facebook URL"}
                               title={'Facebook'}
                               errors={errors}
                               name={'facebook'}
                               required={false}
                               form={form.facebook}
                               dirtyFields={dirtyFields}
                               isSubmitted={isSubmitted}
                />

                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Telegram<span style={{ color: "transparent" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    placeholder={"Guild Telegram invite URL"}
                    maxLength={120}
                    {...form.telegram()}
                  />
                  {isSubmitted &&
                    dirtyFields.website &&
                    !errors.website &&
                    !!watch("telegram").length && (
                      <img src={iconSuccess} alt="warning" />
                    )}
                </div>
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Website<span style={{ color: "transparent" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    placeholder={"Guild Website URL"}
                    maxLength={256}
                    {...form.website()}
                  />
                  {errors.website && !!watch("website").length && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>
                        Please enter a valid website
                      </span>
                    </>
                  )}
                  {isSubmitted &&
                    dirtyFields.website &&
                    !errors.website &&
                    !!watch("website").length && (
                      <img src={iconSuccess} alt="warning" />
                    )}
                </div>

                {/*primary contact*/}
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Primary Contact
                    <span style={{ color: "#E40B0B", alignSelf: "flex-start" }}>
                      *
                    </span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    maxLength={200}
                    placeholder={"Contact's Name"}
                    {...form.primaryContact()}
                  />
                  {errors.primaryContact && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>
                        {errors.primaryContact && "This is required"}
                      </span>
                    </>
                  )}
                  {isSubmitted && dirtyFields.primaryContact && !errors.primaryContact && (
                    <img src={iconSuccess} alt="warning" />
                  )}
                </div>

                {/*contact email*/}
                <div className={s.formGroup}>
                  <label className={s.formLabel}>
                    Contact Email<span style={{ color: "#E40B0B" }}>*</span>
                  </label>
                  <input
                    autoComplete="off"
                    className={s.formControl}
                    type="text"
                    maxLength={200}
                    placeholder={"Primary Contact’s Email Address"}
                    {...form.contactEmail()}
                  />
                  {errors.contactEmail && (
                    <>
                      <img src={iconWarning} alt="warning" />
                      <span className={s.formFeedback}>{errors.contactEmail?.type === 'validate' ? 'Please enter a valid email address' :'This is required'}</span>
                    </>
                  )}
                  {isSubmitted &&
                    dirtyFields.contactEmail &&
                    !errors.contactEmail && (
                      <img src={iconSuccess} alt="warning" />
                    )}
                </div>

                <RegisterDropdown title={'Platform'}
                                  options={dropdownOptionsEnums.platform}
                                  state={platform}
                                  setState={setPlatform}
                                  isSubmitted={isSubmitted}
                                  placeholder={'Primary device used'}
                                  width={width}
                                  required
                />
                <RegisterDropdown title={'How did you hear about Rebel Bots? '}
                                  options={dropdownOptionsEnums.hear}
                                  state={howDidHear}
                                  setState={setHowDidHear}
                                  isSubmitted={isSubmitted}
                                  placeholder={''}
                                  width={width}
                                  required
                                  hear
                />
                {
                  howDidHear === 'other' &&
                  <RegisterInput title={'Additional information'}
                                 errors={errors}
                                 form={form.other}
                                 name={'other'}
                                 dirtyFields={dirtyFields}
                                 isSubmitted={isSubmitted}
                                 required={false}
                  />
                }

              </form>
              <div style={{ width: "100%", display: "flex" }}>
                <CustomButton
                  className={s.formControl}
                  form="application"
                  label="Submit Form"
                  callback={(e) => {
                    onSubmit(e);
                    setIsSubmitted(true);
                  }}
                  texture={
                    width < 576
                      ? "submit-application-mobile.svg"
                      : width < 1024
                        ? "submit-application-tablet.svg"
                        : "submit-application.svg"
                  }
                />
              </div>
            </>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Guilds;
