import React from 'react';
import s from "../../page/LandsNftPage/components/YourLands/styles.module.scss";
import EmptyLandsImage from "../../Image/emptyLands.png";
import EmptyLandsImageTablet from "../../Image/emptyLandsTablet.svg";
import {useDimensions} from "../../hooks/useDimensions";

const EmptyAssetsList = () => {
  const {width} = useDimensions()

  return (
    <div className={s.lands__empty}>
      <img src={(width > 1024 || width < 576) ? EmptyLandsImage : EmptyLandsImageTablet}
           alt="empty"/>
      <div className={s.lands__emptyText}>
        <p className='web48'>
          It looks like you
          don’t have any Lands.
        </p>
        <p className='web48'>
          <b>You can get them here:</b>
        </p>
        <div className={s.land__buttonContainer}>
          <a
            className={`button-type1 notAdaptive ${s.buttonUrl}`}
            href={`https://opensea.io/collection/rebel-bots-xoilium-lands`}
            target={"_blank"}
            rel="noreferrer"
            data-regular="Buy Now"
          >
            <span className="button-type1-before"/>
            <span className={`label `}>Buy Lands</span>
            <span className="button-type1-after"/>
          </a>
        </div>
      </div>
    </div>
  );
}

export default EmptyAssetsList;