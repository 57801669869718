import React from "react";

import { StandardLayout } from "components/layouts/StandardLayout";
import CustomButton from "components/CustomButton/CustomButton";

import ubisoft from "Image/ubisoft-landing.png";
import animoca from "Image/animoca-landing.png";
import overwolf from "Image/overwolf-landing.png";
import ubisoftMobile from "Image/ubisoft.png";
import animocaMobile from "Image/animoca.png";
import overwolfMobile from "Image/overwolf.png";
import gameScreen1 from "Image/gameScreen1.png";
import gameScreen2 from "Image/gameScreen2.png";
import gameScreen3 from "Image/gameScreen3.png";
import gameScreen1Mobile from "Image/gameScreen1Mobile.png";
import gameScreen2Mobile from "Image/gameScreen2Mobile.png";
import gameScreen3Mobile from "Image/gameScreen3Mobile.png";
import bots from "Image/landing-bots.png";
import botsMobile from "Image/landing-botsMobile.png";
import habitat from "Image/landing-habitat.png";
import habitatMobile from "Image/landing-habitatMobile.png";
import avacado from "Image/brandAvacadoLanding.png";
import avacadoMobile from "Image/brandAvacado.png";
import avacadoTablet from "Image/brandAvacadoLandingTablet.png";
import pathDao from "Image/brandPathDaoLanding.png";
import pathDaoMobile from "Image/brandPathDao.png";
import pathDaoTablet from "Image/brandPathDaoLandingTablet.png";
import juice from "Image/brandJuiceLanding.png";
import juiceMoobile from "Image/brandJuice.png";
import juiceTablet from "Image/brandJuiceLandingTablet.png";
import polemos from "Image/brandPolemosLanding.png";
import polemosTablet from "Image/brandPolemosLandingTablet.png";
import realDeal from "Image/brandRealDealLanding.png";
import realDealTablet from "Image/brandRealDealLandingTablet.png";
import crown from "Image/brandCrown.png";
import crownTablet from "Image/brandCrownTablet.png";
import magnum from "Image/brandMagnum.png";
import magnumTablet from "Image/brandMagnumTablet.png";
import inb from "Image/brandINB.png";
import inbTablet from "Image/brandINBTablet.png";

import s from "./styles.module.scss";
import { useDimensions } from "hooks/useDimensions";

const Landing = () => {
  const { width } = useDimensions();
  return (
    <StandardLayout noLinks>
      <main className={`main ${s.landing}`}>
        <div className={s.imageHolder}></div>
        <div className={s.sponsors}>
          <h4>Backed by</h4>
          <div className={s.sponsor__logos}>
            <a
              href="https://www.animocabrands.com/"
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                if (width < 1024) {
                  e.target.focus();
                  e.preventDefault();
                  setTimeout(() => {
                    window.open("https://www.animocabrands.com/");
                    e.target.blur();
                  }, 300);
                } else {
                  e.target.blur();
                }
              }}
            >
              <img src={width > 756 ? animoca : animocaMobile} alt="logo" />
            </a>
            <a
              href="https://www.ubisoft.com/en-us/"
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                if (width < 1024) {
                  e.target.focus();
                  e.preventDefault();
                  setTimeout(() => {
                    window.open("https://www.ubisoft.com/en-us/");
                    e.target.blur();
                  }, 300);
                } else {
                  e.target.blur();
                }
              }}
            >
              <img src={width > 756 ? ubisoft : ubisoftMobile} alt="logo" />
            </a>
            <a
              href="https://www.overwolf.com/"
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                if (width < 1024) {
                  e.target.focus();
                  e.preventDefault();
                  setTimeout(() => {
                    window.open("https://www.overwolf.com/");
                    e.target.blur();
                  }, 300);
                } else {
                  e.target.blur();
                }
              }}
            >
              <img src={width > 756 ? overwolf : overwolfMobile} alt="logo" />
            </a>
          </div>
        </div>
        <div className={s.content}>
          <div className={s.content__desc}>
            <h3>
              <span>the ultimate</span>{" "}
              {(width > 1024 || width < 756) && <br />} play
              {width < 1024 && width > 756 && <br />} and earn game
            </h3>
            <p>
              Rebel Bots - Xoil Wars is a cross platform card battle game,
              players will be able to play, progress and earn in a single player
              or multiplayer sci-fi themed game taking place in outer space. The
              game will require players to unite around extra-terrestrial
              kingdoms, build their army of fighting robots and compete with
              other players for the valuable Xoil resource.
            </p>
            <p>
              Backed by some of the biggest names in gaming and blockchain, Xoil
              Wars is aiming to become a leading game franchise within the
              blockchain gaming space by creating a true social and fun
              experience for players so they can both enjoy playing and at the
              same time earn crypto rewards. Are you ready to join the battle?
            </p>
            <button
              onClick={(e) => window.open("/whitepaper") + e.target.blur()}
              alt="content-sticker"
            />
          </div>
          <div className={s.gameScreens}>
            <img
              src={width < 756 ? gameScreen1Mobile : gameScreen1}
              alt="screenshot"
            />
            <img
              src={width < 756 ? gameScreen2Mobile : gameScreen2}
              alt="screenshot"
            />
            <img
              src={width < 756 ? gameScreen3Mobile : gameScreen3}
              alt="screenshot"
            />
          </div>
          <div className={s.playRules}>
            <h3>
              <span>
                which assets <br /> needed to{" "}
              </span>{" "}
              play?
            </h3>
            <p>
              To play you need to have a land and 3 Fighting bots. You’re <br />
              getting 3 bots for free when you buy a land.
            </p>
            <div className={s.images}>
              <img src={width < 756 ? habitatMobile : habitat} alt="habitat" />
              <span>+</span>
              <img src={width < 756 ? botsMobile : bots} alt="habitat" />
            </div>
          </div>
          <div className={s.landSale}>
            <h2>
              land sale{" "}
              <span>
                {" "}
                {width < 756 && <br />}is {width < 756 && <br />} ongoing!
              </span>
            </h2>
            <div>
              <CustomButton
                callback={(e) => (window.location.href = "/lands")}
                className={s.formControl}
                form="application"
                label="Get your lands & Fighting Bots"
                texture={"landing-btn.svg"}
              />
            </div>
          </div>
        </div>
        <div className={s.partners}>
          <h2>partnered guilds</h2>
          <div className={s.partner__logos}>
            <img
              src={
                width < 756
                  ? avacadoMobile
                  : width < 1024
                  ? avacadoTablet
                  : avacado
              }
              alt="guild-logo"
            />
            <img
              src={width < 1024 ? realDealTablet : realDeal}
              alt="guild-logo"
            />
            <img
              src={
                width < 756 ? juiceMoobile : width < 1024 ? juiceTablet : juice
              }
              alt="guild-logo"
            />
            <img src={width < 1024 ? inbTablet : inb} alt="guild-logo" />
            {width < 1024 && (
              <>
                <img
                  src={width < 1024 ? crownTablet : crown}
                  alt="guild-logo"
                />
                <img
                  src={width < 1024 ? magnumTablet : magnum}
                  alt="guild-logo"
                />
                <img
                  src={
                    width < 756
                      ? pathDaoMobile
                      : width < 1024
                      ? pathDaoTablet
                      : pathDao
                  }
                  alt="guild-logo"
                />
                <img
                  src={width < 1024 ? polemosTablet : polemos}
                  alt="guild-logo"
                />
              </>
            )}
          </div>
          {width > 1024 && (
            <div className={s.partner__logos}>
              <img src={crown} alt="guild-logo" />
              <img src={magnum} alt="guild-logo" />
              <img src={pathDao} alt="guild-logo" />
              <img src={polemos} alt="guild-logo" />
            </div>
          )}
        </div>
        <div className={s.moreInfo}>
          <h4>
            More{width < 1024 && <br />} information is {width < 756 && <br />}
            waiting for
            {width < 1024 && width > 756 && <br />} you in{" "}
            {width > 1440 && <br />}
            the official website
          </h4>
          <div>
            <CustomButton
              className={s.formControl}
              label="Go to website"
              callback={() => (window.location.href = "/")}
              texture={
                width < 576
                  ? "submit-application-mobile.svg"
                  : width < 1024
                  ? "submit-application-tablet.svg"
                  : "submit-application.svg"
              }
            />
          </div>
        </div>
      </main>
    </StandardLayout>
  );
};

export default Landing;
