import React from 'react';
import s from "../styles.module.scss";
import BlueArrow from "../../../Image/blueArrow.svg";
import UndeterminedIcon from "../../../Image/Undetermined.svg";
import PlusIcon from "../../../Image/plusIcon.svg";
import {useBotStats} from "../../../hooks/useBotStats";
import InfoButtonIcon from '../../../Image/infoButtonIcon.svg'
import EngineerIcon from '../../../Image/Enginee_main.svg'
import IndustrialIcon from '../../../Image/Industrial_main.svg'
import MilitaryIcon from '../../../Image/Military_main.svg'

const BuilderStats = ({selectedCards, cardsCount}) => {

  const {mainClass, hpMin, hpMax, passiveSkills} = useBotStats(selectedCards, cardsCount)

  const classIcons = {
    'Engineer': EngineerIcon,
    'Industrial': IndustrialIcon,
    'Military': MilitaryIcon
  }

  return (
    <div className={s.stats}>
      <div>
        <div className={s.stats_title}><img src={BlueArrow} alt="arrow"/> <p>class</p> </div>
        <div className={s.stats_item}>
          <img
            className={s.classIcon}
            src={mainClass === 'Undetermined' ? UndeterminedIcon : classIcons[mainClass]}
            alt="class"
          />
          <p>{mainClass}</p>
        </div>
        <div className={s.stats_item}><img src={PlusIcon} alt="icon"/>
          {!cardsCount ? <p>???? - ????</p> : <p> {hpMin} - {hpMax} </p>}
        </div>
      </div>
      <div>
        <div className={s.stats_title}><img src={BlueArrow} alt="arrow"/>
          <p>Potential skills</p>
          <div className={s.infoButton}>
            <img src={InfoButtonIcon} alt="info"/>
          </div>

        </div>
        <div className={s.stats_itemsWrapper}>
          {
            passiveSkills.map(skill => {
              if(typeof skill === 'object' ){
                
                return <div className={s.stats_item} key={skill['Skill name']}>
                  <img src={skill.ImageURL} alt={skill['Skill name']}/>
                  <p>{skill['Skill name']}</p>
                </div>
              }
              
              return  <div className={s.stats_item} ><img src={UndeterminedIcon } alt="icon"/> <p>Undetermined</p> </div>
            })
          }
        </div>
      </div>
    </div>
  );
}

export default BuilderStats;