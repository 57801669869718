import React from 'react';
import s from './styles.module.scss'
import {useParallax} from "react-scroll-parallax";
import {useDimensions} from "../../../../hooks/useDimensions";


const HomeVideo = ({homeVideoRef}) => {
  const {width} = useDimensions()

  const parallax = useParallax({
    speed: width > 1224 ? 40 : 0,
  });

  return (
    <section className={s.HomeVideo}  ref={homeVideoRef} >
        <div ref={parallax.ref} className={s.videoWrapper} id='homeVideo'>
          <iframe src="https://www.youtube.com/embed/kNBZS_94QtI" title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen></iframe>
        </div>
    </section>
  );
}

export default HomeVideo;