import React from 'react';
import s from './styles.module.scss'
import {useParallax} from "react-scroll-parallax";
import {useDimensions} from "../../../../hooks/useDimensions";

const HomePrepare = () => {

  const {width} = useDimensions()

  const parallax = useParallax({
    speed: width > 1024 ? 60 : 0,
  });

  return (
    <section ref={parallax.ref} className={s.homePrepare__wrapper}>
      <div  className={s.homePrepare}>
        <h3>PREPARE FOR BATTLE</h3>
        <h4 className='yellow'>Join the Xoilium Elite and Secure Your Battle Set</h4>
        <a href={'/battleSet'}><span>Buy Battle Set</span></a>
      </div>
    </section>
  );
}

export default HomePrepare;