import React from "react";

import Header from "components/Header";
import Footer from "components/Footer";

const Terms = () => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <main className="faq">
          <div className="terms-container">
            <div className="privacy-title">
              <h1>Terms and Conditions</h1>
            </div>
            <div className="faq-list">
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="terms-item__title" />
                </div>
                <div className="terms__item js-faq-item-answer">
                  <p>
                    Updated: October 19, 2023
                    <br />
                    These Terms and Conditions (the "<b>Terms</b>") are a
                    binding agreement between Xoilium Limited ("<b>We</b>" or "
                    <b>Us</b>") and you ("<b>You</b>" or "<b>User</b>") and they
                    apply to your use of the website provided by us at:{" "}
                    <a href="https://www.xoilium.com"> www.xoilium.com</a>{" "}
                    and <a href="https://www.epicwar.gg"> www.epicwar.gg</a> (the
                    "<b>WEBSITES</b>"), through any electronic device (mobile,
                    tablet or otherwise). By using the WEBSITES you agree that
                    you have read, understood, accept and agree to be bound by
                    these Terms, our Privacy Policy (which you can review here:{" "}
                    <a href="https://www.xoilium.com/">www.xoilium.com</a>) and
                    by any other guidelines and policies posted by us from time
                    to time. If you do not agree with any provision of these
                    Terms or any other policies, you must immediately cease
                    using the WEBSITES.
                  </p>
                  <p>
                    Any and all services or information provided by us through
                    the WEBSITES from time to time are not intended for use by
                    any person or entity in any jurisdiction where such use
                    would be contrary to applicable law or regulations, or which
                    would subject us to any registration requirement within such
                    jurisdiction. The use of the WEBSITES from any jurisdiction
                    is being made on the User's own initiative and such User is
                    solely responsible for compliance with any applicable local
                    laws.
                  </p>
                  <p>
                    For the avoidance of any doubt, the WEBSITES is provided as a
                    general source of information regarding the Rebel Bots game
                    and platform for the users of the same and is not meant as
                    an advertising or marketing tool for any products or
                    services which we may offer from time to time.
                  </p>
                  <p>
                    To use the WEBSITES you must be a natural person, at least 18
                    years old. At our sole discretion, we may require proof that
                    you meet this condition in connection with your use of the
                    WEBSITES and/or use automatic or other means in order to
                    verify your legal age.
                  </p>
                  <h3 className="terms-item__title">
                    1. GENERAL REPRESENTATIONS & ELIGIBILITY TO USE THE WEBSITES
                  </h3>
                  <p>
                    1.1. You declare and warrant that: (a) you are over 18 years
                    of age or such higher minimum legal age of majority as
                    stipulated in the jurisdiction of your residence; (b) all
                    information that you provide to us in the course of any
                    registration (if applicable) or otherwise as you engage with
                    us (including by contacting us through the WEBSITES) is true,
                    complete and correct; and (c) you will not be involved in
                    any fraudulent, collusive, fixing or other unlawful activity
                    in relation to your use of the WEBSITES and you will not use
                    any software-assisted methods or techniques or hardware
                    devices.
                  </p>
                  <p>
                    1.2. By accepting these Terms you agree that your use of the
                    WEBSITES is at your sole option, discretion and risk. You
                    will have no claims whatsoever against us or any of our
                    partners, or respective directors, officers or employees in
                    relation to the same.
                  </p>
                  <h3 className="terms-item__title">
                    2. LICENCE TO USE THE WEBSITES
                  </h3>
                  <p>
                    2.1. Subject to your agreement and continuing compliance
                    with these Terms, we grant you a personal, revocable,
                    non-exclusive, non-transferable, non-sublicensable, limited
                    license to access and use the WEBSITES through a supported
                    Web browser or mobile device, solely for your personal,
                    private entertainment and no other reason. We may cancel
                    this license at any time and for any reason or for no reason
                    at our sole and absolute discretion, without the need to
                    provide an explanation.
                  </p>
                  <p>
                    2.2. These Terms do not grant you any right, title or
                    interest in the WEBSITES or any content (including without
                    limitations, text, graphics, UI, photographs, trademarks,
                    logos, audio, music, artwork, computer code and other
                    material used or displayed as part of the WEBSITES ("
                    <b>Content</b>"). All rights to the WEBSITES and/or any
                    Content will remain in the exclusive ownership of us and our
                    licensors, as applicable.
                  </p>
                  <p>
                    2.3. You acknowledge and agree that your license to use the
                    WEBSITES is limited by these Terms and if you do not agree
                    to, or act in contravention of, these Terms, your license to
                    use the WEBSITES (including any Content) may be immediately
                    terminated.
                  </p>
                  <p>
                    2.4. Where the WEBSITES is deemed to be illegal under the
                    laws of the jurisdiction in which you reside or are
                    situated, you will not be granted any license to, and must
                    refrain from accessing, the WEBSITES.
                  </p>
                  <p>
                    2.5. We reserve the right to add and remove information or
                    services from the WEBSITES at our sole discretion. In
                    addition, we reserve the right to change the features, look
                    and feel, characteristics and other features of the WEBSITES
                    at any time.
                  </p>
                  <h3 className="terms-item__title">3. SERVICES</h3>
                  <p>
                    3.1. The WEBSITES may offer to You from time-to-time products
                    or services which you may be able to purchase or register to
                    through the WEBSITES (the "<b>Services</b>"). Services
                    offered on the WEBSITES may have their own terms of use,
                    rules or policies which we will post separately. It is your
                    responsibility to read the rules of a specific Service
                    before playing. You must familiarize yourself with the
                    applicable terms of use and read the relevant rules before
                    using any Services.
                  </p>
                  <p>
                    3.2. We may change the rules of a Service at any time at our
                    discretion and such change will become effective immediately
                    unless otherwise set forth by us.
                  </p>
                  <h3 className="terms-item__title">4. FRAUDULENT CONDUCT</h3>
                  <p>
                    4.1. You will not, directly or indirectly: (a) hack into any
                    part of the Services or WEBSITES through password mining,
                    phishing, or any other means; (b) attempt to modify, reverse
                    engineer, or reverse-assemble any part of the Services or
                    WEBSITES; (c) knowingly introduce viruses, Trojans, worms,
                    logic bombs, spyware, malware, or other similar material;
                    (d) circumvent the structure, presentation or navigational
                    function of any Service so as to obtain information that we
                    have chosen not to make publicly available on the WEBSITES or
                    may other benefit or advantage; (e) engage in any form of
                    illegal activity, including without limitations, an activity
                    which constitute a breach of any applicable laws or
                    regulations (including without limitations, any privacy
                    laws, data protection laws, consumer laws and intellectual
                    property protection laws); or (f) use the WEBSITES or any
                    Service to facilitate any type of illegal money transfer
                    (including money laundering proceeds of crime).
                  </p>
                  <p>
                    4.2. If we suspect that you may be engaging in, or have
                    engaged in fraudulent, unlawful or improper activity,
                    including money laundering activities or any conduct which
                    violates these Terms, your access to the WEBSITES and to any
                    Services will be deactivated immediately. In addition, we
                    may pass any necessary information on to the relevant
                    authorities, other online service providers, banks, credit
                    card companies, electronic payment providers or other
                    financial institutions. You will cooperate fully with any
                    investigation into such activity.
                  </p>
                  <p>
                    4.3. If you suspect any unlawful or fraudulent activity or
                    prohibited transaction by another User, please notify us
                    immediately.
                  </p>
                  <h3 className="terms-item__title">
                    5. INTELLECTUAL PROPERTY
                  </h3>
                  <p>
                    5.1. The computer software, the computer graphics, the
                    WEBSITES, the Services and the Content that we make available
                    to you is owned by, or licensed to, us or our associates and
                    related parties and protected by relevant intellectual
                    property laws. You may only use the above for your own
                    personal, recreational uses in accordance with all rules,
                    terms and conditions we have established (including these
                    Terms) and in accordance with all applicable laws, rules and
                    regulations. You acknowledge that we are the proprietor or
                    authorized licensee of all intellectual property in relation
                    to any Content.
                  </p>
                  <p>
                    5.2. Your use of the Services and WEBSITES does not provide
                    you with any intellectual property rights in the Content,
                    Services or WEBSITES. You grant us, and represent and warrant
                    that you have the right to grant us, an irrevocable,
                    perpetual, worldwide, non-exclusive, royalty-free license to
                    use in whatever way we see fit, any information, images,
                    videos, comments, messages, music or profiles you publish or
                    upload to any website or social media page controlled and
                    operated by us and including without limitations any
                    feedback you provide to us as part of your use of the
                    WEBSITES and Services.
                  </p>
                  <p>
                    5.3. You must not reproduce or modify the Content in any
                    way, including by removing any copyright or trademark
                    notice. All trademarks and logos displayed in the Services
                    and WEBSITES are the property of their respective owners and
                    are protected by applicable trademarks and copyright laws.
                  </p>
                  <h3 className="terms-item__title">
                    6. THIRD PARTY WEBSITES AND LINKS
                  </h3>
                  <p>
                    6.1. You acknowledge and agree that we: (a) are not
                    responsible for any third-party websites, apps, services or
                    products linked to or referred to through the WEBSITES and/or
                    any Services ("<b>Third Party Websites</b>"); and (b) make
                    no guarantee as to the content, functionality, or accuracy
                    of any Third Party Website. Third Party Websites are subject
                    to the terms and conditions outlined by that third party.
                  </p>
                  <p>
                    6.2. Any links to Third Party Websites do not: (a) indicate
                    a relationship between us and the third party; or (b)
                    indicate any endorsement or sponsorship by us of the Third
                    Party Website, or the goods or services it provides, unless
                    specifically indicated by us.
                  </p>
                  <h3 className="terms-item__title">
                    7. DISRUPTIONS AND CHANGE
                  </h3>
                  <p>
                    7.1. The WEBSITES, Services and Content are provided on an
                    “as is” basis and to the fullest extent permitted by law, we
                    make no warranty or representation, whether express or
                    implied, in relation to the satisfactory quality, fitness
                    for purpose, completeness or accuracy of the WEBSITES,
                    Services and Content.
                  </p>
                  <p>
                    7.2. We are not liable for any downtime, server disruptions,
                    lagging, or any technical or political disturbance to usage,
                    nor attempts by you to use the WEBSITES and Services by
                    methods, means or ways not intended by us.
                  </p>
                  <p>
                    7.3. We accept no liability for any damages or losses which
                    are deemed or alleged to have arisen out of or in connection
                    with any Services, the WEBSITES or its Content including,
                    without limitation, delays or interruptions in operation or
                    transmission, loss or corruption of data, communication or
                    lines failure, any person’s misuse of a WEBSITES or its
                    Content or any errors or omissions in Content.
                  </p>
                  <p>
                    7.4. We reserve the right to remove any part of the Services
                    from the WEBSITES at any time and without notice. In
                    addition, we reserve the right to suspend, modify, remove or
                    add Content to the WEBSITES at our sole discretion with
                    immediate effect and without notice. We will not be liable
                    to you for any loss suffered as a result of any changes made
                    or for any modification or suspension of or discontinuance
                    of the WEBSITES or any Services and you will have no claims
                    against us in such regard.
                  </p>
                  <p>
                    7.5. We may temporarily suspend the whole or any part of the
                    WEBSITES or any Services for any reason at our sole
                    discretion. We may, but will not be obliged to, give you as
                    much notice as is reasonably practicable of such suspension.
                    We will restore the WEBSITES, as soon as is reasonably
                    practicable, after such temporary suspension.
                  </p>
                  <h3 className="terms-item__title">8. PRIVACY POLICY</h3>
                  <p>
                    8.1. We are committed to protecting and respecting your
                    privacy and complying with all applicable data protection
                    and privacy laws. Our Privacy Policy is inseparably linked
                    to these Terms and its acceptance is a prerequisite to any
                    use of the WEBSITES and/or any Services.
                  </p>
                  <h3 className="terms-item__title">9. BLOCKING</h3>
                  <p>
                    9.1. We reserve the right to block you or refuse your use of
                    the WEBSITES and/or any Services for any reason whatsoever or
                    for no reason at any time without notifying you. Without
                    limiting the above, we reserve the right, at our sole
                    discretion, to do the same where we have reason to believe
                    that you have engaged or are likely to engage in any of the
                    following activities: (a) you breached, or assisted another
                    party to breach, any provision of these Terms or we have a
                    reasonable ground to suspect such breach; (b) your
                    communication with us consists of harassment or offensive
                    behavior, including (but not limited to) threatening,
                    derogatory, abusive or defamatory statements, or racist,
                    sexually explicit, pornographic, obscene or offensive
                    language; (c) you provide incorrect or misleading
                    information; (d) your identity cannot be verified; or (e)
                    you attempt to use or access the WEBSITES and/or any Services
                    through a VPN, proxy or similar service that masks or
                    manipulates the identification of your real location, or by
                    otherwise providing false or misleading information
                    regarding your citizenship, location or place of residence,
                    or through a third party or on behalf of a third party.
                  </p>
                  <p>
                    9.2. The rights set out in this clause are without prejudice
                    to any other rights that we may have against you under these
                    Terms or otherwise.
                  </p>
                  <h3 className="terms-item__title">
                    10. INDEMNITY AND LIMITATION OF LIABILITY
                  </h3>
                  <h5>
                    10.1. YOU AGREE TO INDEMNIFY AND HOLD HARMLESS US, OUR
                    DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, AGENTS AND
                    AFFILIATES, OUR ULTIMATE PARENT AND PARENT COMPANIES AND ANY
                    OF OUR SUBSIDIARIES AGAINST ANY AND ALL COSTS, EXPENSES,
                    LIABILITIES AND DAMAGES (WHETHER DIRECT, INDIRECT, SPECIAL,
                    CONSEQUENTIAL, EXEMPLARY OR PUNITIVE OR OTHER) ARISING FROM
                    ANY PARTICIPATION BY YOU, INCLUDING WITHOUT LIMITATION: (a)
                    ACCESSING OR USING THE WEBSITES AND/OR ANY SERVICES; (b)
                    BREACHING THESE TERMS OR ANY OTHER TERMS OR RULES OR
                    POLICIES OF ANY SERVICES; OR (с) BREACHING ANY LAWS OR
                    REGULATIONS.
                  </h5>
                  <h5>
                    10.2. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
                    UNDER NO CIRCUMSTANCES WHATEVER WILL WE OR OUR AFFILIATES,
                    SUBSIDIARIES, PARTNERS, OFFICERS, DIRECTORS, EMPLOYEES,
                    SHAREHOLDERS, AGENTS, LICENSORS, SUBCONTRACTORS AND
                    SUPPLIERS, BE RESPONSIBLE OR LIABLE TO YOU OR TO ANY OTHER
                    ENTITY, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
                    UNDER ANY LEGAL THEORY, WHETHER CONTRACT, TORT OR OTHERWISE,
                    FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
                    EXEMPLARY, OR PUNITIVE DAMAGES, INCLUDING ANY LOST PROFITS
                    AND LOST BUSINESS OPPORTUNITIES, BUSINESS INTERRUPTION, LOST
                    REVENUE, INCOME, GOODWILL, USE OF DATA OR OTHER INTANGIBLE
                    LOSSES, IN EACH CASE THAT RESULT FROM OR RELATE IN ANY
                    MANNER TO YOUR USE OF THE WEBSITES OR ANY SERVICES OR ANY
                    OTHER ACT OR OMISSION BY US.
                  </h5>
                  <h5>
                    10.3. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
                    UNDER NO CIRCUMSTANCES WILL WE, OUR AFFILIATES,
                    SUBSIDIARIES, PARTNERS, OFFICERS, DIRECTORS, EMPLOYEES,
                    SHAREHOLDERS, AGENTS, LICENSORS, SUBCONTRACTORS OR
                    SUPPLIERS, BE LIABLE TO YOU FOR MORE THAN US$100.
                  </h5>
                  <h5>
                    10.4. NOTHING IN THESE TERMS WILL OPERATE SO AS TO EXCLUDE
                    ANY LIABILITY OF VGW GROUP FOR DEATH OR PERSONAL PHYSICAL
                    INJURY THAT IS DIRECTLY AND PROXIMATELY CAUSED BY OUR
                    NEGLIGENCE OR WILFUL MISCONDUCT.
                  </h5>
                  <h3 className="terms-item__title">11. MISCELLANEOUS</h3>
                  <p>
                    11.1. These Terms constitute the entire agreement between
                    you and us with respect to the issues herein and supersede
                    all prior or contemporaneous communications and proposals,
                    whether electronic, oral or written, between you and us with
                    respect to your use of the WEBSITES.
                  </p>
                  <p>
                    11.2. We hereby reserve the right to amend these Terms or to
                    implement or amend any procedures, at any time. Any
                    amendments will be published on the WEBSITES and such changes
                    will be binding and effective immediately. Your continued
                    use of the WEBSITES following the changes constitutes your
                    acceptance of the revised Terms. If the modified Terms are
                    not acceptable to you, your only recourse is to discontinue
                    your use of the WEBSITES.
                  </p>
                  <p>
                    11.3. We will not be liable or responsible for any failure
                    to perform, or delay in performance of, any of our
                    obligations under these Terms that is caused by events
                    outside of our reasonable control.
                  </p>
                  <p>
                    11.4. Nothing in these Terms will be construed as creating
                    any agency, partnership, trust arrangement, fiduciary
                    relationship or any other form of joint enterprise between
                    you and us.
                  </p>
                  <p>
                    11.5. If any of the Terms are determined by any competent
                    authority to be invalid, unlawful or unenforceable to any
                    extent, such term, condition or provision will, to that
                    extent, be severed from these Terms. All remaining terms,
                    conditions and provisions will continue to be valid to the
                    fullest extent permitted by law. In such cases, the part
                    deemed invalid or unenforceable will be amended in a manner
                    consistent with the Applicable law to reflect, as closely as
                    possible, our original intent.
                  </p>
                  <p>
                    11.6. These Terms are personal to you, and are not
                    assignable, transferable or sub-licensable by you except
                    with our prior written consent. We reserve the right to
                    assign, transfer or delegate any of our rights and
                    obligations hereunder to any third party without notice to
                    you.
                  </p>
                  <p>
                    11.7. These Terms will be governed and interpreted in
                    accordance with the laws of the British Virgin Islands
                    without regard for its choice of conflict of law principles.
                    The application of the United Nations Convention on
                    Contracts for the International Sale of Goods is
                    specifically excluded. Any dispute, controversy or claim
                    arising out of or in connection with these Terms, or the
                    breach, termination or invalidity of these Terms, will be
                    submitted exclusively to the courts in the British Virgin
                    Islands and you and we consent to the venue and personal
                    jurisdiction of those courts. Notwithstanding the foregoing,
                    any motion to compel arbitration or to enforce an arbitral
                    award issued hereunder may be brought before any court of
                    competent jurisdiction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Terms;
