import React from 'react';
import s from "../../Modals/ShareBotModal/styles.module.scss";

const Loader = () => {
  return (
    <svg style={{marginTop: '30px'}} className={s.spinner} viewBox="0 0 50 50">
      <circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
    </svg>
  );
}

export default Loader;