import  React, {useCallback, useContext, useEffect, useState} from "react";
import verifiedIcon from "Image/verified.svg";
import s from "./styles.module.scss";
import {verify, verifyPoll} from "../../../../api/veriff";
import {Input} from "components/ui/Input";
import {tooltipFactory} from "utils/asset.utils";
import tooltipIcon from "Image/tooltipIcon.svg";
import {LandSaleContext} from "Context/LandSaleWallet";
import {getBotsSearch} from "api/bots";
import {DropdownSearch} from "components/ui/DropdownSearch";
import {getKingdomLands} from "api/land";
import {useDimensions} from "../../../../hooks/useDimensions";
import {createVeriffFrame, MESSAGES} from '@veriff/incontext-sdk';
import {PopupContext} from "../../../../Context/Popup";
import {VerificationExpiredPopup, VerificationNegativePopup, VerificationPopup} from "../../../Landsale/popups";

const Tooltip = tooltipFactory(
  true, {
    backgroundColor: "#fff",
    padding: "16px 20px",
    color: "#000",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    maxWidth: "586px",
  },
  {
    color: "#fff",
  },
  "bottom"
);

const BattleSetBuy = () => {

  const {purchase} = useContext(LandSaleContext)

  const [verified, setVerified] = useState("open");

  const {width} = useDimensions()

  const [landFirstTooltip, setLandFirstTooltip] = useState(false);
  const [landSecondTooltip, setLandSecondTooltip] = useState(false);

  const [landNumber, setLandNumber] = useState(0);
  const [landNumberRandom, setLandNumberRandom] = useState(1);

  const [inputGetLandValue, setGetLandValue] = useState("");
  const [rBots, setRBots] = useState([]);
  const [pickedBot, setPickedBot] = useState();
  const [lands, setLands] = useState([]);
  const [searchValue, setSearchValue] = useState('')
  const {setPopup} = useContext(PopupContext);
  const [noResult, setNoResult] = useState(false)
  const [reasonStatus, setReasonStatus] = useState('')
  const veriffIsClosed = window.config.VERIFF_IS_CLOSED
  // let as;
  // const veriffIsClosed = true

  const handlePickedBot = (item) => {
    setPickedBot(item)
  }

  const verifier = async () => {
    let res = {verification: {}};
    res.verification.id = localStorage.getItem('veriffSessionId')

    console.log(verified + reasonStatus)
    if(verified === "Not approved" && (reasonStatus === 'Face not clearly visible' || reasonStatus === 'Full document not visible' || reasonStatus === 'Poor image quality' || reasonStatus === 'Video and/or photos missing')){
      res.verification.url = localStorage.getItem('verificationUrl')
    }else{
      res = await (await verify()).json();
      localStorage.setItem('verificationUrl', res.verification.url)
    }

    // window.open(res.verification.url, '_blank');
    createVeriffFrame({
      url: res.verification.url,
      onEvent: (msg) => {
        // eslint-disable-next-line default-case
        switch (msg) {
          case MESSAGES.FINISHED:
            localStorage.setItem("veriffSessionId", res.verification.id);
            setVerified('Pending')
            break;
        }
      }
    })
    if (verified) {
      if (verified === 'Not approved'
        || verified === 'Negative'
        || verified === 'Resubmit') {
        // localStorage.setItem('verified', null)
        setVerified(null);
      }
    }
  };

  // function parseValue() {
  //   return localStorage.getItem("verified")
  //     ? localStorage.getItem("verified") === 'null' ? null
  //       : localStorage.getItem("verified")
  //     : null
  // }

  // not approved logic
  /* Modal shows to the user w/ button Proceed again.
   *  When user clicks, throws verifier() function, then state isVerified
   *  changes to null, to poll the backend
   *  */
  const searchHandler = (e) => {
    let text = e
    if(typeof e !== 'string'){
      text = e?.target?.value
    }
    setSearchValue(text)
    if(!text.length){
      setLands([])
      setLandNumber(0)
      setPickedBot(null)
    }
    setGetLandValue(e.target.value)
  }

  // TODO Veriff musn't be in this release
  useEffect(() => {
    if(veriffIsClosed){
      return
    }
    if (localStorage.getItem('veriffSessionId') && verified !== "Not approved" && verified !== "Positive") {
      const veriffInterval = setInterval(async () => {
        let isVerified;
        if (localStorage.getItem('veriffSessionId')) {
          const {status, reason} = await verifyPoll(
            // '9220a826-d881-4dc5-862d-395ad13b9df9'
            localStorage.getItem("veriffSessionId")
          );
          isVerified = status

          if(reason){
            setReasonStatus(reason)
          }
        }
        if (isVerified === undefined) {
          if (localStorage.getItem('veriffSessionId')) return setVerified('Pending')
          else return
        }

        // if(isVerified === 'Negative' || isVerified === 'Resubmit' || isVerified !=="Review"){
        //   // VerificationNegativePopup(setPopup)
        //   return
        // }
        if (isVerified !== "Resubmit"){
          setReasonStatus(null)
        }

        if(isVerified !== "Negative" && isVerified !== null && isVerified !== "Resubmit" && isVerified !=="Review"){
          setVerified(isVerified)
          clearInterval(veriffInterval)
        }else if(isVerified !== "Expired"){
          setVerified("Not approved")
          clearInterval(veriffInterval)
        }else if(isVerified === "Expired"){
          setVerified("Expired")
          clearInterval(veriffInterval)
        }
      }, 5000);
      return () => clearInterval(veriffInterval);
    }else if(verified !== "Not approved" && verified !== "Positive"){
      setVerified(null)
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verified]);

  useEffect(() => {
    if (verified !== null && verified !== 'Pending') {
      setPopup(null)
    }
    if(verified === 'Pending'){
      VerificationPopup(setPopup)
    }
    if(verified === "Expired"){
      VerificationExpiredPopup(setPopup)
    }
    if(verified === "Not approved"){
      VerificationNegativePopup(setPopup)
    }

    // localStorage.setItem("verified", verified);
  }, [verified, setPopup]);

  useEffect(() => {
    if (inputGetLandValue.length > 0)
      getBotsSearch(inputGetLandValue)
        .then((res) => {
          setRBots(res.content)
          console.log(res.content)
          if(!res.content?.length && inputGetLandValue.length){
            setNoResult(true)
            return
          }
          setNoResult(false)
        })
        .catch(() => setRBots([]));

    else setRBots([]);
  }, [inputGetLandValue]);

  const getLands = useCallback(() => {
    if (pickedBot) {
      getKingdomLands(pickedBot.id).then((res) => {
        setLands(res);
        setLandNumber(Number(!!res.length));
      });
    }
  }, [pickedBot])

  useEffect(() => {
    if (pickedBot) {
      setGetLandValue("");
      getLands();
    }
  }, [pickedBot, getLands]);

  const refreshPurchase = useCallback(async (object) => {
    await purchase(object, true)
    getLands();
  }, [purchase, getLands])



  return (
    <>
      <div className={`${s.battleSetBuy} ${veriffIsClosed ? s.battleSetBuy_closedVerif : ''}`} id="battleSetBuy" name='battleSetBuy'>
        <h4>
          <span className="yellow">buy your</span> battle set
        </h4>
        <div className={`${s.buttonContainer} ${veriffIsClosed ? s.buttonContainer_closedVerif : ''}`}>
          {((verified !== "Not approved" && verified !== "Expired" && verified !== null && verified !== 'Pending' && verified !== "open") || veriffIsClosed) ?
            !veriffIsClosed
              ? <div className={s.button + " " + s.verified}>
                <img src={verifiedIcon} alt="ID Verification"/>
                Your ID was verified!
              </div>
              : <></>
            : (
              <button
                className={s.button}
                onClick={verified !== 'Pending' && verified !== "open" ? verifier : null}
              >
                {verified === 'Pending' ? 'Your ID is being reviewed' : 'Verify your ID to buy'}
              </button>
            )}
        </div>
      </div>
      {((verified !== "Not approved" && verified !== "Expired" && verified !== null && verified !== 'Pending' && verified !== "open") || veriffIsClosed) && (
        // {true && (
        <div className={s.fullBlock}>
          <div className={s.landBg}>
            <div className={s.getLand}>
              <div className={s.kingdom}>
                    <span className={s.title} style={{display: width <= 450 ? 'block' : 'flex'}}>
                      get land in a specific kingdom
                      <Tooltip
                        onClick={() => setLandFirstTooltip(!landFirstTooltip)}
                        open={landFirstTooltip}
                        title="Buy in a Kingdom to play alongside a friend, empower your guild, or as a Rebel Bot Kingdom Lord owner."
                      >
                        <img className={s.tooltipIcon} src={tooltipIcon} alt="Tooltip Icon"/>
                      </Tooltip>
                    </span>
                <DropdownSearch
                  options={(rBots.length > 0 && searchValue.length) ? rBots : []}
                  isOpen={!!searchValue.length}
                  search={{isLoading: (rBots.length) > 0 ? false : undefined}}
                  landsLookup={true}
                  forceNoResult={noResult}
                  buy={true}
                  choose={handlePickedBot}
                >
                  <Input
                    type={"text"}
                    placeholder={"Kingdom name/Bot name/ID"}
                    onChange={searchHandler}
                    onClear={() => searchHandler('')}
                    value={searchValue}
                  />
                </DropdownSearch>
                <span className={s.availableTitle}>
                                  {pickedBot ?
                                    lands.length > 0 ?
                                      `There are ${lands.length} lands available in this kingdom`
                                      : `No lands available in this kingdom`
                                    : null
                                  }
                                </span>
              </div>
            </div>
            {landNumber ? <div className={s.purchase}>
              <div className={s.inputs}>
                <div className={s.landFilter}>
                  <div className={s.maxLands}>
                    <button
                      onClick={() => setLandNumber(landNumber - 1)}
                      disabled={landNumber === 1}
                      className={s.minus}
                    >
                      -
                    </button>
                    <span>{landNumber} Lands</span>
                    <div className={s.plusmax}>
                      <button
                        className={s.plus}
                        onClick={() => setLandNumber(landNumber + 1)}
                        disabled={landNumber === lands.length}
                      >
                        +
                      </button>
                      <button
                        className={s.max}
                        onClick={() =>
                          lands.length
                            ? setLandNumber(lands.length)
                            : setLandNumber(24)
                        }
                      >
                        Max
                      </button>
                    </div>
                  </div>
                </div>
                <div className={s.amountToBuy}>
                  <span>{`${(landNumber * window.config.BS_PRICE / 1_000_000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USDC`}</span>
                  <button
                    className={s.purchaseButton}
                    disabled={!pickedBot && landNumber === 0}
                    onClick={async () => {
                      const object = {
                        total: landNumber * window.config.BS_PRICE / 1_000_000,
                        tokenNumber: null,
                        specifics:
                          landNumber <= lands.length
                            ? lands
                              .slice(0, landNumber)
                              .map((item) => item.toString())
                            : [],
                      };
                      await refreshPurchase(object);
                    }}
                  >
                    Purchase
                  </button>
                </div>
              </div>
            </div> : null}
          </div>
          <div className={s.or}>
            <div className={s.orTitle}>or</div>
          </div>
          <div className={s.random}>
            <div className={s.randomBlock}>
              <span className={s.title}>
                get a random land
                <Tooltip
                  title="Jump into the battle in a random Kingdom with land available."
                  onClick={() => setLandSecondTooltip(!landSecondTooltip)}
                  open={landSecondTooltip}
                >
                  <img src={tooltipIcon} alt="Tooltip Icon"/>
                </Tooltip>
              </span>
              <div className={s.inputs}>
                <div className={s.landFilter}>
                  <div className={s.maxLands}>
                    <button
                      className={s.minus}
                      onClick={() => setLandNumberRandom(landNumberRandom - 1)}
                      disabled={landNumberRandom === 1}
                    >
                      -
                    </button>
                    <span>{landNumberRandom} Lands</span>
                    <div className={s.plusmax}>
                      <button
                        className={s.plus}
                        onClick={() => setLandNumberRandom(landNumberRandom + 1)}
                        disabled={landNumberRandom === 24}
                      >
                        +
                      </button>
                      <button
                        className={s.max}
                        onClick={() => setLandNumberRandom(24)}
                      >
                        Max
                      </button>
                    </div>
                  </div>
                </div>
                <div className={s.amountToBuy}>
                  <span>{`${(landNumberRandom * window.config.BS_PRICE / 1_000_000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USDC`}</span>
                  <button
                    className={s.purchaseButton}
                    onClick={async () => {
                      const obj = {
                        total: landNumberRandom * window.config.BS_PRICE / 1_000_000,
                        specifics: null,
                        tokenNumber: landNumberRandom,
                      }
                      await refreshPurchase(obj);
                    }}
                  >
                    Purchase
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BattleSetBuy;
