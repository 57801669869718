import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import Web3 from "web3";
import RebelBots from "../lib/RebelBots.json";
import detectEthereumProvider from "@metamask/detect-provider";
import { isMobile } from "react-device-detect";
import { PopupContext } from "./Popup";
import {LandSaleContext} from "./LandSaleWallet";
import { WrongNetworkPopup} from "../page/Landsale/popups";

export const WalletContext = createContext();

const gasStationUrl =
  window.config.ENVIRONMENT === "prod"
    ? "https://gasstation.polygon.technology/v2"
    : "https://gasstation-testnet.polygon.technology/amoy";

/*
  if (e.code === -32002) {
    setPopup({
      type: "error",
      title: "Error",
      text: "Already processing request. Please wait.",
      btnText: "OK",
      onClick: () => {
        setPopup(null);
      },
    });
  }
 */

const Wallet = ({ children }) => {
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [account, setAccount] = useState(undefined);
  const [isConnected, setIsConnected] = useState(false);
  const [balance, setBalance] = useState(null);
  const { popup, setPopup } = useContext(PopupContext);

  const { account: accountLandSaleContext, web3: web3LandSaleProvider } = useContext(LandSaleContext)

  const [getReservedTokens, setGetReservedTokens] = useState(BigInt("0"));
  const [price, setPrice] = useState(BigInt("0"));
  // const [device, setDevice] = useState(null);
  const [device] = useState(null);
  const [claimAddress, setClaimAddress] = useState(null);
  const [transactionState, setTransactionState] = useState({
    name: "none",
  });

  // if user pressed connect or changed account in the wallet app.
  const [accountChangedManually, setAccountChangedManually] = useState(false);

  // const { pathname, host } = window.location;
  const { pathname } = window.location;

  useEffect(() => {
    setAccount(account ? account : accountLandSaleContext)
  }, [account, accountLandSaleContext])

  useEffect(() => {
    if (web3 && window.config.BUY_CONTRACT) {
      setContract(
        new web3.eth.Contract(RebelBots.abi, window.config.BUY_CONTRACT)
      );
    } else {
      setContract(null);
    }
  }, [web3]);

  const getMediumGasValue = async () => {
    try {
      const data = await (await fetch(gasStationUrl)).json();
      const gasPrice = web3.utils.toWei(data.fast.maxFee.toFixed(9), "gwei");
      console.log("gas price: ", data.fast.maxFee);
      console.log("gas price wei: ", gasPrice);
      return gasPrice;
    } catch (error) {
      console.error(error);
      //if request fails, return 0 gas price
      return 0;
    }
  };

  // Clear transaction state after success popup is closed.
  useEffect(() => {
    if (transactionState.name === "receipt" && !popup) {
      const timeout = window.setTimeout(() => {
        setTransactionState({
          name: "none",
        });
      }, 5000);

      return () => {
        window.clearTimeout(timeout);
      };
    }
  }, [transactionState, popup]);

  function checkBalance(newBalance) {
    if (newBalance === 0) {
      // if desktop and account connected automatically
      if (!accountChangedManually && !isMobile) return;
      // if mobile and account connected automatically
      if (isMobile && !localStorage.getItem("checkBalance")) return;
      localStorage.removeItem("checkBalance");
      setPopup({
        type: "error",
        title: "Sorry",
        text: "You cannot participate in the community sale. Wallet not whitelisted.",
        btnText: "OK",
        onClick: () => {
          setPopup(null);
        },
      });
    }
  }

  const fetchBalance = useCallback(async () => {
    console.log(isConnected, account);

    if (isConnected && account && contract) {
      // setPopup(null);
      // Todo: setPopup ^ diturb, might need to delete it
      const myBalance = await contract.methods
        .getRebelBotsHolderBalance(account)
        .call();

      const newBalance = parseInt(myBalance);
      console.log("New balance: ", newBalance);
      setBalance(newBalance);
      checkBalance(newBalance);

      const myReservedTokens = await contract.methods
        .getTotalReservedTokens(account)
        .call();
      console.log("Reserved tokens: ", myReservedTokens);
      setGetReservedTokens(BigInt(myReservedTokens) / BigInt("100000000"));

      const rblsPrice = await contract.methods.getPrice().call();
      console.log("RBLS price: ", rblsPrice);
      setPrice(BigInt(rblsPrice));
    } else {
      setBalance(null);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isConnected, account, contract]);

  useEffect(() => {
    if (account) {
      setClaimAddress(account);
      fetchBalance();
    }
    // don't depend on fetchBalance
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isConnected, account]);

  const checkAccounts = (isConnect, web3) => {
    if (isConnect) setAccountChangedManually(true);

    let accountsPromise;
    // if presed by hand, request accounts
    // with popup if needed.
    // otherwise silently fetch
    // already connected accounts.
    if (isConnect) {
      console.log('dsad')
      accountsPromise = web3.eth.requestAccounts();
    } else {
      console.log({ web3, web3Acc: web3.eth.getAccounts() })
      accountsPromise = web3.eth.getAccounts();
    }

    accountsPromise.then(
      (newAccounts) => {
        if (newAccounts[0]) {
          setAccount(newAccounts[0]);
          console.log({ newAccount: newAccounts[0] })
        }else {
          console.log({ accountLandSaleContext })
          setAccount(accountLandSaleContext)
        }
      },
      (error) => {
        switch (error.code) {
          // already pending
          case -32002:
            break;
          // manually rejected
          case 4001:
            break;
          default:
            setPopup({
              type: "error",
              title: "Oops!",
              text: "Something went wrong. Please try again later.",
              btnText: "OK",
              onClick: () => {
                setPopup(null);
              },
            });
        }
      }
    );
  };

  const toCheckSumWallet = useCallback((address) => {
    return web3?.utils?.toChecksumAddress(address)
  }, [web3, account])

  const fetchProvider = async (isConnect, options, botId, nonce) => {
    if (!(pathname === "/token-sale" || pathname.includes("/profile") || pathname === '/botsNft')) return;

    if (web3 && nonce) {
      const text = window.config.SIGN_SHOP_TEXT
        ? window.config.SIGN_SHOP_TEXT.replace("%s", nonce)
        : `Please sign in to edit your bot story.\n\n Nonce: ${nonce}`;

      return new Promise(async (resolve, reject) =>
        await web3.eth.personal.sign(
          web3.utils.fromUtf8(text),
          account,
          "",
          (err, signature) => {
            if (err) return reject(err);
            return resolve({ account, signature });
          }
        )
      );
    }

    const provider = await detectEthereumProvider({
      mustBeMetaMask: true,
    });
    if (provider) {
      if (!provider.selectedAddress && web3LandSaleProvider) {
        // provider.selectedAddress =  account ? account : accountLandSaleContext;
        // console.log({ state: provider.state })
        // console.log({ provider })
        setWeb3(web3LandSaleProvider)
      } else {
        const newWeb3 = new Web3(provider);
        setWeb3(newWeb3);
        provider.on("disconnected", () => {
          console.log("disconnecting");
          setWeb3(null);
          setAccount(undefined);
          setIsConnected(false);
          setPopup({
            type: "error",
            title: "Error",
            text: "Disconnected from Metamask app.",
            btnText: "OK",
            onClick: () => {
              setPopup(null);
            },
          });
        });

        provider.on("networkChanged", (id) => {
          // don't show popup when changed in metamask.
          checkNetwork(id, false, provider, options);
        });

        const id = await newWeb3.eth.net.getId();
        // if network is wrong, don't connect accounts.
        if (!checkNetwork(id, isConnect, provider, options)) return;

        checkAccounts(isConnect, newWeb3, options);
        provider.on("accountsChanged", (newAccounts) => {
          if (isMobile) {
            localStorage.setItem("checkBalance", true);
            // otherwise metamask app switches to old account...
            //window.location.reload();
          }
          setAccountChangedManually(true);
          setAccount(newAccounts[0]);
        });
      }

      if (isMobile) {
        // no provider, device is mobile. Try to open metamask app.
        // const isProfile = pathname.includes("/profile");
        // const metamaskHost = "https://metamask.app.link/dapp";
        // setDevice(
        //     isProfile && botId
        //         ? `${metamaskHost}/${host}/wprofile/${botId}`
        //         : `${metamaskHost}/${host}/token-sale`
        // );
      }
      // else if (!isConnect) {
      //   console.log("No provider");
      //   metamaskNotInstalledPopup(setPopup)
      // }
    }
  };

  function checkNetwork(idData, isConnect ) {
    const id = idData.toString();
    if (
      (window.config.ENVIRONMENT === "dev" && id === "80002") ||
      (window.config.ENVIRONMENT === "prod" && id === "137") ||
        (window.config.ENVIRONMENT === "dev" && id === "3")
    ) {
      setIsConnected(true);
      // Todo: Either remove function call for Sorry modal or remove setPopup(null)
      // setPopup(null);
      return true;
    } else {
      setIsConnected(false);
      // show popup only when connect metamask button is pressed.
      if (isConnect) WrongNetworkPopup(); // Todo: might need to make the condition only for Metamask
      return false;
    }
  }

  const reserveToken = async (amount, claimAddress) => {
    if (web3 && isConnected && account && contract) {
      //                                   87654321
      const realAmount = amount * BigInt("100000000");
      const totalPrice = realAmount * price;
      const gasPrice = await getMediumGasValue();

      return new Promise(async () => {
        try {
          const gas = await contract.methods
            .reserveTokens(realAmount, claimAddress)
            .estimateGas({
              from: account,
              value: totalPrice.toString(),
            });

          console.log("estimated gas: ", gas);

          setTransactionState({
            name: "start",
          });

          let hashReceived = false;
          let confirmationReceived = false;

          contract.methods
            .reserveTokens(realAmount, claimAddress)
            .send({
              from: account,
              gasPrice: gasPrice,
              value: totalPrice.toString(),
              gas,
            })
            .on("transactionHash", function (hash) {
              if (!hashReceived) {
                hashReceived = true;
                setTransactionState({
                  name: "transactionHash",
                });
              }
              console.log("hash: ", hash);
            })
            .on("confirmation", function (confirmationNumber, receipt) {
              if (!confirmationReceived) {
                hashReceived = true;
                if (confirmationNumber >= 12) {
                  confirmationReceived = true;
                  setTransactionState({
                    name: "receipt",
                  });
                  setPopup({
                    type: "success",
                    style: "popup-claim",
                    title: "Transaction complete",
                    amount,
                    claimAddress,
                  });
                  fetchBalance();
                } else if (
                  !(confirmationNumber < transactionState.confirmationNumber)
                ) {
                  setTransactionState({
                    name: "confirmation",
                    confirmationNumber,
                  });
                }
              }

              console.log(
                "confirmationNumber: ",
                confirmationNumber,
                ", receipt: ",
                receipt
              );
            })
            .on("receipt", function (receipt) {
              /*               hashReceived = true;
              confirmationReceived = true;
              setTransactionState("receipt");
              console.log("receipt: ", receipt);
              setPopup({
                type: "success",
                style: "popup-claim",
                title: "Transaction complete",
                amount,
                claimAddress,
              });
              fetchBalance();
              */
            })
            .on("error", function (error, receipt) {
              if (
                error.message &&
                error.message.match("Be aware that it might still be mined!")
              )
                return;

              setTransactionState({
                name: "none",
              });

              console.log("error: ", error, ", receipt: ", receipt);
              setPopup({
                type: "error",
                title: "Error",
                text: "Error occured while processing your transaction.",
                btnText: "OK",
                onClick: () => {
                  setPopup(null);
                },
              });
            });
        } catch (e) {
          setTransactionState({
            name: "none",
          });

          console.error(e);

          if (e.message.match(`"code": -32000`)) {
            setPopup({
              type: "error",
              title: "Transaction Failed",
              text: "There are insufficient funds in your wallet to perform the transaction.",
              btnText: "OK",
              onClick: () => {
                setPopup(null);
              },
            });
          } else if (
            e.message.match(
              "Tokens number exceeds the allowed number to reserve"
            )
          ) {
            setPopup({
              type: "error",
              title: "Limit reached",
              text: "You have reached your RBLS token limit for the community token sale.",
              btnText: "OK",
              onClick: () => {
                setPopup(null);
              },
            });
          } else {
            setPopup({
              type: "error",
              title: "Error",
              text: "Error occured while processing your transaction.",
              btnText: "OK",
              onClick: () => {
                setPopup(null);
              },
            });
          }
        }
      });
    }

    return false;
  };

  const exp = {
    account,
    fetchProvider,
    isConnected,
    balance,
    fetchBalance,
    reserveToken,
    getReservedTokens,
    price,
    device,
    popup,
    setPopup,
    transactionState,
    claimAddress,
    setClaimAddress,
    toCheckSumWallet
  };

  return (
    <WalletContext.Provider value={exp}>{children}</WalletContext.Provider>
  );
};

export default Wallet;
