import axios from "axios";

export const getPost = async () => {
  try {
    const { data } = await axios.get("https://cdn.rebelbots.com/wiki/posts");
    return data;
  } catch (e) {}
};

export const getSupportPosts = async () => {
  try {
    const { data } = await axios.get("https://cdn.rebelbots.com/wiki/support");
    return data;
  } catch (e) {}
};

export const getSupportCategories = async () => {
  try {
    const { data } = await axios.get("https://cdn.rebelbots.com/wiki/supportcategories");
    return data;
  } catch (e) {}
};
