import React from "react";
import { useEffect, useState } from "react";

function Timer({ date, handleSet, showColumn = true }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  /* eslint-disable react-hooks/exhaustive-deps */

  const countDown = () => {
    const today = new Date().getTime();
    if (date > today) {
      handleSet(true);
      const endDate = date;
      const today = new Date().getTime();
      const timeDiff = endDate - today;
      const s = 1000;
      const m = s * 60;
      const h = m * 60;
      const d = h * 24;
      let timeDays = Math.floor(timeDiff / d);
      let timeHours = Math.floor((timeDiff % d) / h);
      let timeMinutes = Math.floor((timeDiff % h) / m);
      let timeSeconds = Math.floor((timeDiff % m) / s);

      timeDays = timeDays < 10 ? "0" + timeDays : timeDays;
      timeHours = timeHours < 10 ? "0" + timeHours : timeHours;
      timeMinutes = timeMinutes < 10 ? "0" + timeMinutes : timeMinutes;
      timeSeconds = timeSeconds < 10 ? "0" + timeSeconds : timeSeconds;

      setDays(timeDays);
      setHours(timeHours);
      setMinutes(timeMinutes);
      setSeconds(timeSeconds);
    } else {
      handleSet(false);
    }
  };

  useEffect(() => {
    countDown();
    const id = setInterval(countDown, 1000);
    return () => {
      clearTimeout(id);
    };
  }, []);

  return (
    <h2>
      <span>{days}d</span>
      {showColumn ? <span>:</span> : ""}
      {hours}:{minutes}:{seconds}
    </h2>
  );
}

export default Timer;
