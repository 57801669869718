import React from "react";
import { Route } from "react-router-dom";

import AppLayout from "./layouts/AppLayout";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// import TokenSale from "page/TokenSale";
import Dao from "page/Dao";
import Faq from "page/Faq";
// import Lands from "page/Lands";
import Privacy from "page/Privacy";
import Terms from "page/Terms";
import WhitePaper from "page/WhitePaper";
import Tsl from "page/Tsl";
import Profile from "page/Profile";
import ProfileDetails from "page/ProfileDetails";
import Careers from "page/Careers";
import CareerDetail from "page/CareerDetail";
import CareerApply from "page/CareerApply";
import Team from "page/Team";
import Landing from "page/Landing";
import TeamDetail from "page/TeamDetail";
import CardsExplorer from "./page/CardsExplorer";
import CardPage from "./page/CardPage";
import NftPage from "./page/NftPage/NftPage";
import Wiki from "./page/Wiki/Wiki";
import WikiCategories from "./page/WikiCategroies/WikiCategories";
import WikiItem from "./page/WikiItem/WikiItem";
import LandsNft from "./page/LandsNftPage";
import BotsNftPage from "./page/BotsNftPage/BotsNftPage";
import BattleSet from "./page/BattleSet/BattleSet";
import FightingBots from "./page/FightingBots/FightingBots";
import GamingGuilds from "./page/GamingGuilds/GamingGuilds";
import RblsXoilPage from "./page/RblsXoilPage/RblsXoilPage";
import Guilds from "./page/Guilds";
import RoadmapPage from "./page/RoadmapPage/RoadmapPage";
import Home from "./page/Home/Home";
import Contact from "./page/Contact/Contact";
import TradingLeaderboard from "./page/TradingLeaderboard/TradingLeaderboard";
import Play from "./page/Play/Play";
import Support from "./page/Support/Support";
import SupportCategories from "./page/SupportCategories/SupportCategories";
import SupportItem from "./page/SupportItem/SupportItem";

const App = () => {

    const RedirectToWikiItem = () => {
        const navigate = useNavigate();

        useEffect(() => {
            navigate('/wikiItem/82762');
        }, [navigate]);

        return null;
    };

  return (
    <>
        <AppLayout>
            <Route path="/" element={<Home />} />
            {/* <Route path="/token-sale" element={<TokenSale />} /> */}
            <Route path="/dao" element={<Dao />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/whitePaper" element={<WhitePaper />} />
            {/*<Route path="/lands" element={<LandSale />} />*/}
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/tsl" element={<Tsl />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/:id" element={<ProfileDetails />} />
            <Route path="/profile/:id/*" element={<ProfileDetails />} />
            <Route path="/careers/" element={<Careers />} />
            <Route path="/careers/:id" element={<CareerDetail />} />
            <Route path="/careers/apply/:id" element={<CareerApply />} />
            <Route path="/guilds" element={<GamingGuilds />} />
            <Route path="/team" element={<Team />} />
            <Route path="/team/:id" element={<TeamDetail />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/cardsExplorer/*" element={<CardsExplorer />} />
            <Route path="/card/:id" element={<CardPage />} />
            <Route path="/nft" element={<NftPage />} />
            <Route path="/landsNft" element={<LandsNft /> } />
            <Route path="/botsNft" element={<BotsNftPage /> } />
            <Route path="/wiki" element={<Wiki /> } />
            <Route path="/wiki/:category" element={<WikiCategories /> } />
            <Route path="/wikiItem/:id" element={<WikiItem /> } />
            <Route path="/support" element={<Support /> } />
            <Route path="/support/:category" element={<SupportCategories /> } />
            <Route path="/supportItem/:id" element={<SupportItem /> } />
            <Route path="/battleSet" element={<BattleSet /> } />
            <Route path="/fightingBots" element={<FightingBots /> } />
            <Route path="/roadmap" element={<   RoadmapPage /> } />
            <Route path="/rbls" element={<RblsXoilPage type='rbls' /> } />
            <Route path="/xoil" element={<RblsXoilPage type='xoil' /> } />
            <Route path="/registerGuild" element={<Guilds /> } />
            <Route path="/leaderboard" element={<TradingLeaderboard /> } />
            <Route path="/play" element={<Play /> } />
            <Route path="/contact" element={<Contact /> } />
            <Route path="/loginguide" element={<RedirectToWikiItem />} />
        </AppLayout>
    </>
  );
};

export default App;
