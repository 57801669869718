import React from 'react';
import s from './styles.module.scss'

const FightingBotsItem = ({ rarityData, passiveSkills, skill}) => {

  let skillImage
  for (let key in passiveSkills){
    if(passiveSkills[key]['Skill name'] === skill){
      skillImage = passiveSkills[key]['ImageURL']
    }
  }

  return (
    <div>
      <p className={s.assetItem__claimed}>HP:
        {/*<span> {hp}</span></p>*/}
        <span> -</span></p>
      <p className={s.assetItem__claimed}>Spark:
        {/*<span className={s.assetItem__claimed__span}> {spark}</span></p>*/}
        <span className={s.assetItem__claimed__span}> -</span></p>

      <div className={s.carts}>
        <div>
          <img src={skillImage} alt="class"/>
        </div>
        <div>{rarityData.common}</div>
        <div>{rarityData.rare}</div>
        <div>{rarityData.epic}</div>
        <div>{rarityData.legendary}</div>
      </div>
    </div>
  );
}

export default FightingBotsItem;