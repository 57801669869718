import React from 'react';
import s from './styles.module.scss'

const BattleSetTitle = ({shortened}) => {
  return (
    <div className={s.battleSetTitle}>
      {
        shortened ?  <h2><span className={'yellow'}>COMING </span> SOON</h2> : <h2><span className={'yellow'}>battle</span> sets</h2>
      }

      <p>
        Get your new battle set to dominate the competition and play, earn and win crypto rewards. Your battle set unlocks your
        home base where you will command your army of free fighting bots to climb the leaderboards and win epic prizes.

        <div>Join the Xoil Wars now to conquer and earn your victory!</div>
      </p>
    </div>
  );
}

export default BattleSetTitle;