import React from 'react';
import s from "./styles.module.scss";

const RblsClaim = () => {
  return (
    <div className={s.buttonContainer}>
      <a rel='noreferrer' target={'_blank'} href='https://vesting.rebelbots.com/' className={s.button}>Claim RBLS</a>
    </div>
  );
}

export default RblsClaim;