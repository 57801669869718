import React, {useEffect, useState} from 'react';
import s from './styles.module.scss'
import {useDimensions} from "../../../../hooks/useDimensions";
import html2canvas from "html2canvas";
import {saveBotBuilder} from "../../../../api/card";
import ProfileShareModal from "../../../../components/Modals/ProfileShareModal/ProfileShareModal";
import shareTwitterButton from "../../../../Image/shareTwitterButtonHover.svg"
import shareFacebookButton from "../../../../Image/shareFacebookButtonHover.svg"


const ProfileShareButtons = () => {

  const {width} = useDimensions()

  const [urls, setUrls] = useState(null)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [bot, setBot] = useState(null)

  const saveImage = (name) => {
    html2canvas(document.querySelector('#profileShare'), {
      letterRendering: 1,
      allowTaint: true,
      useCORS: true,
      backgroundColor: '#0c0c0c',
      windowHeight:  800,
      windowWidth: 1720,
      width: 1720,
      height: 800,
      imageTimeout: 15000,
      logging: true,
      scale: 2,
      ignoreElements: function( element ) {
        if('user_metamask' === element.id || 'profileShare__textArea' === element.id || element.classList.contains( 'user_metamask') || element.classList.contains('edit-bot')) {
          return true;
        }
      },
      onclone: function (clonedDoc) {
          clonedDoc.getElementById('botName').style.opacity = '1'
          clonedDoc.getElementById('botName').style.height = 'auto'
      }
    })
      .then((canvasShare) => {
        canvasShare.style.display = 'none';
        let image = canvasShare.toDataURL("image/jpeg", 0.7)
        setBot(image)
        // let a = document.createElement("a");
        // a.setAttribute('download', 'bot.jpeg');
        // a.setAttribute('href', image);
        // a.click();
        setModalIsOpen(true)
        saveBotBuilder(image).then(({data}) => {
          // const shareBaseUrl = 'https://bots-dev.rebelbots.com/cardsExplorer/' + data.split('botbuilder/')[1]?.slice(0, -4)
          const shareBaseUrl = window.location.href + '/' + data.split('botbuilder/')[1]?.slice(0, -4)

          const shareUrl = {
            twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareBaseUrl)}&text=${encodeURIComponent('Check out my Xoil Wars Kingdom Lord ')}&hashtags=rebelbots`,
            facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareBaseUrl)}&quote=${encodeURIComponent('Check out my Xoil Wars Kingdom Lord ')}`
          }

          setUrls(shareUrl[name])

          // window.open(baseUrls[name], '_blank');
        })
      })
  }

  useEffect(() => {
    let image = new Image();
    image.src = shareTwitterButton;
    image.src = shareFacebookButton;
  }, [])

  return (
    <div className={s.profileShareButtons}>
      {/*<img width={300} src={botImage} alt=""/>*/}
        <img src={shareTwitterButton} alt="" hidden />
        <img src={shareFacebookButton} alt="" hidden />
      <ProfileShareModal shareUrl={urls} open={modalIsOpen} handleClose={() => setModalIsOpen(false)} botImage={bot} />
      <div className={s.buttons}>
        {
          width >= 576 ?
            <>
            <button onClick={() => saveImage('twitter') } >
            </button>
            <button onClick={() => saveImage('facebook')}>
            </button>
          </>
          : <>
              <button onClick={() => saveImage('twitter') }>
                <div>
                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.2489 1.28143C17.2338 1.0535 17.0837 0.85678 16.8678 0.782067C16.6519 0.707354 16.4123 0.769177 16.2595 0.939001C15.8668 1.37556 15.3704 1.67138 14.8666 1.80626C14.166 1.15212 13.2245 0.750816 12.19 0.750816C9.96961 0.750816 8.17115 2.55331 8.17115 4.77495V5.27476C4.77187 5.01206 2.95406 2.42982 2.46355 1.11978C2.38426 0.908027 2.18697 0.763434 1.96117 0.751595C1.73537 0.739756 1.52404 0.862924 1.42305 1.06523C0.233536 3.44792 0.0502611 5.75891 0.914792 7.72679C1.67212 9.45065 3.19518 10.8238 5.35382 11.7336C4.37277 12.4288 2.72156 13.2513 0.796765 13.2513C0.54645 13.2513 0.325622 13.4151 0.252999 13.6547C0.180376 13.8942 0.273069 14.1531 0.481244 14.2921C2.32689 15.5244 4.26123 15.5243 6.06039 15.5241L6.11546 15.5241C11.6392 15.5241 16.1155 11.0396 16.1155 5.50952V4.96599C16.5285 4.54623 16.8501 4.11653 17.0471 3.56102C17.267 2.94117 17.3113 2.22244 17.2489 1.28143Z" />
                  </svg>
                  Share to Twitter
                </div>
              </button>
              <button onClick={() => saveImage('facebook')}>
                <button >
                  <div>
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.0912 8.9429C18.0912 4.17801 14.2295 0.316223 9.46457 0.316223C4.69968 0.316223 0.837891 4.17801 0.837891 8.9429C0.837891 13.2492 3.99067 16.8181 8.11732 17.4654V11.4386H5.92864V8.9429H8.11732V7.04267C8.11732 4.88229 9.4029 3.68669 11.3742 3.68669C12.3195 3.68669 13.3075 3.85687 13.3075 3.85687V5.97916H12.2204C11.1474 5.97916 10.8165 6.64571 10.8165 7.32641V8.9429H13.2084L12.8256 11.4386H10.8165V17.4654C14.9385 16.8178 18.0912 13.2492 18.0912 8.9429Z" />
                      <path d="M12.8188 11.4385L13.2016 8.94285H10.8098V7.32636C10.8098 6.64566 11.1454 5.97912 12.2136 5.97912H13.3007V3.85682C13.3007 3.85682 12.3127 3.68665 11.3675 3.68665C9.39647 3.68665 8.11056 4.88259 8.11056 7.04262V8.94285H5.92188V11.4385H8.11056V17.4654C8.55031 17.5362 8.99917 17.5695 9.4578 17.5695C9.91643 17.5695 10.3653 17.5318 10.805 17.4654V11.4385H12.8188Z" fill="black"/>
                    </svg>
                    Share to Facebook
                  </div>
                </button>
              </button>
            </>
        }

      </div>
    </div>
  );
}

export default ProfileShareButtons;