export const dropdownOptionsEnums = {
  subGuild: [
    { id: 1, title: "No", value: true, hasValue: true },
    { id: 2, title: "Yes", value:  false, hasValue: true },
  ],
  ownsNFT: [
    { id: 1, title: "Not yet", value: true, hasValue: true },
    { id: 2, title: "Yes", value:  false, hasValue: true },
  ],
  languages: [
    {id: 1, title: "English"},
    {id: 2, title: "Spanish"},
    {id: 3, title: "Filipino"},
    {id: 4, title: "Tagalog"},
    {id: 5, title: "German"},
    {id: 6, title: "French"},
    {id: 7, title: "Turkish"},
    {id: 8, title: "Ukrainian"},
    {id: 9, title: "Thai"},
    {id: 10, title: "Swedish"},
    {id: 11, title: "Portuguese"},
    {id: 12, title: "Dutch"},
    {id: 13, title: "Chinese"},
    {id: 14, title: "Bulgarian"},
  ],
  region: [
    {id: 1, title: "North America"},
    {id: 2, title: "South America"},
    {id: 3, title: "Europe"},
    {id: 4, title: "Southeast Asia (Indonesia, Malaysia, the Philippines, and Thailand)"},
    {id: 5, title: "Asia (China, Taiwan, Korea, etc.)"},
    {id: 6, title: "Africa"},
    {id: 7, title: "Australia"},
  ],
  hear: [
    {id: 1, title: "Friends/Family"},
    {id: 2, title: "Other Guild"},
    {id: 3, title: "Business Network"},
    {id: 4, title: "Google"},
    {id: 5, title: "Twitter"},
    {id: 6, title: "Facebook"},
    {id: 7, title: "YouTube"},
    {id: 8, title: "Blog/Article"},
    {id: 9, title: "other"},
  ],
  platform: [
    {id: 1, title: "PC"},
    {id: 2, title: "Phone"},
    {id: 3, title: "I like Both"},
  ],
}