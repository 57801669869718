import React, { useContext } from "react";
import { PopupContext } from "Context/Popup";
import PopupGeneric from "../PopupGeneric";

const PopupError = () => {
  const { popup } = useContext(PopupContext);

  if (!popup || popup.type !== "error") return null;

  const PopupContent = popup.text ? "p" : popup.element ? "div" : null;

  return (
    <PopupGeneric>
      <PopupContent className="box-desc">
        {popup.text || popup.element}
      </PopupContent>
      {popup?.onClick && (
        <button className="box-button" onClick={popup.onClick}>
          {popup.btnText}
        </button>
      )}
    </PopupGeneric>
  );
};

export default PopupError;
