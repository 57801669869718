import fbBotsBg1 from "../../../../Image/fbBotsBg1.png";
import fbBotsBg2 from "../../../../Image/fbBotsBg2.png";
import fbBotsBg3 from "../../../../Image/fbBotsBg3.png";
import fbBotsBg1Tablet from "../../../../Image/fbBotsBg1Tablet.png";
import fbBotsBg2Tablet from "../../../../Image/fbBotsBg2Tablet.png";
import fbBotsBg3Tablet from "../../../../Image/fbBotsBg3Tablet.png";
import fbBotsBg1Mobile from "../../../../Image/fbBotsBg1Mobile.png";
import fbBotsBg2Mobile from "../../../../Image/fbBotsBg2Mobile.png";
import fbBotsBg3Mobile from "../../../../Image/fbBotsBg3Mobile.png";
import fbBots1 from "../../../../Image/fbBots1.png";
import fbBots2 from "../../../../Image/fbBots2.png";
import fbBots3 from "../../../../Image/fbBots3.png";
import fbBots1Tablet from "../../../../Image/fbBots1Tablet.png";
import fbBots2Tablet from "../../../../Image/fbBots2Tablet.png";
import fbBots3Tablet from "../../../../Image/fbBots3Tablet.png";
import fbBots1Mobile from "../../../../Image/fbBots1Mobile.png";
import fbBots2Mobile from "../../../../Image/fbBots2Mobile.png";
import fbBots3Mobile from "../../../../Image/fbBots3Mobile.png";

export const classList = [
  {
    backgroundImage: fbBotsBg1,
    backgroundImageTablet: fbBotsBg1Tablet,
    backgroundImageMobile: fbBotsBg1Mobile,
    botsImage: fbBots1,
    botsImageTablet: fbBots1Tablet,
    botsImageMobile: fbBots1Mobile,
    title: 'industrial',
    titleColor: '#FFC328',
    description: `Built to withstand the most devastating attacks and keep on fighting, 
      Industrial Bots are the tank of your team. With huge health pools and unbeatable durability, 
      when the going gets tough, these bots take the heat.`
  },
  {
    backgroundImage: fbBotsBg2,
    backgroundImageTablet: fbBotsBg2Tablet,
    backgroundImageMobile: fbBotsBg2Mobile,
    botsImage: fbBots2,
    botsImageTablet: fbBots2Tablet,
    botsImageMobile: fbBots2Mobile,
    title: 'military',
    titleColor: '#7A8F2A',
    description: `The most dangerous Bots on the battlefield! With their high-powered 
      attacks these destructive bots command respect. Want to prove your mettle? You'll be needing a Military bot.`
  },
  {
    backgroundImage: fbBotsBg3,
    backgroundImageTablet: fbBotsBg3Tablet,
    backgroundImageMobile: fbBotsBg3Mobile,
    botsImage: fbBots3,
    botsImageTablet: fbBots3Tablet,
    botsImageMobile: fbBots3Mobile,
    title: 'engineer',
    titleColor: '#4DBCDF',
    description: `
      Programmed as master tacticians, Engineer bots have an array of attacks and 
      survival skills at their disposal. These bots can provide life-saving support to allies 
      or scramble your opponent's software. Stay one step ahead with an Engineer Bot in your army.
    `
  }
]