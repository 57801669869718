import React, {useState} from "react";
import { StandardLayout } from "components/layouts/StandardLayout";
import s from "./styles.module.scss";
import {SearchInput} from "../../components/ui/SearchInput/SearchInput";
import CardsExplorerFilters from "./components/CardsExplorerFilters/CardsExplorerFilters";
import {SortFilter} from "./components/SortFilter";
import {useCardFilter} from "../../hooks/useCardFilter";
import {useSelectCard} from "../../hooks/useSelectCard";
import {useDimensions} from "../../hooks/useDimensions";
import CardsExplorerWrapper from "../../components/BotBuilder/components/CardsExplorerWrapper";
import {BotBuilder} from "../../components/BotBuilder";
import AboutCards from "../../components/BotBuilder/components/AboutCard/AboutCards";

const CardExplorer = () => {
  const { cards, sort, onSearch, filters, filter, setFilters } = useCardFilter()
  const { setCard, selectedCards, removeCard, setBot, bot, isSelection, setIsSelection} = useSelectCard(setFilters)
  const [kingdomCard, setKingdomCard] = useState(null)
  const { width } = useDimensions();

  const handleSearch = (data) => {
    onSearch(data)
  }


  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
        <AboutCards />
        <h3>
          card <span style={{  color: "#FFC328" }}>explorer</span>
        </h3>
        <BotBuilder isSelection={isSelection}
                    setIsSelection={setIsSelection}
                    selectedCards={selectedCards}
                    removeCard={removeCard}
                    setFilters={setFilters}
                    bot={bot}
                    setBot={setBot}
                    kingdomCard={kingdomCard}
        />
        {
          width < 1024 &&
          <h3 className={s.browseCards}>
            browse  <span style={{  color: "#FFC328" }}>the cards</span>
          </h3>
        }

        <div className={s.content}>

          {width < 992 && <SearchInput handleSearch={handleSearch} />}

          <CardsExplorerFilters cardsLength={cards.length} sort={sort} filters={filters} filter={filter} setFilters={setFilters} />

          <div style={{width: '100%'}}>
            {width > 992 &&
              <>
                <h4>{cards.length} cards</h4>
                <SearchInput handleSearch={handleSearch} />
              </>
            }
            {(width > 992 || width < 576) &&
              <SortFilter sort={sort}  />
            }

            {/*CARDS*/}
            <CardsExplorerWrapper setIsSelection={setIsSelection}
                                  setCard={setCard}
                                  isSelection={isSelection}
                                  setFilters={setFilters}
                                  cards={cards}
                                  selectedCards={selectedCards}
                                  setKingdomCard={setKingdomCard}
                                  kingdomCard={kingdomCard}
            />
          </div>

        </div>
      </main>
    </StandardLayout>
  );
};

export default CardExplorer;
