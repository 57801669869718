import React from 'react';
import LegendPopupImage from '../../../../Image/LegendPopup.svg'
import s from './styles.module.scss'
import {useDimensions} from "../../../../hooks/useDimensions";

const LegendPopup = ({isOpen, closePopup}) => {
  const {width} = useDimensions()

  return (
    <div
      onClick={closePopup}
      className={s.legendPopup}
      style={{display: isOpen ? 'block' : 'none'}}
    >
        {
          width > 576
            ? <img src={LegendPopupImage} alt="popup"/>
            : <div></div>
        }
    </div>
  );
}

export default LegendPopup;