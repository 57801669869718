import React, { useState } from "react";

import { ReactComponent as Right } from "Image/right.svg";
import { ReactComponent as Left } from "Image/left.svg";
import { useDimensions } from "hooks/useDimensions";
import s from "./styles.module.scss";

export const FeaturedBots = ({ botsData }) => {
  const { width } = useDimensions();
  const [botIndex, setBotIndex] = useState(0);
  if (!botsData?.[botIndex]) return null;

  const isDisabledLeft = botIndex === 0;
  const isDisabledRight = botsData.length === botIndex + 1;

  const toggleFeaturedBots = (step) => {
    if ((isDisabledLeft && step < 0) || (isDisabledRight && step > 0)) return;
    setBotIndex((prev) => prev + step);
  };

  const { id, img, name, code, botDesc } = botsData[botIndex];

  return (
    <div className={s.users_block_background}>
      {width >= 1024 && (
        <div className={s.user}>
          <div className={s.user_wrapper}>
            <div className={s.desktop}>
              <p className={s.desktop_bot_names}>FEATURED BOTS</p>
              <div className={s.desktop_bot_photo_block}>
                <Left
                  className={isDisabledLeft ? s.next_disabled : s.left}
                  onClick={() => toggleFeaturedBots(-1)}
                />
                <div className={s.desktop_photo_text}>
                  <div className={s.desktop_bot_photo}>
                    <div>
                      <div className={s.userShadow} />
                      <img src={img} alt="bot" />
                    </div>
                  </div>
                  <div className={s.desktop_bot_info}>
                    <a className={s.desktop_bot_name} href={`/profile/${id}`}>
                      {name && code ? `${name} - ${code}` : name || code}
                    </a>
                    <div
                      className={s.desktop_bot_desk}
                      dangerouslySetInnerHTML={{
                        __html: botDesc,
                      }}
                    />
                  </div>
                </div>
                <Right
                  onClick={() => toggleFeaturedBots(1)}
                  className={isDisabledRight ? s.next_disabled : s.right}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {width <= 1024 && width >= 670 && (
        <div className={s.user}>
          <div className={s.user_wrapper}>
            <div className={s.tablet}>
              <p className={s.tablet_bot_names}>FEATURED BOTS</p>
              <div className={s.tablet_bot_photo_block}>
                <Left
                  className={isDisabledLeft ? s.next_disabled : s.left}
                  onClick={() => toggleFeaturedBots(-1)}
                />
                <div className={s.tablet_photo_text}>
                  <div className={s.tablet_bot_photo}>
                    <div>
                      <div className={s.userShadow} />
                      <img src={img} alt="bot" />
                    </div>
                  </div>
                  <div className={s.tablet_bot_info}>
                    <a className={s.tablet_bot_name} href={`/profile/${id}`}>
                      {name && code ? `${name} - ${code}` : name || code}
                    </a>
                    <div
                      className={s.tablet_bot_desk}
                      dangerouslySetInnerHTML={{
                        __html: botDesc,
                      }}
                    />
                  </div>
                </div>
                <Right
                  onClick={() => toggleFeaturedBots(1)}
                  className={isDisabledRight ? s.next_disabled : s.right}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {width <= 670 && (
        <div className={s.user}>
          <div className={s.user_wrapper}>
            <div className={s.mobile}>
              <div className={s.bot}>
                <p className={s.bot_names}>FEATURED BOTS</p>
                <div className={s.bot_photo_block}>
                  <Left
                    className={isDisabledLeft ? s.next_disabled : s.left}
                    onClick={() => toggleFeaturedBots(-1)}
                  />
                  <div className={s.bot_photo}>
                    <div className={s.userShadow} />
                    <img src={img} alt="bot" />
                  </div>
                  <Right
                    onClick={() => toggleFeaturedBots(1)}
                    className={isDisabledRight ? s.next_disabled : s.right}
                  />
                </div>
                <div className={s.bot_info}>
                  <a className={s.bot_name} href={`/profile/${id}`}>
                    {name && code ? `${name} - ${code}` : name || code}
                  </a>
                  <div
                    className={s.bot_desk}
                    dangerouslySetInnerHTML={{
                      __html: botDesc,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
