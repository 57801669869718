import React from 'react';
import ExplorerModalWrapper from "../ExplorerModalWrapper/ExplorerModalWrapper";
import {useDimensions} from "../../../hooks/useDimensions";
import s from "../ShareBotModal/styles.module.scss";


const ProfileShareModal = ({open, handleClose, isTeamBuilder = false, shareUrl, botImage}) => {
  const {width} = useDimensions()

  return (
    <ExplorerModalWrapper open={open}
                          handleClose={handleClose}
                          title={'Share this rebel bot'}>
      <div className={`${s.wrapper} ${s.profile} ${isTeamBuilder ? s.battleSet : ''}`}>
        <div className={s.info__mobile}>
          Share this rebel {isTeamBuilder ? 'battle set' : 'bot'} with your friends and subscribers
        </div>
          <img className={s.bot} src={botImage} alt="bot"/>
        <div className={s.shareButtons}>
          <div className={s.info}>
            Share this rebel {isTeamBuilder ? 'Battle set' : 'bot'} with your friends and subscribers
          </div>
          {
            width >= 1224
              ?  <a rel={'noreferrer'} href={shareUrl} target={'_blank'} className={`${s.buttonsWrapper} ${!!shareUrl ? '' : s.buttonsWrapper_block}`}>
                <button
                  title={'share'}
                >
                  <div className={`${s.button} ${width > 1018 ? 'button-type1 black' : ''}`}>
                    {
                      shareUrl
                        ? <>
                          <span className="button-type1-before" />
                          <span className={`${s.label} label`}>
                          <span> Share</span>
                          </span>
                          <span className="button-type1-after" />
                        </>
                        : <svg className={s.spinner} viewBox="0 0 50 50">
                          <circle className={s.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                        </svg>
                    }

                  </div>
                </button>

              </a>
              : <a rel={'noreferrer'} href={shareUrl} target={'_blank'} className={s.buttonsWrapper__mobile}>
                <button
                  title={'share'}
                  style={{color: '#fff', fontSize: 18}}
                >
                  share
                </button>
              </a>

          }
        </div>
      </div>

    </ExplorerModalWrapper>
  );
}

export default ProfileShareModal;