import React, {
    useRef,
    useState,
    useEffect,
    useContext,
    useCallback,
    useMemo,
} from "react";
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {Editor} from "react-draft-wysiwyg";
import {useParams} from "react-router-dom";
import TextArea from "react-textarea-autosize";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {ReactComponent as FormButtonMobile} from "Image/formButtonMobile.svg";
import {ReactComponent as FormButton} from "Image/formButton.svg";
import {ReactComponent as EditIcon} from "Image/editIcon.svg";

import s from "./styles.module.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import {WalletContext} from "Context/Wallet";
import {LandSaleContext} from "Context/LandSaleWallet";
import {PopupContext} from "Context/Popup";
import {useDimensions} from "hooks/useDimensions";
import {ProfileInfoBlock} from "components/ProfileInfoBlock";
import {putBotById, getBotById, getNonceByAddress} from "api/bots";
import ProfileShareButtons from "../Profile/components/profileShareButtons/ProfileShareButtons";
// import ProfileKpi from "./components/ProfileKpi/ProfileKpi";

const ProfileDetails = () => {
    const {id} = useParams();
    const {width} = useDimensions();
    const [account, setAccount] = useState(undefined);
    const [checkSumFunc, setToCheckSumFunc] = useState(undefined);
    const {setPopup} = useContext(PopupContext);
    const {account: accountWalletContext, fetchProvider, toCheckSumWallet} = useContext(WalletContext);
    const {account: accountLandSaleContext, toCheckSum} = useContext(LandSaleContext);

    const canvasRef = useRef();
    const botNameRef = useRef();
    const botInfoRef = useRef();
    const aboutMeRef = useRef();

    const createEmpty = () => EditorState.createEmpty();

    const [botName, setBotName] = useState("");
    const [aboutBotEditorState, setAboutBotEditorState] = useState(createEmpty);
    const [aboutUserEditorState, setAboutUserEditorState] = useState(createEmpty);

    const [isOwner, setIsOwner] = useState(false);
    const [botByIdData, setBotByIdData] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [focusedOnly, setFocusedOnly] = useState(null);
    const [isForceOpen, setIsForceOpen] = useState(false);
    const [isAllowSave, setIsAllowSave] = useState(false);
    const [editIconPosition, setEditIconPosition] = useState(0);

    /* eslint-disable react-hooks/exhaustive-deps */

    const getHtmlStringText = (html) => {
        // eslint-disable-next-line no-undef
        return jQuery("<div>").html(html).text();
    };

    const getAndSetBot = async () => {
        try {
            const info = await getBotById(id);
            setBotByIdData(info);
            initState(info);
        } catch (e) {
        }
    };

    useEffect(() => {
        getAndSetBot();
    }, []);

    useEffect(() => {
        setAccount(() => accountWalletContext ? accountWalletContext : accountLandSaleContext)
    }, [])

    useEffect(() => {
        setAccount(() => accountWalletContext ? accountWalletContext : accountLandSaleContext)
    }, [accountWalletContext, accountLandSaleContext])

    useEffect(() => {
        setToCheckSumFunc(() => toCheckSum ? toCheckSum : toCheckSumWallet)
    }, [])

    useEffect(() => {
        setToCheckSumFunc(() => toCheckSum ? toCheckSum : toCheckSumWallet)
    }, [toCheckSum, toCheckSumWallet])

    const requestFailedPupup = async () => {
        setPopup({
            type: "error",
            title: "Save Failed",
            text: "Saving new story failed. Please try again.",
            btnText: "OK",
            onClick: () => {
                setPopup(null);
            },
        });
    };

    const onSaveChanges = async () => {
        try {
            const nonce = await getNonceByAddress(account);
            const {signature} = await fetchProvider(false, {}, undefined, nonce);
            const bot = {
                name: botName,
                botDesc: draftToHtml(
                  await convertToRaw(aboutBotEditorState.getCurrentContent())
                ),
                ownerDesc: draftToHtml(
                  await convertToRaw(aboutUserEditorState.getCurrentContent())
                ),
            };
            const {status} = await putBotById({
                id,
                address: checkSumFunc(account),
                sign: signature,
                bot,
            });
            if (status !== 200) {
                requestFailedPupup();
            } else {
                setBotByIdData((prev) => ({...prev, ...bot}));
                setFocusedOnly(null);
                setIsAllowSave(false);
            }
        } catch (e) {
            requestFailedPupup();
        }
    };

    useEffect(() => {
        if (botByIdData.owner && typeof botByIdData?.owner !== "undefined") {
            setIsOwner((botByIdData.owner?.toLowerCase() === account?.toLowerCase())
            // setIsOwner((true)
            );
        }
    }, [botByIdData, account]);

    const onEditMode = () => {
        setIsAllowSave(false);
        setIsEditMode((prev) => !prev);
    };

    const initState = useCallback((info) => {
        setBotName(info.name)

        const botsInfoBlocksFromHTML = convertFromHTML(info.botDesc || "");
        const botsInfoState = ContentState.createFromBlockArray(
          botsInfoBlocksFromHTML.contentBlocks,
          botsInfoBlocksFromHTML.entityMap
        );
        setAboutBotEditorState(EditorState.createWithContent(botsInfoState));

        const ownerDescBlocksFromHTML = convertFromHTML(
          info.ownerDesc || info.owner || ""
        );
        const ownerDescState = ContentState.createFromBlockArray(
          ownerDescBlocksFromHTML.contentBlocks,
          ownerDescBlocksFromHTML.entityMap
        );
        setAboutUserEditorState(EditorState.createWithContent(ownerDescState));
    }, []);

    const saveAboutBotEditorState = useCallback(
      (state) => {
          const str = getHtmlStringText(
            draftToHtml(convertToRaw(state.getCurrentContent()))
          );
          const oldStr = getHtmlStringText(
            draftToHtml(convertToRaw(aboutBotEditorState.getCurrentContent()))
          );

          if (str.length <= 9999) {
              setAboutBotEditorState(state);
          } else {
              setAboutBotEditorState(aboutBotEditorState);

              if (oldStr.length !== str.length) {
                  botInfoRef?.current?.blur();
              }
          }
      },
      [aboutBotEditorState]
    );

    const saveAboutUserEditorState = useCallback(
      (state) => {
          const str = getHtmlStringText(
            draftToHtml(convertToRaw(state.getCurrentContent()))
          );
          const oldStr = getHtmlStringText(
            draftToHtml(convertToRaw(aboutUserEditorState.getCurrentContent()))
          );

          if (str.length <= 9999) {
              setAboutUserEditorState(state);
          } else {
              setAboutUserEditorState(aboutUserEditorState);
              if (oldStr.length !== str.length) {
                  aboutMeRef?.current?.blur();
              }
          }
          setAboutUserEditorState(state);
      },
      [aboutUserEditorState]
    );

    const onCancelChanges = useCallback(() => {
        initState(botByIdData);
        setIsEditMode(false);
        setFocusedOnly(null);
        botNameRef?.current?.blur();
    }, [botByIdData, initState]);

    useEffect(() => {
        switch (focusedOnly) {
            case "name":
                botNameRef?.current?.focus();
                break;
            case "me":
                aboutMeRef?.current?.focus();
                break;
            case "owner":
                botInfoRef?.current?.focus();
                break;
            default:
                return;
        }
    }, [
        focusedOnly,
        botNameRef?.current,
        aboutMeRef?.current,
        botInfoRef?.current,
    ]);

    // Make icon of textarea to be on a correct position for name
    // Todo: Refactor, this calculations might be unnecessary
    useEffect(() => {
        if (width <= 1024) return;
        const ctx = canvasRef?.current?.getContext("2d");
        if (ctx && botName && focusedOnly !== "name") {
            let text = botName;
            let space = 0;
            let margins = 0;
            let maxSize = 0;
            if (width > 3800) {
                space = 10;
                margins = 192;
                maxSize = width - 147;
                ctx.font = "192px Cold Warm";
            } else if (width > 1650) {
                space = 10;
                margins = 159;
                maxSize = width - 147;
                ctx.font = "97px Cold Warm";
            } else if (width <= 1650 && width > 1500) {
                space = 10;
                margins = 100;
                maxSize = width - 90;
                ctx.font = "97px Cold Warm";
            } else if (width <= 1500 && width > 1024) {
                space = 10;
                margins = 60;
                maxSize = width - 50;
                ctx.font = "97px Cold Warm";
            }
            // else if (width <= 1024 && width > 500) {
            //   space = 10;
            //   margins = 60;
            //   maxSize = width - 50;
            //   ctx.font = "70px Cold Warm";
            // } else if (width <= 500) {
            //   space = 10;
            //   margins = 30;
            //   maxSize = width - 30;
            //   ctx.font = "40px Cold Warm";
            // }
            const textSize = ctx.measureText(text).width + margins + space;
            setEditIconPosition(textSize > maxSize ? maxSize : textSize);
        }
    }, [canvasRef?.current, botByIdData?.name, focusedOnly, botName, width]);

    // Selected state was not working with bad internet connection with custom button
    const SvgButtonBg = useMemo(() => {
        if (width <= 550) return <FormButtonMobile className={s.svg_background}/>;

        return <FormButton className={s.svg_background}/>;
    }, [width]);

    return (
      <div className="wrapper">
          {botName && <canvas ref={canvasRef} style={{position: "absolute"}}/>}
          <Header setIsForceOpen={setIsForceOpen} isForceOpen={isForceOpen}/>
          <main className="main">
              <div className={s.main_container}>
                  <div id='profileShare'>
                      <div className={s.page_top}>
                          <TextArea
                            ref={botNameRef}
                            value={botName}
                            disabled={focusedOnly !== "name"}
                            className={`${s.about_textarea} ${s.bot_name}`}
                            onChange={({target: {value}}) => {
                                setBotName((prev) => (value.length <= 64 ? value : prev));
                            }}
                            id='profileShare__textArea'
                          />
                          {/*h2 for share func*/}
                          <div className={s.edit_wrapper}>
                              <h2 id='botName'>{botName}</h2>
                              {isEditMode && isOwner && focusedOnly !== "name" && (
                                <EditIcon
                                  // prettier-ignore
                                  className={`${s.edit_icon} ${isEditMode ? `${s.is_edit_mode}` : ""}`}
                                  {...(width > 1024 && {
                                      style: {position: "absolute", left: editIconPosition},
                                  })}
                                  onClick={() => {
                                      setFocusedOnly("name");
                                      setIsAllowSave(true);
                                  }}
                                />
                              )}
                              {isOwner && !isEditMode && (
                                <span  onClick={onEditMode} className={`${s.edit_button} edit-bot`}>
                                <EditIcon  className={`${s.edit_icon} edit-bot`}/>
                              Edit profile
                            </span>
                              )}
                          </div>
                      </div>
                      {botByIdData?.id >= 0 && <ProfileInfoBlock data={botByIdData}/>}
                        {/*<ProfileKpi />*/}
                  </div>
                  <ProfileShareButtons id={id}/>

                  <div className={s.main_bottom}>
                      <div>
                          <p className={s.about_me}>
                              {isEditMode && isOwner && focusedOnly !== "me" && (
                                <EditIcon
                                  className={s.edit_icon}
                                  onBlur={() => setIsAllowSave(false)}
                                  onClick={() => {
                                      setFocusedOnly("me");
                                      setIsAllowSave(true);
                                  }}
                                />
                              )}
                              ABOUT <span>ME</span>
                          </p>
                          {/* ABOUT ME */}
                          {isEditMode && focusedOnly === "me" ? (
                            <Editor
                              editorRef={(ref) => (aboutMeRef.current = ref)}
                              editorState={aboutBotEditorState}
                              onEditorStateChange={saveAboutBotEditorState}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                            />
                          ) : (
                            <p className={s.about_textarea}
                               dangerouslySetInnerHTML=
                                 {{
                                     __html: draftToHtml(convertToRaw(aboutBotEditorState.getCurrentContent()))
                                 }}/>
                          )}
                      </div>
                      <div>
                          <p className={s.about_owner}>
                              {isEditMode && isOwner && focusedOnly !== "owner" && (
                                <EditIcon
                                  className={s.edit_icon}
                                  onBlur={() => setIsAllowSave(false)}
                                  onClick={() => {
                                      setFocusedOnly("owner");
                                      setIsAllowSave(true);
                                  }}
                                />
                              )}
                              ABOUT <span>THE OWNER</span>
                          </p>
                          {/* ABOUT THE OWNER */}
                          {isEditMode && focusedOnly === "owner" ? (
                            <Editor
                              editorRef={(ref) => (botInfoRef.current = ref)}
                              editorState={aboutUserEditorState}
                              onEditorStateChange={saveAboutUserEditorState}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                            />
                          ) : (
                            <p
                              className={s.about_textarea}
                              dangerouslySetInnerHTML={{
                                  __html: draftToHtml(
                                    convertToRaw(aboutUserEditorState.getCurrentContent())
                                  ),
                              }}
                            />
                          )}
                      </div>
                      {isEditMode && isAllowSave && (
                        <div className={s.edit_form_buttons}>
                            <button onClick={onCancelChanges} className={s.form_button}>
                                Cancel
                                {SvgButtonBg}
                            </button>
                            <button onClick={onSaveChanges} className={s.form_button}>
                                Save
                                {SvgButtonBg}
                            </button>
                        </div>
                      )}
                  </div>
              </div>
          </main>
          <Footer/>
      </div>
    );
};

export default ProfileDetails;
