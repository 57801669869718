import React from "react";

import s from "./styles.module.scss";

export const Dropdown = ({
  isOpen,
  options,
  children,
  onSelect,
  onMouseEnter,
  onMouseLeave,
  optionsBgColor = 'rgba(35, 35, 35, 0.94)'
}) => {
  return (
    <div
      className={s.dropdown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      {isOpen && (
        <div
          style={{
            paddingTop: 5,
            position: "absolute",
            width: "100%",
          }}
        >
          <div style={{background: optionsBgColor}} className={s.option_wrapper}>
            {options?.map((item) => (
              <p
                key={item.id}

                className={s.option_item}
                onClick={() => onSelect(item)}
              >
                {item.title}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
