import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

import PopupError from "../../components/PopupError";
import PopupClaim from "../../components/PopupClaim";
import PopupSuccess from "../../components/PopupSuccess";

/* eslint-disable react-hooks/exhaustive-deps */

// Otherwise the jquery will not work

const Redirect = ({ to }) => {
  useEffect(() => {
    window.location.pathname = to;
  }, []);
  return null;
};

TagManager.initialize({ gtmId: "GTM-NDQV5P5" });

const AppLayout = ({ children }) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    // Todo: this renders late on /landsale
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <div>
      <Routes>
        {children}
        <Route path="*" element={<Redirect to="/" />} />
      </Routes>
      <PopupError />
      <PopupClaim />
      <PopupSuccess />
    </div>
  );
};

export default AppLayout;
