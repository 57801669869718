import React from 'react';
import s from "./styles.module.scss";
import metamaskIcon from '../../../../Image/metamaskIcon.png'
import wcIcon from '../../../../Image/wcIcon.png'

const PlayConnect = () => {
  const mmLink = 'https://metamask.io/'
  const wcLink = 'https://walletconnect.com/'

  return (
    <section className={s.PlayConnect}>
      <h3><span className='yellow'>connect</span> a wallet</h3>

      <p>Launch Xoil Wars and connect your battle set wallet on the Polygon mainnet to login and play.</p>
      <div className={s.buttons}>
        <a href={mmLink} rel={'noreferrer'} target={'_blank'}><img src={metamaskIcon} alt=""/> Metamask</a>
        <a href={wcLink} rel={'noreferrer'} target={'_blank'}><img src={wcIcon} alt=""/>WalletConnect</a>
      </div>
    </section>
  );
}

export default PlayConnect;