import React, {useState, useEffect} from "react";
// import { isMobile } from "react-device-detect";

export default function CustomButton({
                                       texture,
                                       callback,
                                       className,
                                       label,
                                       width,
                                       height,
                                       disabled,
                                       style,
                                       disableHover,
                                       connect = false
                                     }) {
  let classNames = ["custom-button"];

  if (className) {
    classNames.push(className);
  }

  //only for jpg, svg and png. jpeg won't work!
  const fileName = texture.slice(0, -4);
  const extension = texture.slice(-3);

  let [appendix, setAppendix] = useState("");

  useEffect(() => {
    if (disabled) {
      setAppendix("");
    }
  }, [disabled]);

  // preload textures that don't render immidiately, hover and selected
  // doesn't work with dev tool :( maybe will work in real case?
  useEffect(() => {
    let imageHover = new Image();
    imageHover.src = `assets/${fileName}Hover.${extension}`;

    let imageSelected = new Image();
    imageSelected.src = `assets/${fileName}Selected.${extension}`;
  }, [fileName, extension]);

  let file = `${fileName}${disabled ? "Disabled" : appendix}.${extension}`;

  if (disabled) {
    classNames.push("custom-button--disabled");
  }

  return (
    // TODO background-image makes new request every time :( maybe img will not?
    <button
      className={classNames.join(" ")}
      data-selected={appendix === 'Selected'}
      onPointerEnter={
        disabled || disableHover
          ? undefined
          : () => {
            setAppendix("Hover");
          }
      }
      onPointerLeave={
        disabled
          ? undefined
          : () => {
            setAppendix("");
          }
      }
      onPointerDown={
        disabled
          ? undefined
          : (e) => {
            // e.preventDefault(); // prevents button from getting focused outline state when clicked
            setAppendix("Selected");
          }
      }
      onPointerUp={
        disabled
          ? undefined
          : (e) => {
            connect === false && callback(e);
            setAppendix("");
          }
      }
      onClick={
        disabled
          ? undefined
          : (e) => {
            connect && callback(e)
            setAppendix("");
          }
      }
      style={{
        backgroundImage: `url(/assets/${file})`,
        width: `${width}px`,
        height: `${height}px`,
        cursor: disabled ? "default" : "pointer",
        ...style,
      }}
      disabled={disabled}
    >
      {label}
    </button>
  );
}
