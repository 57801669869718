import React from 'react';
import s from './styles.module.scss'
import {StandardLayout} from "../../components/layouts/StandardLayout";
import {rblsXoilData} from './RblsXoilData'
import RblsClaim from "./components/RblsClaim/RblsClaim";
import {useDimensions} from "../../hooks/useDimensions";

const RblsXoilPage = ({type}) => {

  const data = rblsXoilData[type]
  const {width} = useDimensions()


  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
        <h2 className={`yellow ${s.title}`}>{data.title}</h2>
        <h4 className={s.subtitle}>{data.subtitle}</h4>

        <div className={`${s.descriptionWrapper} ${data.title === 'rbls' ? s.descriptionWrapper_rbls : s.descriptionWrapper_xoil}`}>

          <img src={width > 576 ? data.image : data.imageMobile} alt=""/>

          <h3>{type === 'rbls' ? 'buy' : 'swap' } {data.title}</h3>
          <div className={`${s.buy__urls} ${s.buy__urls_mobile}`}>
            {
              data.infoUrls.map(button => <a key={button.name} rel='noreferrer' target={button.selfOpen ? '_self' :'_blank' } href={button.url} >{button.name}</a>)
            }
          </div>

          <div dangerouslySetInnerHTML={{ __html:  data.description}}></div>
        </div>

        <div className={s.buy}>

          <h3>{type === 'rbls' ? 'buy' : 'swap' } {data.title}</h3>
          <div className={s.buy__buttonsWrapper}>
            <div className={s.buy__buttons}>
              {
                data.buyButtons.map(button => <a rel='noreferrer' target={button.selfOpen ? '_self' : '_blank'}
                                                 key={button.name} href={button.url}><span>{button.name}</span> </a>)
              }
            </div>
            <div className={s.buy__urls}>
              {
                data.infoUrls.map(button => <a rel='noreferrer' target={button.selfOpen ? '_self' : '_blank'}
                                               key={button.name} href={button.url}>{button.name}</a>)
              }
            </div>
          </div>
        </div>



        <div className={`${s.content}`}>
          {
            data.content.map(el => (
              <div key={el.id} className={s.content__item}>
                <h4 dangerouslySetInnerHTML={{ __html:  el.title}}></h4>
                <p dangerouslySetInnerHTML={{ __html:  el.text}}></p>
              </div>
            ))
          }
        </div>

        {
          data.infoButtons.map((el, index) => (
            <a rel='noreferrer'
               target={el.selfOpen ? '_self' :'_blank' }
               href={el.url} className={`${s.infoButton} 
               ${index === 1 ? s.infoButton__second : ''}
               ${el.disabled ? `${s.infoButton__disabled} coming-soon-item`  : ''}
               `}
               key={el.name}
            >{el.name}</a>
          ))
        }

        {
          type === 'rbls' &&
          <>
            <div className={s.content}>
              <div className={s.content__item}>
                <h4><span className={'yellow'}>CLAIM YOUR</span>  RBLS</h4>
                <p>Did you participate in our RBLS community sale or Beta rewards program? Claim your tokens here.
                </p>
              </div>
            </div>
            <RblsClaim/>
          </>
        }
      </main>
    </StandardLayout>
  );
}

export default RblsXoilPage;