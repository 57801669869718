import React from 'react';
import Roadmap from "../HomePage/components/Roadmap/Roadmap";
import s from "./styles.module.scss";
import {StandardLayout} from "../../components/layouts/StandardLayout";

const RoadmapPage = () => {
  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
        <Roadmap />
      </main>
    </StandardLayout>
  );
}

export default RoadmapPage;