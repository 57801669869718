import React, { createContext, useState } from "react";

export const PopupContext = createContext();

const Popup = ({ children }) => {
  const [popup, setPopup] = useState(null);

  const exp = {
    popup,
    setPopup,
  };

  return <PopupContext.Provider value={exp}>{children}</PopupContext.Provider>;
};

export default Popup;
