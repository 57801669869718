import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {setId} from "../../../components/GuildTable/GTableHelpers";
import s from "../../../components/GuildTable/styles.module.scss";
import { tooltipFactory} from "../../../utils/asset.utils";
import {getLeaderboard} from "../../../api/TradingLeaderborad";
import TradingLeaderboardTableRow from "./TradingLeaderboardTableRow";
import Lookup from "../../../components/Lookup/Lookup";

const TradingLeaderboardTable = () => {
  const [page, setPage] = useState(1)
  const [tipPage, setTipPage] = useState(0);
  const [changedGuilds, setChangeGuild] = useState([])
  const [guilds, setGuilds] = useState([])

  useEffect(() => {
    getAllGuilds()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getAllGuilds = useCallback(() => {
    getLeaderboard().then(res => {

      setGuilds(res.items)
      setChangeGuild(res.items )
    })

  }, [])


  const paginationNumbers = useMemo(() => {
    const length = Math.ceil(changedGuilds.length / 20);
    return [...Array(length).keys()]
  }, [changedGuilds])

  const headers = useMemo(() => ['TRADER', 'TRADE VOLUME'], [])

  const paginationPage = useMemo(() => {
    const limit = {
      minPage: 0,
      maxPage: 0
    }

    if (page >= 5) {
      limit.minPage = page - 4
      limit.maxPage = page
    }

    if (paginationNumbers.length >= 4 && paginationNumbers.length - page < 4) {
      limit.minPage = paginationNumbers.length - 4
      limit.maxPage = paginationNumbers.length
    }

    return limit;

  }, [page, paginationNumbers])

  const paginationTip = (
    <div className={s.guild__pagination__tip}>
      <input type='text' className={s.guild__pagination__tip__input} value={tipPage} onChange={(val) => setTipPage(+val.target.value)} />
      <button onClick={() => setPage(tipPage)} className={s.guild__pagination__tip__btn}>OK</button>
    </div>
  )

  const [reverseSizeSorting, setReverseSizeSorting] = useState(false)

  const sort = (type) =>{
    if(type === 'TRADE VOLUME'){
      const sortingGuilds = guilds.sort(function(a, b) {
        return a.trades - b.trades;
      })

      if(reverseSizeSorting){
        setChangeGuild([...sortingGuilds].reverse())
        setReverseSizeSorting(false)
      }else{
        setChangeGuild([...sortingGuilds])
        setReverseSizeSorting(true)
      }
    }
  }

  const guildsForRender = changedGuilds.length > 20
    ?  setId(changedGuilds).slice((page - 1) * 20, page * 20)
    :  setId(changedGuilds)

  const search = (arr, value) => {
    return setId(arr.filter(el => el.address.toLowerCase().includes(value.toLowerCase())))
  }

  const searchGuilds = (value) => {
    if(!value && guilds.length){
      setChangeGuild(guilds)
      return
    }
    setPage(1)
    value && setChangeGuild(search(guilds, value))
  }

  return (
    <>
      <Lookup type={'leaderboard'} lookupPlaceholder={'Search'} hasFactoryCodeSearch searchFetcher={searchGuilds} />

      <div className={`${s.guild__table__wrapper} ${s.guild__table__leaderboard}`}>
        <table id='guildsTable' className={s.guild__table}>
          <TableHeaders headers={headers} sort={sort} />
          <tbody>
          {guildsForRender.map((el, index) => (<TradingLeaderboardTableRow  index={index}  {...el}/>))}
          </tbody>
        </table>
      </div>


      <div className={s.guild__pagination}>
        <div className={s.guild__pagination__block}>
          <p className={`${s.guild__pagination__number}`}
             onClick={page <= 1 ? null : () => setPage(prev => prev - 1)}>{'<'}</p>
          {
            paginationNumbers.length > 3 && page >= paginationNumbers.length - 3 && <>
              <p className={s.guild__pagination__number} onClick={() => setPage(1)}>1</p>
              {paginationNumbers.length >= 6 &&
                <PaginationChooseTooltip title={paginationTip}>
                  <p className={s.guild__pagination__number}>...</p>
                </PaginationChooseTooltip>
              }
            </>
          }
          {
            paginationNumbers.slice(paginationPage.minPage, paginationPage.maxPage <= 4 ? 4 : paginationPage.maxPage).map(el => (
              <p style={{color: page === el + 1 ? '#FFC328' : '#FFF'}}
                 className={`${s.guild__pagination__number} web63`}
                 onClick={() => setPage(el + 1)}>{el + 1}</p>))
          }
          {
            page < paginationNumbers.length - 3 && <>
              {paginationNumbers.length >= 6 &&
                <PaginationChooseTooltip title={paginationTip}>
                  <p className={s.guild__pagination__number}>...</p>
                </PaginationChooseTooltip>
              }
              <p className={s.guild__pagination__number}
                 onClick={() => setPage(paginationNumbers.length)}>{paginationNumbers.length}</p>
            </>
          }
          <p className={`${s.guild__pagination__number}`}
             onClick={page >= paginationNumbers.length ? null : () => setPage(prev => prev + 1)}>{'>'}</p>
        </div>


      </div>
      <p className={s.reward}> <span>*</span> Rewards will be vested and unlocked over a 3 month period at end of contest</p>
    </>
  )
}

const TableHeaders = ({headers, sort}) => {

  return (
    <thead>
    <tr className={`${s.guild__table__header}`}>
      {
        headers.map((el, index) => (
          <td className={index === 0 ? `${s.guild__table__headData} ${s.guild__table__headData__trading} ${s.guild__table__headData__text}` : s.guild__table__headData__text}>
            <span onClick={() => sort(el)}>{el}</span>
          </td>
          )
        )
      }
    </tr>
    </thead>
  )
}

const PaginationChooseTooltip = tooltipFactory(true, {
  backgroundColor: '#1D1D1D',
  maxWidth: 'none',
  left: '10px'
}, {
  top: '0',
  color: '#1D1D1D',
  width: '23.23px',
  height: '15.56px',
})


export default TradingLeaderboardTable;