import React, {useContext} from 'react';
import {StandardLayout} from "../../components/layouts/StandardLayout";
import s from "./styles.module.scss";
import BotNftPageMain from "./components/BotNftPageMain/BotNftPageMain";
import BotNftPageBuyBlock from "./components/BotNftPageBuyBlock/BotNftPageBuyBlock";
import BotNftList from "./components/BotNftList/BotNftList";
import Lookup from "../../components/Lookup/Lookup";
import {getBotsSearch, getRebelBotsLookup} from "../../api/bots";
import CustomButton from "../../components/CustomButton/CustomButton";
import {LandSaleContext} from "../../Context/LandSaleWallet";
import {useDimensions} from "../../hooks/useDimensions";
import {useLocation} from "react-router-dom";

const BotsNftPage = () => {

    const {width} = useDimensions();
    const {account, isCorrectNetwork, connect} = useContext(LandSaleContext)
    const location = useLocation()

    return (
        <StandardLayout isHeaderForced={true}>
            <main className={s.background}>
                <div className={s.container}>
                    <BotNftPageMain/>
                    <BotNftPageBuyBlock/>
                    {/*<BotNftList account={'0x7228CcBcd50474fb5ea2c4472bBCA1b75634694f'}/>*/}
                    {
                        isCorrectNetwork && account ? <BotNftList account={account}/> : <>
                            <div
                                className={`${s.timer} ${s.connectWalet}`}>
                                <>
                                    <h3>
                                        Check your bots
                                    </h3>
                                    <p>Connect your wallet to proceed.</p>
                                    <CustomButton
                                        className={s.formControl}
                                        form="application"
                                        label="Connect wallet"
                                        callback={() => connect(false, location.pathname)}
                                        connect={true}
                                        // disabled={true}
                                        texture={
                                            width < 576
                                                ? "btn-apply-guild-mobile.svg"
                                                : width < 1024
                                                    ? "btn-apply-guild-tablet.svg"
                                                    : "btn-apply-guild.svg"
                                        }
                                    />
                                </>
                            </div>
                        </>
                    }
                    <Lookup type='Rebel bot claim' padding={'150px'} hasFactoryCodeSearch searchFetcher={getBotsSearch}
                            lookupPlaceholder='Search by factory name or SN'
                            assetFetcher={getRebelBotsLookup}/>
                </div>
            </main>
        </StandardLayout>
    );
}

export default BotsNftPage;