import React from "react";

import Header from "components/Header";
import Footer from "components/Footer";

const Terms = () => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <main className="faq">
          <div className="terms-container">
            <div className="privacy-title">
              <h1>Token Sale Rules</h1>
            </div>
            <div className="faq-list">
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="terms-item__title">General</div>
                </div>
                <div className="terms__item js-faq-item-answer">
                  <p>
                    These Token Sale Rules (these "<b>Rules</b>") are provided
                    by Xoilium Limited, a British Virgin Islands business
                    company incorporated in the British Virgin Islands with
                    limited liability, with respect to the purchase of RBLS
                    tokens (the "<b>Tokens</b>") through our website at:
                    <a
                      href="https://www.xoilium.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.xoilium.com
                    </a>{" "}
                    (the "<b>Website</b>").
                  </p>
                  <p>
                    Nothing herein shall be deemed to be an offer to sell, a
                    recommendation or solicitation to purchase Tokens and any
                    and all documents or other information and materials
                    provided or made available herein (collectively, the "
                    <b>Documentation</b>") are provided for informational and
                    educational purposes only and should be independently
                    verified by you prior to making any decision to purchase
                    Tokens. No part of the Website or the Documentation should
                    be considered to be business, legal, financial, investment,
                    or tax advice, or advice of a broker regarding any matters
                    to which all or any part of such information relates. You
                    should consult your own legal, financial, tax, or other
                    professional advisors regarding any such information before
                    making any decision.
                  </p>
                  <h3 className="terms-item__title">
                    Exclusion of Representations and Warranties
                  </h3>
                  <h5>
                    WE MAKE NO WARRANTY WHATSOEVER WITH RESPECT TO THE TOKENS,
                    THE WEBSITE OR THE DOCUMENTATION INCLUDING ANY (i) WARRANTY
                    OF MERCHANTABILITY; (ii) WARRANTY OF FITNESS FOR A
                    PARTICULAR PURPOSE; (c) WARRANTY OF TITLE; OR (iii) WARRANTY
                    AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A
                    THIRD PARTY; WHETHER ARISING BY LAW, COURSE OF DEALING,
                    COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE. EXCEPT
                    AS EXPRESSLY SET FORTH HEREIN, YOU ACKNOWLEDGE THAT YOU HAVE
                    NOT RELIED UPON ANY REPRESENTATION OR WARRANTY MADE BY US,
                    OR ANY OTHER PERSON ON OUR BEHALF.
                  </h5>
                  <h3 className="terms-item__title">
                    Purchaser Representations
                  </h3>
                  <p>
                    You expressly warrant, represent and covenant that you are a
                    non-US person (as defined in Rule 902 of Regulation S under
                    the U.S. Securities Act of 1933). The sale of any Tokens to
                    you is made in express reliance upon your status as non-U.S.
                    person as well as the below representations and covenants.
                  </p>
                  <p>
                    You are not currently the subject or the target of any U.S.
                    sanctions administered by the Office of Foreign Assets
                    Control of the U.S. Department of the Treasury or the U.S.
                    Department of State, the United Nations Security Council,
                    the European Union, Her Majesty’s Treasury of the United
                    Kingdom, or other relevant sanctions authority. You are not
                    domiciled, located, incorporated or otherwise established
                    in, or a citizen or resident of: (i) the United States of
                    America, Afghanistan, Albania, Belarus, Bosnia &amp;
                    Herzegovina, Bolivia, Burundi, Cambodia, Laos, Tanzania,
                    Nepal, Algeria, Morocco, Bangladesh, Cambodia, Canada,
                    China, Colombia, Congo, Cuba, Ecuador, Egypt, Indonesia,
                    Iraq, Iran, Lebanon, Liberia, Libya, Malaysia, Jordan,
                    Nigeria, New Zealand, North Korea, Sudan, Syria, Mozambique,
                    Mali, Kyrgyzstan, Uzbekistan, Turkmenistan, Qatar, Russia,
                    Taiwan, Turkey, Vietnam, Thailand, Serbia, Sri Lanka,
                    Tunisia, Uganda, Ukraine, Zimbabwe, Pakistan or the UAE;
                    (ii) any state, country or other jurisdiction that is
                    sanctioned or embargoed by the United States of America;
                    (iii) a jurisdiction where it would be illegal according to
                    applicable law for you (by reason of your nationality,
                    domicile, citizenship, residence or otherwise) to acquire
                    Tokens; or (iv) where the sale of Tokens is prohibited or
                    contrary to local law or regulation.
                  </p>
                  <p>
                    You have full legal capacity, power and authority to execute
                    and deliver the purchase of the Tokens in accordance with
                    these Rules and any Documentation, and to perform Your
                    obligations.
                  </p>
                  <p>
                    You have been advised that the Tokens and/or their sale and
                    purchase have not been registered under any country’s
                    securities laws and, therefore, might not be resold in
                    certain territories and/or subject to certain limitations.
                    You are purchasing the Tokens for Your own account for
                    investment, not as a nominee or agent, and not with a view
                    to, or for resale in connection with, the distribution
                    thereof, and the You have no present intention of selling,
                    granting any participation in, or otherwise distributing the
                    same.
                  </p>
                  <p>
                    You have such knowledge and experience in financial and
                    business matters that You are capable of evaluating the
                    merits and risks of your purchasing of the Tokens, are able
                    to incur a complete loss of the funds used to purchase the
                    Tokens without impairing Your financial condition and are
                    able to bear the economic risk of such purchase for an
                    indefinite period of time.
                  </p>
                  <p>
                    You understand that the Tokens involve risks, all of which
                    the You fully and completely assumes, including, but not
                    limited to, the risk that the technology associated with the
                    Tokens, the Website and/or all other products and services
                    which we might offer from time to time, will not function as
                    intended, be available at all times or will be successful,
                    completed and/or delivered as expected.
                  </p>
                  <p>
                    Ownership of Tokens carries no rights, express or implied,
                    other than the right to use the Tokens as set forth in the
                    Documentation (subject to any disclaimers herein). In
                    particular, You understand and accept that the Tokens do not
                    represent or confer any ownership right or stake, share,
                    equity or security or equivalent rights, or any right to
                    receive future revenue, dividends, shares, intellectual
                    property rights or any other form of participation or
                    governance in or relating to Xoilium Limited or to any of
                    its affiliate.
                  </p>
                  <p>
                    You understand and expressly accept that the Tokens will be
                    created and delivered to You at your sole risk on an “AS IS”
                    basis. You understand and expressly accept that You have not
                    relied on any representations or warranties made by us,
                    including, but not limited to, conversations of any kind,
                    whether through oral or electronic communication, or any
                    white paper.
                  </p>
                  <p>
                    WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU
                    ASSUMES ALL RISK AND LIABILITY FOR THE RESULTS OBTAINED BY
                    THE USE OF ANY TOKENS, THE WEBSITE OR ANY OTHER PRODUCTS OR
                    SERVICES WE MAY OFFER FROM TIME TO TIME AND REGARDLESS OF
                    ANY ORAL OR WRITTEN STATEMENTS MADE BY US, BY WAY OF
                    TECHNICAL ADVICE OR OTHERWISE, RELATED TO THE USE OF THE
                    ABOVE.
                  </p>
                  <p>
                    You understand that You bear sole responsibility for any
                    taxes as a result of the matters and transactions the
                    subject of the sale and purchase of the Tokens, and any
                    future acquisition, ownership, use, sale or other
                    disposition of Tokens held by You.
                  </p>
                  <p>
                    None of the funds used by You to purchase Tokens are derived
                    from or are the proceeds of any unlawful activity, with the
                    result that the purchase of the Tokens is prohibited by law
                    or the purchase of Tokens is in violation of law or may
                    cause any of the Tokens or funds received by the Company to
                    be subject to forfeiture or seizure.
                  </p>
                  <p>
                    You acknowledge and accept that we reserve the right, at our
                    own and complete discretion acting in good faith, to modify
                    or to temporarily or permanently suspend or eliminate any of
                    our products and/or services.
                  </p>
                  <h3 className="terms-item__title">KYC Requirements</h3>
                  <p>
                    You acknowledge and agree that you may be required to
                    provide user information and to pass a Know Your Customer
                    (“KYC”) test, whereby you will be screened against Office of
                    Foreign Assets Control lists and other watch lists. The
                    benefits provided by the Tokens may be denied for any
                    purchaser who fails to meet the KYC suitability screening
                    requirements. In case decided or required by us, we will
                    perform the relevant KYC checks from a suitable independent
                    KYC provider and keep a copy for our records and You agree
                    to provide any and all relevant information and assistance
                    in this process in a timely manner. To the extent that we
                    determine in our sole and absolute discretion that it is
                    reasonably necessary to obtain certain information about You
                    in order to comply with any applicable laws in connection
                    with the Token sale, You shall provide the Company with such
                    information promptly upon request, and You acknowledge and
                    agree that we may refuse to proceed with the Token sale or
                    withhold delivery of your Tokens until such requested
                    information has been provided to our reasonable satisfaction
                    in our sole discretion.
                  </p>
                  <h3 className="terms-item__title">
                    Indemnity and Limitation of Liability
                  </h3>
                  <p>
                    You do hereby to the fullest extent permitted by applicable
                    laws and regulations agree to indemnify, defend and hold us,
                    our affiliates and our respective employees, service
                    providers, directors, shareholders, officers, consultants,
                    representatives, agents or contractors (collectively, the "
                    <b>Indemnified Parties</b>") harmless from and against any
                    and all loss, penalty, claim, damage, liability or expense
                    whatsoever (including reasonable attorneys’ fees and
                    disbursements) due to or arising out of or based upon (i)
                    any inaccurate representation or warranty made by you, or
                    breach or failure by you to comply with any covenant or
                    agreement made by you or in any other document furnished by
                    you to any of the foregoing persons in connection with the
                    Tokens, or (ii) any action instituted by or on your behalf
                    against any of the foregoing persons that is finally
                    resolved by judgment against you or in favor of any of the
                    foregoing persons.
                  </p>
                  <p>
                    To the maximum extent permitted by applicable laws and
                    regulations, in no event shall any of the Indemnified
                    Parties be liable or responsible for any direct, indirect,
                    special, punitive, exemplary, incidental, or consequential
                    damages or losses of any kind, nor shall they be liable for
                    the loss of goodwill, loss of profits (including expected),
                    loss of data, diminution of value, and business interruption
                    arising out of or in connection with the purchase, sell,
                    holding or any other use of the Tokens, the Website and/or
                    the Documentation, whether based upon breach of warranty or
                    contract, negligence, strict liability, tort, or any other
                    legal theory, regardless of whether we have been advised of
                    the possibility of such damages or losses.
                  </p>
                  <h3 className="terms-item__title">Miscellaneous</h3>
                  <p>
                    We may amend these Rules at our discretion by posting an
                    updated version on the Website and you are hereby requested
                    to review these Terms periodically to make sure you are
                    aware of the most updated version. These Rules constitute
                    the sole agreement of the parties and supersedes all oral
                    negotiations and prior writings with respect to the subject
                    matter hereof. These Rules shall be governed by the laws of
                    the BVI, without giving effect to the principles of conflict
                    of laws. Any dispute arising under or in relation to this
                    Agreement shall be resolved exclusively in the courts of the
                    BVI.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Terms;
