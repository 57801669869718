import React from 'react';
import s from './styles.module.scss'

const HomeGlory = () => {
  return (
    <section className={s.homeGlory}>

      <div className={s.textBlock}>
        <h3><span className='yellow'>rise to</span> glory</h3>

        <div className={s.textBlock__content}>
          <p>
            Experience the ultimate thrill of tactical strategy card battle gaming then max
            out your achievements by unlocking the power of NFTs and crypto rewards.
          </p>
          <p>
            Build and Command Your Army of Fighting Bots and Embark on
            Epic Adventures to Claim Your Victory and Xoil Riches.
          </p>
        </div>
      </div>

    </section>
  );
}

export default HomeGlory;