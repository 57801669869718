import {getPost} from "../api/cdnApi";
import {useEffect, useState} from "react";


export const useWiki = (category, id) => {

  const [categories, setCategories] = useState([])
  const [categoriesItems, setCategoriesItems] = useState([])
  const [post, setPost] = useState({})
  const [wikiTitle, setWikiTitle] = useState('')

  const getTitle = (item) => {
    item.desc = ''
    const startIndex = item.content.indexOf('//*')
    const endIndex = item.content.indexOf('*//')
    if(endIndex > 0){
      const contentArray = [...item.content.split('')]
      item.desc = contentArray.splice(startIndex, endIndex + 4).join('').substring(4, endIndex - 3)
      item.desc = deleteTags(item.desc)
      item.content = contentArray.join('')
    }
    return item
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getData = async () => {
    let data  = await getPost();
    data = data.filter(item => item.status === 'Public')
    data.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setCategories( data.filter(el => el.category.includes('category')))
    if(category){
      setCategoriesItems( data.filter(el => el.category.includes(category)).map(getTitle) )
    }
    if(id){
      setPost(getTitle(getTitle(data.find(post => +post['post-id'] === +id)))   )
    }

    setWikiTitle(data.filter(el =>  el.category.includes('mainTitle'))[0]['content'] )
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect( getData, [])

  const deleteTags = (str) => {
    str = str?.replace(/(<([^>]+)>)/gi, "")?.replace(/&nbsp;/g, '')
    return str;
  }





  return {
    categories,
    post,
    items: categoriesItems,
    deleteTags,
    title: deleteTags(wikiTitle)
  }

}