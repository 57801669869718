export const validateEmail = (email) =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(email);

export const validateUrl = (url, isOptional) =>
  isOptional && url === ""
    ? true
    : //eslint-disable-next-line
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi.test(
        url
      );

export const validateDisord = (url, isOptional) =>
  isOptional && url === ""
    ? true
    : url?.includes("#")
    ? /\w+#\d{4}/i.test(url)
    : /(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/g.test(
        url
      );

export const validateTelegram = (url, isOptional) =>
  isOptional && url === ""
    ? true
    : url?.trim()?.[0] === "@"
    ? /.*\B@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/.test(url)
    : /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/.test(url);

export const validateLinkedInUrl = (url, isOptional) =>
  isOptional && url === ""
    ? true
    : /^(http(s?):\/\/)?((www)\.)?linkedin\.com\/[\w]+\/[a-zA-Z0-9-]+\/?$/.test(
        url
      );
