import React from 'react';
import s from './styles.module.scss'
import OutlineButton from "../../../../components/ui/OutlineButton/OutlineButton";
import {config} from "../../../../utils/config";

const FightingBotsCompetition = () => {
  return (
    <div className={s.fightingBotsCompetition}>
      <div className={s.content}>
        <h3>dominate <span>the competition</span></h3>
        <p className='fb-text'>
          Assemble your army of Fighting Bots and hone your strategy to take on any challenge that
          comes your way! Outwit and outfight your opponents to climb the ranks, boost your MMR,
          and win valuable Season rewards.
        </p>
        <div className={s.buyBlock}>
          <a target={'_blank'} rel="noreferrer" href={config.MARKETPLACE_LINK + '/marketplace/bots' }>Marketplace</a>
          <a target={'_blank'} rel="noreferrer" href="https://polygonscan.com/token/0x17892c8c3eb60c144872c18f013626471c3658bf">Contract</a>
          <OutlineButton variant={'selfOpen'}  href="battleSet" text="Buy Battle Set" />
        </div>
      </div>
    </div>
  );
}

export default FightingBotsCompetition;