import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./index.scss";
import App from "./App";
import WalletContext from "Context/Wallet";
import PopupContext from "Context/Popup";
import LandSaleWallet from "Context/LandSaleWallet";

console.log("Version 1.0.0");

// bypass auto scrolling to fix bug on iphone
if (window.history && "scrollRestoration" in window.history) {
  window.history.scrollRestoration = "manual";
}

ReactDOM.render(
    <PopupContext>
      <LandSaleWallet>
        <WalletContext>
          <BrowserRouter>
            <App/>
          </BrowserRouter>
        </WalletContext>
      </LandSaleWallet>
    </PopupContext>,
    document.getElementById("root")
);
