import axios from "axios";

export const getReleaseHeadCards = async () =>{
  return await axios.get('https://cdn.rebelbots.com/cards/cardDB.json?refreshagain')
}

export const getCard = async (id) => {
  return await axios.get(`https://cdn.rebelbots.com/cards/${id}.json`)
}

export const getKeywords = async () => {
  return await axios.get(`https://cdn.rebelbots.com/cards/keywords.json?refresh`)
}

export const getPassiveSkills = async () => {
  return await axios.get(`https://cdn.rebelbots.com/cards/passiveSkills.json?refresh`)
}
export const saveBotBuilder = async (img) => {
  let arr = img.split(','),
    // mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  const botImage = new Blob([u8arr], {
    type: 'image/png'
  });

  const fd = new FormData()
  fd.append("bot", new File([botImage], 'test.png', {type: "image/png",}))
  return await axios.post(`${window.config.SERVER_API}/botbuilder/save`, fd, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}