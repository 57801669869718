import React from 'react';
import s from './styles.module.scss'
import {StandardLayout} from "../../components/layouts/StandardLayout";
import supportIcon from '../../Image/contactSupport.svg'
import emailIcon from '../../Image/contactMail.svg'
import mapIcon from '../../Image/contactMap.svg'

import tgIcon from '../../Image/telegram_icon.svg'
import twiiterIcon from '../../Image/twitter_icon.svg'
import discordIcon from '../../Image/discord_icon.svg'
import mediumIcon from '../../Image/mediumIcon.svg'

const Contact = () => {
  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
          <h2 className='yellow'>we’re here for you</h2>

          <div className={s.contactItems}>
            <a href='https://rebelbots.com/support' rel='noreferrer'>
              <img src={supportIcon} alt="support" data-type='support'/>
              <span>support</span>
            </a>
            <a href='mailto: contact@xoilium.com' target='_blank' rel='noreferrer'>
              <img src={emailIcon} alt="email" data-type='mail'/>
              <span>email us</span>
            </a>
            <a href='/wiki'>
              <img src={mapIcon} alt="map"/>
              <span>game guides</span>
            </a>
          </div>

          <div className={s.social}>
            <h4 className='yellow'>let’s keep in touch</h4>
            <ul>
              <li>
                <a href="https://twitter.com/REBEL_BOTS" target='_blank' rel='noreferrer'>
                  <img src={twiiterIcon} alt="twitter"/>
                </a>
              </li>
              <li>
                <a href="https://t.me/RebelBotsXoilWars" target='_blank' rel='noreferrer'>
                  <img src={tgIcon} alt="telegram"/>
                </a>
              </li>
              <li>
                <a href="https://discord.gg/rebel-bots" target='_blank' rel='noreferrer'>
                  <img src={discordIcon} alt="discord"/>
                </a>
              </li>
              <li>
                <a href="https://medium.com/@Rebel_Bots" target='_blank' rel='noreferrer'>
                  <img src={mediumIcon} alt="medium"/>
                </a>
              </li>
            </ul>
          </div>
      </main>
    </StandardLayout>
  );
}

export default Contact;