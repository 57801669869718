import React from 'react';
import {StandardLayout} from "../../components/layouts/StandardLayout";
import s from "../Wiki/styles.module.scss";
import {useParams} from "react-router-dom";
import {useWiki} from "../../hooks/useWiki";

const WikiCategories = () => {
  const {category} = useParams();
  const {items, categories, deleteTags} = useWiki(category)

  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
        <h3>{category} </h3>
        <p className={s.desc}>
          {
            deleteTags( categories.find(el => el.name === category)?.content)
          }
        </p>

        <h4>All items</h4>
        {/*<div className={s.alphabetWrapper}>*/}
        {/*  <span>#</span>*/}
        {/*  <span>a</span>*/}
        {/*  <span>b</span>*/}
        {/*  <span>c</span>*/}
        {/*  <span>d</span>*/}
        {/*  <span>e</span>*/}
        {/*</div>*/}
        <div className={s.itemsWrapper}>
          {
            items.map((el, index) => (
              <a href={'/wikiItem/'+ el['post-id']} className={s.item} title={el} key={index}>
                <div className={s.item__header}>{el.name}</div>
                <div className={s.item__desk}>{el.desc}</div>
              </a>
            ))
          }

        </div>
      </main>
    </StandardLayout>
  );
}

export default WikiCategories;