export const jobsData = [
  {
    team: null,
    link: "https://xoilium.bamboohr.com/jobs/view.php?id=31",
    id: "67f7d922",
    title: "Business Data Analyst",
    location: "Remote",
    tag: "Economy",
    aboutCompany: `<strong>Xoilium is developing a new Play-To-Earn game called Rebel Bots- Xoil Wars</strong>, it is a cross platform card battle game, players will be able to play, progress,and earn in  a single player or multiplayer sci-fi themed game taking place in outer space.  

<strong>Our Vision</strong> is to create a leading game franchise within the blockchain games space by building the Rebel Bots brand with several gaming titles.  Players will be able to use the characters they own in all games within the Rebel Bots Universe.`,
    jobDescription:
      "We're looking for a sharp and passionate Business Data Analyst to join our top-notch team. Using your technical and analytical skills, you'll be responsible for telling the story behind the numbers and to understand how people interact with our game and other related products.",
    responsibilities: [
      "Be on the cutting edge of analysis, leveraging our data sources to propose new ideas that you’ll then see implemented.",
      "Analyze large, complex data sets representing users’ behavior, see beyond numbers and help to inform, influence, support and execute our business decisions.",
      "Work closely with Product, Monetization and management, be proactive and provide actionable recommendations and conclusions from game data to shape features, game economies and future product roadmaps and business strategies.",
      "Plan and analyze effective A/B tests in order to improve business KPIs.",
      "Balance and monitor flows from all the features in the game.",
      "Measure and improve performance of promotions and live-ops for each segment.",
      "Build reports and dashboards to monitor product performance, key business metrics and player’s experience.",
      "Describe data tracking requirements and advise on the best set of tools to analyse the data that flows from the game.",
    ],
    requirements: [
      "3+ years of analytical work experience, preferably done in an online gaming company.",
      "BSc/BA in a highly quantitative field such as mathematics / Industrial Engineering / Economics / Statistics or equivalent academic knowledge.",
      "Outstanding analytical & technical skills.",
      "Strong SQL with experience in querying large, complex data sets.",
      "Self-motivated and independent.",
      "Ability to turn complex concepts into actionable recommendations.",
    ],
    advantage: [
       "Previous experience as a product analyst in the gaming industry.",
       "Python, R or any other scripting language.",
       "Previous experience in business analysis management position.",
       "Blockchain or gaming enthusiast",
    ],

    offers: [
      "Become a key stakeholder in an established company within the emerging blockchain based gaming space",
      "We value flexibility and diversity, we are location- independent",
      "We value and reward talent and creativity",
      "We’re big on teamwork and collaboration",
      "Like our games, we take FUN seriously",
      "We are growth-minded and we encourage learning and knowledge- sharing",
      "You will enjoy autonomy and creative freedom",
      "We offer competitive salary and benefits package",
    ],
  },
  {
    team: null,
    link: "https://xoilium.bamboohr.com/jobs/view.php?id=25",
    id: "67f7dbd4",
    title: "Game Economy Manager",
    location: "Remote",
    tag: "Economy",
    aboutCompany: `<strong>Xoilium is developing a new Play-To-Earn game called Rebel Bots- Xoil Wars</strong>, it is a cross platform card battle game, players will be able to play, progress,and earn in  a single player or multiplayer sci-fi themed game taking place in outer space.  

<strong>Our Vision</strong> is to create a leading game franchise within the blockchain games space by building the Rebel Bots brand with several gaming titles.  Players will be able to use the characters they own in all games within the Rebel Bots Universe.  Among our goals is to create a truly  social gameplay which enables players to join forces with other players and experience.`,
    jobDescription:
      "Rebel Bots is currently looking for a highly analytic Game Economy Manager.  The Game Economy Manager will be monitoring and analyzing the Game’s players behaviors and looking for revenue opportunities within the game’s economy.  The Game Economy Manager will propose changes / new features within the game’s economy with the goal of improving the overall game experience and player retention",
    responsibilities: [
      "Design and improve UX from an economy point of view while ensuring alignment of the features to the game’s overall direction",
      "Formulate game economy models for new features through data analysis and insights",
      "Make data- driven decisions in the development of new features",
      "Implement new features depending on what will capture the players’ attention and increase desirable behavior",
      "Collaborate with other teams in implementing new features",
      "Effectively communicate data analytics to all stakeholders/across different teams by breaking down data into smaller, more digestible format for each type of audience",
      "In charge of the pricing of the currency in the game",
      "Forecast results on the change in the game’s content based on previous data analysis, ensuring overall health of the game’s economy",
      "Track and understand KPIs to balance great customer/ player experience and revenue for in-game currency and virtual goods",
    ],
    skills: [
      "A degree in Statistics, Mathematics, Economics or related program in the quantitative research field",
      "Experience in the finance sector an advantage",
      "Experience in Data- Science and Analytics",
      "At least 2 years of experience in gaming (Social Games of Play-to-earn)",
      "Extensive knowledge about Cryptocurrency, Blockchain, DeFi",
      "Excellent collaborative skills",
      "Knowledge in SQL an advantage",
      "Able to work within the CET timezone",
      "Native or close to native English",
    ],
    offers: [
      "Become a key stakeholder in an established company within the emerging blockchain based gaming space.",
      "We value flexibility and diversity, we are location- independent.",
      "We value talent and creativity",
      "We’re big on teamwork and collaboration",
      "Like our games, we take FUN seriously",
      "We are growth- minded and we encourage learning and knowledge- sharing",
      "You will enjoy autonomy and creative freedom",
      "We offer competitive salary and benefits package",
    ],
  },
  {
    team: null,
    link: "https://xoilium.bamboohr.com/jobs/view.php?id=28",
    id: "22f0e29c-8e1c-433a-9cd5-b9a6ec80bc8a",
    title: ".Net Developer",
    location: "Remote",
    tag: "Development",
    aboutCompany: `<strong>Xoilium is developing a new Play-To-Earn game called Rebel Bots- Xoil Wars</strong>, it is a cross platform card battle game, players will be able to play, progress,and earn in  a single player or multiplayer sci-fi themed game taking place in outer space.  

<strong>Our Vision</strong> is to create a leading game franchise within the blockchain games space by building the Rebel Bots brand with several gaming titles.  Players will be able to use the characters they own in all games within the Rebel Bots Universe.  Among our goals is to create a truly  social gameplay which enables players to join forces with other players and experience.`,
    jobDescription:
      "The .Net Developer will be responsible for building  software using languages and technologies of the .NET framework. You will create applications from scratch, configure existing systems and provide user support.",
    responsibilities: [
      "Undertake development tasks according to the specifications of the products",
      "Complete .Net programming and unit testing by required framework independently",
      "Follow the development specifications to ensure code quality and continuous code optimization",
    ],
    skills: [
      "Must have at least 5 years of experience as a C # /. Net developer;",
      "With extensive experience in Web API Core, MS SQL, Entity Framework Core",
      "Works with minimal supervision, and able to do QC on own  output to ensure it is within standards",
      "Nice to haves: Azure functions and DevOps, blob storage, Unittest frameworks",
      "Experience with directly liaising with clients is a big advantage",
      "Native or close to native English skills",
      "Able to work within the CET timezone",
    ],
    offers: [
      "Become a key stakeholder in an established company within the emerging blockchain based gaming space.",
      "We value flexibility and diversity, we are location- independent.",
      "We value and reward talent and creativity",
      "We’re big on teamwork and collaboration",
      "Like our games, we take FUN seriously",
      "We are growth- minded and we encourage learning and knowledge- sharing",
      "You will enjoy autonomy and creative freedom",
      "We offer competitive salary and benefits package",
    ],
  },
];
