import React from "react";
import { StandardLayout } from "components/layouts/StandardLayout";
import s from "./styles.module.scss";
import { TeamMember } from "components/TeamMemberCard";
import { teamData } from "db/team";

const Team = () => {
  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
        <h1>Our team</h1>
        <div className={s.members}>
          {teamData.map((member, key) => (
            <TeamMember data={{ ...member }} key={key} />
          ))}
        </div>
      </main>
    </StandardLayout>
  );
};

export default Team;
