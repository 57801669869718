import React from 'react';
import s from './styles.module.scss'
import bannerImage from '../../../../Image/gamingGuilds.png'
import OutlineButton from "../../../../components/ui/OutlineButton/OutlineButton";

const GamingGuildsMain = () => {
  return (
    <div className={s.gamingGuildsMain}>
      <img className={s.bannerImage} src={bannerImage} alt="banner" />

      <h2><span className='yellow'>find</span> a guild</h2>
      <h3>Join Forces to Conquer Xoilium</h3>
      <div className={s.buttonContainer}>
        <OutlineButton href={'registerGuild'} variant={'selfOpen'} text={'Register Guild'} />

        <a href='#guildsTable'
           className={`button-type1 notAdaptive ${s.exploreButton}`}
        >
          <span className="label">Explore Guilds</span>
        </a>
      </div>
      <div className={s.textContainer}>
          <p>Kick off your play to earn gaming experience by joining a Xoilium guild:</p>
        <ol>
          <li>Battle alongside your guild mates in a Kingdom to compete for season rewards </li>
          <li>Gain tips and strategies to level up  </li>
          <li>Become part of a community with the same goal - have fun and earn!</li>
        </ol>
        <p>
          Find your new guild from the directory below.
          {/*Or, check the marketplace for open Battle Set rentals:*/}
        </p>
      </div>
      {/*<button className={s.rentButtonContainer}>*/}
      {/*  <OutlineButton text={'Rent Battle set'} />*/}
      {/*</button>*/}
    </div>
  );
}

export default GamingGuildsMain;