import React, {useState} from 'react';
import s from "../styles.module.scss";
import ShareIcon from "../../../Image/shareIcon.svg";
import SaveBotModal from "../../Modals/SaveBotModal/SaveBotModal";
import ShareBotModal from "../../Modals/ShareBotModal/ShareBotModal";
import SaveTeamModal from "../../Modals/SaveTeamModal/SaveTeamModal";

const BuilderButtons = ({cardsCount, isTeamBuilder = false}) => {
  const [openSaveModal, setOpenSaveModal] = useState(false)
  const [openShareModal, setOpenShareModal] = useState(false)

  return (
    <div className={s.buttonsBlock} id='botBuilderButton'>
      {/*MODALS*/}
      {
        isTeamBuilder
          ? <SaveTeamModal open={openSaveModal} handleClose={() => setOpenSaveModal(false)}  isTeamBuilder={isTeamBuilder} />
          : <SaveBotModal open={openSaveModal} handleClose={() => setOpenSaveModal(false)}  isTeamBuilder={isTeamBuilder} />
      }

      <ShareBotModal open={openShareModal} handleClose={() => setOpenShareModal(false)} isTeamBuilder={isTeamBuilder}/>

      <div className={s.button_wrapper} style={{left: isTeamBuilder ? '-38vw' : '-22vw'}}>
        <button className={`${s.button} ${cardsCount !== 5 ? s.button__disabled : ''}`}
                onClick={() => setOpenSaveModal(true)} >
          Save
        </button>
        <button
                className={`${s.button} ${s.button__share} ${cardsCount !== 5 ? s.button__disabled : ''}`}
                onClick={() => setOpenShareModal(true)}
        >
          <img src={ShareIcon} alt="share"/>
          Share
        </button>
      </div>
    </div>

  );
}

export default BuilderButtons;