import {useEffect, useState} from "react";

export function useOutsideClick(ref) {
    const [outside, setOutside] = useState(false)
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOutside(true)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    return {
        setOutside,
        outside
    }
}