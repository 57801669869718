import React from 'react';
import s from './styles.module.scss'

const FightingBotsFavoriteClass = () => {
  return (
    <div className={s.fightingBotsFavoriteClass }>
      <h3>
        which class is <span>your favorite?</span>
      </h3>
      <div className={s.unique}>
        Class is determined by the unique combination of Parts used
      </div>
    </div>
  );
}

export default FightingBotsFavoriteClass;