import React, { useContext, useEffect, useState } from "react";

import s from "./styles.module.scss";
import { ReactComponent as Opensea } from "Image/opensea.svg";
// import { ReactComponent as Twitter } from "Image/twitter.svg";
import { ReactComponent as VerticalDivider } from "Image/VerticalDivider.svg";
import walletButton from "Image/walletButton.svg";
// import metaMask from "Image/metamask.png";
import { useDimensions } from "hooks/useDimensions";
import { WalletContext } from "Context/Wallet";
import { PopupContext } from "Context/Popup";
import { LandSaleContext } from "Context/LandSaleWallet";
import {useLocation} from "react-router-dom";

export const ProfileInfoBlock = ({
                                   data: { id, img, code, rarity, owner, kingdomName },
                                 }) => {
  const [fetchProviderBlocked] = useState(false);
  const { width } = useDimensions();
  const [account, setAccount] = useState(undefined);
  const [isConnected, setIsConnected] = useState(undefined);
  const { setPopup } = useContext(PopupContext);
  const {account: accountLandSaleContext, isCorrectNetwork: isConnectedLandSaleContext, connect  } = useContext(LandSaleContext);
  const { fetchProvider, isConnected: isConnectedWalletContext, account: accountWalletContext, device } = useContext(WalletContext);
  const location = useLocation();

  const yourRebelBotText =
    "Is this your Rebel Bot? Connect your wallet to edit";

  const links = {
    opensea: `https://opensea.io/assets/ethereum/0xbbe23e96c48030dc5d4906e73c4876c254100d33/${id}`,
    twitter: `https://twitter.com/intent/tweet?hashtags=rebelbots&text=${encodeURIComponent(
      "Check out this bot profile on @REB3L_B0TS"
    )}&url=${encodeURIComponent(window.location)}`,
    rarity: `https://rarity.tools/rebelbots/view/${id}`,
  };

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    setIsConnected(() => isConnectedLandSaleContext ? isConnectedLandSaleContext : isConnectedWalletContext)
    setAccount(() => accountWalletContext ? accountWalletContext : accountLandSaleContext)
  }, [])

  useEffect(() => {
    setIsConnected(() => isConnectedLandSaleContext ? isConnectedLandSaleContext : isConnectedWalletContext)
    setAccount(() => accountWalletContext ? accountWalletContext : accountLandSaleContext)
  }, [accountWalletContext, accountLandSaleContext, isConnectedLandSaleContext, isConnectedWalletContext])

  useEffect(() => {
    try {
      if (id || id === 0) fetchProvider(false, {}, id);
    } catch (e) {
      console.error(e);
      setPopup({
        type: "error",
        title: "Error",
        text: "Error occured. Please, reload the page.",
        btnText: "OK",
        onClick: () => {
          window.location.reload();
        },
      });
    }
  }, [id]);

  // const onFetchMetamask = async () => {
  //   setFetchProviderBlocked(true);
  //   await fetchProvider(true, {}, id);
  //   setFetchProviderBlocked(false);
  // };

  return (
    id >= 0 && (
      <>
        <div className={s.user_block} >
          <div
            className={`${s.user_block_content} ${
              isConnected || account ? s.user_block_connected : ""
            }`}
          >
            <div className={s.user_photo}>
              <img src={img} alt="" />
              <div className={s.user_shadow} />
            </div>
            <div className={s.info_table}>
              <div className={s.row}>
                <div className={s.info_left}>Kingdom Lord name:</div>
                <div className={s.info_right}>{kingdomName || code}</div>
              </div>
              <div className={s.row}>
                <div className={s.info_left}>Factory code:</div>
                <div className={s.info_right}>{code}</div>
              </div>
              <div className={s.row}>
                <div className={s.info_left}>Owned by:</div>
                <div className={s.info_right}>
                  <a
                    target="_blank"
                    href={`https://opensea.io/${owner}`}
                    rel="noreferrer"
                  >
                    {owner?.length &&
                      `${owner.slice(0, 5)}...${owner.slice(-3)}`}
                  </a>
                </div>
              </div>
              <div className={s.row}>
                <div className={s.info_left}>Rarity:</div>
                <div className={s.info_right}>
                  {(rarity?.length || rarity >= 0) && `#${rarity}`}
                </div>
              </div>
              <div className={s.row}>
                <div className={s.icons_block}>
                  <div className={s.icon_wrapper}>
                    <a href={links.opensea} target="_blank" rel="noreferrer">
                      <Opensea />
                    </a>
                  </div>
                  {/*<div className={s.icon_wrapper}>*/}
                  {/*  <a href={links.twitter} target="_blank" rel="noreferrer">*/}
                  {/*    <Twitter />*/}
                  {/*  </a>*/}
                  {/*</div>*/}

                  <div className={s.rarity}>
                    <a href={links.rarity} target="_blank" rel="noreferrer">
                      rarity.tools
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {width > 1024 && (!account || !isConnected) && (
              <div className={`${s.user_metamask} user_metamask`}>
                <VerticalDivider />
                <div>
                  <p>{yourRebelBotText}</p>
                  <div className={s.line}></div>
                  <button
                    className={s.button}
                    onClick={() => connect(false, location.pathname)}
                    disabled={fetchProviderBlocked}
                  >
                    <span>
                      {/*<img src={metaMask} alt="metaMask" />*/}
                      Connect Wallet
                    </span>
                  </button>
                  <div className={s.line}></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          {width <= 1024 && (!account || !isConnected) && (
            <div className={`${s.user_metamask} user_metamask`}>
              <p>{yourRebelBotText}</p>
              <div className={s.button_and_test}>
                <div className={s.line}></div>
                <div
                  className={`section__connecting-metamask a ${s.metamask_mobile}`}
                >
                  {device ? (
                    <a
                      style={{ backgroundImage: `url(${walletButton})` }}
                      target={"_blank"}
                      href={device}
                      rel="noreferrer"
                    >
                      <span>
                        Connect Wallet
                        {/*<img src={metaMask} alt="" />*/}
                      </span>
                    </a>
                  ) : (
                    <button
                      className={s.button}
                      onClick={() => connect(false, location.pathname)}
                      disabled={fetchProviderBlocked}
                    >
                      <span>
                        Connect Wallet
                        {/*<img src={metaMask} alt="metaMask" />*/}
                      </span>
                    </button>
                  )}
                </div>
                <div className={s.line}></div>
              </div>
            </div>
          )}
        </div>
      </>
    )
  );
};
