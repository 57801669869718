import React from 'react';
import BuilderBot from "./BuilderBot";
import s from "../styles.module.scss";
import BuilderStats from "./BuilderStats";
import CardsSelectPanel from "./CardsSelectPanel";
import BuilderButtons from "./BuilderButtons";

const BuilderWrapper = ({selectedCards,
                          setFilters,
                          isSelection,
                          setIsSelection,
                          removeCard,
                          bot}) => {

  let cardsCount = 0

  for(let key in selectedCards){
    if(selectedCards[key].card){
      cardsCount += 1
    }
  }

  return (
    <div className={s.builderWrapper} id='builder'>
        <BuilderBot selectedCards={selectedCards} />

        <BuilderStats selectedCards={selectedCards}
                      cardsCount={cardsCount}

        />

        <CardsSelectPanel setFilters={setFilters}
                          isSelection={isSelection}
                          setIsSelection={setIsSelection}
                          selectedCards={selectedCards}
                          removeCard={removeCard}
                          bot={bot}
        />

        <BuilderButtons cardsCount={cardsCount}/>
    </div>
  );
}

export default BuilderWrapper;