import React from 'react';
import s from './styles.module.scss'
import OutlineButton from "../../../../components/ui/OutlineButton/OutlineButton";
import Lines from '../../../../Image/botNftLines.svg'

const BotNftPageBuyBlock = () => {
    return (
        <div className={s.botNftPageBuyBlock}>
            <img className={s.lines} src={Lines} alt="lines"/>
            <div className={s.entitlesList}>
                <h4>YOUR REBEL BOT UNLOCKS UNIQUE BENEFITS:</h4>
                <ul>
                    <li>Receive a share of your Kingdom's initial Land sale revenue </li>
                    <li>Collect Xoil from your Kingdom players' earnings </li>
                    <li>Vote and tie-break each new Seasons' Kingdom Battle Card </li>
                    <li>Encode Your Bot's Name in the Blockchain</li>
                    <li>Craft your Kingdom's Title and Lore  </li>
                    <li>Gain access to exclusive holder rewards like whitelists, airdrops, and giveaways </li>
                    <li>Claim the prestige of ruling a Kingdom in the epic Xoil Wars </li>
                </ul>
            </div>
            <div className={s.buyBlock__footer}>
                <div className={s.buyTitle}>Buy your Rebel Bot and cement <br className={s.lineBreaker}/> your Xoilium Legacy</div>
                <div className={s.buyBlock}>
                    <a target="_blank" rel="noreferrer"
                       href="https://etherscan.io/address/0xbbe23e96c48030dc5d4906e73c4876c254100d33">Contract</a>
                    <a href="/profile">Rebel Stories</a>
                    <OutlineButton variant={'botNft'} href="https://opensea.io/collection/rebelbots" text="Buy Rebel Bot"/>
                </div>
            </div>
        </div>
    );
}

export default BotNftPageBuyBlock;