import React, { useContext, useEffect, useRef, useState } from "react";

import exit from "Image/exit.svg";
import { PopupContext } from "Context/Popup";
import { useDimensions } from "hooks/useDimensions";

const PopupGeneric = (props) => {
  const popupRef = useRef(null);
  const isInit = useRef(false);
  const { popup, setPopup } = useContext(PopupContext);
  const { width, height } = useDimensions();
  const [scale, setScale] = useState(1);

  useEffect(() => {
    // calculate target scales, including small offset
    if (!popupRef || !popupRef.current) return;
    const xScale = (width / popupRef.current.clientWidth) * 0.97;
    const yScale = (height / popupRef.current.clientHeight) * 0.97;

    let scale = Math.min(1, xScale, yScale);

    if (!isInit.current && typeof props.setInitialScale === "function") {
      isInit.current = true;
      props.setInitialScale(scale);
    }

    if (!!props.customScale) scale = props.customScale;
    else scale = Math.min(1, xScale, yScale);

    setScale(scale);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [width, height, popup, props.customScale, props.setInitialScale]);

  if (!popup) return null;

  return (
    <div className="popup-generic-wrapper">
      <div
        className={`popup-generic ${popup.style}`}
        ref={popupRef}
        style={{
          transform: `scale(${scale})`,
          transition: "transform .2s ease",
        }}
      >
        <div className="exit-button-wrapper">
          {!popup.hideClose && (
            <button
              onClick={() => {
                setPopup(null);
              }}
            >
              <img src={exit} alt="" className="box-svg" />
            </button>
          )}
        </div>
        <h2 className="box-title">{popup.title}</h2>
        {props.children}
      </div>
    </div>
  );
};

export default PopupGeneric;
