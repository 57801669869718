import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  useLayoutEffect,
} from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { MetaMaskBase64 } from "Image/metamask.base64";
import { isMobile } from "react-device-detect";

import {
  LoadingPopup,
  WrongNetworkPopup,
  PurchaseFailedPopup,
  PurchaseFailedLandPopup,
  PurchaseFailedFundsPopup,
  metamaskNotInstalledPopup,
} from "page/Landsale/popups";
import { PopupContext } from "./Popup";
import {
  getLandIds as getLandIdsReq,
  getKingdomLands as getKingdomLandsReq,
} from "api/land";
import MarketContract from "lib/MarketContract.json";
import USDCContract from "lib/USDCContract.json";
import {buyBattleSet, buyTokens, getMediumGas} from "../blockchain.service/methods";

export const LandSaleContext = createContext();

const MAX_LAND_SUPPLY = 150000;

const {
  location: { host },
  config: {
    LAND_TOKEN_PRICE,
    USDC_CONTRACT,
    MARKET_CONTRACT, BS_MARKET_CONTRACT,
    ENVIRONMENT,
    INFURA_ID,
    RPC_URL,
  },
} = window;

// prettier-ignore
const chainId = ENVIRONMENT === "dev" ? 80002 : ENVIRONMENT === "prod" ? 137 : null;

const ConstructWeb3Modal = async () => {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      bridge: "https://bridge.walletconnect.org",
      options: {
        // polygon testnet is not working on walletconnect
        infuraId: INFURA_ID,
        // chainId: 137,
        chainId,
        rpc: {
          [chainId]: RPC_URL,
          // [chainId]: RPC_URL,
          // 137: RPC_URL,
          // 80002: RPC_URL,
        },
        qrcodeModalOptions: {
          mobileLinks: [
            "rainbow",
            "metamask",
            "argent",
            "trust",
            "gnosis",
            "onto",
            "steakwallet",
            "bitpay",
            "1inch",
            "mykey",
            "zelcore",
            "safepal",
            "unstoppable",
            "peakdefi",
            "aktionariat",
            "talken",
            "kyberswap",
            "hashkey",
            "valora",
            "essentials",
            "fuse",
            "avacus",
            "coinstats",
            "klever",
            "edge wallet",
            "kryptogo",
            "autonomy",
            "s-one",
            "boo",
            "coingrig",
            "assure",
            "safemoon",
            "spot",
            "keyring",
            "qubic",
            "pltwallet",
          ],
        },
      },
    },
  };

  try {
    const isMetaMaskInstalled = window.ethereum && window.ethereum.isMetaMask;

    if (!isMetaMaskInstalled) {
      providerOptions["custom-metamask"] = {
        display: {
          name: "MetaMask",
          description: "Connect your MetaMask Wallet",
          logo: MetaMaskBase64,
        },
        package: detectEthereumProvider,
        connector: () => null,
      };
    }
  } catch {}

  return new Web3Modal({
    cacheProvider: true,
    providerOptions,
    // theme: "dark",
  });
};

const LandSaleWallet = ({ children }) => {
  const [web3, setWeb3] = useState();
  const [account, setAccount] = useState();
  const [landIds, setLandIds] = useState();
  const [balance, setBalance] = useState(0);
  const [isEnough, setIsEnough] = useState();
  const [provider, setProvider] = useState();
  const [web3Modal, setWeb3Modal] = useState();
  const [contract, setContract] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [allowance, setAllowance] = useState(0);
  const [bsAllowance, setBSAllowance] = useState(0);
  const [isPurchasing, setIsPurchasing] = useState();
  const [landSaleEnded, setLandSaleEnded] = useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState();
  const [landSaleOpened, setLandSaleOpened] = useState(false);
  const [isApprovingUSDC, setIsApprovingUSDC] = useState(false);
  const [connectedManually, setConnectedManually] = useState(false);
  const [kingdomLands, setKingdomLands] = useState({
    id: undefined,
    isLoading: false,
    lands: [],
  });
  const { setPopup, popup } = useContext(PopupContext);

  /* eslint-disable react-hooks/exhaustive-deps */
  useLayoutEffect(() => {
    ConstructWeb3Modal().then((res) => {
      setWeb3Modal(res);
    });
  }, []);

  const connect = async (isCached, path) => {
    if (!isCached) setConnectedManually(true);
    try {
      if (!!web3Modal) {
        if (!isCached) await web3Modal.clearCachedProvider();
        const provider = await web3Modal.connect();
        if (provider) {
          setProvider(provider);
          const newWeb3 = new Web3(provider);
          // removed line - should fix XOIL-1601
          setWeb3(newWeb3);
        } else if (!isCached) {
          // Todo: Do we need to open a popup on every condition?
          if (isMobile) {
            const metamaskHost = "https://metamask.app.link/dapp";
            // window.open(
            //   ENVIRONMENT === "dev"
            //     ? `${metamaskHost}/${host}${path}`
            //     : `${metamaskHost}/${host}${path}`,
            //   "_blank"
            // );

            const a = document.createElement("a");
            a.href = ENVIRONMENT === "dev"
              ? `${metamaskHost}/${host}${path}`
              : `${metamaskHost}/${host}${path}`;
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            a.remove();
          } else metamaskNotInstalledPopup(setPopup);
        }
      }
    } catch {}
  };

  const getAccounts = useCallback(async () => {
    return await web3.eth.getAccounts();
  }, [web3]);

  const toCheckSum = useCallback(
    (address) => {
      return web3?.utils?.toChecksumAddress(address);
    },
    [web3, account]
  );

  const checkNetwork = (id, networkChangedManually) => {
    if (id && account) {
      if (
        (ENVIRONMENT === "dev" && id.toString() === "80002") ||
        (ENVIRONMENT === "prod" && id.toString() === "137")
      ) {
        setPopup(null);
        setIsCorrectNetwork(true);
      } else {
        if (networkChangedManually || connectedManually)
          WrongNetworkPopup(
            setPopup,
            async () =>
              await window["ethereum"].request({
                method: "wallet_switchEthereumChain",
                params: [
                  {
                    chainId: ENVIRONMENT === "dev" ? "0x13882" : "0x89",
                  },
                ],
              })
          );
        setIsCorrectNetwork(false);
      }
    }
  };

  const getLandIds = async () => {
    try {
      if (landSaleOpened && !landSaleEnded) {
        const data = await getLandIdsReq();
        setLandIds(data);
      }
    } catch {}
  };

  const signTransaction = useCallback(
    (nonce) => {
      if (web3 && nonce) {
        const text = window.config.SIGN_SHOP_TEXT
          ? window.config.SIGN_SHOP_TEXT.replace("%s", nonce)
          : `Please sign in to edit your bot story.\n\n Nonce: ${nonce}`;

        return new Promise(
          async (resolve, reject) =>
            await web3?.eth.personal.sign(
              web3?.utils.fromUtf8(text),
              account,
              "",
              (err, signature) => {
                if (err) return reject(err);
                return resolve({ account, signature });
              }
            )
        );
      }
    },
    [web3, account]
  );

  useEffect(() => {
    getLandIds();
    const interval = setInterval(() => getLandIds(), 60 * 1000);
    return () => clearInterval(interval);
  }, [landSaleOpened, landSaleEnded]);

  useEffect(() => {
    if (provider && web3Modal) {
      provider.on("accountsChanged", (data) => {
        if (web3?.eth?.net?.getId) {
          web3.eth.net.getId().then((id) => {
            checkNetwork(id, true);
          });
        }

        setAccount(data[0]);
        setAccounts(data);
        if (!data.length) {
          setBalance(0);
          web3Modal.clearCachedProvider();
        }
      });

      provider.on("disconnect", () => {
        if (web3) {
          if (accounts.length > 1) {
            getAccounts().then((data) => {
              setAccount(data[0]);
              setAccounts(data);
            });
          } else {
            setAccount(undefined);
            setAccounts([]);
            web3Modal.clearCachedProvider();
          }
        }
      });

      provider.on("networkChanged", (id) => {
        if (web3) {
          checkNetwork(id, true);
        }
      });
    }
  }, [web3, web3Modal, provider, account]);

  useEffect(() => {
    if (!!web3Modal?.cachedProvider) {
      connect(true);
    }
  }, [web3Modal]);

  useEffect(() => {
    if (web3 && web3Modal)
      getAccounts().then((data) => {
        setAccount(data[0]);
        setAccounts(data);
        if (!data.length) web3Modal.clearCachedProvider();
      });
  }, [web3, web3Modal]);

  useEffect(() => {
    if (web3 && !!provider && !!account) {
      web3.eth.net.getId().then((id) => {
        if (id === 1) {
          id = provider.chainId;
        }
        checkNetwork(id, false);
      });
    }
  }, [web3, provider, account]);

  useEffect(() => {
    if (!isCorrectNetwork) return;
    if (web3) {
      setContract({
        market: new web3.eth.Contract(MarketContract.abi, MARKET_CONTRACT),
        usdc: new web3.eth.Contract(USDCContract.abi, USDC_CONTRACT),
      });
    }
  }, [web3, isCorrectNetwork]);

  const totalAmount = (count) => LAND_TOKEN_PRICE * count; // count * 10 ** 6;
  const calculateDelta = (a, b) => Math.abs(a - b);
  const convertToUSDC = (token) => token / 1000000;
  const commify = (n) => {
    const parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return (
      numberPart.replace(thousands, ",") +
      (decimalPart ? "." + decimalPart : "")
    );
  };

  const balanceOf = useCallback(
    async (total) => {
      if (!isCorrectNetwork) return;
      if (web3 && account && contract.usdc) {
        await contract.usdc.methods
          .balanceOf(account)
          .call()
          .then((balance) => {
            // console.log({ total })
            setIsEnough(+balance / 1_000_000 >= total);
            setBalance(+balance / 1_000_000);
          })
          .catch((err) => console.log(err));
      }
    },
    [web3, account, contract.usdc, isCorrectNetwork]
  );

  const getAllowance = useCallback(
    async (isApproving = false, bs) => {
      if (!isCorrectNetwork) return;
      if (web3 && account && contract.usdc) {
        const allowMethod = bs ? setBSAllowance : setAllowance
        try {
          await contract.usdc.methods
            .allowance(account, bs ? BS_MARKET_CONTRACT : MARKET_CONTRACT)
            .call()
            .then((data) =>
              allowMethod((prevState) => {
                // return +data
                return (isApproving
                  ? prevState <= +data
                    ? +data
                    : prevState
                  : +data) / 1_000_000;
              })
            );
        } catch {}
      }
    },
    [web3, account, contract.usdc, isEnough, isCorrectNetwork]
  );
  const increaseAllowanse = useCallback(
    async (delta, bs) => {
      await balanceOf(account);
      if (!isCorrectNetwork) return;
      if (web3 && account && contract.usdc) {
        // const gas = await contract.usdc.methods
        //   .increaseAllowance(MARKET_CONTRACT, delta)
        //   .estimateGas({
        //     from: account,
        //   });

        const { gasMaxPriorityFee } = await getMediumGas(web3);
        // This is for not triggering "Be aware"
        web3.eth.transactionBlockTimeout = 7500;
        web3.eth.transactionPollingTimeout = 7500;

        // This is for not triggering "Be aware"
        web3.eth.transactionBlockTimeout = 7500;
        web3.eth.transactionPollingTimeout = 7500;

        return await contract.usdc.methods
          .increaseAllowance(bs ? BS_MARKET_CONTRACT : MARKET_CONTRACT, delta)
          .send({
            from: account,
            // contractAddress: bs ? BS_MARKET_CONTRACT : MARKET_CONTRACT,
            // gasPrice: gasPrice,
            // maxFeePerGas: gasMaxFee,
            maxPriorityFeePerGas: gasMaxPriorityFee,
          }).on('transactionHash', () => {
            LoadingPopup(setPopup)
          })
          .on("receipt", function (receipt) {
            const method = bs ? setBSAllowance : setAllowance
            method(+receipt.events.Approval.returnValues[2] / 1_000_000);
            setIsApprovingUSDC(false);
            console.log("receipt: ", receipt);
          })
          .on("error", function (error, receipt) {
            console.error(error, receipt, typeof error);

            if (
              error.message &&
              error.message.match("Be aware that it might still be mined!")
            ) {
            }
          });
      }
    },
    [web3, account, contract.usdc, isCorrectNetwork]
  );

  const getKingdomLands = async (id = kingdomLands.id) => {
    setKingdomLands((prevState) => ({ ...prevState, isLoading: true }));
    const lands = await getKingdomLandsReq(id);
    setKingdomLands({
      id,
      isLoading: false,
      lands,
    });
    return lands;
  };

  const purchase = async ({ total, specifics, tokenNumber }, bs) => {
    // TODO only for battle set
    if (!account) return connect(false, '/battleSet')
    if (balance < total) return PurchaseFailedFundsPopup(setPopup)
    await balanceOf(total);
    if (!isCorrectNetwork) return;

    let transactionCompletedOnce = false;

    function transactionCompleted() {
      if (transactionCompletedOnce) return;
      transactionCompletedOnce = true;
      setIsApprovingUSDC(false);
      setIsPurchasing(false);
      balanceOf(account);
      // should fix XOIL-1786
      // getAllowance();
    }

    try {
      // TODO works without this line
      // probably we need to track MATIC balance,
      // and we are tracking USDC balance?
      if (balance < total) return PurchaseFailedFundsPopup(setPopup);

      // TODO probably incorrect logic!
      // check all occurences of this variables
      // and fix
      // if (landIds >= MAX_LAND_SUPPLY) return PurchaseFailedLandPopup(setPopup);

      setIsPurchasing(true);

      const allow = bs ? bsAllowance : allowance
      if (allow < total) {
        const delta = calculateDelta(total, allow);
        if (balance >= total && balance >= delta) {
          setIsApprovingUSDC(true);
          await increaseAllowanse(delta * 1_000_000, bs);
          bs
            ? await buyBattleSet({specificTokens: specifics, tokenNumber}, web3, account, setPopup)
            : await buyTokens(specifics, tokenNumber, account, contract, MARKET_CONTRACT, setPopup, transactionCompleted, kingdomLands, web3)
        } else {
          PurchaseFailedFundsPopup(setPopup);
        }
      } else {
        if (!!specifics?.length || !!tokenNumber) {
          if (balance < total) {
            return PurchaseFailedFundsPopup(setPopup)
          }
          if (web3 && account && contract?.market) {
            bs
              ? await buyBattleSet({specificTokens: specifics, tokenNumber}, web3, account, setPopup)
              : await buyTokens(specifics, tokenNumber, account, contract, MARKET_CONTRACT, setPopup, transactionCompleted, kingdomLands, web3)
          }
        } else if (!specifics?.length && !tokenNumber) {
          throw new Error();
        }
      }
      await getAllowance();
      await getAllowance(false, true)
    } catch (e) {
      // Todo: Might need to check for err.code !== 4001
      console.error(e);
      if (
        (typeof e === "string" && e.match("This token already minted")) ||
        (typeof e.message === "string" &&
          e.message.match("This token already minted")) ||
        (e.data?.message &&
          typeof e.data.message === "string" &&
          e.message.match("This token already minted"))
      ) {
        await getKingdomLands(kingdomLands.id);
        const newSpecifics = kingdomLands.lands
          .filter((i) => specifics.indexOf(i) < 0)
          .slice(0, specifics.length);
        if (newSpecifics.length) {
          await purchase({
            total,
            specifics: newSpecifics,
            tokenNumber: null,
          });
        } else {
          PurchaseFailedLandPopup(setPopup);
        }
      } else if (e.message.match(`"code": -32000`)) {
        PurchaseFailedFundsPopup(setPopup);
      } else {
        PurchaseFailedPopup(setPopup);
      }
    }
    transactionCompleted();
  };

  console.log(bsAllowance, balance)

  //
  // useEffect(() => {
  //   if (isCorrectNetwork) {
  //     console.log({ account })
  //     getAllowance();
  //   }
  // }, [web3, account, contract.usdc, isEnough, isCorrectNetwork]);

  useEffect(() => {
    if (isCorrectNetwork) {
      balanceOf(totalAmount(1));
      getAllowance();
      getAllowance(false, true);
    }
  }, [web3, account, contract.usdc, balanceOf, isCorrectNetwork]);

  const exp = {
    web3,
    account,
    connect,
    landIds,
    commify,
    balance,
    purchase,
    allowance,
    totalAmount,
    getLandIds,
    isPurchasing,
    convertToUSDC,
    isApprovingUSDC,
    MAX_LAND_SUPPLY,
    landSaleOpened,
    setLandSaleOpened,
    landSaleEnded,
    setLandSaleEnded,
    getKingdomLands,
    kingdomLands,
    setKingdomLands,
    isCorrectNetwork,
    toCheckSum,
    popup,
    signTransaction,
  };

  return (
    <LandSaleContext.Provider value={exp}>{children}</LandSaleContext.Provider>
  );
};

export default LandSaleWallet;
