import React from 'react';
import {StandardLayout} from "../../components/layouts/StandardLayout";
import s from "./styles.module.scss";
import {Link} from "react-router-dom";
import {useWiki} from "../../hooks/useWiki";

const Wiki = () => {
  const {categories, deleteTags, title} = useWiki()


  return (
    <StandardLayout isHeaderForced={true}>
      <main className={s.background}>
        <h3>Rebel Bots Game Wiki </h3>
        <p className={s.desc}>
          {title}
        </p>

        <div>
          {
            categories.map(category =>
              <div className={s.category} key={category['post-id']}>
                <Link to={'/wiki/' + category.name} >{category.name}</Link>
                <div>{deleteTags(category.content)}</div>
              </div>)
          }
        </div>
      </main>
    </StandardLayout>
  );
}

export default Wiki;