import React from 'react';
import s from "../../../page/NftPage/compoents/NftPageInfoButtonsBlock/styles.module.scss";

const OutlineButton = ({href, text, onClick, style, variant, disabled = false}) => {

  return (
    <a onClick={href ? null : onClick}
            style={style}
            target={variant === 'selfOpen' ? '_self' : '_blank'}
            rel="noreferrer" href={href}
            className={`
              ${s.infoButtons__details} 
              ${variant === 'botNft' ? s.infoButtons__botNft : ''}
              ${variant === 'claim' ? s.infoButtons__claim : ''}
              ${disabled ? s.disabled : ''}
              `}>{text}
    </a>
  );
}

export default OutlineButton;