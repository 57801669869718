import {validateEmail, validateUrl} from "../../utils/helpers";


export const GuildSchema = (register, onBlurInput, registerCreator ) => ({
  name: () => {
    const { ref, ...rest } = register("name", {
      required: true,
      onBlur: () => onBlurInput(0),
    });
    return registerCreator(0, ref, rest);
  },
  description: () => {
    const { ref, ...rest } = register("description", {
      required: true,
      onBlur: () => onBlurInput(0),
    });
    return registerCreator(0, ref, rest);
  },
  mainWallet: () => {
    const { ref, ...rest } = register("mainWallet", {
      required: true,
      pattern: {
        value: /^0x[a-fA-F0-9]{40}$/,
        message: "Enter a valid Ethereum address",
      },
      onBlur: () => onBlurInput(0),
    });
    return registerCreator(0, ref, rest);
  },
  mainGuildName: () => {
    const { ref, ...rest } = register("mainGuildName", {
      required: true,
      onBlur: () => onBlurInput(0),
    });
    return registerCreator(0, ref, rest);
  },
  // subGuild: () => {
  //   const { ref, ...rest } = register("subGuild", {
  //     required: true,
  //     onBlur: () => onBlurInput(0),
  //   });
  //   return registerCreator(0, ref, rest);
  // },
  // languages: () => {
  //   const { ref, ...rest } = register("languages", {
  //     required: false,
  //     onBlur: () => onBlurInput(0),
  //   });
  //   return registerCreator(0, ref, rest);
  // },
  nfts: () => {
    const { ref, ...rest } = register("nfts", {
      required: false,
      onBlur: () => onBlurInput(0),
    });
    return registerCreator(0, ref, rest);
  },
  other: () => {
    const { ref, ...rest } = register("other", {
      required: false,
      onBlur: () => onBlurInput(0),
    });
    return registerCreator(0, ref, rest);
  },

  // platform: () => {
  //   const { ref, ...rest } = register("platform", {
  //     required: false,
  //     onBlur: () => onBlurInput(0),
  //   });
  //   return registerCreator(0, ref, rest);
  // },
  // howDidHear : () => {
  //   const { ref, ...rest } = register("howDidHear", {
  //     required: false,
  //     onBlur: () => onBlurInput(0),
  //   });
  //   return registerCreator(0, ref, rest);
  // },
  website: () => {
    const { ref, ...rest } = register("website", {
      required: false,
      validate: (url) => validateUrl(url, true),
      onBlur: () => onBlurInput(1),
    });
    return registerCreator(1, ref, rest);
  },
  contactDiscordId: () => {
    const { ref, ...rest } = register("contactDiscordId", {
      required: false,
      onBlur: () => onBlurInput(1),
    });
    return registerCreator(1, ref, rest);
  },
  twitter: () => {
    const { ref, ...rest } = register("twitter", {
      required: false,
      onBlur: () => onBlurInput(0),
    });
    return registerCreator(0, ref, rest);
  },
  facebook: () => {
    const { ref, ...rest } = register("twitter", {
      required: false,
      onBlur: () => onBlurInput(0),
    });
    return registerCreator(0, ref, rest);
  },
  telegram: () => {
    const { ref, ...rest } = register("telegram", {
      required: false,
      onBlur: () => onBlurInput(0),
    });
    return registerCreator(0, ref, rest);
  },
  discord: () => {
    const { ref, ...rest } = register("discord", {
      required: false,
      onBlur: () => onBlurInput(0),
    });
    return registerCreator(0, ref, rest);
  },
  primaryContact: () => {
    const { ref, ...rest } = register("primaryContact", {
      required: true,
      onBlur: () => onBlurInput(2),
    });
    return registerCreator(2, ref, rest);
  },
  region: () => {
    const { ref, ...rest } = register("region", {
      required: true,
      onBlur: () => onBlurInput(3),
    });
    return registerCreator(3, ref, rest);
  },
  contactPerson: () => {
    const { ref, ...rest } = register("contactPerson", {
      required: true,
      onBlur: () => onBlurInput(4),
    });
    return registerCreator(4, ref, rest);
  },
  email: () => {
    const { ref, ...rest } = register("email", {
      required: true,
      validate: email => validateEmail(email)
    });
    return registerCreator(4, ref, rest);
  },
  contactEmail: () => {
    const { ref, ...rest } = register("contactEmail", {
      required: true,
      validate: email => validateEmail(email)
    });
    return registerCreator(4, ref, rest);
  },
  details: () => {
    const { ref, ...rest } = register("details", {
      onBlur: () => onBlurInput(5),
    });
    return registerCreator(5, ref, rest);
  },
})