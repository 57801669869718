import React from 'react';
import s from './styles.module.scss'

export const Checkbox = ({title, label, setFilters, value, id}) => {
  const handleValue = () => {
    if(label === 'Select all'){
      setFilters(prevState => {
        const newState = prevState[title]
        const newStateValue = Object.values(newState).includes(false)
        for (let key in newState){
          newState[key] = newStateValue
        }
        return {...prevState, [title]: {...newState}}
      })

      return
    }
    setFilters(prevState => ({...prevState, [title]: {...prevState[title],[label]: !value } }))
  }

  return (
    <div className={s.checkboxWrapper}>
      <input  type="checkbox" id={id || 'checkbox'} checked={value} onChange={console.log}/>
      <span onClick={ handleValue} className={s.checkmark}></span>
      <label className={s.label} htmlFor={id || 'checkbox'}>{label}</label>
    </div>
  );
}

