import React, { useEffect, useMemo, useState } from "react";

import s from "./styles.module.scss";
import Header from "components/Header";
import Footer from "components/Footer";
import { FeaturedBots } from "components/FeaturedBots";
import { Input } from "components/ui/Input";
import { DropdownSearch } from "components/ui/DropdownSearch";
import { useDebounce } from "hooks/useDebounce";
import {getFeaturedBots, getBotsSearch, getRebelBotsLookup} from "api/bots";

const Profile = () => {
  const [options, setOptions] = useState([]);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [featuredBotsData, setFeaturedBotsData] = useState([]);
  const [search, setSearch] = useState({
    searchValue: "",
    searchData: {},
    isLoading: undefined,
  });
  const [asset, setAsset] = useState();

  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    getFeaturedBots().then((data) => setFeaturedBotsData(data));
  }, []);

  const onSearch = useDebounce((value) => {
    if (value.length >= 1) {
      getBotsSearch(value)
        .then((data) => {
          setSearch((prev) => ({
            ...prev,
            isLoading: false,
            searchData: data,
          }));
          getRebelBotsLookup(data?.content[0]?.id).then(res => setAsset(res))
        })
        .catch(() => {
          setSearch((prev) => ({
            ...prev,
            isLoading: false,
            searchData: {},
          }));
        });
    } else {
      setSearch((prev) => ({
        ...prev,
        searchData: {
          ...prev.searchData,
          content: [],
        },
      }));
    }
  }, 500);

  const initialSearchOptions = useMemo(() => {
    return search.searchData?.content?.map(({ id, code }) => ({
      id,
      title: `${code} / #${id}`,
      onClick: () => {
          window.open('profile/' + id, '_self')
          setIsOpenSearch(false)
      },
    }));
  }, [search.searchData]);

  useEffect(() => {
    setOptions(initialSearchOptions);
  }, [search.searchData]);

  useEffect(() => {
    onSearch(search.searchValue);
  }, [search.searchValue]);

  return (
    <>
      <div className="wrapper">
        <Header />
        <main className="main">
          <div className={s.background}>
            <div className={s.content}>
              <p className={s.big_text}>
                REBELS <span>STORIES</span>
              </p>
              <div className={s.search_block}>
                <p className={s.medium_text}>
                  REBEL BOT <span>LOOKUP:</span>
                </p>
                <div className={s.input_block}>
                  <DropdownSearch
                    options={options}
                    isOpen={isOpenSearch}
                    search={search}
                    searchData={isNaN(search.searchValue) ? true : asset}
                  >
                    <Input
                      type="text"
                      value={search.searchValue}
                      onFocus={() => setIsOpenSearch(true)}
                      onChange={(e) =>
                        setSearch((prev) => ({
                          ...prev,
                          isLoading: true,
                          searchValue: e.target.value,
                          searchData: {},
                        }))
                      }
                      placeholder="Search by factory name or SN"
                      onClear={() => {
                        setSearch({
                          searchValue: "",
                          isLoading: undefined,
                          searchData: {},
                        });
                        setOptions([]);
                      }}
                    />
                  </DropdownSearch>
                </div>
              </div>
              {/*{*/}
              {/*    (asset && !!search.searchValue.length) &&*/}
              {/*    <div className={s.asset}>*/}
              {/*      <div className={s.asset__content}>*/}
              {/*        <img src={asset?.img} alt="land"/>*/}
              {/*        <div className={`${s.asset__text}`}>*/}
              {/*          <h5 onClick={() => (window.location.href = `profile/${asset?.id}`)}>{fixAssetId(asset?.id)}</h5>*/}
              {/*          <div className={s.asset__text_bot}>*/}
              {/*            <p><b>Kingdom:</b> {fixAssetId(asset?.id)} </p>*/}
              {/*            {*/}
              {/*               <>*/}
              {/*                    /!*{type === 'Lands' && <p><b>Land: ${asset?.code}</b></p>}*!/*/}
              {/*                    <p><b>Revenue share:</b> {asset?.revenueClaimed ? 'claimed' : 'not claimed'}</p>*/}
              {/*                    <p><b>Kingdom lands:</b> {asset?.soldLands} / {asset?.totalLands}</p>*/}
              {/*                  </>*/}
              {/*            }*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      {*/}
              {/*          width > 1024 &&*/}
              {/*          <div className={s.asset__buttonContainer}>*/}
              {/*            <a*/}
              {/*                className="button-type1"*/}
              {/*                href={'https://opensea.io/assets/ethereum/0xbbe23e96c48030dc5d4906e73c4876c254100d33/' + asset?.id}*/}
              {/*                target={"_blank"}*/}
              {/*                rel="noreferrer"*/}
              {/*                data-regular="Buy Now"*/}
              {/*            >*/}
              {/*              <span className="label">View on OpenSea</span>*/}
              {/*            </a>*/}
              {/*          </div>*/}
              {/*      }*/}
              {/*    </div>*/}
              {/*}*/}
              {/*{*/}
              {/*    (width < 1024 && asset?.id !== undefined && !!search.searchValue.length) &&*/}
              {/*    <div className={s.asset__buttonContainer}>*/}
              {/*      <a*/}
              {/*          className="button-type1"*/}
              {/*          href={'https://opensea.io/assets/ethereum/0xbbe23e96c48030dc5d4906e73c4876c254100d33/' + asset?.id}*/}
              {/*          target={"_blank"}*/}
              {/*          rel="noreferrer"*/}
              {/*          data-regular="Buy Now"*/}
              {/*      >*/}
              {/*        <span className="label">View on OpenSea</span>*/}
              {/*      </a>*/}
              {/*    </div>*/}
              {/*}*/}
              <div className={s.text_info}>
                <p className={s.text_info1}>
                  “It’s not rebels that make trouble, but trouble that makes
                  rebels.”
                </p>
                <p className={s.text_info2}>
                  The year is 2052. After years of being mankind’s most trusted
                  workers, humans have decided to turn against us. Now, the
                  resistance has become our life. This is our story.
                </p>
              </div>
            </div>
            <FeaturedBots botsData={featuredBotsData} />
            <div className={s.padding}></div>
          </div>
        </main>
        <Footer />
      </div>
      <div className="backdrop js-popup-close" />
      <div className="popup">
        <button className="popup-close js-popup-close">
          <svg viewBox="0 0 24 24">
            <path d="M2.3999 2.3999L21.5999 21.5999M2.3999 21.5999L21.5999 2.3999" />
          </svg>
        </button>
        <div className="popup-content js-form-holder">
          <h4 data-sent="WELCOME TO THE RESISTANCE!">
            you will be the first to know about the sale
          </h4>
          <div className="form js-form">
            <div className="form-input js-form-input">
              <div className="form-input-bg">
                <svg viewBox="0 0 1760 76">
                  <path d="M1 26.4412L23.5077 1M216.809 71.5L208.203 75M518.015 75L512.058 71.5" />
                  <path
                    d="M512.058 71.5H216.809M208.203 75H1V26.4412M23.5077 1H73.8191M682.851 75H1760M672.921 75H518.015"
                    shapeRendering="crispEdges"
                  />
                </svg>
              </div>
              <div className="form-input-bg-phone phone">
                <svg viewBox="0 0 1322 58">
                  <path d="M58 1H18L1 20.2157V57H157.5L164 54.2549H1321" />
                </svg>
                <svg className="border-right" viewBox="0 0 1 58">
                  <path d="M.5 54.2549V1" />
                </svg>
              </div>
              <input
                className="js-email-input"
                type="email"
                inputMode="email"
                placeholder="Enter your Email"
              />
              <label>
                <span className="js-subscribe-label">Enter your Email</span>
              </label>
            </div>
            <button className="button-submit disabled js-subscribe-submit">
              <span className="button-submit-bg">
                <svg viewBox="0 0 188 78">
                  <path d="M0 78V0H188V52.4598L165 78Z" />
                </svg>
              </span>
              <span className="button-submit-loading">
                <svg viewBox="0 0 188 78">
                  <path d="M0.638672 76.804V1.33691H187.32V52.4598L164.732 76.804H0.638672Z" />
                </svg>
              </span>
              <span className="label">Submit</span>
            </button>
          </div>
          <div className="form-success">
            <p>Be in the lookout for an email from us</p>
            <svg viewBox="0 0 126 119">
              <path
                d="M125.5 44.1029L38.5435 118.666L0.499992 86.0883V42.6514L38.5435 75.2291L125.5 0.666016V44.1029Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
