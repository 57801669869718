import React, {useState} from 'react';
import {Dropdown} from "../../../../components/ui/Dropdown";
import s from "./styles.module.scss";
import {ReactComponent as DropdownArrow} from "../../../../Image/filterArrow.svg";
import {useDimensions} from "../../../../hooks/useDimensions";

export const SortFilter = ({sort}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { width } = useDimensions();
  const [state, setState] = useState('Alphabetical (A-Z)')

  const setStateValue = (data) => {
    setState(data);
    sort(data)
    setIsOpen(false);
  };


  return (
    <div className={s.formIcon}>
      <Dropdown
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        className={s.formControl}
        isOpen={isOpen}
        name="state"
        onSelect={(item) => setStateValue(item.title)}
        optionsBgColor={'#191919'}
        options={[
          { id: 1, title: "Alphabetical (A-Z)" },
          { id: 2, title: "CPU Cost - Low to High" },
          { id: 3, title: "CPU Cost - High to Low" },
        ]}
      >
        <div
          onClick={() => setIsOpen((prev) => !prev)}
          className={`${s.dropdown} ${s.formControl}`}
        >
          {state
            ? state
            : "Alphabetical (A-Z)"}
        </div>
        <DropdownArrow
          className={s.down}
          onClick={() => setIsOpen((prev) => !prev)}
          style={{
            transform: `translateX(${width > 3800 ? '-30px' : '-10px'})${width > 3800 ? ' scale(2) translateY(13px)' : ''} rotate(${
              !isOpen ? "180deg" : "0deg"
            })`,
          }}
        />
      </Dropdown>
    </div>
  );
}