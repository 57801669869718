import {styled, Tooltip, tooltipClasses} from "@mui/material";
import React from "react";
import s from "../components/GuildTable/GuildRow/styles.module.scss";

export const fixAssetId = (landName) => {
    return `${landName}`.padStart(7, '#000000')
}

export const commaNumber = (number) => {
    return number.toLocaleString('en-US') === '0' ? '-' : number.toLocaleString('en-US');
}

export const fixKingdomName = (kingdomName) => {
    let shouldSplitThere = false;

    // logic for no space when splitting
    if (kingdomName && kingdomName.length >= 15) {
        const splittedText = kingdomName.slice(0, 15);
        let i = splittedText.length - 1;
        while (!shouldSplitThere) {
            if (splittedText[i] !== ' ') {
                shouldSplitThere = true;
                break;
            } else {
                i--;
            }
        }
        return `${splittedText.slice(0, i + 1)}...`
    }

    return kingdomName ?? ''
}

export const tooltipFactory = (arrow, rest, arrowRest, placement) => {
    return styled(({className, type, ...props}) => (
        <Tooltip  {...props} arrow={arrow} placement={placement}  classes={{popper: className}} />
    ))(() => ({
        [`& .${tooltipClasses.arrow}`]: {
            ...arrowRest,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            ...rest
        },
    }));
}

export const colorizedSizeGuild = (size) => {
    if (!size) return '-'
    const item = guildColors.find(el => el.name === size)
    return <p className={s.table__row__size} style={{backgroundColor: item.color}}>{item.name}</p>
}

const guildColors = [
    {
        name: 'Platoon',
        size: 'Less than 100',
        color: '#BFBFBF'
    },
    {
        name: 'Troop',
        size: '100 - 249',
        color: '#85BA16'
    },
    {
        name: 'Battalion',
        size: '250 - 499',
        color: '#4DBCDF'
    },
    {
        name: 'Regiment',
        size: '500 - 999',
        color: '#AA44CD'
    },
    {
        name: 'Brigade',
        size: '1000 - 4999',
        color: '#FF9C28'
    },
    {
        name: 'Empire',
        size: '5000+',
        color: '#FCE61F'
    }
]