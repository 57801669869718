import React, {useState} from 'react';
import s from './styles.module.scss'
import {DropdownFilter} from "../../../../components/ui/DropdownFilter";
import CardClearFilters from "./CardClearFilters";
import {useDimensions} from "../../../../hooks/useDimensions";
import {SortFilter} from "../SortFilter";

const CardsExplorerFilters = ({filters,  setFilters, sort, cardsLength}) => {
  const [isOpenFilters, setIsOpenFilters] = useState(false)
  const { width } = useDimensions();

  const applyFilters = (e) => {
    e.preventDefault()
    setIsOpenFilters(false)
  }

  return (
    <div>
      <div className={s.filtersButtons}>
        <CardClearFilters isOpenFilters={isOpenFilters} setIsOpenFilters={setIsOpenFilters} filters={filters} setFilters={setFilters} />
        {width < 992 && width > 576  &&
          <SortFilter sort={sort} />
        }
      </div>


      {(width < 992 && width > 556 && isOpenFilters) &&
        <>
          <h4>{cardsLength} cards</h4>
        </>
      }
      <form className={`${s.form} ${isOpenFilters ? s.open : ''}`}>

        <DropdownFilter title={'part'}  options={filters.part} setFilters={setFilters}/>
        <DropdownFilter title={'rarity'}  options={filters.rarity} setFilters={setFilters}/>
        <DropdownFilter showTitle={'bot class'} title={'type'}  options={filters.type} setFilters={setFilters} />
        <DropdownFilter showTitle={'Attack type'} title={'attack'}  options={filters.attack} setFilters={setFilters} />
        <DropdownFilter title={'abilities'}  options={filters.abilities} setFilters={setFilters} />
        <DropdownFilter title={'target'}  options={filters.target} setFilters={setFilters} />
        <button onClick={ applyFilters } className={s.button}>
          Apply filters
        </button>
      </form>

    </div>

  );
}

export default CardsExplorerFilters;