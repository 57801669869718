import React from 'react';
import s from './styles.module.scss'
import arrows from '../.././../../Image/arrowsBg.svg'

const BattleSetSecure = ({shortened}) => {
  return (
    <div className={s.battleSetSecure}>
      {
        !shortened
          ? <>
            <img className={s.arrowBackground} src={arrows} alt=""/>
            <a href={'#battleSetBuy'} className={s.button}>Secure your Battle set</a>
              </>
          : <></>
      }
    </div>
  );
}

export default BattleSetSecure;