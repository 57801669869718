import React from "react";

import Footer from "components/Footer";
import { ReactComponent as DownloadIcon } from "Image/download-icon.svg";
import { ReactComponent as Arrow } from "Image/white-paper-arrow.svg";
import Header from "components/Header";

import s from "./styles.module.scss";

const WhitePaper = () => {
  return (
    <>
      <Header />
      <div className={s.background}>
        <h1>
          White<span> Paper</span>
        </h1>
        <div className={s.list}>
          <a
            href="https://cdn.rebelbots.com/wp/Whitepaper_v12_English.pdf"
            download="Whitepaper_v12_English.pdf"
            target="_blank"
            rel="noreferrer"
            className={s.listItem}
            onClick={(e) => e.target.blur()}
          >
            <div className={s.listItemName}>
              <div>
                <Arrow className={s.yellowArrow} />
                <Arrow className={s.redArrow} />
              </div>
              <span>English</span>
            </div>
            <DownloadIcon
              onClick={(e) => e.target.parentElement.blur()}
              className={s.downLoadIcon}
            />
          </a>
          <a
            href="https://cdn.rebelbots.com/wp/Whitepaper_v12_German.pdf"
            download="Whitepaper_v12_German.pdf"
            target="_blank"
            rel="noreferrer"
            className={s.listItem}
            onClick={(e) => e.target.blur()}
          >
            <div className={s.listItemName}>
              <div>
                <Arrow className={s.yellowArrow} />
                <Arrow className={s.redArrow} />
              </div>
              <span>German</span>
            </div>
            <DownloadIcon
              onClick={(e) => e.target.parentElement.blur()}
              className={s.downLoadIcon}
            />
          </a>
          <a
            href="https://cdn.rebelbots.com/wp/Whitepaper_v12_Portuguese.pdf"
            download="Whitepaper_v12_Portuguese.pdf"
            target="_blank"
            rel="noreferrer"
            //Add this to disable link
            // aria-disabled="true"
            className={s.listItem}
            onClick={(e) => e.target.blur()}
          >
            <div className={s.listItemName}>
              <div>
                <Arrow className={s.yellowArrow} />
                <Arrow className={s.redArrow} />
              </div>
              <span>Portuguese</span>
            </div>
            <DownloadIcon
              onClick={(e) => e.target.parentElement.blur()}
              className={s.downLoadIcon}
            />
          </a>
          <a
            href="https://cdn.rebelbots.com/wp/Whitepaper_v12_Spanish.pdf"
            download="Whitepaper_v12_Spanish.pdf"
            target="_blank"
            rel="noreferrer"
            className={s.listItem}
            onClick={(e) => e.target.blur()}
          >
            <div className={s.listItemName}>
              <div>
                <Arrow className={s.yellowArrow} />
                <Arrow className={s.redArrow} />
              </div>
              <span>Spanish</span>
            </div>
            <DownloadIcon
              onClick={(e) => e.target.parentElement.blur()}
              className={s.downLoadIcon}
            />
          </a>
          <a
            href="https://cdn.rebelbots.com/wp/Whitepaper_v12_Tagalog.pdf"
            download="Whitepaper_v12_Tagalog.pdf"
            target="_blank"
            rel="noreferrer"
            className={s.listItem}
            onClick={(e) => e.target.blur()}
          >
            <div className={s.listItemName}>
              <div>
                <Arrow className={s.yellowArrow} />
                <Arrow className={s.redArrow} />
              </div>
              <span>Tagalog</span>
            </div>
            <DownloadIcon
              onClick={(e) => e.target.parentElement.blur()}
              className={s.downLoadIcon}
            />
          </a>
          <a
            href="https://cdn.rebelbots.com/wp/Whitepaper_v12_Turkish.pdf"
            download="Whitepaper_v12_Turkish.pdf"
            target="_blank"
            rel="noreferrer"
            className={s.listItem}
            onClick={(e) => e.target.blur()}
          >
            <div className={s.listItemName}>
              <div>
                <Arrow className={s.yellowArrow} />
                <Arrow className={s.redArrow} />
              </div>
              <span>Turkish</span>
            </div>
            <DownloadIcon
              onClick={(e) => e.target.parentElement.blur()}
              className={s.downLoadIcon}
            />
          </a>
          <a
            href="https://cdn.rebelbots.com/wp/Whitepaper_v12_Vietnamese.pdf"
            download="Whitepaper_v12_Vietnamese.pdf"
            target="_blank"
            rel="noreferrer"
            className={s.listItem}
            onClick={(e) => e.target.blur()}
          >
            <div className={s.listItemName}>
              <div>
                <Arrow className={s.yellowArrow} />
                <Arrow className={s.redArrow} />
              </div>
              <span>Vietnamese</span>
            </div>
            <DownloadIcon
              onClick={(e) => e.target.parentElement.blur()}
              className={s.downLoadIcon}
            />
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WhitePaper;
