import React from 'react';
import s from './styles.module.scss'
import {useDimensions} from "../../../../hooks/useDimensions";

const BattleSetSteps = () => {
  const {width} = useDimensions()

  const steps = [
    {
      id: 1,
      content: `One <span class="yellow">Land</span> in any available Kingdom`
    },
    {
      id: 2,
      content: width > 576
        ? `A package of <span class="yellow">three</span> Fighting Bots`
        : `A <span class="yellow">package</span> of three Fighting Bots`
    },
    {
      id: 3,
      content: `<span class="yellow">Everything</span> you need to play and earn`
    },
  ]

  return (
    <div className={s.battleSetSteps}>
      <h3>Battle Sets include:</h3>

      <div className={s.steps}>
        {
          steps.map(step => (
            <div className={s.stepItem} key={step.id}>
              <div className={s.stepItem__number}>{step.id}</div>
              <div className={s.stepItem__content} dangerouslySetInnerHTML={{ __html:  step.content }}></div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default BattleSetSteps;