import React from "react";

import s from "./styles.module.scss";

// Todo: Refactor props.search functionality
export const DropdownSearch = ({
                                 ref,
                                 isOpen,
                                 options,
                                 children,
                                 search,
                                 buy,
                                 choose,
                                 forceNoResult = false
                               }) => {
  const { isLoading } = search;

  if(options){
    if(options.length !== 0 && Array.isArray(options[0])){
      options = options[0]
    }
  }


  return (
    <div className={s.dropdown} ref={ref}>
      {children}
      {((isOpen && (typeof isLoading !== "undefined" )) || forceNoResult) && (
        <div className={`${s.dropdown_options} ${(buy && !forceNoResult) ? s.dropdown_options_buy : ''}`} >
          {((!isLoading && (!options?.length || options[0] === undefined)) || forceNoResult) ? (
            <div className={s.option_wrapper}>
              <p className={s.no_result}>No results to show</p>
            </div>
          ) : (
            !isLoading &&
            options?.length && (
              <div className={s.option_wrapper}>
                {buy
                  ? options?.map((item) => (
                    <div className="display" onClick={() => choose(item)}>
                     <p key={item.id} className={s.option_item}>
                       #{item.id
                          ? item.id
                          : item.nickName
                            ? item.nickName
                            : item.name}
                       /{item.code}
                      </p>
                      <img src={item.img} alt='' />
                    </div>
                  ))
                  : options[0] !== undefined && options?.map(({ id, onClick = undefined, title }) => (
                  <p key={id} className={s.option_item} onClick={onClick}>
                    {title}
                  </p>
                ))}
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};
