import React from 'react';
import s from "../styles.module.scss";
import EmptyBot from "../../../Image/dis.svg";

const BuilderBot = ({selectedCards}) => {
  let emptySelectCard = true

  for (let key in selectedCards){
   if(selectedCards[key]['card']) {
     emptySelectCard = false
   }
  }


  return (
    <div id='bot' className={s.bot}>
      {emptySelectCard && <p>Choose all five parts  <br/> to see your fighting  <br/>bots  model</p>}

      { Object.values(selectedCards).map(({card}) => {
        if (card){
          const part = card.part.replace(/\s/g, '');
          return <img src={card.partURI} className={`${s.part} ${s[part]}`} key={part} alt={part}/>
        }
        return ''
      }) }
      {emptySelectCard && <img src={EmptyBot} alt="empty bot" className={s.emptyBot}/>}

    </div>
  );
}

export default BuilderBot;