export const roadmapArray = [
  {title: 'Token Launch', description: 'Rebel Bot owners given the opportunity to purchase the RBLS token in a community token sale.', date: 'March 2022', area: 'nft'},
  {title: 'Xoilium Land Sale', description: 'Xoil Wars land sale goes live, giving players the opportunity to purchase land in their desired kingdom.', date: 'May 2022', area: 'nft'},
  {title: '1 Year Anniversary', description: 'Rebel Bots celebrates the 1 year anniversary of its original NFT drop that started it all.', date: 'July 2022', area: 'nft'},
  {title: 'SPARX Explorer', description: 'Cards are released along with the SPARX explorer, allowing players to explore and build strategies.', date: 'August 2022', area: 'tools'},
  {title: 'Beta Launch', description: 'Players landed on Xoilium and played for the first time with the launch of Xoil Wars.', date: 'October 2022', area: 'game'},
  {title: 'Fighting Bot Claims ', description: 'Land owners can claim their 3 Fighting Bots. ', date: 'Q1 2023', area: 'game'},
  {title: 'Marketplace Launch', description: 'An NFT marketplace with low costs and fast transactions for sales and rentals.', date: 'Q1 2023', area: 'tools'},
  {title: 'Testnet Launch', description: 'Prepare for battle in the full feature testnet launch of Xoil Wars.', date: 'Q1 2023', area: 'game'},
  {title: 'World-Wide Launch', description: 'Global mainnet launch of Rebel Bots: Xoil Wars.', date: 'Q2 2023', area: 'game'},
  {title: 'Guild Dashboards', description: 'Guild dashboard platform to measure performance of players and assets.', date: 'Q2 2023', area: 'tools'},
  {title: 'Guild Player Management', description: 'No more managing your scholarships over IM, every guild will have its own portal for managing its players.', date: 'Q2 2023', area: 'tools'},
  {title: 'Roadmap 3.0', description: 'Rebel Bots keep building! Tune in to find out what’s next.', date: 'Q3 2023', area: 'tools'},
  {title: 'Rebel Bots DAO', description: 'A Decentralized Autonomous Organization for the Rebel Bots will be formed because there is no I in Robots.', date: 'Q4 2023', area: 'nft'},
]