import React from 'react';
import s from '../styles.module.scss'

const SelectBot = ({bot, setBot}) => {
  const botButtons = ['bot 1', 'bot 2', 'bot 3']

  return (
    <div className={s.selectBotButtonsWrapper}>
      {
        botButtons.map(botButton =>
          <button key={botButton}
                  id={+botButton[4] === bot ? s.focus : ''}
                  onClick={() => setBot(+botButton[4])}
        >{botButton}</button>)
      }
      <button
        id={bot === 0 ? s.focus : ''}
        onClick={() => setBot(0)}
      >team</button>
    </div>
  );
}

export default SelectBot;