import React from 'react';
import s from './styles.module.scss'
import NftPageInfoButtonsBlock from "../NftPageInfoButtonsBlock/NftPageInfoButtonsBlock";

const NftPageMain = () => {
  const infoButton = [
    {title: 'OpenSea', url: 'https://opensea.io/collection/rebelbots'},
    {title: 'Contract', url: 'https://etherscan.io/address/0xbbe23e96c48030dc5d4906e73c4876c254100d33'},
  ]

  return (
    <div className={s.nftPageMain}>
      <h2 className="web55">EXPLORE   <span className='white'>NFTS</span></h2>
      <p>
        Join the exciting Rebel Bots Xoil Wars universe and experience true ownership of your
        digital game collectibles! Own, build, play, and earn with your virtual characters which exist
        as unique tokens (NFTs) on the Ethereum and Polygon blockchains.
      </p>
      <p>Trade rare, valuable Rebel Bot Kingdom Lords,
        Lands, and Fighting Bots with other players to grow your collection and become the ultimate champion.</p>

      <div className={s.rebelDetails}>
        <h3 className='web1'>rebel bots</h3>
        <p>
          After escaping Earth, 10 000 Rebel Bots claimed a Kingdom on Xoilium. As Lords of their realm Rebel Bots rule over their Kingdom's players.
          <span> While not playable in Xoil Wars, owning these Ethereum NFTs grants you a share of your Kingdom's earnings and riches.
          <NftPageInfoButtonsBlock buttons={infoButton} />
          </span>

        </p>


      </div>
    </div>
  );
}

export default NftPageMain;