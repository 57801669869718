import React from 'react';
import s from './styles.module.scss'
import DeckImage1 from '../../../../Image/BotsDeckItem1.jpg'
import DeckImage2 from '../../../../Image/BotsDeckItem2.jpg'
import {config} from "../../../../utils/config";

const FightingBotsDeck = () => {
  return (
    <div className={s.fightingBotsDeck}>
      <h3><span> build your</span> <span>battle deck</span></h3>

      <div className={s.itemContainer}>
        <div className={s.item}>
          <img src={DeckImage1} alt="deck"/>
          <div className={s.item__content}>
            <p className={'fb-text'}>
              Assemble your dream team and arm yourself for victory -
              browse the marketplace for your Fighting Bot champions!
            </p>
            <div className={`${s.button} `}>
              <a
                className={`black button-type1 `}
                target='_blank'
                rel="noreferrer"
                href={config.MARKETPLACE_LINK + '/marketplace/bots'}
              >
                <span className="button-type1-before" />
                <span className="label ">Buy now!</span>
                <span className="button-type1-after" />
              </a>
            </div>
          </div>
        </div>
        <div className={s.item}>
          <img src={DeckImage2} alt="deck"/>
          <div className={s.item__content}>
            <p className={'fb-text'}>
              Plan the ultimate strategy to dominate the Xoil Wars using the  <br/>
              <b>Card Explorer and SPARX Fighting Bot Prototyper!</b>
            </p>
            <div className={s.button}>
              <a
                className={`black button-type1 `}
                href={'/cardsExplorer'}
              >
                <span className="button-type1-before" />
                <span className="label">Check it out</span>
                <span className="button-type1-after" />
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default FightingBotsDeck;