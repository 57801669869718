import React from 'react';
import s from "./styles.module.scss";
import CloseIcon from "../../../Image/closeModal.svg";

const ExplorerModalWrapper = ({open, handleClose, title, children}) => {

    return (
        <div className={`${s.modal} ${open ? s.open : ''}`}>
            <div className={s.modal__body}>
                {handleClose && <img onClick={handleClose} className={s.close} src={CloseIcon} alt="close"/>}
                <div className={s.modal__title}>{title}</div>
                {children}
            </div>
        </div>
    );
}

export default ExplorerModalWrapper;