import React, {useState} from 'react';
import s from "../../page/Guilds/styles.module.scss";
import {Dropdown} from "../ui/Dropdown";
import {ReactComponent as DropdownArrow} from "../../Image/dropdownArrow.svg";
import iconWarning from "../../Image/icon-warning.svg";
import iconSuccess from "../../Image/icon-success.svg";
import closeIcon from "../../Image/closeModal.svg"

const LanguagesPicker = ({title, name,  width, state,  setState, isSubmitted, options, placeholder, required, hear}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [languages, setLanguages] = useState([])

  const setGuildValue =  (data) => {
    setLanguages(prevState => [...prevState, data]);

    setState([...languages, data]);
    setIsOpen(false);
  };

  const deleteItem = (index) => {
    setLanguages(prevState => {
      const newLanguages = [...prevState]
      newLanguages.splice(index, 1)
      return newLanguages
    });
  }

  return (
    <div className={`${s.formGroup} ${s.formGroupDropDown} ${hear ? s.formGroup__hear : ''}`}>
      <label className={s.formLabel}>
        {title} {required && <span style={{ color: "#E40B0B" }}>*</span>}
      </label>
      <div className={s.formIcon}>
        <Dropdown
          onMouseEnter={() => setIsOpen(!(languages.length >= 3))}
          onMouseLeave={() => setIsOpen(false)}
          className={s.formControl}
          isOpen={isOpen}
          name={name}
          onSelect={(item) => setGuildValue( item.title)}
          options={ options.filter(el => !languages.includes(el.title)) }
        >
          <div
            onClick={() => setIsOpen((prev) =>   languages.length >= 3 ? false : !prev)}
            className={`${s.dropdown} ${s.formControl} ${s.dropdown__language}`}
          >
            {languages.length > 0
              ? languages.map((el, index) => <LanguageItem key={el} name={el} index={index} deleteItem={deleteItem} />)
              : placeholder}
          </div>
          {
            !(languages.length >= 3) &&
          <DropdownArrow
            className={s.down}
            onClick={() => setIsOpen((prev) => prev ? !(languages.length >= 3) : !prev)}
            style={{
              transform: `translateX(${width > 3800 ? '-30px' : '-10px'})${width > 3800 ? ' scale(2) translateY(13px)' : ''} rotate(${
                isOpen ? "180deg" : "0deg"
              })`,
            }}
          />
          }
        </Dropdown>
        {!state && isSubmitted && (
          <>
            <img src={iconWarning} alt="warning" />
          </>
        )}
        {isSubmitted && state && (
          <img src={iconSuccess} alt="warning" />
        )}
        {!state && isSubmitted && (
          <>
            <span className={s.formFeedback}>This is required</span>
          </>
        )}
      </div>
    </div>
  );
}

const LanguageItem = ({name, index, deleteItem}) => {
  return <div onClick={() => deleteItem(index)} className={s.languageItem}>
            {name} <img src={closeIcon} data-alt='remove' alt="remove"/>
         </div>
}

export default LanguagesPicker;