export const HpRanges = {
  Military: {
    common: [60, 100],
    rare: [50, 95],
    epic: [60, 90],
    legendary: [70, 85]
  },
  Engineer: {
    common: [100, 150],
    rare: [85, 145],
    epic: [100, 140],
    legendary: [110, 135]
  },
  Industrial: {
    common: [150, 180],
    rare: [140, 175],
    epic: [144, 170],
    legendary: [148, 165]
  }
}