import React, { useContext, useEffect } from "react";

import twitterLogo from "Image/twitter_logo.svg";
import { PopupContext } from "Context/Popup";
import PopupGeneric from "../PopupGeneric";
import Timer from "../CountDown/Timer";

const PopupSuccess = () => {
  const { popup, setPopup } = useContext(PopupContext);

  useEffect(() => {
    if (!popup || popup.type !== "success") return null;
  }, [popup]);

  if (!popup || popup.type !== "success") return null;

  let time = window.config.AIRDROP_TS * 1000;
  const claimDate = new Date(time);
  const dd = claimDate.getDate() < 10 ? `0${claimDate.getDate()}` : claimDate.getDate();
  const mm = claimDate.getMonth() + 1 < 10 ? `0${claimDate.getMonth() + 1}` : claimDate.getMonth() + 1;
  const yyyy = claimDate.getFullYear();
  const claimDateText = `${dd}/${mm}/${yyyy}`;

  const tweetText = encodeURIComponent(
    'The Rebel Bots token is coming #RBLS #Jointheresistance'
  );

  return (
    <PopupGeneric>
      <p className="popup-success box-desc">
        Your RBLS token transaction is now complete! <br />Welcome to the Rebel Bots resistance
      </p>
      <div>
        <p className="popup-subdesc">
          Your tokens can be claimed on {claimDateText} with wallet
          <br />
          {popup.claimAddress}
          <br />
          On <a href='http://vesting.rebelbots.com/' target="_blank" rel="noreferrer">vesting.rebelbots.com</a>
        </p>
      </div>
      <div className="claim-1-airdrop success">
        <a
          className="twitter-share-button"
          target={"_blank"}
          rel={"noopener noreferrer"}
          href={`https://twitter.com/intent/tweet?text=${tweetText}&hashtags=RebelBots,RBLS,MATIC,Polygon`}
        >
          <img src={twitterLogo} alt="Twitter logo" />
          <span>SHARE</span>
        </a>
      </div>
      <div className="claim-timer">
        Claim countdown: <Timer date={time} handleSet={() => {}}></Timer>
      </div>
      <button
        className="box-button"
        onClick={() => {
          setPopup(null);
        }}
      >
        OK
      </button>
    </PopupGeneric>
  );
};

export default PopupSuccess;
