import React from 'react';
import {StandardLayout} from "../../components/layouts/StandardLayout";
import s from "../GamingGuilds/styles.module.scss";
import TradingLeaderboardMain from "./components/TradingLeaderboardMain";
import TradingLeaderboardTable from "./components/TradingLeaderboardTable";
const TradingLeaderboard = () => {
  return (
    <div>
      <StandardLayout isHeaderForced={true}>
        <main className={s.background}>
          <div className={s.gamingGuilds}>

            <TradingLeaderboardMain />
            <TradingLeaderboardTable />
          </div>
        </main>
      </StandardLayout>
    </div>
  );
}

export default TradingLeaderboard;