import Loader from "components/Loader";
import React from "react";

// const { pathname } = window.location

// const isForbidden = pathname !== '/lands'

export const VerificationPopup = (setPopup, clickHandler) => {
  setPopup({
    type: "error",
    title: "VERIFICATION IN PROCESS",
    style: "popup-error  purchase-error",
    btnText: "OK",
    onClick: clickHandler ? () => clickHandler() : () => setPopup(null),
    element: (
      <div
      style={{
        textAlign: "center",
      }}
      >
        <span>Review of your documents is in progress and will be completed soon.</span>
      </div>
    ),
  })
}
export const VerificationNegativePopup = (setPopup, clickHandler) => {
  setPopup({
    type: "error",
    title: "VERIFICATION FAILED",
    style: "popup-error  purchase-error verification",
    btnText: "OK",
    onClick: clickHandler ? () => clickHandler() : () => setPopup(null),
    element: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <span>Verification of your ID could not be completed. </span>
        <span>If you feel that this is an error, </span>
        <span>please re-submit your documents for review.</span>
        <span>Ensure that documents are clearly visible for best results.</span>
      </div>
    ),
  })
}
export const VerificationExpiredPopup = (setPopup, clickHandler) => {
  setPopup({
    type: "error",
    title: "Verification Expired",
    style: "popup-error  purchase-error verification",
    btnText: "Resubmit",
    onClick: clickHandler ? () => clickHandler() : () => setPopup(null),
    element: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <span>The verification process has expired while it was pending further information.</span>
        <span>To proceed with the Xoil Wars Battle Set purchase you</span>
        <span>will need to restart your verification process.</span>
        <span>We apologize for the inconvenience.</span>
      </div>
    ),
  })
}
export const WrongNetworkPopup = (setPopup, clickHandler) => {
  // if (isForbidden) return;
  setPopup({
    type: "error",
    title: "Connection Failed",
    style: "popup-error  purchase-error",
    btnText: "OK",
    onClick: clickHandler ? () => clickHandler() : () => setPopup(null),
    element: (
      <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      >
        <span>We couldn't connect your wallet,</span>
        <span>please make sure your wallet is</span>
        <span>set on Polygon Mainnet</span>
      </div>
    ),
  });
}

export const LoadingPopup = (setPopup) => {
  // if (isForbidden) return;
  setPopup({
    type: "error",
    style: "popup-error no-btn",
    title: "Processing transaction",
    hideClose: true,
    element: <Loader className="land-sale-loader" />,
  });
}

export const SuccessFullPurchasePopup = (setPopup) => {
  // Todo: change the article link
  // if (isForbidden) return;
  setPopup({
    type: "error",
    style: "popup-error purchase-success",
    title: "Purchase completed",
    btnText: "OK",
    onClick: () => setPopup(null),
    element: (
      <div id='successPurchasedLand'>
        <span > You have successfully purchased a Battle Set.</span> <br />
        Can&rsquo;t find your new Land and Fighting Bots in your digital wallet? Check this
        <a href="https://medium.com/@Rebel_Bots/how-to-see-land-nfts-in-opensea-and-metamask-afa71cda11e7" target="_blank" rel="noreferrer">article</a>
        for help.
      </div>
    ),
  });
}
  
export const PurchaseFailedPopup = (setPopup) => {
  // if (isForbidden) return;
  setPopup({
    type: "error",
    style: "popup-error purchase-error",
    title: "Purchase failed",
    btnText: "OK",
    onClick: () => setPopup(null),
    element: (
      <div id='failedPurchasedLand'>
        Your transaction couldn&rsquo;t be completed.
        <br />
        Please try again or choose a different land.
      </div>
    ),
  });
}

export const PurchaseFailedFundsPopup = (setPopup) => {
  // if (isForbidden) return;
  setPopup({
    type: "error",
    style: "popup-error fund-error",
    title: "Purchase failed",
    btnText: "OK",
    onClick: () => setPopup(null),
    element: (
      <>
        Your transaction couldn&rsquo;t be completed.
        <br />
        You have insufficient USDC in your wallet to complete the transaction
      </>
    ),
  }); 
}

export const PurchaseFailedLandPopup = (setPopup) => {
  // if (isForbidden) return;
  setPopup({
    type: "error",
    style: "popup-error fund-error land-error",
    title: "Purchase failed",
    btnText: "OK",
    onClick: () => setPopup(null),
    element: (
      <>
        Your transaction couldn&rsquo;t be completed.
        <br />
        This kingdom doesn&rsquo;t have any available land for pusrchase and so
        the transaction could not be completed
      </>
    ),
  });
}

export const metamaskNotInstalledPopup = (setPopup) => {
  // if (isForbidden) return;
  setPopup({
    type: "error",
    title: "Connection Failed",
    text: "Metamask Plugin is not installed",
    btnText: "OK",
    onClick: () => {
      setPopup(null);
    },
  });
}

  
  // Not useed yed
// export const twoButtonPopup = (setPopup) =>
//   setPopup({
//     type: "error",
//     style: "popup-error no-btn",
//     title: "you will be the first to 2",
//     onClick: () => setPopup(null),
//     element: (
//       <>
//         <p>
//           Robots do not age, but we felt the years pass as we roamed the galaxy
//           searching for a new planet. <br /> Oil supplies ran low and our joints
//           began to rust. But on the outer edges of a new.
//         </p>
//         <div className="confirmation-buttons">
//           <button className="box-button">No</button>
//           <button className="box-button">Yes</button>
//         </div>
//       </>
//     ),
//   });
