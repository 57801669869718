export const teamData = [
  {
    id: 1,
    name: "Jane Smith 1",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember.png"),
    description: `Lorem t, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
  {
    id: 2,
    name: "Jane Smith 2",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember2.png"),
    description: `Lorem ipsum dolor sit amt amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
  {
    id: 3,
    name: "Jane Smith 3",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember3.png"),
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
  {
    id: 4,
    name: "Jane Smith 4",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember.png"),
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
  {
    id: 5,
    name: "Jane Smith 5",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember2.png"),
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
  {
    id: 6,
    name: "Jane Smith 6",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember3.png"),
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
  {
    id: 7,
    name: "Jane Smith 7",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember.png"),
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
  {
    id: 8,
    name: "Jane Smith 8 ",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember2.png"),
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
  {
    id: 9,
    name: "Jane Smith 9",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember3.png"),
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
  {
    id: 10,
    name: "Jane Smith 10",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember.png"),
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
  {
    id: 11,
    name: "Jane Smith 11",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember2.png"),
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
  {
    id: 12,
    name: "Jane Smith 12",
    position: "Web Developer",
    short_desc: "Level 20 web developer and fire sorceress",
    img: require("../Image/teamMember3.png"),
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore  magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut   `,
    attack_power: 5,
    defence_power: 9,
    spell_power: 3,
    famous_for: "Expert React JS",
    extra_skill: "Air magic,max length of the line of text should be this",
    special_ability: "Expert Coffee breaks manager",
    best_leadership: "1 cat",
  },
];
