import React from 'react';
import s from './styles.module.scss'
import legs1 from "../../../../Image/legs1.webp";
import legs2 from "../../../../Image/legs2.webp";
import legs3 from "../../../../Image/legs3.webp";
import rarm1 from "../../../../Image/rarm1.webp";
import rarm2 from "../../../../Image/rarm2.webp";
import rarm3 from "../../../../Image/rarm3.webp";
import body1 from "../../../../Image/body1.webp";
import body2 from "../../../../Image/body2.webp";
import body3 from "../../../../Image/body3.webp";
import head1 from "../../../../Image/head1.webp";
import head2 from "../../../../Image/head2.webp";
import head3 from "../../../../Image/head3.webp";
import larm1 from "../../../../Image/larm1.webp";
import larm2 from "../../../../Image/larm2.webp";
import larm3 from "../../../../Image/larm3.webp";


const HomeAssemble = () => {

  return (
    <section  className={s.homeAssemble}>
      <div  className={`building ${s.building}`}>
        <div  className="container">
          <div  className={`building-row ${s.buildingRow}`}>
            <div className={`building-content ${s.buildingContent}`}>
              <h3><span className='yellow'> ASSEMBLE YOUR</span> ARMY</h3>
              <p>
                Design and build your high caliber army
                of Fighting Bots from 70+ mechnical parts and
                3 combat-ready classes to arm yourself for victory.
              </p>
              <p>
                The time has come to unleash your inner
                champion and dominate the Xoil Wars!
              </p>
            </div>
            <div className={`building-robot ${s.buildingRobot}`}>
              <div className="building-robot-constructor">
                <img
                  className="js-legs"
                  src={legs1}
                  alt="Building Bots"
                />
                <img
                  className="selected js-legs"
                  src={legs2}
                  alt="Building Bots"
                />
                <img
                  className="js-legs"
                  src={legs3}
                  alt="Building Bots"
                />
                <img
                  className="js-rarm"
                  src={rarm1}
                  alt="Building Bots"
                />
                <img
                  className="js-rarm"
                  src={rarm2}
                  alt="Building Bots"
                />
                <img
                  className="selected js-rarm"
                  src={rarm3}
                  alt="Building Bots"
                />
                <img
                  className="js-body"
                  src={body1}
                  alt="Building Bots"
                />
                <img
                  className="selected js-body"
                  src={body2}
                  alt="Building Bots"
                />
                <img
                  className="js-body"
                  src={body3}
                  alt="Building Bots"
                />
                <img
                  className="selected js-head"
                  src={head1}
                  alt="Building Bots"
                />
                <img
                  className="js-head"
                  src={head2}
                  alt="Building Bots"
                />
                <img
                  className="js-head"
                  src={head3}
                  alt="Building Bots"
                />
                <img
                  className="js-larm"
                  src={larm1}
                  alt="Building Bots"
                />
                <img
                  className="selected js-larm"
                  src={larm2}
                  alt="Building Bots"
                />
                <img
                  className="js-larm"
                  src={larm3}
                  alt="Building Bots"
                />
                <div className="building-robot-bullet building-robot-bullet-head js-building-head">
                  <svg viewBox="0 0 123 121" fill="none">
                    <circle
                      opacity="0.2"
                      cx="56.549"
                      cy="55.4631"
                      r="28.8627"
                    />
                    <path
                      d="M21.3726 56.266C21.3726 70.4553 29.8213 82.6768 41.9759 88.2011M56.549 21.1886C60.2257 21.1886 63.7706 21.7511 67.102 22.7945M67.102 89.7376C81.3734 85.2678 91.7255 71.9725 91.7255 56.266C91.7255 47.2347 88.3027 39.0005 82.6801 32.783"
                      strokeWidth="1"
                    />
                    <circle
                      opacity="0.3"
                      cx="56.9999"
                      cy="55.9141"
                      r="22.098"
                    />
                    <path
                      d="M73.4503 38.5734C69.1651 34.5069 63.374 32.0121 57.0001 32.0121C43.7994 32.0121 33.0981 42.7134 33.0981 55.914C33.0981 69.1147 43.7994 79.816 57.0001 79.816C70.2008 79.816 80.9021 69.1147 80.9021 55.914C80.9021 54.2935 80.7408 52.7106 80.4334 51.1805"
                      strokeWidth="1"
                    />
                    <circle cx="56.924" cy="55.8389" r="16.0465" />
                  </svg>
                  <span>Head</span>
                </div>
                <div className="building-robot-bullet building-robot-bullet-rarm js-building-rarm">
                  <svg viewBox="0 0 123 121" fill="none">
                    <circle
                      opacity="0.2"
                      cx="56.549"
                      cy="55.4631"
                      r="28.8627"
                    />
                    <path
                      d="M21.3726 56.266C21.3726 70.4553 29.8213 82.6768 41.9759 88.2011M56.549 21.1886C60.2257 21.1886 63.7706 21.7511 67.102 22.7945M67.102 89.7376C81.3734 85.2678 91.7255 71.9725 91.7255 56.266C91.7255 47.2347 88.3027 39.0005 82.6801 32.783"
                      strokeWidth="1"
                    />
                    <circle
                      opacity="0.3"
                      cx="56.9999"
                      cy="55.9141"
                      r="22.098"
                    />
                    <path
                      d="M73.4503 38.5734C69.1651 34.5069 63.374 32.0121 57.0001 32.0121C43.7994 32.0121 33.0981 42.7134 33.0981 55.914C33.0981 69.1147 43.7994 79.816 57.0001 79.816C70.2008 79.816 80.9021 69.1147 80.9021 55.914C80.9021 54.2935 80.7408 52.7106 80.4334 51.1805"
                      strokeWidth="1"
                    />
                    <circle cx="56.924" cy="55.8389" r="16.0465" />
                  </svg>
                  <span>Left Arm</span>
                </div>
                <div className="building-robot-bullet building-robot-bullet-larm js-building-larm">
                  <svg viewBox="0 0 123 121" fill="none">
                    <circle
                      opacity="0.2"
                      cx="56.549"
                      cy="55.4631"
                      r="28.8627"
                    />
                    <path
                      d="M21.3726 56.266C21.3726 70.4553 29.8213 82.6768 41.9759 88.2011M56.549 21.1886C60.2257 21.1886 63.7706 21.7511 67.102 22.7945M67.102 89.7376C81.3734 85.2678 91.7255 71.9725 91.7255 56.266C91.7255 47.2347 88.3027 39.0005 82.6801 32.783"
                      strokeWidth="1"
                    />
                    <circle
                      opacity="0.3"
                      cx="56.9999"
                      cy="55.9141"
                      r="22.098"
                    />
                    <path
                      d="M73.4503 38.5734C69.1651 34.5069 63.374 32.0121 57.0001 32.0121C43.7994 32.0121 33.0981 42.7134 33.0981 55.914C33.0981 69.1147 43.7994 79.816 57.0001 79.816C70.2008 79.816 80.9021 69.1147 80.9021 55.914C80.9021 54.2935 80.7408 52.7106 80.4334 51.1805"
                      strokeWidth="1"
                    />
                    <circle cx="56.924" cy="55.8389" r="16.0465" />
                  </svg>
                  <span>Right Arm</span>
                </div>
                <div className="building-robot-bullet building-robot-bullet-body js-building-body">
                  <svg viewBox="0 0 123 121" fill="none">
                    <circle
                      opacity="0.2"
                      cx="56.549"
                      cy="55.4631"
                      r="28.8627"
                    />
                    <path
                      d="M21.3726 56.266C21.3726 70.4553 29.8213 82.6768 41.9759 88.2011M56.549 21.1886C60.2257 21.1886 63.7706 21.7511 67.102 22.7945M67.102 89.7376C81.3734 85.2678 91.7255 71.9725 91.7255 56.266C91.7255 47.2347 88.3027 39.0005 82.6801 32.783"
                      strokeWidth="1"
                    />
                    <circle
                      opacity="0.3"
                      cx="56.9999"
                      cy="55.9141"
                      r="22.098"
                    />
                    <path
                      d="M73.4503 38.5734C69.1651 34.5069 63.374 32.0121 57.0001 32.0121C43.7994 32.0121 33.0981 42.7134 33.0981 55.914C33.0981 69.1147 43.7994 79.816 57.0001 79.816C70.2008 79.816 80.9021 69.1147 80.9021 55.914C80.9021 54.2935 80.7408 52.7106 80.4334 51.1805"
                      strokeWidth="1"
                    />
                    <circle cx="56.924" cy="55.8389" r="16.0465" />
                  </svg>
                  <span>Body</span>
                </div>
                <div className="building-robot-bullet building-robot-bullet-legs js-building-legs">
                  <svg viewBox="0 0 123 121" fill="none">
                    <circle
                      opacity="0.2"
                      cx="56.549"
                      cy="55.4631"
                      r="28.8627"
                    />
                    <path
                      d="M21.3726 56.266C21.3726 70.4553 29.8213 82.6768 41.9759 88.2011M56.549 21.1886C60.2257 21.1886 63.7706 21.7511 67.102 22.7945M67.102 89.7376C81.3734 85.2678 91.7255 71.9725 91.7255 56.266C91.7255 47.2347 88.3027 39.0005 82.6801 32.783"
                      strokeWidth="1"
                    />
                    <circle
                      opacity="0.3"
                      cx="56.9999"
                      cy="55.9141"
                      r="22.098"
                    />
                    <path
                      d="M73.4503 38.5734C69.1651 34.5069 63.374 32.0121 57.0001 32.0121C43.7994 32.0121 33.0981 42.7134 33.0981 55.914C33.0981 69.1147 43.7994 79.816 57.0001 79.816C70.2008 79.816 80.9021 69.1147 80.9021 55.914C80.9021 54.2935 80.7408 52.7106 80.4334 51.1805"
                      strokeWidth="1"
                    />
                    <circle cx="56.924" cy="55.8389" r="16.0465" />
                  </svg>
                  <span>Legs</span>
                </div>
                <div className={`building-robot-sign ${s.buildingRobotSign}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeAssemble;