import React from "react";
import { useNavigate } from "react-router-dom";
import { isIOS } from "utils/detectOS";

import s from "./styles.module.scss";

export const Back = ({ className, to = "/" }) => {
  const navigate = useNavigate();

  return isIOS() ? (
    <a href={to} className={`${s.backButton} ${className}`}>
      {"< Back"}
    </a>
  ) : (
    <span
      className={`${s.backButton} ${className}`}
      onClick={() => navigate(to)}
    >
      {"< Back"}
    </span>
  );
};
