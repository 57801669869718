import React, {useState} from 'react';
import s from './styles.module.scss'
import WarningIcon from '../../../../Image/cardsWarning.svg'
import LegendPopup from "./LegendPopup";

const AboutCards = () => {
  const [popupIsOpen, setPopupIsOpen] = useState(false)

  const openPopup = () => {
    document.querySelector('body').style.overflow = 'hidden'
    setPopupIsOpen(true)
  }

  const closePopup  = () => {
    document.querySelector('body').style.overflow = 'auto'
    setPopupIsOpen(false)
  }

  return (
    <>
    <LegendPopup isOpen={popupIsOpen} closePopup={closePopup}/>

    <div onClick={openPopup} className={s.aboutCards}>
      <img src={WarningIcon} alt="warning"/>
      <p>About the cards</p>
    </div>
    </>
  );
}

export default AboutCards;