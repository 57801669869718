import React from 'react';
import s from "../../page/LandsNftPage/styles.module.scss";
import Brand1 from "../../Image/brandAvacado.png";
import Brand4 from "../../Image/brandRealDeal.png";
import Brand5 from "../../Image/brandJuice.png";
import Brand3 from "../../Image/brandPolemos.png";
import Brand2 from "../../Image/brandPathDao.png";
import {useDimensions} from "../../hooks/useDimensions";

const GuildsListBanner = () => {
  const {width} = useDimensions()

  return (

    <div
      style={{marginTop: 150}}
      className={`${s.applyGuild}`}
    >
      <h3>join these guilds {width < 1024 && <br/>}on xoilium</h3>
      <div className={s.guildBrands}>
        <div className={s.guildBrandsLogos}>
          <img src={Brand1} alt="brand"/>
          {width > 1024 && <img src={Brand4} alt="brand"/>}
          {width < 670 && <img src={Brand4} alt="brand"/>}
          {width > 1024 && <img src={Brand5} alt="brand"/>}
          <img src={Brand3} alt="brand"/>
          {width < 670 && <img src={Brand5} alt="brand"/>}
          <img src={Brand2} alt="brand"/>
        </div>
        <div className={s.guildBrandsOhters}>
          +360 <br/> others
        </div>
      </div>
    </div>
  );
}

export default GuildsListBanner;