import React from 'react';
import s from './styles.module.scss'
import CardsImage from '../../../../Image/homeRealmCards.png'
import CardsImageTablet from '../../../../Image/homeRealmCardsTablet.png'
import {useDimensions} from "../../../../hooks/useDimensions";

const HomeRealm = () => {

  const {width} = useDimensions()

  return (
    <section className={s.homeRealm}>
      <div>
        <div className={s.textBlock}>
          <h3><span className='yellow'>conquer</span> your realm</h3>
          <p>
            It's Your Time to Join the Ranks of the Elite and Conquer
            the Xoilium Kingdoms. Master the art of battle and craft your
            strategy from over 300 lethal cards to create an unbeatable deck.
          </p>
          <p>
            Execute your battle plan and outmaneuver your opponents
            to reach ultimate victory and win epic loot.
          </p>
        </div>
        <a
          className="button-type1"
          data-regular="Card Explorer!"
          href={'/cardsExplorer'}
        >
          <span className="button-type1-before" />
          <span className="label">Card Explorer</span>
          <span className="button-type1-after" />
        </a>
      </div>

      <img src={width > 1024 ? CardsImage : CardsImageTablet} alt="cards"/>

    </section>
  );
}

export default HomeRealm;