import React from 'react';
import s from "../../page/Guilds/styles.module.scss";
import iconWarning from "../../Image/icon-warning.svg";
import iconSuccess from "../../Image/icon-success.svg";

const RegisterInput = ({title, form, errors, isSubmitted, dirtyFields, max = 100, placeholder = '', required = true, name}) => {


  return (
    <div className={s.formGroup}>
      <label className={s.formLabel}>
        {title} {required && <span style={{ color: "#E40B0B" }}>*</span>}
      </label>
      <input
        autoComplete="off"
        className={s.formControl}
        type="text"
        placeholder={placeholder}
        maxLength={max}
        {...form()}
      />
      {errors[name] && (
        <>
          <img src={iconWarning} alt="warning" />
          <span className={s.formFeedback}>{errors[name].message || 'This is required'}</span>
        </>
      )}
      {isSubmitted && dirtyFields[name] && !errors[name] && (
        <img src={iconSuccess} alt="warning" />
      )}
    </div>
  );
}

export default RegisterInput;