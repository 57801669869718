import React, {useEffect, useMemo, useState} from "react";
import {getRebelBots} from "../../../../api/bots";
import s from "./styles.module.scss";
import EmptyLandsImage from "../../../../Image/emptyLands.png";
import EmptyLandsImageTablet from "../../../../Image/emptyLandsTablet.svg";
import {useDimensions} from "../../../../hooks/useDimensions";
import AssetList from "../../../../components/AssetList/AssetList";
// import {tooltipFactory} from "../../../../utils/asset.utils";

const BotNftList = ({account}) => {

    const {width} = useDimensions()
    const [bots, setBots] = useState([]);
    const [fetched, setFetched] = useState(false)
    const [refresh, isRefresh] = useState(false)
    const [page, setPage] = useState(0)

    const botFetch = () => {
        if (page !== null) {
            // For pagination
            setTimeout(() => {
                getRebelBots(account, page).then((res) => {
                    const {botsData, hasNext} = res.data;
                    if (botsData) {
                        setBots(prev => [...prev, ...botsData])
                        setFetched(true)
                    }

                    hasNext ? setPage(page => page + 1) : setPage(null)
                })
            }, 1000)
        }
    }

    useEffect(() => {
        if (refresh === null) {
            // For refresh after saving edit
            return getRebelBots(account, 0).then((res) => {
                const {botsData, hasNext} = res.data;
                if (botsData) {
                    setBots(botsData)
                    setFetched(true)
                }
                hasNext ? setPage(0) : setPage(null)
            })
        }
        botFetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh])

    const botsBlockStyle = useMemo(() => {
        return {
            background: !bots.length && width < 1024 && 'none',
            marginTop: width <= 1024 ? !bots.length ? '195px' : '121.04px' : '41.77px',
            // opacity: !bots.length ? 0.85 : 1
        }
    }, [bots, width])

    return (
        <>
            <div className={s.botsTitleBlock}>
                <h3 className={'web27'}> {width > 1024
                    ? <><span className='yellow'>your </span> rebel bots</>
                    : width > 576
                        ? <><span className='yellow'>your rebel </span> <br/> bots</>
                        : <><span className='yellow'>your  </span>rebel <br/> bots</>
                }
                </h3>
            </div>
            <div className={s.botsBlock} style={botsBlockStyle}>
                <div className={s.botsInlineBlock}>
                    <div className={s.landsWrapper}>
                        {
                            bots.length > 0 ?
                                <AssetList refresher={bots.length > 4 ? isRefresh : console.log} account={account} assets={bots} type={'bots'}/>
                                :
                                !fetched ? LoadingElement
                                    :
                                    <>
                                        <div className={s.bots__empty}>
                                            <img
                                                src={(width > 1024 || width < 576) ? EmptyLandsImage : EmptyLandsImageTablet}
                                                alt="empty"/>
                                            <div className={s.bots__emptyText}>
                                                <p className='web48'>
                                                    It looks like you
                                                    don’t have any Rebel Bots.
                                                </p>
                                                <p className={`${s.botsContentText} web48`}>
                                                    <b>You can get them here:</b>
                                                </p>
                                                <div className={s.bot__buttonContainer}>
                                                    <a
                                                        className={`button-type1 ${s.openSeaBtn}`}
                                                        href={`https://opensea.io/collection/rebelbots`}
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                        data-regular="Buy Now"
                                                    >
                                                        <span className="button-type1-before"/>
                                                        <span className="label">Buy Rebel Bot</span>
                                                        <span className="button-type1-after"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                        }
                    </div>
                </div>
            </div>
            {/*{*/}
            {/*    bots.length > 0 &&*/}
            {/*    <LightTooltip title={'Available upon sellout of initial Land sale.'}>*/}
            {/*        <div className={s.botsClaimBotsBtnBlock}>*/}
            {/*            <OutlineButton text={'Claim all Revenue Shares'}*/}
            {/*                           variant={'claim'}*/}
            {/*                           disabled={true}*/}
            {/*                // disabled={bots.length >= 0}*/}
            {/*                           onClick={console.log}/>*/}
            {/*        </div>*/}
            {/*    </LightTooltip>*/}
            {/*}*/}
        </>
    )
}

const LoadingElement = (
    <div className={`${s.bots__empty} ${s.loadingBlock}`}>
        <div className={`${s.gradient} ${s.loadingTextBlock}`}/>
        <div className={`${s.gradient} ${s.loadingTextBlock}`}/>
        <div className={`${s.gradient} ${s.loadingTextBlock}`}/>
        <div className={`${s.gradient} ${s.loadingTextBlock}`}/>
    </div>
)

// const LightTooltip = tooltipFactory(false, {
//     color: 'rgba(0, 0, 0, 0.87)',
//     backgroundColor: 'white',
// })

export default BotNftList;