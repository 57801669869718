import React from 'react';
import s from './styles.module.scss'
import bannerImage from '../../../../Image/playDominateBanner.png'
import {useDimensions} from "../../../../hooks/useDimensions";

const PlayDominate = () => {
  const {width} = useDimensions()

  return (
    <section className={s.PlayDominate}>
      <div className={s.topWrapper}>
        {
          width > 1024 && <img src={bannerImage} alt=""/>
        }
        <div>
          <h3>dominate</h3>
          {
            width < 1024 && <img src={bannerImage} alt=""/>
          }
          <p>
            Dominate the Xoil Wars and launch your ultimate battle plan to claim victory and Xoil riches.
          </p>
        </div>


      </div>
      <svg className="svg" style={{height: 0}}>
        <clipPath id="my-clip-path" clipPathUnits="objectBoundingBox">
          <path
            d="M0.394,0.004 H0.939 L1,0.278 V1 H0.634 H0.069 L0.002,0.734 V0.004 H0.183 L0.205,0.037 H0.377 L0.394,0.004"></path>
        </clipPath>
      </svg>
      <div className={s.buttons}>
        <a href="/wiki">Wiki</a>
        <a href="/cardsExplorer">SPARX</a>
        <a href="https://www.youtube.com/@REBELBOTS" target={'_blank'} rel='noreferrer'>YouTube</a>
      </div>
    </section>
  );
}


export default PlayDominate;