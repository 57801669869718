import React, {useEffect, useState} from 'react';
import s from './styles.module.scss'
import html2canvas from "html2canvas";
import ExplorerModalWrapper from "../ExplorerModalWrapper/ExplorerModalWrapper";
import Loader from "../../ui/Loader/Loader";
import {useDimensions} from "../../../hooks/useDimensions";

const SaveBotModal = ({handleClose, open}) => {
  const [botImage, setBotImage] = useState('')
  const [builderImage, setBuilderImage] = useState('')
  const [showBot, setShowBot] = useState(false)
  const {width} = useDimensions()

  useEffect(() => {
    const html2canvasConfig = {
      letterRendering: 1,
      allowTaint: true,
      useCORS: true,
      backgroundColor: '#1E1E1E',
      windowHeight: 1080,
      windowWidth: 1620,
      ignoreElements: function( element ) {
        if( 'botBuilderButton' === element.id || element.classList.contains( 'closeCard' )) {
          return true;
        }
      },
      onclone: function (clonedDoc) {
        // clonedDoc.querySelector('#builderCards > div').style.height = '400px'
      }
    }

    const saveImg = () => {
      html2canvas(document.querySelector('#bot'), {
        letterRendering: 1,
        allowTaint: true,
        useCORS: true,
        backgroundColor: '#1E1E1E',
        windowHeight: 1080,
        windowWidth: 1920,
        width: 500,
        height: 500,
        ignoreElements: function( element ) {
          if( 'botBuilderButton' === element.id || element.classList.contains( 'closeCard' )) {
            return true;
          }
        },
      } )
        .then((canvas) => {
          canvas.style.display = 'none';
          let image = canvas.toDataURL("image/jpeg")
          if(image){

            setBotImage(image)
          }
        })

      html2canvas(document.querySelector('#builderWrapper'), html2canvasConfig)
        .then((canvas) => {
          canvas.style.display = 'none';
          let image = canvas.toDataURL("image/jpeg")
          if(image && open){
            setBuilderImage(image)
            setTimeout(() => setShowBot(true), 4000)
          }
        })
    }

    saveImg()
  }, [open])

  const save = (e, image) => {
    e.stopPropagation()
    let a = document.createElement("a");
    a.setAttribute('download', 'bot.jpeg');
    a.setAttribute('href', image);
    a.click();
  }

  return (
    <ExplorerModalWrapper open={open} handleClose={handleClose} title={'save this bot'}>
      <div className={s.content}>
        <div>
          {
            (showBot && builderImage) && <img src={builderImage} className={s.bot__team} alt="bot" />
          }
          {
            (showBot && botImage) &&
            <button
              className={`${s.button} ${width > 1018 ? 'button-type1 black' : ''}`}
              onClick={(e) => save(e, builderImage)}
            >
              {width > 1018 && <span className="button-type1-before" />}
              <span className="label">Save .jpeg</span>
              {width > 1018 && <span className="button-type1-after" />}
            </button>
          }
        </div>
        <div className={s.bot__soloWrapper}>
          {
            (showBot && botImage) && <img src={botImage} className={s.bot__solo} alt="bot" />
          }
          {
            (showBot && botImage) &&
            <button
              className={`${s.button} ${width > 1018 ? 'button-type1 black' : ''}`}
              onClick={(e) => save(e, botImage)}
            >
              {width > 1018 && <span className="button-type1-before" />}
              <span className="label">Save .jpeg</span>
              {width > 1018 && <span className="button-type1-after" />}
            </button>
          }
        </div>
        {
          !showBot &&
            <Loader />
        }

      </div>
    </ExplorerModalWrapper>
  );
}

export default SaveBotModal;