import React, {useEffect, useRef, useState} from 'react';
import s from './styles.module.scss'
import EmailIcon from "../../../Image/email_icon.svg";
import WebsiteIcon from "../../../Image/website_icon.svg";
import TwitterIcon from "../../../Image/twitter_icon.svg";
import TelegramIcon from "../../../Image/telegram_icon.svg";
import DiscordIcon from "../../../Image/discord_icon.svg";
import FacebookIcon from "../../../Image/facebook_icon.svg";
import Star from "../../../Image/redStart.png";
import {colorizedSizeGuild, tooltipFactory} from "../../../utils/asset.utils";
import gamingGuilds from '../../../Image/gamingGuilds.png'
import EmptyIcon from "../../../Image/emty.svg";

const GuildRow = ({logo, size, name, languages, telegram, discord, fb, twitter, website, email, id, featured}) => {

    let languagesString = Array.isArray(languages) ? languages.join(', ').substring(0, 27) : '-'
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const formattingName = name?.length > 20 ? name.substring(0, 20) + '...' : name

    const tooltipHandler = () => {
      setTooltipIsOpen(prevState => !prevState)
    }

    const rootEl = useRef(null);

    useEffect(() => {
      const onClick = e => rootEl.current?.contains(e.target) || setTooltipIsOpen(false)
      document.addEventListener('click', onClick);
      return () => document.removeEventListener('click', onClick);
    }, []);


  return (
        <tr key={name} className={s.table__row}>
            <td className={s.table__row__guild}>
                <p className={s.table__row__guild__rating}>{id + 1} </p>
                <img className={s.table__row__guild__star} src={featured ? Star : EmptyIcon } alt="featured"/>
                <img className={logo ? s.table__row__guild__logo : s.table__row__guild__logo__empty } alt='logo' src={logo ?? gamingGuilds}/>
                <p className={s.table__row__guild__name}>{formattingName}</p>
            </td>
            <td>{colorizedSizeGuild(size)}</td>
            <td className={s.table__row__language}>
              {languagesString}
              {languagesString.length >= 27 &&
                <LanguageTooltip
                  title={languages.join(', ')}
                  open={tooltipIsOpen}
                  type={'guildInfo'}
                >
                  <span ref={rootEl} onClick={ tooltipHandler } style={{cursor: 'Pointer'}}>...</span>
                </LanguageTooltip>}
            </td>
            <td className={s.table__row__socialMedias}>
              {email && <a href={'mailto:' + email}><img src={EmailIcon} alt='Email' /></a>}
              {website && <a rel="noreferrer" target='_blank' href={website}><img src={WebsiteIcon} alt='Website' /></a>}
              {twitter && <a rel="noreferrer" target='_blank' href={twitter}><img src={TwitterIcon} alt='Twitter' /></a>}
              {telegram && <a rel="noreferrer" target='_blank' href={telegram}><img src={TelegramIcon} alt='Telegram' /></a>}
              {discord && <a rel="noreferrer" target='_blank' href={discord}><img src={DiscordIcon} alt='Discord' /></a>}
              {fb && <a rel="noreferrer" target='_blank' href={fb}><img src={FacebookIcon} alt='Facebook' /></a>}
            </td>
        </tr>
    )
}

const LanguageTooltip = tooltipFactory(true, {
  backgroundColor: '#FFF',
  color: '#000000',
  maxWidth: 'none',
  fontSize: 14,
  padding: '8px 15px',
  top: '-5px',
  fontWeight: 600,
  left: 25
}, {
  color: '#ffffff',
  left: '-17px !important',
}, 'bottom-end')

export default GuildRow;