import React from "react";

import Header from "components/Header";
import Footer from "components/Footer";

const Privacy = () => {
  return (
    <div>
      <div className="wrapper">
        <Header />
        <main className="faq">
          <div className="privacy-container">
            <div className="privacy-title">
              <h1>Privacy policy</h1>
            </div>
            <div className="faq-list">
              <div className="faq-list__item js-faq-item">
                <div className="faq-list__item-top js-faq-item-top">
                  <div className="privacy-item__title">1. GENERAL</div>
                </div>
                <div className="privacy__item js-faq-item-answer">
                  <p>
                    1.1. Xoilium Limited (“<b>Xoilium</b>”) operates a website
                    located at:{" "}
                    <a
                      href="https://www.xoilium.com"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      www.xoilium.com
                    </a>{" "} and {" "}
                    <a
                        href="https://www.epicwar.gg"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                      www.epicwar.gg
                    </a>{" "}
                    (the  “<b>Websites</b>”)  which
                    may display and/or allow to join or purchase, from time to time,
                    certain products and/or services, including without limiting, the
                    participation in various games such as the “Rebel Bots Xoil Wars”
                    and “Rebel Bots Epic War” (the “<b>Games</b>”; and together with the
                    Websites, the “<b>Services</b>”). Xoilium takes its users' privacy very
                    seriously and is committed to protecting their information in full
                    compliance with all applicable laws and regulations and to
                    maintaining the confidence and trust of its users.
                  </p>
                  <p>
                    1.2. This Privacy Policy has been created by us in order to inform
                    you about how Xoilium collects, store, process and otherwise use
                    and share the information you provide us from time to time in
                    connection with your use of any Services. This Privacy Policy
                    governs the manner in which Xoilium and other companies in the
                    Xoilium group of companies may collect, store, process and otherwise
                    use and share such information and unless otherwise specifically set
                    forth in this Privacy Policy, any reference to the rights of Xoilium
                    shall also include such companies.{" "}
                  </p>
                  <p>
                    1.3. By using the Websites and/or the Services, you agree to the collection,
                    disclosure, storage, processing and usage of your information as outlined
                    in this Privacy Policy. If you do not agree to any section in this
                    Privacy Policy, please stop using the Websites and/or any Services immediately.
                  </p>
                  <p>
                    1.4. Xoilium may change this Privacy Policy from time to time without
                    notice to you and you are encouraged to review this Privacy Policy
                    frequently in order to make sure that you are familiar with its most
                    updated version. Anything to the contrary above notwithstanding,
                    your continued use of the Websites and/or Services indicates your consent
                    to be bound by the most updated version of this Privacy Policy.
                  </p>
                  <p>
                    1.5. Please note that Xoilium will not share any of your
                    personal information or other identifiable information
                    protected under applicable privacy laws and regulations,
                    including, without limitations, the European Union's
                    GDPR (”<b>GDPR</b>”) (the “<b>Personal Information</b>”) with third
                    parties for direct marketing purposes unless you
                    affirmatively consent to such sharing of information,
                    generally by "opting in" to such option.
                  </p>
                  <p>
                    1.6. Among others, you may opt-in by providing us with your
                    email address and/or other information including other
                    communications methods (e.g. mobile number) for the purpose
                    of receiving commercial offers or other notifications and
                    updates from time to time.
                  </p>
                  <h3 className="privacy-item__title">
                    2. COLLECTION OF INFORMATION AND TYPES OF INFORMATION
                    COLLECTED
                  </h3>
                  <p>
                    2.1. Xoilium only collects Personal Information only to the
                    extent it is necessary to fulfil any of the Website's or Services'
                    functions and activities. Among others, Xoilium will receive Personal
                    Information when you register and open an account in order to participate
                    in any Games. In addition, Xoilium may collect Personal Information
                    when you interact with any other users as part of your participation
                    in any Games or use of any of the Services, including if you contact
                    us for support.
                  </p>
                  <p>
                    2.2. If you use any Services through a third-party platform
                    (such as Google), Xoilium may receive certain Personal
                    Information about you from such third-party platform.
                  </p>
                  <p>
                    2.3. As an example only, Xoilium may collect the following
                    Personal Information from you directly
                    (e.g. when you provide such information in order to open an account with us)
                    or from third parties: (i) your personal picture;
                    (ii) your username and your password; (iii) your user ID number;
                    (iv) your e-mail and other contact details you provided to us to the applicable third-party;
                    (v) your contact list; (vi) your location data;
                    (vii) your gender; and (viii) your age and birth date.
                  </p>
                  <p>
                    2.4. Xoilium may also collect the above Personal Information
                    (and additional Personal Information including without
                    limitations, copies of passport or other identification
                    documents, utility bills, letters and other documentation)
                    in case Xoilium is required by applicable law to perform
                    KYC/AML procedures, including without limitations, as a
                    condition to a user redeeming any prizes on Xoilium or
                    performing any other actions from time to time.
                  </p>
                  <p>
                    2.5. Xoilium may also collect certain information about your
                    activities on or through the connected third-party services
                    or through your use of the Service or and other publicly
                    available information about you.
                  </p>
                  <p>
                    2.6. Finally, from time to time Xoilium may use various
                    tracking technologies to collect certain kinds of technical
                    information, including without limitations: (i) the type of
                    mobile or other device you are using; (ii) the type of your
                    operating system, its version and platform type; (iii) your
                    applicable mobile device’s identifiers; (iv) your browser
                    type; (v) domain names, referring pages, pages viewed and
                    other browsing information, etc.
                  </p>
                  <h3 className="privacy-item__title">
                    3. TRANSFER OF INFORMATION INTERNATIONALLY
                  </h3>
                  <p>
                    3.1. When you use our Services, your Personal Information
                    may be sent to other countries. Xoilium may store Personal
                    Information about users on servers located in other
                    countries, including countries in which the level of
                    protection of Personal Information is not as strict as in
                    your own country. When Xoilium transfers your Personal
                    Information internationally Xoilium will take reasonable
                    steps to ensure that your Personal Information is treated
                    securely, lawfully and in the manner Xoilium describes here.
                    Please note that laws vary from jurisdiction to
                    jurisdiction, and so the privacy laws applicable to the
                    places where your Personal Information is transferred to or
                    stored, used or processed in, may be different from the
                    privacy laws applicable to the place where you are resident.
                    If you are a resident in a jurisdiction, where transfer of
                    your Personal Information to another jurisdiction requires
                    your consent, then you provide us your express and
                    unambiguous consent to such transfer for us to provide you
                    with our Services.
                  </p>
                  <h3 className="privacy-item__title">4. COOKIES</h3>
                  <p>
                    4.1. A cookie is a small data file that is transferred to
                    your computer’s hard disk or your mobile device for
                    record-keeping purposes. The Service may send cookies to a
                    computer or a mobile device when you access the Websites or
                    the Services.
                  </p>
                  <p>
                    4.2. Information contained in a cookie may be linked to
                    personal information for purposes such as improving the
                    quality of the Websites and Services, tailoring
                    recommendations to interests, and making the Websites and the
                    Services easier to use. Third party advertisers on the
                    Websites and the Services may also use, place or read cookies
                    or other technologies as described below on your browser,
                    and those practices are subject to their own policies.
                  </p>
                  <p>
                    4.3. You may disable cookies at any time by changing your
                    web browser’s options to stop accepting cookies, to prompt
                    you before accepting a cookie from the websites you visit or
                    limit the type of cookies you allow, however if you do not
                    accept cookies you may not be able to use all features of
                    the Websites and the Services.
                  </p>
                  <p>
                    4.4. Xoilium and our third-party service providers may also
                    employ other similar technologies including, without
                    limitations: <br />
                    4.4.1. web beacons, which allow us to know if a certain page
                    was visited or whether an e-mail was opened; <br />
                    4.4.2. tracking pixels, which allow us or our advertising
                    partners to advertise more efficiently and effectively by
                    excluding our users from certain promotional messages,
                    identifying the source of a new installation or delivering
                    ads to you on other websites; and <br />
                    4.4.3. local shared objects which help us to reduce fraud,
                    remember your preferences and speed up load times.
                  </p>
                  <h3 className="privacy-item__title">
                    5. HOW WE USE AND SHARE YOUR INFORMATION
                  </h3>
                  <p>
                    5.1. Xoilium may collect and store your information for the
                    following purposes: (i) registration and enabling the
                    Services, including opening accounts; (ii) improving,
                    optimizing and personalizing Websites and the Services;
                    (iii) provide technical support; (iv) protect the safety
                    and rights of users; (v) protect our rights and enforce this
                    Privacy Policy and our Terms; (vi) prevent fraud or potentially
                    illegal activities; (vii) managing and delivering contextual
                    and behavioral advertising (subject to your consent as set
                    forth hereunder); (viii) notify users of new products or services,
                    updates or other commercial or non-commercial offers from us or
                    from our partners; or (ix) comply with our legal obligations
                    or resolve legal disputes.
                  </p>
                  <h3 className="privacy-item__title">
                    6. MARKETING AND ADVERTISING PRACTICES
                  </h3>
                  <p>
                    6.1. Xoilium may contact you in different ways for marketing
                    and advertising purposes provided that you agreed to receive
                    such messages from us and we will provide you the option to
                    indicate that you do not want to continue to receive our
                    marketing and advertising messages (opting out). Among
                    others, we may tell you about new features or services we
                    think you would be interested in or to show you how to use a
                    particular feature.
                  </p>
                  <p>
                    6.2. Xoilium may utilize and engage with advertising
                    platforms and services providers to help deliver relevant
                    advertising and marketing proposals to you. Xoilium and such
                    third parties may use your Personal Information to provide
                    interest-based advertising and marketing proposals to you.
                    You can "opt out" of interest-based advertising and
                    marketing proposals by clicking relevant links inside the
                    messages themselves or contacting us at support@xoilium.com.
                  </p>
                  <p>
                    6.3. Xoilium does not actively share Personal Information
                    with third-parties for their direct marketing purposes
                    unless you give us your consent.
                  </p>
                  <p>
                    6.4. Depending on your mobile device and applicable
                    operating system, you may be able to reset your identifiers
                    to limit or prevent such information collection, including
                    through the privacy settings on your mobile device.
                  </p>
                  <h3 className="privacy-item__title">
                    7. OTHER THIRD-PARTY SERVICE PROVIDERS
                  </h3>
                  <p>
                    7.1. Xoilium may share information with third-party
                    suppliers and partners to perform services on our behalf,
                    like data analytic services, e-mail delivery services,
                    hosting services, customer support and other services,
                    wallet services providers and to help us in our marketing
                    efforts. Xoilium requires such third-parties to maintain the
                    confidentiality of the information Xoilium shares with them
                    and not to use your information for anything other than to
                    provide services on our behalf.
                  </p>
                  <h3 className="privacy-item__title">8. SALE OR MERGER</h3>
                  <p>
                    8.1. In the event that Xoilium consummates a merger or an
                    acquisition by another entity or otherwise perform any other
                    kind of a change of control transaction, Xoilium may
                    disclose or transfer all of your information, including your
                    Personal Information, to the successor company. Such
                    transferred information will be handled in according to the
                    acquiring entity's privacy policies however Xoilium will
                    make its best efforts to make sure that your privacy rights
                    are protected.
                  </p>
                  <h3 className="privacy-item__title">
                    9. OTHER CASES IN WHICH WE MAY SHARE YOUR INFORMATION
                  </h3>
                  <p>
                    9.1. Xoilium may also share your information: (i) in
                    response to lawful requests by any authorized governmental
                    and other authorities, including to meet law enforcement
                    requirements, or legal process (for example, a court order,
                    search warrant or subpoena); (ii) to satisfy any applicable
                    laws or regulations; (iii) where Xoilium believes that the
                    Websites and/or the Services is being used in breach of our
                    Terms, for committing or assisting a crime, including to
                    report such criminal or otherwise illegal activity or to
                    share information with other third parties for the purposes
                    of fraud protection, credit risk reduction, and other
                    security precautions; (iv) when Xoilium believes that there
                    is an emergency that poses a threat to the health and/or
                    safety of any user or any other person or the public
                    generally; and (v) to protect the rights, assets,
                    reputation, etc. of Xoilium and other applicable third
                    parties, including to enforce our Terms.
                  </p>
                  <h3 className="privacy-item__title">
                    10. ACCESSING AND MANAGING YOUR INFORMATION – YOUR RIGHTS
                  </h3>
                  <p>
                    10.1. Under applicable data protection laws and regulations,
                    including the GDPR, you have the right to: <br />
                    10.1.1. access your Personal Information and receive a copy
                    thereof, i.e. obtain information on whether Xoilium
                    processes your Personal Information and specific information
                    about any such processing; <br />
                    10.1.2. rectify (amend) your Personal Information, i.e.
                    request to amend inaccurate Personal Information; <br />
                    10.1.3. erase your Personal Information, provided that one
                    of relevant grounds for such actions apply (and that no
                    exclusions under applicable law apply); <br />
                    10.1.4. restrict the processing of your Personal
                    Information, provided that one of relevant grounds for such
                    actions apply (and that no exclusions under applicable law
                    apply); <br />
                    10.1.5. complain to a supervisory authority(ies); <br />
                    10.1.6. withdraw your consent. <br />
                    10.2. You may exercise any of your rights in relation to
                    your Personal Information by written notice to us at:
                    gdpr@xoilium.com.
                  </p>
                  <h3 className="privacy-item__title">
                    11. HOW WE PROTECT YOUR INFORMATION
                  </h3>
                  <p>
                    11.1. Xoilium has made reasonable technical and
                    organizational measures designed to secure your Personal
                    Information from accidental loss and from unauthorized
                    access. While Xoilium takes reasonable precautions against
                    possible security breaches of our Services, no Websites or
                    Internet transmission is completely secure, and Xoilium
                    cannot guarantee that unauthorized access, hacking, data
                    loss, or other breaches will never occur.
                  </p>
                  <h3 className="privacy-item__title">
                    12. HOW LONG WE KEEP YOUR INFORMATION
                  </h3>
                  <p>
                    12.1. How long Xoilium retains your Personal Information
                    depends on why Xoilium collected it and how Xoilium uses it.
                    Xoilium will not retain your Personal Information for longer
                    than is necessary for our business purposes or for legal
                    requirements. For instance, Xoilium may retain all or part
                    of your Personal Information for a few years after you
                    stopped using the Websites or any Services if this is
                    necessary to meet our legal obligations or to exercise legal
                    rights.
                  </p>
                  <p>
                    12.2. To determine the appropriate retention period for
                    Personal Information, Xoilium considers the amount, nature,
                    and sensitivity of the relevant Personal Information, the
                    potential risk of harm from unauthorized use or disclosure
                    of such Personal Information, the purposes for which Xoilium
                    processes your Personal Information and whether Xoilium can
                    achieve those purposes through other means, and the
                    applicable legal requirements.
                  </p>
                  <p>
                    12.3. In some circumstances you can ask us to delete your
                    Personal Information. In some circumstances Xoilium may
                    anonymize your Personal Information (so that it can no
                    longer be associated with you) for research or statistical
                    purposes in which case Xoilium may use this information
                    indefinitely without further notice to you.
                  </p>
                  <h3 className="privacy-item__title">
                    13. CHANGES TO OUR PRIVACY POLICY
                  </h3>
                  <p>
                    13.1. If Xoilium decides to make material Changes to this
                    Privacy Policy, Xoilium will update you and other users by
                    placing a notice on the Websites or by sending you a notice
                    to the e-mail address Xoilium has on file for you prior to
                    the change becoming effective. Xoilium may supplement this
                    process by placing notices on blogs, social networks, etc.
                    You should periodically check the Websites for updates.
                  </p>
                  <p>
                    13.2. For any questions, comments or concerns about Xoilium'
                    Privacy Policy or any other issue (including without
                    limitations in order to exercise any of your rights with
                    respect to the managing of your Personal Information) please
                    contact us at: contact@xoilium.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Privacy;
